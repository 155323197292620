<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Create Vendor Lot</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendor_lot.label}}{{fields.vendor_lot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendor_lot.label}}">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vendor_lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendor_lot.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_lot.control.placeholder}}"
                                [ngStyle]="fields.vendor_lot.control.styles.style"
                                [ngClass]="fields.vendor_lot.control.styles.classes"> 
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vat" *ngIf="!fields.vat.hidden" 
                            class="field-container standard {{fields.vat.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vat.styles.style"
                            [ngClass]="fields.vat.styles.classes">
                        <div class="label-container"
                              title="{{fields.vat.label}}{{fields.vat.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vat.label}}">{{fields.vat.label}}<span *ngIf="fields.vat.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vat"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vat.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vat.control.placeholder}}"
                                [ngStyle]="fields.vat.control.styles.style"
                                [ngClass]="fields.vat.control.styles.classes"> 
                        <ng-container *ngIf="fields.vat.invalid">
                          <ng-container *ngFor="let error of fields.vat.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-manufacture_date" *ngIf="!fields.manufacture_date.hidden" 
                            class="field-container standard {{fields.manufacture_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.manufacture_date.styles.style"
                            [ngClass]="fields.manufacture_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.manufacture_date.label}}{{fields.manufacture_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.manufacture_date.label}}">{{fields.manufacture_date.label}}<span *ngIf="fields.manufacture_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="manufacture_date"
                            [format]="fields.manufacture_date.control.format"
                            [mode]="fields.manufacture_date.control.mode"
                            [ngStyle]="fields.manufacture_date.control.styles.style"
                            [ngClass]="fields.manufacture_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.manufacture_date.invalid">
                          <ng-container *ngFor="let error of fields.manufacture_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expiration_date" *ngIf="!fields.expiration_date.hidden" 
                            class="field-container standard {{fields.expiration_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expiration_date.styles.style"
                            [ngClass]="fields.expiration_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.expiration_date.label}}{{fields.expiration_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.expiration_date.label}}">{{fields.expiration_date.label}}<span *ngIf="fields.expiration_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expiration_date"
                            [format]="fields.expiration_date.control.format"
                            [mode]="fields.expiration_date.control.mode"
                            [ngStyle]="fields.expiration_date.control.styles.style"
                            [ngClass]="fields.expiration_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.expiration_date.invalid">
                          <ng-container *ngFor="let error of fields.expiration_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container double {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                              title="{{fields.description.label}}{{fields.description.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.description.label}}">{{fields.description.label}}<span *ngIf="fields.description.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>