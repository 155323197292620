import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_dock_appointment_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { dockAppointmentId: number }): 
  Promise<{ result: { Id?: number, AssignedOn?: string, CancelledOn?: string, CheckedInOn?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, EmptyTrailerTemp?: number, EstimatedPallets?: number, EstimatedQuantity?: number, FullTrailerTemp?: number, InProcessOn?: string, linkedDockAppointmentId?: number, LoadConfiguration?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledDeparture?: string, SealNumber?: string, TrailerNumber?: string, TypeId?: number, VehicleLicense?: string, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string, WarehouseId?: number }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, Name?: string }, ScheduledProject?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Items?: { Id?: number, ItemEntityId?: number, ItemEntityType?: string }[], AssignedLocation?: { Id?: number, Name?: string, WarehouseId?: number }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, convertedCancelledOn?: { id?: number, convertedDate?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dockAppointmentId)) {
      missingRequiredInParams.push('\'dockAppointmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointment_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
