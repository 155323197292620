import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_transfer_creation_form',
  templateUrl: './FootPrintManager.inventory_transfer_creation_form.component.html'
})
export class FootPrintManager_inventory_transfer_creation_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    source_owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    source_project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    target_owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    target_project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Code', false)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    source_owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Source owner', false)
,
    source_project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Source project', true)
,
    target_owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Target owner', false)
,
    target_project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Target project', true)
,
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
  source: new FieldsetModel('Source', true, false, true),
  target: new FieldsetModel('Target', true, false, true),
};

  //#region fields inParams
  get $fields_source_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_source_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_project.control.value;
    
    return expr;
  }

  get $fields_source_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_source_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_owner.control.value;
    
    return expr;
  }

  cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds: number[];
  get $fields_source_project_selector_inParams_excludedProjectIds(): number[] {
    const $form = this;
    const $utils = this.utils;
    const expr = $utils.isDefined($form.fields.target_project.control.value) ? [$form.fields.target_project.control.value] : null;
    
    if(!isEqual(this.cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds, expr)) {
      this.cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds = expr;
    }
    return this.cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds;
  }

  get $fields_target_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_target_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.target_project.control.value;
    
    return expr;
  }

  get $fields_target_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_target_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.target_owner.control.value;
    
    return expr;
  }

  cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds: number[];
  get $fields_target_project_selector_inParams_excludedProjectIds(): number[] {
    const $form = this;
    const $utils = this.utils;
    const expr = $utils.isDefined($form.fields.source_project.control.value) ? [$form.fields.source_project.control.value] : null;
    
    if(!isEqual(this.cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds, expr)) {
      this.cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds = expr;
    }
    return this.cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    source_owner: this.fields.source_owner.control.valueChanges
    ,
    source_project: this.fields.source_project.control.valueChanges
    ,
    target_owner: this.fields.target_owner.control.valueChanges
    ,
    target_project: this.fields.target_project.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create inventory transfer';
  
    const $form = this;
    const $utils = this.utils;

    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_value_changed();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_value_changed();
      });
    this.$formGroupFieldValidationObservables
      .source_owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .source_project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_project_changed();
      });
    this.$formGroupFieldValidationObservables
      .target_owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .target_project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_project_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_value_changed(event = null) {
    return this.on_value_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_value_changedInternal(
    $form: FootPrintManager_inventory_transfer_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let hasAllRequired = true;
  
  for (let key in $form.fields) {
      if ($form.fields[key].required && !$utils.isDefined($form.fields[key].control.value)) {
          hasAllRequired = false;
      }
  }
  
  if (hasAllRequired) {
      if ($form.fields.source_project.control.value !== $form.fields.target_project.control.value) {
          let result = (await $flows.InventoryTransfers.create_inventory_transfer_flow({
              lookupCode: $form.fields.lookupcode.control.value,
              sourceProjectId: $form.fields.source_project.control.value,
              targetProjectId: $form.fields.target_project.control.value
          }));
  
          if ($utils.isDefined(result.reasons)) {
              await $shell.InventoryTransfers.openErrorDialog('Error creating inventory transfer', 'Could not create inventory transfer: ' + (await $flows.Utilities.grammar_format_string_array_flow({values: result.reasons})).formattedValue);
          }
          else {
              $form.close();
              
              // Open new inventory transfer
              $shell.FootPrintManager.openinventory_transfer_editor({ inventoryTransferId: result.inventoryTransferId, warehouseId: $form.fields.warehouse.control.value });
          }
      }
  }
  }
  on_source_project_changed(event = null) {
    return this.on_source_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_project_changedInternal(
    $form: FootPrintManager_inventory_transfer_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($form.fields.source_owner.control.value) && $utils.isDefined($form.fields.source_project.control.value))  {
      let project = (await $datasources.Owners.ds_get_project_by_projectId.getList({ $top: 1, projectId: $form.fields.source_project.control.value})).result[0];
  
      $form.fields.source_owner.control.value = project.OwnerId;
  }
  
  await $form.on_value_changed();
  }
  on_target_project_changed(event = null) {
    return this.on_target_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_project_changedInternal(
    $form: FootPrintManager_inventory_transfer_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($form.fields.target_owner.control.value) && $utils.isDefined($form.fields.target_project.control.value))  {
      let project = (await $datasources.Owners.ds_get_project_by_projectId.getList({ $top: 1, projectId: $form.fields.target_project.control.value})).result[0];
  
      $form.fields.target_owner.control.value = project.OwnerId;
  }
  
  await $form.on_value_changed();
  }
  on_source_owner_changed(event = null) {
    return this.on_source_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_owner_changedInternal(
    $form: FootPrintManager_inventory_transfer_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.fields.source_project.control.value = null;
  }
  on_target_owner_changed(event = null) {
    return this.on_target_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_owner_changedInternal(
    $form: FootPrintManager_inventory_transfer_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.fields.target_project.control.value = null;
  }
  //#endregion private flows
}
