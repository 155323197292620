import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_custom_format_order_lookupcode_flow_multipleService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { context: string, orders?: { orderId?: number, additionalData?: any }[] }): Promise<{ errors?: string[], orders?: { orderId?: number, formattedLookupCode?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.context)) {
      missingRequiredInParams.push('\'context\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/functions/custom_format_order_lookupcode_flow_multiple`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

