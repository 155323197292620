import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { WarehouseTransfers_ShellService, EModalSize, EToasterType, EToasterPosition } from './WarehouseTransfers.shell.service';
import { WarehouseTransfers_OperationService } from './WarehouseTransfers.operation.service';
import { WarehouseTransfers_DatasourceService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_FlowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_ReportService } from './WarehouseTransfers.report.index';
import { WarehouseTransfers_LocalizationService } from './WarehouseTransfers.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './WarehouseTransfers.frontend.types'
import { $frontendTypes as $types} from './WarehouseTransfers.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'WarehouseTransfers-outbound_order_total_picked_units_widget',
  templateUrl: './WarehouseTransfers.outbound_order_total_picked_units_widget.component.html'
})
export class WarehouseTransfers_outbound_order_total_picked_units_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { TotalPackagedAmount?: number, ShipmentLine?: { OrderId?: number, ShipmentId?: number } };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: WarehouseTransfers_ShellService,
private datasources: WarehouseTransfers_DatasourceService,
private flows: WarehouseTransfers_FlowService,
private reports: WarehouseTransfers_ReportService,
private localization: WarehouseTransfers_LocalizationService,
private operations: WarehouseTransfers_OperationService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $widget.inParams.orderId ,
      shipmentId:  $widget.inParams.shipmentId 
    };

    const data = await this.datasources.WarehouseTransfers.ds_outbound_order_total_picked_units_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $utils.isDefined($widget.entity.TotalPackagedAmount) ? $widget.entity.TotalPackagedAmount : 0;

    
    this.originalValue = $utils.isDefined($widget.entity.TotalPackagedAmount) ? $widget.entity.TotalPackagedAmount : 0;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
