import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ds_get_serialNumber_validationService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serial_lookupCode: string, material_lookupCode: string }): 
  Promise<{ result: { Id?: number, Archived?: boolean, LookupCode?: string, Lot?: { Id?: number, Material?: { Id?: number, LookupCode?: string, Status?: { Name?: string } }, Status?: { Name?: string } }, Status?: { Name?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serial_lookupCode)) {
      missingRequiredInParams.push('\'serial_lookupCode\'');
    }
    if (isNil(inParams.material_lookupCode)) {
      missingRequiredInParams.push('\'material_lookupCode\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_get_serialNumber_validation/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
