import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_inventoryCount_mainService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { JobId?: string, ItemNo?: string, Location?: string, InventoryCountResult?: $frontendTypes.LocusRobotics.InventoryCountResult }): Promise<{ InventoryResultsResponse?: any, JobId?: string, ItemNo?: string, Location?: string, Result?: boolean, ResultReason?: string, CountDetails?: { UOMLabel?: string, Quantity?: number }[] }> 
  {
    let url = `${environment.backendUrl}api/LocusRobotics/functions/inventoryCount_main`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

