<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="search" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_search_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-appointment_type" *ngIf="!fields.appointment_type.hidden" 
                            class="field-container standard {{fields.appointment_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.appointment_type.styles.style"
                            [ngClass]="fields.appointment_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.appointment_type.label}}{{fields.appointment_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.appointment_type.label}}">{{fields.appointment_type.label}}<span *ngIf="fields.appointment_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <DockAppointments-dock_appointment_types_dd_single 
                            data-cy="selector"
                            [type]="fields.appointment_type.control.type"
                            formControlName="appointment_type"
                            (displayTextChange)="fields.appointment_type.control.displayText=$event"
                            [placeholder]="fields.appointment_type.control.placeholder"
                            [styles]="fields.appointment_type.control.styles"
                          [option]="$fields_appointment_type_selector_inParams_option"
                        >
                        </DockAppointments-dock_appointment_types_dd_single>
                        <ng-container *ngIf="fields.appointment_type.invalid">
                          <ng-container *ngFor="let error of fields.appointment_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <DockAppointments-dock_appointment_statuses_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                        >
                        </DockAppointments-dock_appointment_statuses_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                          [warehouseIds]="$fields_warehouse_selector_inParams_warehouseIds"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dock_door" *ngIf="!fields.dock_door.hidden" 
                            class="field-container standard {{fields.dock_door.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dock_door.styles.style"
                            [ngClass]="fields.dock_door.styles.classes">
                        <div class="label-container"
                              title="{{fields.dock_door.label}}{{fields.dock_door.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dock_door.label}}">{{fields.dock_door.label}}<span *ngIf="fields.dock_door.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <DockAppointments-dock_doors_dd_single 
                            data-cy="selector"
                            [type]="fields.dock_door.control.type"
                            formControlName="dock_door"
                            (displayTextChange)="fields.dock_door.control.displayText=$event"
                            [placeholder]="fields.dock_door.control.placeholder"
                            [styles]="fields.dock_door.control.styles"
                          [warehouseIds]="$fields_dock_door_selector_inParams_warehouseIds"
                          [appointmentType]="$fields_dock_door_selector_inParams_appointmentType"
                        >
                        </DockAppointments-dock_doors_dd_single>
                        <ng-container *ngIf="fields.dock_door.invalid">
                          <ng-container *ngFor="let error of fields.dock_door.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scheduled_arrival" *ngIf="!fields.scheduled_arrival.hidden" 
                            class="field-container standard {{fields.scheduled_arrival.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.scheduled_arrival.styles.style"
                            [ngClass]="fields.scheduled_arrival.styles.classes">
                        <div class="label-container"
                              title="{{fields.scheduled_arrival.label}}{{fields.scheduled_arrival.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.scheduled_arrival.label}}">{{fields.scheduled_arrival.label}}<span *ngIf="fields.scheduled_arrival.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="scheduled_arrival"
                            [format]="fields.scheduled_arrival.control.format"
                            [mode]="fields.scheduled_arrival.control.mode"
                            [ngStyle]="fields.scheduled_arrival.control.styles.style"
                            [ngClass]="fields.scheduled_arrival.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.scheduled_arrival.invalid">
                          <ng-container *ngFor="let error of fields.scheduled_arrival.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scheduled_departure" *ngIf="!fields.scheduled_departure.hidden" 
                            class="field-container standard {{fields.scheduled_departure.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.scheduled_departure.styles.style"
                            [ngClass]="fields.scheduled_departure.styles.classes">
                        <div class="label-container"
                              title="{{fields.scheduled_departure.label}}{{fields.scheduled_departure.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.scheduled_departure.label}}">{{fields.scheduled_departure.label}}<span *ngIf="fields.scheduled_departure.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="scheduled_departure"
                            [format]="fields.scheduled_departure.control.format"
                            [mode]="fields.scheduled_departure.control.mode"
                            [ngStyle]="fields.scheduled_departure.control.styles.style"
                            [ngClass]="fields.scheduled_departure.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.scheduled_departure.invalid">
                          <ng-container *ngFor="let error of fields.scheduled_departure.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference_number" *ngIf="!fields.reference_number.hidden" 
                            class="field-container standard {{fields.reference_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference_number.styles.style"
                            [ngClass]="fields.reference_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.reference_number.label}}{{fields.reference_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reference_number.label}}">{{fields.reference_number.label}}<span *ngIf="fields.reference_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="reference_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference_number.control.placeholder}}"
                                [ngStyle]="fields.reference_number.control.styles.style"
                                [ngClass]="fields.reference_number.control.styles.classes"> 
                        <ng-container *ngIf="fields.reference_number.invalid">
                          <ng-container *ngFor="let error of fields.reference_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!fields.order.hidden" 
                            class="field-container double {{fields.order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order.styles.style"
                            [ngClass]="fields.order.styles.classes">
                        <div class="label-container"
                              title="{{fields.order.label}}{{fields.order.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order.label}}">{{fields.order.label}}<span *ngIf="fields.order.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <DockAppointments-orders_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.order.control.type"
                            formControlName="order"
                            (displayTextChange)="fields.order.control.displayText=$event"
                            [placeholder]="fields.order.control.placeholder"
                            [styles]="fields.order.control.styles"
                          [projectId]="$fields_order_selector_inParams_projectId"
                          [warehouseId]="$fields_order_selector_inParams_warehouseId"
                          [typeId]="$fields_order_selector_inParams_typeId"
                        >
                        </DockAppointments-orders_dd_multi>
                        <ng-container *ngIf="fields.order.invalid">
                          <ng-container *ngFor="let error of fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier.label}}{{fields.carrier.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier.label}}">{{fields.carrier.label}}<span *ngIf="fields.carrier.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Carriers-carriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                        >
                        </Carriers-carriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-estimated_pallets" *ngIf="!fields.estimated_pallets.hidden" 
                            class="field-container standard {{fields.estimated_pallets.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.estimated_pallets.styles.style"
                            [ngClass]="fields.estimated_pallets.styles.classes">
                        <div class="label-container"
                              title="{{fields.estimated_pallets.label}}{{fields.estimated_pallets.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.estimated_pallets.label}}">{{fields.estimated_pallets.label}}<span *ngIf="fields.estimated_pallets.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="estimated_pallets"
                                matInput
                                numberBox
                                [format]="fields.estimated_pallets.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.estimated_pallets.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.estimated_pallets.control.placeholder}}"
                                [ngStyle]="fields.estimated_pallets.control.styles.style"
                                [ngClass]="fields.estimated_pallets.control.styles.classes">
                        <ng-container *ngIf="fields.estimated_pallets.invalid">
                          <ng-container *ngFor="let error of fields.estimated_pallets.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-estimated_quantity" *ngIf="!fields.estimated_quantity.hidden" 
                            class="field-container standard {{fields.estimated_quantity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.estimated_quantity.styles.style"
                            [ngClass]="fields.estimated_quantity.styles.classes">
                        <div class="label-container"
                              title="{{fields.estimated_quantity.label}}{{fields.estimated_quantity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.estimated_quantity.label}}">{{fields.estimated_quantity.label}}<span *ngIf="fields.estimated_quantity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="estimated_quantity"
                                matInput
                                numberBox
                                [format]="fields.estimated_quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.estimated_quantity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.estimated_quantity.control.placeholder}}"
                                [ngStyle]="fields.estimated_quantity.control.styles.style"
                                [ngClass]="fields.estimated_quantity.control.styles.classes">
                        <ng-container *ngIf="fields.estimated_quantity.invalid">
                          <ng-container *ngFor="let error of fields.estimated_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-load_configuration" *ngIf="!fields.load_configuration.hidden" 
                            class="field-container standard {{fields.load_configuration.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.load_configuration.styles.style"
                            [ngClass]="fields.load_configuration.styles.classes">
                        <div class="label-container"
                              title="{{fields.load_configuration.label}}{{fields.load_configuration.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.load_configuration.label}}">{{fields.load_configuration.label}}<span *ngIf="fields.load_configuration.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="load_configuration"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.load_configuration.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.load_configuration.control.placeholder}}"
                                [ngStyle]="fields.load_configuration.control.styles.style"
                                [ngClass]="fields.load_configuration.control.styles.classes"> 
                        <ng-container *ngIf="fields.load_configuration.invalid">
                          <ng-container *ngFor="let error of fields.load_configuration.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-trailer_number" *ngIf="!fields.trailer_number.hidden" 
                            class="field-container standard {{fields.trailer_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.trailer_number.styles.style"
                            [ngClass]="fields.trailer_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.trailer_number.label}}{{fields.trailer_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.trailer_number.label}}">{{fields.trailer_number.label}}<span *ngIf="fields.trailer_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="trailer_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.trailer_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.trailer_number.control.placeholder}}"
                                [ngStyle]="fields.trailer_number.control.styles.style"
                                [ngClass]="fields.trailer_number.control.styles.classes"> 
                        <ng-container *ngIf="fields.trailer_number.invalid">
                          <ng-container *ngFor="let error of fields.trailer_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-full_trailer_temp" *ngIf="!fields.full_trailer_temp.hidden" 
                            class="field-container standard {{fields.full_trailer_temp.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.full_trailer_temp.styles.style"
                            [ngClass]="fields.full_trailer_temp.styles.classes">
                        <div class="label-container"
                              title="{{fields.full_trailer_temp.label}}{{fields.full_trailer_temp.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.full_trailer_temp.label}}">{{fields.full_trailer_temp.label}}<span *ngIf="fields.full_trailer_temp.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="full_trailer_temp"
                                matInput
                                numberBox
                                [format]="fields.full_trailer_temp.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.full_trailer_temp.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.full_trailer_temp.control.placeholder}}"
                                [ngStyle]="fields.full_trailer_temp.control.styles.style"
                                [ngClass]="fields.full_trailer_temp.control.styles.classes">
                        <ng-container *ngIf="fields.full_trailer_temp.invalid">
                          <ng-container *ngFor="let error of fields.full_trailer_temp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-empty_trailer_temp" *ngIf="!fields.empty_trailer_temp.hidden" 
                            class="field-container standard {{fields.empty_trailer_temp.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.empty_trailer_temp.styles.style"
                            [ngClass]="fields.empty_trailer_temp.styles.classes">
                        <div class="label-container"
                              title="{{fields.empty_trailer_temp.label}}{{fields.empty_trailer_temp.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.empty_trailer_temp.label}}">{{fields.empty_trailer_temp.label}}<span *ngIf="fields.empty_trailer_temp.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="empty_trailer_temp"
                                matInput
                                numberBox
                                [format]="fields.empty_trailer_temp.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.empty_trailer_temp.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.empty_trailer_temp.control.placeholder}}"
                                [ngStyle]="fields.empty_trailer_temp.control.styles.style"
                                [ngClass]="fields.empty_trailer_temp.control.styles.classes">
                        <ng-container *ngIf="fields.empty_trailer_temp.invalid">
                          <ng-container *ngFor="let error of fields.empty_trailer_temp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>