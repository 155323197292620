import { Inject, Injectable, Injector } from '@angular/core';


import { Utilities_check_footprint_versionService } from './Utilities.flow.index';
import { Utilities_configurationsService } from './Utilities.flow.index';
import { Utilities_configurations_deleteService } from './Utilities.flow.index';
import { Utilities_configurations_distinctService } from './Utilities.flow.index';
import { Utilities_configurations_getService } from './Utilities.flow.index';
import { Utilities_configurations_get_oneService } from './Utilities.flow.index';
import { Utilities_configurations_purgeService } from './Utilities.flow.index';
import { Utilities_configurations_updateService } from './Utilities.flow.index';
import { Utilities_configurations_writeService } from './Utilities.flow.index';
import { Utilities_convert_dates_between_timezones_flowService } from './Utilities.flow.index';
import { Utilities_convert_distance_flowService } from './Utilities.flow.index';
import { Utilities_convert_volume_flowService } from './Utilities.flow.index';
import { Utilities_convert_weight_flowService } from './Utilities.flow.index';
import { Utilities_crud_batch_request_flowService } from './Utilities.flow.index';
import { Utilities_crud_create_flowService } from './Utilities.flow.index';
import { Utilities_crud_delete_dual_pk_flowService } from './Utilities.flow.index';
import { Utilities_crud_delete_dual_pk_flow_stringService } from './Utilities.flow.index';
import { Utilities_crud_delete_flowService } from './Utilities.flow.index';
import { Utilities_crud_delete_quad_pk_flowService } from './Utilities.flow.index';
import { Utilities_crud_delete_triple_pk_flowService } from './Utilities.flow.index';
import { Utilities_crud_update_dual_pk_flowService } from './Utilities.flow.index';
import { Utilities_crud_update_dual_pk_flow_stringService } from './Utilities.flow.index';
import { Utilities_crud_update_flowService } from './Utilities.flow.index';
import { Utilities_crud_update_quad_pk_flowService } from './Utilities.flow.index';
import { Utilities_crud_update_triple_pk_flowService } from './Utilities.flow.index';
import { Utilities_date_get_from_footprintService } from './Utilities.flow.index';
import { Utilities_date_get_utc_flowService } from './Utilities.flow.index';
import { Utilities_date_get_warehouse_flowService } from './Utilities.flow.index';
import { Utilities_flatten_object_propertyService } from './Utilities.flow.index';
import { Utilities_format_username_flowService } from './Utilities.flow.index';
import { Utilities_get_username_flowService } from './Utilities.flow.index';
import { Utilities_grammar_format_string_array_flowService } from './Utilities.flow.index';
import { Utilities_list_applicationsService } from './Utilities.flow.index';
import { Utilities_list_processesService } from './Utilities.flow.index';
import { Utilities_list_statusesService } from './Utilities.flow.index';
import { Utilities_list_transaction_typesService } from './Utilities.flow.index';
import { Utilities_logsService } from './Utilities.flow.index';
import { Utilities_logs_cleanService } from './Utilities.flow.index';
import { Utilities_logs_deleteService } from './Utilities.flow.index';
import { Utilities_logs_distinctService } from './Utilities.flow.index';
import { Utilities_logs_getService } from './Utilities.flow.index';
import { Utilities_logs_purgeService } from './Utilities.flow.index';
import { Utilities_logs_updateService } from './Utilities.flow.index';
import { Utilities_logs_writeService } from './Utilities.flow.index';
import { Utilities_math_round_flowService } from './Utilities.flow.index';
import { Utilities_messagesService } from './Utilities.flow.index';
import { Utilities_messages_cleanService } from './Utilities.flow.index';
import { Utilities_messages_deleteService } from './Utilities.flow.index';
import { Utilities_messages_distinctService } from './Utilities.flow.index';
import { Utilities_messages_getService } from './Utilities.flow.index';
import { Utilities_messages_nextService } from './Utilities.flow.index';
import { Utilities_messages_purgeService } from './Utilities.flow.index';
import { Utilities_messages_purge_stringService } from './Utilities.flow.index';
import { Utilities_messages_updateService } from './Utilities.flow.index';
import { Utilities_messages_writeService } from './Utilities.flow.index';
import { Utilities_odata_encode_special_characters_for_urlService } from './Utilities.flow.index';
import { Utilities_reserve_batchId_flowService } from './Utilities.flow.index';
import { Utilities_reserve_nextId_flowService } from './Utilities.flow.index';
import { Utilities_storage_populate_date_numerService } from './Utilities.flow.index';
import { Utilities_storage_purgeService } from './Utilities.flow.index';
import { Utilities_storage_testService } from './Utilities.flow.index';
import { Utilities_string_encryptService } from './Utilities.flow.index';
import { Utilities_upload_file_to_blob_storage_flowService } from './Utilities.flow.index';
import { Utilities_validate_component_required_fieldsService } from './Utilities.flow.index';

import { $frontendTypes } from './Utilities.frontend.types'

@Injectable({ providedIn: 'root' })
export class Utilities_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Utilities: Utilities_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _check_footprint_version: Utilities_check_footprint_versionService;
  public async check_footprint_version(inParams: { minimumVersion?: string }): Promise< { productVersion?: string, suggestedMessage?: string, schemaVersion?: string, meetsMinimumVersion?: boolean, logs?: string[] }> {
    if(!this._check_footprint_version) {
      this._check_footprint_version = this.injector.get(Utilities_check_footprint_versionService);
    }
    return this._check_footprint_version.run(inParams);
  }
   
   

   
 
  private _configurations: Utilities_configurationsService;
  public async configurations(inParams: { payload?: $frontendTypes.Utilities.Configuration[], action: string, skip?: number, take?: number, application_name: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Utilities.Configuration[], success?: boolean, error?: any[], count?: number }> {
    if(!this._configurations) {
      this._configurations = this.injector.get(Utilities_configurationsService);
    }
    return this._configurations.run(inParams);
  }
   
   

   
 
  private _configurations_delete: Utilities_configurations_deleteService;
  public async configurations_delete(inParams: { input?: { configuration_id: string }[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._configurations_delete) {
      this._configurations_delete = this.injector.get(Utilities_configurations_deleteService);
    }
    return this._configurations_delete.run(inParams);
  }
   
   

   
 
  private _configurations_distinct: Utilities_configurations_distinctService;
  public async configurations_distinct(inParams: { input?: $frontendTypes.Utilities.Configuration[], criteria?: { application_name?: string, full_text_search?: string }, field: string, top?: number }): Promise< { output?: { application_name?: string, value?: any }[], success?: boolean, error?: any[], count?: number }> {
    if(!this._configurations_distinct) {
      this._configurations_distinct = this.injector.get(Utilities_configurations_distinctService);
    }
    return this._configurations_distinct.run(inParams);
  }
   
   

   
 
  private _configurations_get: Utilities_configurations_getService;
  public async configurations_get(inParams: { input?: $frontendTypes.Utilities.Configuration[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string, skip?: number, take?: number } }): Promise< { output?: $frontendTypes.Utilities.Configuration[], success?: boolean, error?: any[], count?: number }> {
    if(!this._configurations_get) {
      this._configurations_get = this.injector.get(Utilities_configurations_getService);
    }
    return this._configurations_get.run(inParams);
  }
   
   

   
 
  private _configurations_get_one: Utilities_configurations_get_oneService;
  public async configurations_get_one(inParams: { input?: $frontendTypes.Utilities.Configuration[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string } }): Promise< { output?: $frontendTypes.Utilities.Configuration, success?: boolean, error?: any[] }> {
    if(!this._configurations_get_one) {
      this._configurations_get_one = this.injector.get(Utilities_configurations_get_oneService);
    }
    return this._configurations_get_one.run(inParams);
  }
   
   

   
 
  private _configurations_purge: Utilities_configurations_purgeService;
  public async configurations_purge(inParams: { criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._configurations_purge) {
      this._configurations_purge = this.injector.get(Utilities_configurations_purgeService);
    }
    return this._configurations_purge.run(inParams);
  }
   
   

   
 
  private _configurations_update: Utilities_configurations_updateService;
  public async configurations_update(inParams: { input?: $frontendTypes.Utilities.Configuration[], criteria: { application_name: string, encrypt?: boolean } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._configurations_update) {
      this._configurations_update = this.injector.get(Utilities_configurations_updateService);
    }
    return this._configurations_update.run(inParams);
  }
   
   

   
 
  private _configurations_write: Utilities_configurations_writeService;
  public async configurations_write(inParams: { input?: $frontendTypes.Utilities.Configuration[], criteria: { application_name: string, encrypt?: boolean } }): Promise< { output?: { configuration_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._configurations_write) {
      this._configurations_write = this.injector.get(Utilities_configurations_writeService);
    }
    return this._configurations_write.run(inParams);
  }
   
   

   
 
  private _convert_dates_between_timezones_flow: Utilities_convert_dates_between_timezones_flowService;
  public async convert_dates_between_timezones_flow(inParams: { warehouseIds: number[], convertDates: any, isFromUtcToWarehouse: boolean }): Promise< { timeZoneDates?: { timeZoneId?: string, timeZone?: string, offset?: number, dates?: any, warehouseIds?: number[] }[] }> {
    if(!this._convert_dates_between_timezones_flow) {
      this._convert_dates_between_timezones_flow = this.injector.get(Utilities_convert_dates_between_timezones_flowService);
    }
    return this._convert_dates_between_timezones_flow.run(inParams);
  }
   
   

   
 
  private _convert_distance_flow: Utilities_convert_distance_flowService;
  public async convert_distance_flow(inParams: { inputDistance: number, fromUom: number, toUom: number }): Promise< { convertedDistance?: number, reasons?: string[] }> {
    if(!this._convert_distance_flow) {
      this._convert_distance_flow = this.injector.get(Utilities_convert_distance_flowService);
    }
    return this._convert_distance_flow.run(inParams);
  }
   
   

   
 
  private _convert_volume_flow: Utilities_convert_volume_flowService;
  public async convert_volume_flow(inParams: { inputVolume: number, fromUom: number, toUom: number }): Promise< { convertedVolume?: number, reasons?: string[] }> {
    if(!this._convert_volume_flow) {
      this._convert_volume_flow = this.injector.get(Utilities_convert_volume_flowService);
    }
    return this._convert_volume_flow.run(inParams);
  }
   
   

   
 
  private _convert_weight_flow: Utilities_convert_weight_flowService;
  public async convert_weight_flow(inParams: { inputWeight: number, fromUom: number, toUom: number }): Promise< { convertedWeight?: number, reasons?: string[] }> {
    if(!this._convert_weight_flow) {
      this._convert_weight_flow = this.injector.get(Utilities_convert_weight_flowService);
    }
    return this._convert_weight_flow.run(inParams);
  }
   
   

   
 
  private _crud_batch_request_flow: Utilities_crud_batch_request_flowService;
  public async crud_batch_request_flow(inParams: { entities: { set?: string, keys?: { name?: string, value?: any }[], method?: string, properties?: any }[], withTransaction?: boolean }): Promise< { reasons?: string[], response?: any, message?: string }> {
    if(!this._crud_batch_request_flow) {
      this._crud_batch_request_flow = this.injector.get(Utilities_crud_batch_request_flowService);
    }
    return this._crud_batch_request_flow.run(inParams);
  }
   
   

   
 
  private _crud_create_flow: Utilities_crud_create_flowService;
  public async crud_create_flow(inParams: { entitySet: string, entity: any }): Promise< { result?: any }> {
    if(!this._crud_create_flow) {
      this._crud_create_flow = this.injector.get(Utilities_crud_create_flowService);
    }
    return this._crud_create_flow.run(inParams);
  }
   
   

   
 
  private _crud_delete_dual_pk_flow: Utilities_crud_delete_dual_pk_flowService;
  public async crud_delete_dual_pk_flow(inParams: { entitySet: string, pk1String: string, pk1: number, pk2String: string, pk2: number }): Promise<void> {
    if(!this._crud_delete_dual_pk_flow) {
      this._crud_delete_dual_pk_flow = this.injector.get(Utilities_crud_delete_dual_pk_flowService);
    }
    return this._crud_delete_dual_pk_flow.run(inParams);
  }
   
   

   
 
  private _crud_delete_dual_pk_flow_string: Utilities_crud_delete_dual_pk_flow_stringService;
  public async crud_delete_dual_pk_flow_string(inParams: { entitySet: string, pk1String: string, pk1: number, pk2String: string, pk2: string }): Promise<void> {
    if(!this._crud_delete_dual_pk_flow_string) {
      this._crud_delete_dual_pk_flow_string = this.injector.get(Utilities_crud_delete_dual_pk_flow_stringService);
    }
    return this._crud_delete_dual_pk_flow_string.run(inParams);
  }
   
   

   
 
  private _crud_delete_flow: Utilities_crud_delete_flowService;
  public async crud_delete_flow(inParams: { id: number, entitySet: string }): Promise<void> {
    if(!this._crud_delete_flow) {
      this._crud_delete_flow = this.injector.get(Utilities_crud_delete_flowService);
    }
    return this._crud_delete_flow.run(inParams);
  }
   
   

   
 
  private _crud_delete_quad_pk_flow: Utilities_crud_delete_quad_pk_flowService;
  public async crud_delete_quad_pk_flow(inParams: { entitySet: string, pk1String: string, pk1: number, pk2String: string, pk2: number, pk3String: string, pk3: number, pk4String: string, pk4: number }): Promise<void> {
    if(!this._crud_delete_quad_pk_flow) {
      this._crud_delete_quad_pk_flow = this.injector.get(Utilities_crud_delete_quad_pk_flowService);
    }
    return this._crud_delete_quad_pk_flow.run(inParams);
  }
   
   

   
 
  private _crud_delete_triple_pk_flow: Utilities_crud_delete_triple_pk_flowService;
  public async crud_delete_triple_pk_flow(inParams: { entitySet: string, pk1String: string, pk1: number, pk2String: string, pk2: number, pk3String: string, pk3: number }): Promise<void> {
    if(!this._crud_delete_triple_pk_flow) {
      this._crud_delete_triple_pk_flow = this.injector.get(Utilities_crud_delete_triple_pk_flowService);
    }
    return this._crud_delete_triple_pk_flow.run(inParams);
  }
   
   

   
 
  private _crud_update_dual_pk_flow: Utilities_crud_update_dual_pk_flowService;
  public async crud_update_dual_pk_flow(inParams: { entitySet: string, entity: any, pk1String: string, pk1: number, pk2String: string, pk2: number }): Promise<void> {
    if(!this._crud_update_dual_pk_flow) {
      this._crud_update_dual_pk_flow = this.injector.get(Utilities_crud_update_dual_pk_flowService);
    }
    return this._crud_update_dual_pk_flow.run(inParams);
  }
   
   

   
 
  private _crud_update_dual_pk_flow_string: Utilities_crud_update_dual_pk_flow_stringService;
  public async crud_update_dual_pk_flow_string(inParams: { entitySet: string, entity: any, pk1String: string, pk1: number, pk2String: string, pk2: string }): Promise<void> {
    if(!this._crud_update_dual_pk_flow_string) {
      this._crud_update_dual_pk_flow_string = this.injector.get(Utilities_crud_update_dual_pk_flow_stringService);
    }
    return this._crud_update_dual_pk_flow_string.run(inParams);
  }
   
   

   
 
  private _crud_update_flow: Utilities_crud_update_flowService;
  public async crud_update_flow(inParams: { entitySet: string, id: number, entity: any }): Promise<void> {
    if(!this._crud_update_flow) {
      this._crud_update_flow = this.injector.get(Utilities_crud_update_flowService);
    }
    return this._crud_update_flow.run(inParams);
  }
   
   

   
 
  private _crud_update_quad_pk_flow: Utilities_crud_update_quad_pk_flowService;
  public async crud_update_quad_pk_flow(inParams: { entitySet: string, entity: any, pk1String: string, pk1: number, pk2String: string, pk2: number, pk3String: string, pk3: number, pk4String: string, pk4: number }): Promise<void> {
    if(!this._crud_update_quad_pk_flow) {
      this._crud_update_quad_pk_flow = this.injector.get(Utilities_crud_update_quad_pk_flowService);
    }
    return this._crud_update_quad_pk_flow.run(inParams);
  }
   
   

   
 
  private _crud_update_triple_pk_flow: Utilities_crud_update_triple_pk_flowService;
  public async crud_update_triple_pk_flow(inParams: { entitySet: string, entity: any, pk1String: string, pk1: number, pk2String: string, pk2: number, pk3String: string, pk3: number }): Promise<void> {
    if(!this._crud_update_triple_pk_flow) {
      this._crud_update_triple_pk_flow = this.injector.get(Utilities_crud_update_triple_pk_flowService);
    }
    return this._crud_update_triple_pk_flow.run(inParams);
  }
   
   

   
 
  private _date_get_from_footprint: Utilities_date_get_from_footprintService;
  public async date_get_from_footprint(inParams: {  }): Promise< { date_local?: string, date_utc?: string }> {
    if(!this._date_get_from_footprint) {
      this._date_get_from_footprint = this.injector.get(Utilities_date_get_from_footprintService);
    }
    return this._date_get_from_footprint.run(inParams);
  }
   
   

   
 
  private _date_get_utc_flow: Utilities_date_get_utc_flowService;
  public async date_get_utc_flow(inParams: { warehouseId?: number, warehouseDate?: string }): Promise< { date?: string }> {
    if(!this._date_get_utc_flow) {
      this._date_get_utc_flow = this.injector.get(Utilities_date_get_utc_flowService);
    }
    return this._date_get_utc_flow.run(inParams);
  }
   
   

   
 
  private _date_get_warehouse_flow: Utilities_date_get_warehouse_flowService;
  public async date_get_warehouse_flow(inParams: { warehouseId: number, utcDate?: string }): Promise< { date?: string }> {
    if(!this._date_get_warehouse_flow) {
      this._date_get_warehouse_flow = this.injector.get(Utilities_date_get_warehouse_flowService);
    }
    return this._date_get_warehouse_flow.run(inParams);
  }
   
   

   
 
  private _flatten_object_property: Utilities_flatten_object_propertyService;
  public async flatten_object_property(inParams: { flattenArray: any[], childName: string, flattenValueName?: string, returnReversed?: boolean }): Promise< { result?: any[], reasons?: string[] }> {
    if(!this._flatten_object_property) {
      this._flatten_object_property = this.injector.get(Utilities_flatten_object_propertyService);
    }
    return this._flatten_object_property.run(inParams);
  }
   
   

   
 
  private _format_username_flow: Utilities_format_username_flowService;
  public async format_username_flow(inParams: { inUserName: string }): Promise< { outUserName?: string, reasons?: string[] }> {
    if(!this._format_username_flow) {
      this._format_username_flow = this.injector.get(Utilities_format_username_flowService);
    }
    return this._format_username_flow.run(inParams);
  }
   
   

   
 
  private _get_username_flow: Utilities_get_username_flowService;
  public async get_username_flow(inParams: {  }): Promise< { userName?: string, domain?: string, fullUserName?: string }> {
    if(!this._get_username_flow) {
      this._get_username_flow = this.injector.get(Utilities_get_username_flowService);
    }
    return this._get_username_flow.run(inParams);
  }
   
   

   
 
  private _grammar_format_string_array_flow: Utilities_grammar_format_string_array_flowService;
  public async grammar_format_string_array_flow(inParams: { values: string[], delimiter?: string, finalValueDelimiter?: string }): Promise< { formattedValue?: string }> {
    if(!this._grammar_format_string_array_flow) {
      this._grammar_format_string_array_flow = this.injector.get(Utilities_grammar_format_string_array_flowService);
    }
    return this._grammar_format_string_array_flow.run(inParams);
  }
   
   

   
 
  private _list_applications: Utilities_list_applicationsService;
  public async list_applications(inParams: { application_name?: string, action?: string }): Promise< { list?: { id?: string, application_name?: string }[] }> {
    if(!this._list_applications) {
      this._list_applications = this.injector.get(Utilities_list_applicationsService);
    }
    return this._list_applications.run(inParams);
  }
   
   

   
 
  private _list_processes: Utilities_list_processesService;
  public async list_processes(inParams: { application_name?: string, process_name?: string, action?: string }): Promise< { list?: { id?: string, application_name?: string, process_name?: string }[] }> {
    if(!this._list_processes) {
      this._list_processes = this.injector.get(Utilities_list_processesService);
    }
    return this._list_processes.run(inParams);
  }
   
   

   
 
  private _list_statuses: Utilities_list_statusesService;
  public async list_statuses(inParams: { application_name?: string, status_name?: string, action?: string }): Promise< { list?: { id?: string, application_name?: string, status_name?: string }[] }> {
    if(!this._list_statuses) {
      this._list_statuses = this.injector.get(Utilities_list_statusesService);
    }
    return this._list_statuses.run(inParams);
  }
   
   

   
 
  private _list_transaction_types: Utilities_list_transaction_typesService;
  public async list_transaction_types(inParams: { application_name?: string, transaction_type?: string, action?: string }): Promise< { list?: { id?: string, application_name?: string, transaction_type?: string }[] }> {
    if(!this._list_transaction_types) {
      this._list_transaction_types = this.injector.get(Utilities_list_transaction_typesService);
    }
    return this._list_transaction_types.run(inParams);
  }
   
   

   
 
  private _logs: Utilities_logsService;
  public async logs(inParams: { payload?: $frontendTypes.Utilities.Log[], action: string, skip?: number, take?: number, date_from?: string, date_to?: string, application_name: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Utilities.Log[], success?: boolean, error?: any[], count?: number }> {
    if(!this._logs) {
      this._logs = this.injector.get(Utilities_logsService);
    }
    return this._logs.run(inParams);
  }
   
   

   
 
  private _logs_clean: Utilities_logs_cleanService;
  public async logs_clean(inParams: { criteria: { application_name: string, date_to?: string, confirm?: boolean } }): Promise< { success?: boolean, error?: any[], date_to?: string, count?: number }> {
    if(!this._logs_clean) {
      this._logs_clean = this.injector.get(Utilities_logs_cleanService);
    }
    return this._logs_clean.run(inParams);
  }
   
   

   
 
  private _logs_delete: Utilities_logs_deleteService;
  public async logs_delete(inParams: { input?: { log_id: string }[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._logs_delete) {
      this._logs_delete = this.injector.get(Utilities_logs_deleteService);
    }
    return this._logs_delete.run(inParams);
  }
   
   

   
 
  private _logs_distinct: Utilities_logs_distinctService;
  public async logs_distinct(inParams: { input?: $frontendTypes.Utilities.Log[], criteria?: { application_name?: string, full_text_search?: string, date_from?: string, date_to?: string }, field: string, top?: number }): Promise< { output?: { application_name?: string, value?: any }[], success?: boolean, error?: any[], count?: number }> {
    if(!this._logs_distinct) {
      this._logs_distinct = this.injector.get(Utilities_logs_distinctService);
    }
    return this._logs_distinct.run(inParams);
  }
   
   

   
 
  private _logs_get: Utilities_logs_getService;
  public async logs_get(inParams: { input?: $frontendTypes.Utilities.Log[], criteria: { application_name: string, full_text_search?: string, date_from?: string, date_to?: string, skip?: number, take?: number } }): Promise< { output?: $frontendTypes.Utilities.Log[], success?: boolean, error?: any[], count?: number }> {
    if(!this._logs_get) {
      this._logs_get = this.injector.get(Utilities_logs_getService);
    }
    return this._logs_get.run(inParams);
  }
   
   

   
 
  private _logs_purge: Utilities_logs_purgeService;
  public async logs_purge(inParams: { criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._logs_purge) {
      this._logs_purge = this.injector.get(Utilities_logs_purgeService);
    }
    return this._logs_purge.run(inParams);
  }
   
   

   
 
  private _logs_update: Utilities_logs_updateService;
  public async logs_update(inParams: { input?: $frontendTypes.Utilities.Log[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._logs_update) {
      this._logs_update = this.injector.get(Utilities_logs_updateService);
    }
    return this._logs_update.run(inParams);
  }
   
   

   
 
  private _logs_write: Utilities_logs_writeService;
  public async logs_write(inParams: { input?: $frontendTypes.Utilities.Log[], criteria: { application_name: string } }): Promise< { output?: { log_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._logs_write) {
      this._logs_write = this.injector.get(Utilities_logs_writeService);
    }
    return this._logs_write.run(inParams);
  }
   
   

   
 
  private _math_round_flow: Utilities_math_round_flowService;
  public async math_round_flow(inParams: { inputNumber: number, position?: number }): Promise< { roundedNumber?: number }> {
    if(!this._math_round_flow) {
      this._math_round_flow = this.injector.get(Utilities_math_round_flowService);
    }
    return this._math_round_flow.run(inParams);
  }
   
   

   
 
  private _messages: Utilities_messagesService;
  public async messages(inParams: { payload?: $frontendTypes.Utilities.Message[], action: string, skip?: number, take?: number, date_from?: string, date_to?: string, application_name: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Utilities.Message[], success?: boolean, error?: any[], count?: number }> {
    if(!this._messages) {
      this._messages = this.injector.get(Utilities_messagesService);
    }
    return this._messages.run(inParams);
  }
   
   

   
 
  private _messages_clean: Utilities_messages_cleanService;
  public async messages_clean(inParams: { criteria: { application_name: string, date_to?: string, confirm?: boolean } }): Promise< { success?: boolean, error?: any[], date_to?: string, count?: number, test?: any[] }> {
    if(!this._messages_clean) {
      this._messages_clean = this.injector.get(Utilities_messages_cleanService);
    }
    return this._messages_clean.run(inParams);
  }
   
   

   
 
  private _messages_delete: Utilities_messages_deleteService;
  public async messages_delete(inParams: { input?: { message_id: string }[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._messages_delete) {
      this._messages_delete = this.injector.get(Utilities_messages_deleteService);
    }
    return this._messages_delete.run(inParams);
  }
   
   

   
 
  private _messages_distinct: Utilities_messages_distinctService;
  public async messages_distinct(inParams: { input?: $frontendTypes.Utilities.Message[], criteria?: { application_name?: string, full_text_search?: string, date_from?: string, date_to?: string }, field: string, top?: number }): Promise< { output?: { application_name?: string, value?: any }[], success?: boolean, error?: any[], count?: number }> {
    if(!this._messages_distinct) {
      this._messages_distinct = this.injector.get(Utilities_messages_distinctService);
    }
    return this._messages_distinct.run(inParams);
  }
   
   

   
 
  private _messages_get: Utilities_messages_getService;
  public async messages_get(inParams: { input?: $frontendTypes.Utilities.Message[], criteria: { application_name: string, full_text_search?: string, date_from?: string, date_to?: string, skip?: number, take?: number } }): Promise< { output?: $frontendTypes.Utilities.Message[], success?: boolean, error?: any[], count?: number }> {
    if(!this._messages_get) {
      this._messages_get = this.injector.get(Utilities_messages_getService);
    }
    return this._messages_get.run(inParams);
  }
   
   

   
 
  private _messages_next: Utilities_messages_nextService;
  public async messages_next(inParams: { input?: $frontendTypes.Utilities.Message[], criteria: { application_name: string, full_text_search?: string, date_from?: string, date_to?: string } }): Promise< { output?: $frontendTypes.Utilities.Message, success?: boolean, error?: any[] }> {
    if(!this._messages_next) {
      this._messages_next = this.injector.get(Utilities_messages_nextService);
    }
    return this._messages_next.run(inParams);
  }
   
   

   
 
  private _messages_purge: Utilities_messages_purgeService;
  public async messages_purge(inParams: { criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._messages_purge) {
      this._messages_purge = this.injector.get(Utilities_messages_purgeService);
    }
    return this._messages_purge.run(inParams);
  }
   
   

   
 
  private _messages_purge_string: Utilities_messages_purge_stringService;
  public async messages_purge_string(inParams: { input?: $frontendTypes.Utilities.Message[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._messages_purge_string) {
      this._messages_purge_string = this.injector.get(Utilities_messages_purge_stringService);
    }
    return this._messages_purge_string.run(inParams);
  }
   
   

   
 
  private _messages_update: Utilities_messages_updateService;
  public async messages_update(inParams: { input?: $frontendTypes.Utilities.Message[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._messages_update) {
      this._messages_update = this.injector.get(Utilities_messages_updateService);
    }
    return this._messages_update.run(inParams);
  }
   
   

   
 
  private _messages_write: Utilities_messages_writeService;
  public async messages_write(inParams: { input?: $frontendTypes.Utilities.Message[], criteria: { application_name: string } }): Promise< { output?: { message_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._messages_write) {
      this._messages_write = this.injector.get(Utilities_messages_writeService);
    }
    return this._messages_write.run(inParams);
  }
   
   

   
 
  private _odata_encode_special_characters_for_url: Utilities_odata_encode_special_characters_for_urlService;
  public async odata_encode_special_characters_for_url(inParams: { stringToEncode: string }): Promise< { encodedString?: string }> {
    if(!this._odata_encode_special_characters_for_url) {
      this._odata_encode_special_characters_for_url = this.injector.get(Utilities_odata_encode_special_characters_for_urlService);
    }
    return this._odata_encode_special_characters_for_url.run(inParams);
  }
   
   

   
 
  private _reserve_batchId_flow: Utilities_reserve_batchId_flowService;
  public async reserve_batchId_flow(inParams: { entity: string, batchSize: number }): Promise< { newIdCeiling?: number, reasons?: string[] }> {
    if(!this._reserve_batchId_flow) {
      this._reserve_batchId_flow = this.injector.get(Utilities_reserve_batchId_flowService);
    }
    return this._reserve_batchId_flow.run(inParams);
  }
   
   

   
 
  private _reserve_nextId_flow: Utilities_reserve_nextId_flowService;
  public async reserve_nextId_flow(inParams: { entity: string }): Promise< { nextId?: number, reasons?: string[] }> {
    if(!this._reserve_nextId_flow) {
      this._reserve_nextId_flow = this.injector.get(Utilities_reserve_nextId_flowService);
    }
    return this._reserve_nextId_flow.run(inParams);
  }
   
   

   
 
  private _storage_populate_date_numer: Utilities_storage_populate_date_numerService;
  public async storage_populate_date_numer(inParams: {  }): Promise< { count?: number, output?: any[] }> {
    if(!this._storage_populate_date_numer) {
      this._storage_populate_date_numer = this.injector.get(Utilities_storage_populate_date_numerService);
    }
    return this._storage_populate_date_numer.run(inParams);
  }
   
   

   
 
  private _storage_purge: Utilities_storage_purgeService;
  public async storage_purge(inParams: {  }): Promise<void> {
    if(!this._storage_purge) {
      this._storage_purge = this.injector.get(Utilities_storage_purgeService);
    }
    return this._storage_purge.run(inParams);
  }
   
   

   
 
  private _storage_test: Utilities_storage_testService;
  public async storage_test(inParams: { rounds?: number, messages?: number, purge?: boolean }): Promise< { messages?: any[] }> {
    if(!this._storage_test) {
      this._storage_test = this.injector.get(Utilities_storage_testService);
    }
    return this._storage_test.run(inParams);
  }
   
   

   
 
  private _string_encrypt: Utilities_string_encryptService;
  public async string_encrypt(inParams: { text?: string, encrypted?: string }): Promise< { text?: string, encrypted?: string }> {
    if(!this._string_encrypt) {
      this._string_encrypt = this.injector.get(Utilities_string_encryptService);
    }
    return this._string_encrypt.run(inParams);
  }
   
   

   
 
  private _upload_file_to_blob_storage_flow: Utilities_upload_file_to_blob_storage_flowService;
  public async upload_file_to_blob_storage_flow(inParams: { storageAccount?: string, signature?: string, container?: string, fileContent?: string, fileName?: string }): Promise<void> {
    if(!this._upload_file_to_blob_storage_flow) {
      this._upload_file_to_blob_storage_flow = this.injector.get(Utilities_upload_file_to_blob_storage_flowService);
    }
    return this._upload_file_to_blob_storage_flow.run(inParams);
  }
   
   

   
 
  private _validate_component_required_fields: Utilities_validate_component_required_fieldsService;
  public async validate_component_required_fields(inParams: { componentType: string, component?: any }): Promise< { reasons?: string[], missingFields?: string[], suggestedErrorMessage?: string }> {
    if(!this._validate_component_required_fields) {
      this._validate_component_required_fields = this.injector.get(Utilities_validate_component_required_fieldsService);
    }
    return this._validate_component_required_fields.run(inParams);
  }
   
   

   
}
