import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_sales_order_line_kit_gridComponent } from './FootPrintManager.sales_order_line_kit_grid.component';
import { FootPrintManager_sales_order_line_components_gridComponent } from './FootPrintManager.sales_order_line_components_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_sales_order_line_kit_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_line_components_gridComponent),
  ],
  selector: 'FootPrintManager-sales_order_line_kit_editor',
  templateUrl: './FootPrintManager.sales_order_line_kit_editor.component.html'
})
export class FootPrintManager_sales_order_line_kit_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { material_id: number, project_id: number, packaging_id: number, line_number: number, line_status_id: number, packaged_amount: number, order_id: number, shipment_id: number, warehouse_id: number, order_status_id: number } = { material_id: null, project_id: null, packaging_id: null, line_number: null, line_status_id: null, packaged_amount: null, order_id: null, shipment_id: null, warehouse_id: null, order_status_id: null };
  //#region Inputs
  @Input('material_id') set $inParams_material_id(v: number) {
    this.inParams.material_id = v;
  }
  get $inParams_material_id(): number {
    return this.inParams.material_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('packaging_id') set $inParams_packaging_id(v: number) {
    this.inParams.packaging_id = v;
  }
  get $inParams_packaging_id(): number {
    return this.inParams.packaging_id;
  }
  @Input('line_number') set $inParams_line_number(v: number) {
    this.inParams.line_number = v;
  }
  get $inParams_line_number(): number {
    return this.inParams.line_number;
  }
  @Input('line_status_id') set $inParams_line_status_id(v: number) {
    this.inParams.line_status_id = v;
  }
  get $inParams_line_status_id(): number {
    return this.inParams.line_status_id;
  }
  @Input('packaged_amount') set $inParams_packaged_amount(v: number) {
    this.inParams.packaged_amount = v;
  }
  get $inParams_packaged_amount(): number {
    return this.inParams.packaged_amount;
  }
  @Input('order_id') set $inParams_order_id(v: number) {
    this.inParams.order_id = v;
  }
  get $inParams_order_id(): number {
    return this.inParams.order_id;
  }
  @Input('shipment_id') set $inParams_shipment_id(v: number) {
    this.inParams.shipment_id = v;
  }
  get $inParams_shipment_id(): number {
    return this.inParams.shipment_id;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('order_status_id') set $inParams_order_status_id(v: number) {
    this.inParams.order_status_id = v;
  }
  get $inParams_order_status_id(): number {
    return this.inParams.order_status_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, Description?: string, LookupCode?: string };

  formGroup: FormGroup = new FormGroup({
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    line_details: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      kit_components: new TabItemModel(
        this.rootTabGroup, 
        'Kit components', 
        ),
      kit_lines: new TabItemModel(
        this.rootTabGroup, 
        'Kit lines', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.material_id;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_packaging_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.packaging_id;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_order_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.order_id;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_line_number(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.line_number;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_line_status_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.line_status_id;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_packaged_amount(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.packaged_amount;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_kit_components_sales_order_line_kit_grid_inParams_shipment_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.shipment_id;
      
      return expr;
    }
  
    get $tabs_kit_lines_sales_order_line_components_grid_inParams_order_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.order_id;
      
      return expr;
    }
  
    get $tabs_kit_lines_sales_order_line_components_grid_inParams_shipment_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.shipment_id;
      
      return expr;
    }
  
    get $tabs_kit_lines_sales_order_line_components_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_kit_lines_sales_order_line_components_grid_inParams_order_status_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.order_status_id;
      
      return expr;
    }
  
    get $tabs_kit_lines_sales_order_line_components_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_kit_lines_sales_order_line_components_grid_inParams_line_number(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.line_number;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_kit_components', { read: FootPrintManager_sales_order_line_kit_gridComponent }) $tabs_kit_components: FootPrintManager_sales_order_line_kit_gridComponent;
      @ViewChild('$tabs_kit_lines', { read: FootPrintManager_sales_order_line_components_gridComponent }) $tabs_kit_lines: FootPrintManager_sales_order_line_components_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.kit_components,
      this.tabs.kit_lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.packaging_id)) {
        this.$missingRequiredInParams.push('packaging_id');
      }
      if(isNil(this.inParams.line_number)) {
        this.$missingRequiredInParams.push('line_number');
      }
      if(isNil(this.inParams.line_status_id)) {
        this.$missingRequiredInParams.push('line_status_id');
      }
      if(isNil(this.inParams.packaged_amount)) {
        this.$missingRequiredInParams.push('packaged_amount');
      }
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.shipment_id)) {
        this.$missingRequiredInParams.push('shipment_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
      if(isNil(this.inParams.order_status_id)) {
        this.$missingRequiredInParams.push('order_status_id');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Sale order line kit editor';
    
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      material_id:  $editor.inParams.material_id 
    };

    const data = await this.datasources.FootPrintManager.ds_material_kit_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_kit_components') {
        if (!isNil(this.$tabs_kit_components) && !this.tabs.kit_components.hidden) {
          this.$tabs_kit_components.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_kit_lines') {
        if (!isNil(this.$tabs_kit_lines) && !this.tabs.kit_lines.hidden) {
          this.$tabs_kit_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_sales_order_line_kit_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const lookupcode = $editor.entity.LookupCode;
  const description = $editor.entity.Description;
  let package_name = '';
  
  const packaging_id = $editor.inParams.packaging_id;
  if ($utils.isDefined(packaging_id)) {
  
      package_name = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({
          unitId: packaging_id
      })).result[0].ShortName;
  
      package_name = `(${package_name})`
  
  }
  
  // Set title
  $editor.title = `Kit for ${lookupcode} - ${description} ${package_name}`;
  
  // Set line details
  $editor.fields.line_details.control.text = `Line (${$editor.inParams.line_number})  Quantity (${$editor.inParams.packaged_amount.toString()})`
  
  
  // Check if the order line is a parent
  const line_details = (await $datasources.SalesOrders.ds_get_orderline_by_order_id_line_number.get({
      order_id: $editor.inParams.order_id,
      line_number: $editor.inParams.line_number
  })).result;
  
  if ($utils.isDefined(line_details)) {
  
      const has_children = line_details.HasChildren;
      if (has_children) {
  
          $editor.tabs.kit_components.hidden = true;
      }
      else {
  
          // Check for kits components
          const components = (await $datasources.Materials.ds_get_components_by_material_id_packaging_id.get({
              material_id: $editor.inParams.material_id,
              packaging_id: $editor.inParams.packaging_id
          })).result[0];
  
          if ($utils.isDefined(components)) {
              $editor.tabs.kit_components.hidden = false;
          }
          else {
              $editor.tabs.kit_components.hidden = true;
          }
  
      }
  
  
  }
  
  
  }
  //#endregion private flows
}
