import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { app_custom_dock_appointment_creation_formComponent } from './app.custom_dock_appointment_creation_form.component';
import { app_custom_fda_hold_options_formComponent } from './app.custom_fda_hold_options_form.component';
import { app_custom_inventory_creation_formComponent } from './app.custom_inventory_creation_form.component';
import { app_custom_project_update_project_formComponent } from './app.custom_project_update_project_form.component';
import { app_custom_sales_order_creation_formComponent } from './app.custom_sales_order_creation_form.component';
import { app_custom_tester_formComponent } from './app.custom_tester_form.component';
import { app_custom_vendor_lot_creation_formComponent } from './app.custom_vendor_lot_creation_form.component';
import { app_custom_asn_order_editorComponent } from './app.custom_asn_order_editor.component';
import { app_custom_dock_appointment_editorComponent } from './app.custom_dock_appointment_editor.component';
import { app_custom_inventory_count_editorComponent } from './app.custom_inventory_count_editor.component';
import { app_custom_load_container_editorComponent } from './app.custom_load_container_editor.component';
import { app_custom_location_editorComponent } from './app.custom_location_editor.component';
import { app_custom_lot_editorComponent } from './app.custom_lot_editor.component';
import { app_custom_purchase_order_editorComponent } from './app.custom_purchase_order_editor.component';
import { app_custom_sales_order_editorComponent } from './app.custom_sales_order_editor.component';
import { app_custom_warehouse_transfer_outbound_order_editorComponent } from './app.custom_warehouse_transfer_outbound_order_editor.component';
import { app_custom_work_order_editorComponent } from './app.custom_work_order_editor.component';
import { app_custom_asn_orderlines_gridComponent } from './app.custom_asn_orderlines_grid.component';
import { app_custom_contact_addresses_gridComponent } from './app.custom_contact_addresses_grid.component';
import { app_custom_dock_appointments_gridComponent } from './app.custom_dock_appointments_grid.component';
import { app_custom_excel_location_import_gridComponent } from './app.custom_excel_location_import_grid.component';
import { app_custom_inbound_orders_gridComponent } from './app.custom_inbound_orders_grid.component';
import { app_custom_inventory_licenseplate_gridComponent } from './app.custom_inventory_licenseplate_grid.component';
import { app_custom_inventory_transfer_lines_gridComponent } from './app.custom_inventory_transfer_lines_grid.component';
import { app_custom_material_selector_gridComponent } from './app.custom_material_selector_grid.component';
import { app_custom_materials_udf_gridComponent } from './app.custom_materials_udf_grid.component';
import { app_custom_orderaddresses_gridComponent } from './app.custom_orderaddresses_grid.component';
import { app_custom_ownersaccounts_gridComponent } from './app.custom_ownersaccounts_grid.component';
import { app_custom_purchase_order_lines_gridComponent } from './app.custom_purchase_order_lines_grid.component';
import { app_custom_purchase_order_received_inventory_gridComponent } from './app.custom_purchase_order_received_inventory_grid.component';
import { app_custom_sales_order_lines_gridComponent } from './app.custom_sales_order_lines_grid.component';
import { app_custom_dock_appointments_hubComponent } from './app.custom_dock_appointments_hub.component';
import { app_custom_count_types_dd_singleComponent } from './app.custom_count_types_dd_single.component';
import { app_custom_licenseplate_statuses_dd_singleComponent } from './app.custom_licenseplate_statuses_dd_single.component';
import { app_custom_location_statuses_dd_singleComponent } from './app.custom_location_statuses_dd_single.component';
import { app_custom_lot_statuses_dd_singleComponent } from './app.custom_lot_statuses_dd_single.component';
import { app_custom_material_statuses_dd_singleComponent } from './app.custom_material_statuses_dd_single.component';
import { app_custom_material_udf_disposition_dd_singleComponent } from './app.custom_material_udf_disposition_dd_single.component';
import { app_custom_outbound_orderclasses_dd_singleComponent } from './app.custom_outbound_orderclasses_dd_single.component';
import { app_custom_outbound_processing_strategies_dd_singleComponent } from './app.custom_outbound_processing_strategies_dd_single.component';
import { app_custom_serialnumber_statuses_dd_singleComponent } from './app.custom_serialnumber_statuses_dd_single.component';
import { app_custom_string_licenseplate_statuses_dd_singleComponent } from './app.custom_string_licenseplate_statuses_dd_single.component';
import { app_custom_string_lot_statuses_dd_singleComponent } from './app.custom_string_lot_statuses_dd_single.component';
import { app_custom_udf_receiving_status_singleComponent } from './app.custom_udf_receiving_status_single.component';
import { app_custom_warehouses_dd_singleComponent } from './app.custom_warehouses_dd_single.component';
import { app_custom_count_types_dd_multiComponent } from './app.custom_count_types_dd_multi.component';
import { app_custom_licenseplate_statuses_dd_multiComponent } from './app.custom_licenseplate_statuses_dd_multi.component';
import { app_custom_location_statuses_dd_multiComponent } from './app.custom_location_statuses_dd_multi.component';
import { app_custom_lot_statuses_dd_multiComponent } from './app.custom_lot_statuses_dd_multi.component';
import { app_custom_material_statuses_dd_multiComponent } from './app.custom_material_statuses_dd_multi.component';
import { app_custom_material_udf_disposition_dd_multiComponent } from './app.custom_material_udf_disposition_dd_multi.component';
import { app_custom_outbound_orderclasses_dd_multiComponent } from './app.custom_outbound_orderclasses_dd_multi.component';
import { app_custom_outbound_processing_strategies_dd_multiComponent } from './app.custom_outbound_processing_strategies_dd_multi.component';
import { app_custom_serialnumber_statuses_dd_multiComponent } from './app.custom_serialnumber_statuses_dd_multi.component';
import { app_custom_string_licenseplate_statuses_dd_multiComponent } from './app.custom_string_licenseplate_statuses_dd_multi.component';
import { app_custom_string_lot_statuses_dd_multiComponent } from './app.custom_string_lot_statuses_dd_multi.component';
import { app_custom_udf_receiving_status_multiComponent } from './app.custom_udf_receiving_status_multi.component';
import { app_custom_warehouses_dd_multiComponent } from './app.custom_warehouses_dd_multi.component';
import { app_custom_dock_appointments_total_pallets_widgetComponent } from './app.custom_dock_appointments_total_pallets_widget.component';

import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { AsnOrders_ShellService } from './AsnOrders.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { Cartonization_ShellService } from './Cartonization.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { LoadContainers_ShellService } from './LoadContainers.shell.service';
import { LaborManagement_ShellService } from './LaborManagement.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { ShippingContainers_ShellService } from './ShippingContainers.shell.service';
import { TemperatureReadings_ShellService } from './TemperatureReadings.shell.service';
import { WarehouseTransfers_ShellService } from './WarehouseTransfers.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Replenishments_ShellService } from './Replenishments.shell.service';
import { SerialNumbers_ShellService } from './SerialNumbers.shell.service';
import { ExcelLocationImport_ShellService } from './ExcelLocationImport.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { WorkOrders_ShellService } from './WorkOrders.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { InventoryCounts_ShellService } from './InventoryCounts.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { PackVerification_ShellService } from './PackVerification.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';
import { LocusRobotics_ShellService } from './LocusRobotics.shell.service';
import { Reports_ShellService } from './Reports.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public AsnOrders: AsnOrders_ShellService,
    public Carriers: Carriers_ShellService,
    public Cartonization: Cartonization_ShellService,
    public Locations: Locations_ShellService,
    public Lots: Lots_ShellService,
    public LoadContainers: LoadContainers_ShellService,
    public LaborManagement: LaborManagement_ShellService,
    public Owners: Owners_ShellService,
    public ShippingContainers: ShippingContainers_ShellService,
    public TemperatureReadings: TemperatureReadings_ShellService,
    public WarehouseTransfers: WarehouseTransfers_ShellService,
    public Notifications: Notifications_ShellService,
    public Replenishments: Replenishments_ShellService,
    public SerialNumbers: SerialNumbers_ShellService,
    public ExcelLocationImport: ExcelLocationImport_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public WorkOrders: WorkOrders_ShellService,
    public Materials: Materials_ShellService,
    public InventoryCounts: InventoryCounts_ShellService,
    public Inventory: Inventory_ShellService,
    public PackVerification: PackVerification_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
    public LocusRobotics: LocusRobotics_ShellService,
    public Reports: Reports_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opencustom_dock_appointment_creation_form(inParams:{ warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Dock Appointment',
        referenceName: 'custom_dock_appointment_creation_form',
        component: app_custom_dock_appointment_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointment_creation_formDialog(
    inParams:{ warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointment_creation_formComponent,
      'Create Dock Appointment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_fda_hold_options_form(inParams:{ awb?: string, entryNumber?: string, palletCount?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'FDA Hold Options',
        referenceName: 'custom_fda_hold_options_form',
        component: app_custom_fda_hold_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_fda_hold_options_formDialog(
    inParams:{ awb?: string, entryNumber?: string, palletCount?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, awb?: string, entryNumber?: string, palletCount?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_fda_hold_options_formComponent,
      'FDA Hold Options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_creation_form(inParams:{ projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Inventory',
        referenceName: 'custom_inventory_creation_form',
        component: app_custom_inventory_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_creation_formDialog(
    inParams:{ projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_creation_formComponent,
      'Create Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_project_update_project_form(inParams:{ ownerId: number, currentProjectId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select Target Project',
        referenceName: 'custom_project_update_project_form',
        component: app_custom_project_update_project_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_project_update_project_formDialog(
    inParams:{ ownerId: number, currentProjectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_project_update_project_formComponent,
      'Select Target Project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Sales Order',
        referenceName: 'custom_sales_order_creation_form',
        component: app_custom_sales_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_creation_formComponent,
      'Create Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_tester_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_tester_form',
        referenceName: 'custom_tester_form',
        component: app_custom_tester_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_tester_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_tester_formComponent,
      'custom_tester_form',
      mode,
      dialogSize
    )
  }
  public opencustom_vendor_lot_creation_form(inParams:{ materialId: number, vendorLookupcode?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Vendor Lot',
        referenceName: 'custom_vendor_lot_creation_form',
        component: app_custom_vendor_lot_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_vendor_lot_creation_formDialog(
    inParams:{ materialId: number, vendorLookupcode?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ vendorLotId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_vendor_lot_creation_formComponent,
      'Create Vendor Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_asn_order_editor(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit ASN Order',
        referenceName: 'custom_asn_order_editor',
        component: app_custom_asn_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_asn_order_editorDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_asn_order_editorComponent,
      'Edit ASN Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_appointment_editor(inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Editor',
        referenceName: 'custom_dock_appointment_editor',
        component: app_custom_dock_appointment_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointment_editorDialog(
    inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointment_editorComponent,
      'Dock Appointment Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_count_editor(inParams:{ inventoryCountId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory Count',
        referenceName: 'custom_inventory_count_editor',
        component: app_custom_inventory_count_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_count_editorDialog(
    inParams:{ inventoryCountId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_count_editorComponent,
      'Inventory Count',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_load_container_editor(inParams:{ loadContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load Container Editor',
        referenceName: 'custom_load_container_editor',
        component: app_custom_load_container_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_load_container_editorDialog(
    inParams:{ loadContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_load_container_editorComponent,
      'Load Container Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_location_editor(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Location',
        referenceName: 'custom_location_editor',
        component: app_custom_location_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_location_editorDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_location_editorComponent,
      'Edit Location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_lot_editor(inParams:{ lotId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Lot',
        referenceName: 'custom_lot_editor',
        component: app_custom_lot_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_lot_editorDialog(
    inParams:{ lotId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_lot_editorComponent,
      'Edit Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Purchase Order',
        referenceName: 'custom_purchase_order_editor',
        component: app_custom_purchase_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_editorComponent,
      'Edit Purchase Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Sales Order',
        referenceName: 'custom_sales_order_editor',
        component: app_custom_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_editorComponent,
      'Edit Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_warehouse_transfer_outbound_order_editor(inParams:{ orderId: number, warehouseTransferId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit outbound order',
        referenceName: 'custom_warehouse_transfer_outbound_order_editor',
        component: app_custom_warehouse_transfer_outbound_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_warehouse_transfer_outbound_order_editorDialog(
    inParams:{ orderId: number, warehouseTransferId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_warehouse_transfer_outbound_order_editorComponent,
      'Edit outbound order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_work_order_editor(inParams:{ workOrderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Work Order',
        referenceName: 'custom_work_order_editor',
        component: app_custom_work_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_work_order_editorDialog(
    inParams:{ workOrderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_work_order_editorComponent,
      'Edit Work Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_asn_orderlines_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order Lines',
        referenceName: 'custom_asn_orderlines_grid',
        component: app_custom_asn_orderlines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_asn_orderlines_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_asn_orderlines_gridComponent,
      'Order Lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_contact_addresses_grid(inParams:{ entity: string, entityId: number, typeIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Addresses',
        referenceName: 'custom_contact_addresses_grid',
        component: app_custom_contact_addresses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_contact_addresses_gridDialog(
    inParams:{ entity: string, entityId: number, typeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_contact_addresses_gridComponent,
      'Addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_appointments_grid(inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointments',
        referenceName: 'custom_dock_appointments_grid',
        component: app_custom_dock_appointments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointments_gridDialog(
    inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointments_gridComponent,
      'Dock Appointments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_excel_location_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Location import',
        referenceName: 'custom_excel_location_import_grid',
        component: app_custom_excel_location_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_excel_location_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_excel_location_import_gridComponent,
      'Location import',
      mode,
      dialogSize
    )
  }
  public opencustom_inbound_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inbound orders',
        referenceName: 'custom_inbound_orders_grid',
        component: app_custom_inbound_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inbound_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inbound_orders_gridComponent,
      'Inbound orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_licenseplate_grid(inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by LP',
        referenceName: 'custom_inventory_licenseplate_grid',
        component: app_custom_inventory_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_licenseplate_gridDialog(
    inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_licenseplate_gridComponent,
      'Inventory by LP',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_transfer_lines_grid(inParams:{ inventoryTransferId: number, sourceProjectId: number, targetProjectId: number, warehouseId: number, can_update?: boolean, can_delete?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory transfer lines',
        referenceName: 'custom_inventory_transfer_lines_grid',
        component: app_custom_inventory_transfer_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_transfer_lines_gridDialog(
    inParams:{ inventoryTransferId: number, sourceProjectId: number, targetProjectId: number, warehouseId: number, can_update?: boolean, can_delete?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_transfer_lines_gridComponent,
      'Inventory transfer lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_material_selector_grid(inParams:{ selectedMaterialIds?: number[], isExclude?: boolean, allowEdit: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material selector',
        referenceName: 'custom_material_selector_grid',
        component: app_custom_material_selector_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_material_selector_gridDialog(
    inParams:{ selectedMaterialIds?: number[], isExclude?: boolean, allowEdit: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ selectedMaterialIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_material_selector_gridComponent,
      'Material selector',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_materials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Materials UDF',
        referenceName: 'custom_materials_udf_grid',
        component: app_custom_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_materials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_materials_udf_gridComponent,
      'Custom Materials UDF',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_orderaddresses_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order addresses',
        referenceName: 'custom_orderaddresses_grid',
        component: app_custom_orderaddresses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_orderaddresses_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_orderaddresses_gridComponent,
      'Order addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_ownersaccounts_grid(inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Addresses',
        referenceName: 'custom_ownersaccounts_grid',
        component: app_custom_ownersaccounts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_ownersaccounts_gridDialog(
    inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ accountId?: number, contactId?: number, addressId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_ownersaccounts_gridComponent,
      'Addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order Lines',
        referenceName: 'custom_purchase_order_lines_grid',
        component: app_custom_purchase_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_lines_gridComponent,
      'Order Lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_received_inventory_grid(inParams:{ orderId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Received Inventory',
        referenceName: 'custom_purchase_order_received_inventory_grid',
        component: app_custom_purchase_order_received_inventory_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_received_inventory_gridDialog(
    inParams:{ orderId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_received_inventory_gridComponent,
      'Received Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order Lines',
        referenceName: 'custom_sales_order_lines_grid',
        component: app_custom_sales_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_lines_gridComponent,
      'Order Lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_appointments_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointments Hub',
        referenceName: 'custom_dock_appointments_hub',
        component: app_custom_dock_appointments_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_dock_appointments_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointments_hubComponent,
      'Dock Appointments Hub',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'custom_dock_appointment_creation_form') {
      const title = 'Create Dock Appointment';
      const component = app_custom_dock_appointment_creation_formComponent;
      const inParams:{ warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number } = { warehouseId: [], lookupcode: null, scheduledArrival: null, scheduledDeparture: null, dockDoor: null, typeId: null, ownerId: null, projectId: null, carrierId: null, orderId: null, loadContainerId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lookupcode'))) {
        const paramValueString = params.get('lookupcode');
        // TODO: date
        inParams.lookupcode = paramValueString;
              }
      if (!isNil(params.get('scheduledArrival'))) {
        const paramValueString = params.get('scheduledArrival');
        // TODO: date
        inParams.scheduledArrival = paramValueString;
              }
      if (!isNil(params.get('scheduledDeparture'))) {
        const paramValueString = params.get('scheduledDeparture');
        // TODO: date
        inParams.scheduledDeparture = paramValueString;
              }
      if (!isNil(params.get('dockDoor'))) {
        const paramValueString = params.get('dockDoor');
        inParams.dockDoor = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_fda_hold_options_form') {
      const title = 'FDA Hold Options';
      const component = app_custom_fda_hold_options_formComponent;
      const inParams:{ awb?: string, entryNumber?: string, palletCount?: number } = { awb: null, entryNumber: null, palletCount: null };
      if (!isNil(params.get('awb'))) {
        const paramValueString = params.get('awb');
        // TODO: date
        inParams.awb = paramValueString;
              }
      if (!isNil(params.get('entryNumber'))) {
        const paramValueString = params.get('entryNumber');
        // TODO: date
        inParams.entryNumber = paramValueString;
              }
      if (!isNil(params.get('palletCount'))) {
        const paramValueString = params.get('palletCount');
        inParams.palletCount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_creation_form') {
      const title = 'Create Inventory';
      const component = app_custom_inventory_creation_formComponent;
      const inParams:{ projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number } = { projectId: null, warehouseId: null, materialId: null, packagingId: null, lotId: null, licenseplateId: null, ownerId: null, locationId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_project_update_project_form') {
      const title = 'Select Target Project';
      const component = app_custom_project_update_project_formComponent;
      const inParams:{ ownerId: number, currentProjectId?: number } = { ownerId: null, currentProjectId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('currentProjectId'))) {
        const paramValueString = params.get('currentProjectId');
        inParams.currentProjectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_creation_form') {
      const title = 'Create Sales Order';
      const component = app_custom_sales_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_tester_form') {
      const title = 'custom_tester_form';
      const component = app_custom_tester_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_vendor_lot_creation_form') {
      const title = 'Create Vendor Lot';
      const component = app_custom_vendor_lot_creation_formComponent;
      const inParams:{ materialId: number, vendorLookupcode?: string } = { materialId: null, vendorLookupcode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLookupcode'))) {
        const paramValueString = params.get('vendorLookupcode');
        // TODO: date
        inParams.vendorLookupcode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_asn_order_editor') {
      const title = 'Edit ASN Order';
      const component = app_custom_asn_order_editorComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointment_editor') {
      const title = 'Dock Appointment Editor';
      const component = app_custom_dock_appointment_editorComponent;
      const inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number } = { dockAppointmentId: null, orderId: null, loadContainerId: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_count_editor') {
      const title = 'Inventory Count';
      const component = app_custom_inventory_count_editorComponent;
      const inParams:{ inventoryCountId: number } = { inventoryCountId: null };
      if (!isNil(params.get('inventoryCountId'))) {
        const paramValueString = params.get('inventoryCountId');
        inParams.inventoryCountId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_load_container_editor') {
      const title = 'Load Container Editor';
      const component = app_custom_load_container_editorComponent;
      const inParams:{ loadContainerId: number } = { loadContainerId: null };
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_location_editor') {
      const title = 'Edit Location';
      const component = app_custom_location_editorComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lot_editor') {
      const title = 'Edit Lot';
      const component = app_custom_lot_editorComponent;
      const inParams:{ lotId: number } = { lotId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_editor') {
      const title = 'Edit Purchase Order';
      const component = app_custom_purchase_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_editor') {
      const title = 'Edit Sales Order';
      const component = app_custom_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_warehouse_transfer_outbound_order_editor') {
      const title = 'Edit outbound order';
      const component = app_custom_warehouse_transfer_outbound_order_editorComponent;
      const inParams:{ orderId: number, warehouseTransferId: number } = { orderId: null, warehouseTransferId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseTransferId'))) {
        const paramValueString = params.get('warehouseTransferId');
        inParams.warehouseTransferId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_work_order_editor') {
      const title = 'Edit Work Order';
      const component = app_custom_work_order_editorComponent;
      const inParams:{ workOrderId: number } = { workOrderId: null };
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_asn_orderlines_grid') {
      const title = 'Order Lines';
      const component = app_custom_asn_orderlines_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_contact_addresses_grid') {
      const title = 'Addresses';
      const component = app_custom_contact_addresses_gridComponent;
      const inParams:{ entity: string, entityId: number, typeIds?: number[] } = { entity: null, entityId: null, typeIds: [] };
      if (!isNil(params.get('entity'))) {
        const paramValueString = params.get('entity');
        // TODO: date
        inParams.entity = paramValueString;
              }
      if (!isNil(params.get('entityId'))) {
        const paramValueString = params.get('entityId');
        inParams.entityId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointments_grid') {
      const title = 'Dock Appointments';
      const component = app_custom_dock_appointments_gridComponent;
      const inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] } = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], fromDate: null, toDate: null, dockDoorIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('dockDoorIds'))) {
        const paramValueString = params.get('dockDoorIds');
        inParams.dockDoorIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_excel_location_import_grid') {
      const title = 'Location import';
      const component = app_custom_excel_location_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inbound_orders_grid') {
      const title = 'Inbound orders';
      const component = app_custom_inbound_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], orderClassIds: [], reference: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderClassIds'))) {
        const paramValueString = params.get('orderClassIds');
        inParams.orderClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_licenseplate_grid') {
      const title = 'Inventory by LP';
      const component = app_custom_inventory_licenseplate_gridComponent;
      const inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { licenseplateId: null, ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_transfer_lines_grid') {
      const title = 'Inventory transfer lines';
      const component = app_custom_inventory_transfer_lines_gridComponent;
      const inParams:{ inventoryTransferId: number, sourceProjectId: number, targetProjectId: number, warehouseId: number, can_update?: boolean, can_delete?: boolean } = { inventoryTransferId: null, sourceProjectId: null, targetProjectId: null, warehouseId: null, can_update: null, can_delete: null };
      if (!isNil(params.get('inventoryTransferId'))) {
        const paramValueString = params.get('inventoryTransferId');
        inParams.inventoryTransferId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('sourceProjectId'))) {
        const paramValueString = params.get('sourceProjectId');
        inParams.sourceProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('targetProjectId'))) {
        const paramValueString = params.get('targetProjectId');
        inParams.targetProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('can_update'))) {
        const paramValueString = params.get('can_update');
        inParams.can_update = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('can_delete'))) {
        const paramValueString = params.get('can_delete');
        inParams.can_delete = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_material_selector_grid') {
      const title = 'Material selector';
      const component = app_custom_material_selector_gridComponent;
      const inParams:{ selectedMaterialIds?: number[], isExclude?: boolean, allowEdit: boolean } = { selectedMaterialIds: [], isExclude: null, allowEdit: null };
      if (!isNil(params.get('selectedMaterialIds'))) {
        const paramValueString = params.get('selectedMaterialIds');
        inParams.selectedMaterialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('isExclude'))) {
        const paramValueString = params.get('isExclude');
        inParams.isExclude = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('allowEdit'))) {
        const paramValueString = params.get('allowEdit');
        inParams.allowEdit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_materials_udf_grid') {
      const title = 'Custom Materials UDF';
      const component = app_custom_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_orderaddresses_grid') {
      const title = 'Order addresses';
      const component = app_custom_orderaddresses_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_ownersaccounts_grid') {
      const title = 'Addresses';
      const component = app_custom_ownersaccounts_gridComponent;
      const inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean } = { ownerId: null, accountTypeId: null, typeIds: [], allowSelection: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('accountTypeId'))) {
        const paramValueString = params.get('accountTypeId');
        inParams.accountTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('allowSelection'))) {
        const paramValueString = params.get('allowSelection');
        inParams.allowSelection = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_lines_grid') {
      const title = 'Order Lines';
      const component = app_custom_purchase_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_received_inventory_grid') {
      const title = 'Received Inventory';
      const component = app_custom_purchase_order_received_inventory_gridComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_lines_grid') {
      const title = 'Order Lines';
      const component = app_custom_sales_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null, warehouseId: null, orderState: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderState'))) {
        const paramValueString = params.get('orderState');
        inParams.orderState = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointments_hub') {
      const title = 'Dock Appointments Hub';
      const component = app_custom_dock_appointments_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_count_types_dd_single') {
      const title = 'custom_count_types_dd';
      const component = app_custom_count_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_licenseplate_statuses_dd_single') {
      const title = 'Licenseplate Statuses Dropdown.';
      const component = app_custom_licenseplate_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_location_statuses_dd_single') {
      const title = 'Location Statuses Dropdown.';
      const component = app_custom_location_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lot_statuses_dd_single') {
      const title = 'Lot Status';
      const component = app_custom_lot_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_material_statuses_dd_single') {
      const title = 'Material Statuses dropdown';
      const component = app_custom_material_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_material_udf_disposition_dd_single') {
      const title = 'custom_material_udf_disposition_dd';
      const component = app_custom_material_udf_disposition_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_orderclasses_dd_single') {
      const title = 'Orderclasses by sales order filtered by UA, DEST';
      const component = app_custom_outbound_orderclasses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_processing_strategies_dd_single') {
      const title = 'custom_outbound_processing_strategies_dd';
      const component = app_custom_outbound_processing_strategies_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_serialnumber_statuses_dd_single') {
      const title = 'Serialnumber statuses Dropdown';
      const component = app_custom_serialnumber_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_string_licenseplate_statuses_dd_single') {
      const title = 'String based Licenseplate Statuses Dropdown';
      const component = app_custom_string_licenseplate_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_string_lot_statuses_dd_single') {
      const title = 'String based lot statuses dropdown';
      const component = app_custom_string_lot_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_udf_receiving_status_single') {
      const title = 'custom_udf_receiving_status';
      const component = app_custom_udf_receiving_status_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_warehouses_dd_single') {
      const title = 'custom_warehouses_dd';
      const component = app_custom_warehouses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_count_types_dd_multi') {
      const title = 'custom_count_types_dd';
      const component = app_custom_count_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_licenseplate_statuses_dd_multi') {
      const title = 'Licenseplate Statuses Dropdown.';
      const component = app_custom_licenseplate_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_location_statuses_dd_multi') {
      const title = 'Location Statuses Dropdown.';
      const component = app_custom_location_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lot_statuses_dd_multi') {
      const title = 'Lot Status';
      const component = app_custom_lot_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_material_statuses_dd_multi') {
      const title = 'Material Statuses dropdown';
      const component = app_custom_material_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_material_udf_disposition_dd_multi') {
      const title = 'custom_material_udf_disposition_dd';
      const component = app_custom_material_udf_disposition_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_orderclasses_dd_multi') {
      const title = 'Orderclasses by sales order filtered by UA, DEST';
      const component = app_custom_outbound_orderclasses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_processing_strategies_dd_multi') {
      const title = 'custom_outbound_processing_strategies_dd';
      const component = app_custom_outbound_processing_strategies_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_serialnumber_statuses_dd_multi') {
      const title = 'Serialnumber statuses Dropdown';
      const component = app_custom_serialnumber_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_string_licenseplate_statuses_dd_multi') {
      const title = 'String based Licenseplate Statuses Dropdown';
      const component = app_custom_string_licenseplate_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_string_lot_statuses_dd_multi') {
      const title = 'String based lot statuses dropdown';
      const component = app_custom_string_lot_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_udf_receiving_status_multi') {
      const title = 'custom_udf_receiving_status';
      const component = app_custom_udf_receiving_status_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_warehouses_dd_multi') {
      const title = 'custom_warehouses_dd';
      const component = app_custom_warehouses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointments_total_pallets_widget') {
      const title = 'Total Pallets';
      const component = app_custom_dock_appointments_total_pallets_widgetComponent;
      const inParams:{ statusIds?: number[], carrierId?: number, typeIds?: number[], ownerId?: number, projectId?: number, locationIds?: number[], fromDate?: string, toDate?: string, fullTextSearch?: string, warehouseId?: number[] } = { statusIds: [], carrierId: null, typeIds: [], ownerId: null, projectId: null, locationIds: [], fromDate: null, toDate: null, fullTextSearch: null, warehouseId: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationIds'))) {
        const paramValueString = params.get('locationIds');
        inParams.locationIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.AsnOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Cartonization.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LoadContainers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LaborManagement.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ShippingContainers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.TemperatureReadings.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.WarehouseTransfers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Replenishments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SerialNumbers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelLocationImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.WorkOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.InventoryCounts.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PackVerification.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LocusRobotics.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
