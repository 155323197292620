import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { ShippingContainers_container_type_dd_singleComponent } from './ShippingContainers.container_type_dd_single.component'


interface IFootPrintManager_container_type_assignment_gridComponentEntity {
BoxName?: string, ContainerTypeId?: number, OwnerId?: number, ProjectId?: number, Enabled?: boolean, Width?: number, Length?: number, Height?: number, InnerWidth?: number, InnerLength?: number, InnerHeight?: number, Weight?: number, WeightUom?: string, DimensionUom?: string, WeightCapacity?: number, VolumeCapacity?: number, Volume?: string, Description?: string, CatchAll?: boolean}

interface IFootPrintManager_container_type_assignment_gridComponentInParams {
  ownerId?: number, projectId?: number, materialId?: number, enabled?: boolean, assignmentLevel?: string}


class FootPrintManager_container_type_assignment_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_container_type_assignment_gridComponent;
  entity: IFootPrintManager_container_type_assignment_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    Name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    CatchAll_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['Name_edit'] as DatexFormControl, 
        null, null,
        true, 
        '')
      ),
    Description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Length: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Width: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Height: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Dimension_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    WeightCapacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    CatchAll: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['CatchAll_display'] as DatexFormControl, null, true, '')
,
null
      ),
  }

  get $fields_Name_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.ownerId;
    
    return expr;
  }
  get $fields_Name_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.projectId;
    
    return expr;
  }
  get $fields_Name_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.materialId;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
    this.formGroup
      .controls['Name_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_name_value_changed();
      });
  }

  async $initializeExisting(grid: FootPrintManager_container_type_assignment_gridComponent, entity: IFootPrintManager_container_type_assignment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.ContainerTypeId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_container_type_assignment_gridComponent, entity?: IFootPrintManager_container_type_assignment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.ContainerTypeId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.ContainerTypeId;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      materialId:  $grid.inParams.materialId ,
    };
    const data = await this.datasources.ShippingContainers.ds_container_types_assignment_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Name.displayControl as TextModel).text = $row.entity.BoxName;
    (this.cells.Name.editControl as SelectBoxModel).reset($row.entity.BoxName);
    (this.cells.Description.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.Length.displayControl as TextModel).text = $row.entity.Length?.toString();
    (this.cells.Width.displayControl as TextModel).text = $row.entity.Width?.toString();
    (this.cells.Height.displayControl as TextModel).text = $row.entity.Height?.toString();
    (this.cells.Dimension_uom.displayControl as TextModel).text = $row.entity.DimensionUom;
    (this.cells.WeightCapacity.displayControl as TextModel).text = $row.entity.WeightCapacity?.toString();
    (this.cells.Weight_uom.displayControl as TextModel).text = $row.entity.WeightUom;
    (this.cells.CatchAll.displayControl as CheckBoxModel).reset($row.entity.CatchAll);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_container_type_assignment_gridComponentRowModel,
  $grid: FootPrintManager_container_type_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.Name.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Owners.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  try {
  
      let results: string[] = [];
  
      if ($utils.isDefined($grid.inParams.materialId)) {
          results = (await $flows.ShippingContainers.container_assignment_flow({
              containerTypeId: $row.cells.Name.editControl.value, materialId: $grid.inParams.materialId
          })).reasons
  
          if ($utils.isDefined(results)) {
              throw new Error(results[0])
          }
      }
      else if ($utils.isDefined($grid.inParams.ownerId) || $utils.isDefined($grid.inParams.projectId)) {
          results = (await $flows.ShippingContainers.container_assignment_flow({
              containerTypeId: $row.cells.Name.editControl.value, ownerId: $grid.inParams.ownerId, projectId: $grid.inParams.projectId
          })).reasons
  
          if ($utils.isDefined(results)) {
              throw new Error(results[0])
          }
      }
  
  
      await $grid.refresh();
  
  } catch (error) {
      $shell.Owners.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  }
  on_name_value_changed(event = null) {
    return this.on_name_value_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_name_value_changedInternal(
    $row: FootPrintManager_container_type_assignment_gridComponentRowModel,
  $grid: FootPrintManager_container_type_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let containerInfo = (await $datasources.ShippingContainers.ds_get_container_types_by_Id_top1.get({Id: $row.cells.Name.editControl.value})).result
  
  
  $row.cells.Description.displayControl.text = containerInfo?.Description;
  $row.cells.Length.displayControl.text = containerInfo?.Length?.toString();
  $row.cells.Width.displayControl.text = containerInfo?.Width?.toString();
  $row.cells.Height.displayControl.text = containerInfo?.Height?.toString();
  $row.cells.Dimension_uom.displayControl.text = containerInfo?.DimensionUom?.Short_name;
  $row.cells.Weight_uom.displayControl.text = containerInfo?.WeightUom?.Short_name;
  $row.cells.WeightCapacity.displayControl.text = containerInfo?.WeightCapacity?.toString();
  $row.cells.CatchAll.displayControl.value = containerInfo?.CatchAll;
  
  
  
  
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_container_type_assignment_gridComponentRowModel,
  $grid: FootPrintManager_container_type_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.Name.editControl.readOnly = false; 
  $grid.canEdit = true;
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => ShippingContainers_container_type_dd_singleComponent),
  ],
  selector: 'FootPrintManager-container_type_assignment_grid',
  templateUrl: './FootPrintManager.container_type_assignment_grid.component.html'
})
export class FootPrintManager_container_type_assignment_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_container_type_assignment_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_container_type_assignment_gridComponentInParams = { ownerId: null, projectId: null, materialId: null, enabled: null, assignmentLevel: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', true, false, null),       Description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),       Length: new GridHeaderModel(new HeaderStyles(null, null), 'Length', false, false, null),       Width: new GridHeaderModel(new HeaderStyles(null, null), 'Width', false, false, null),       Height: new GridHeaderModel(new HeaderStyles(null, null), 'Height', false, false, null),       Dimension_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Dimension UOM', false, false, null),       WeightCapacity: new GridHeaderModel(new HeaderStyles(null, null), 'Weight capacity', false, false, null),       Weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Weight UOM', false, false, null),       CatchAll: new GridHeaderModel(new HeaderStyles(null, null), 'Catch all', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_container_type_assignment_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('enabled') set $inParams_enabled(value: any) {
    this.inParams['enabled'] = value;
  }
  get $inParams_enabled(): any {
    return this.inParams['enabled'] ;
  }
  @Input('assignmentLevel') set $inParams_assignmentLevel(value: any) {
    this.inParams['assignmentLevel'] = value;
  }
  get $inParams_assignmentLevel(): any {
    return this.inParams['assignmentLevel'] ;
  }

  topToolbar = {
      browse_containers_button: new ToolModel(new ButtonModel('browse_containers_button', new ButtonStyles(null, null), false, 'Browse containers', 'ms-Icon ms-Icon--Zoom')
    ),
      unassign_containers: new ToolModel(new ButtonModel('unassign_containers', new ButtonStyles(null, null), false, 'Unassign', 'ms-Icon ms-Icon--ProductWarning')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Container assignment grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      materialId:  $grid.inParams.materialId ,
    };
    try {
    const data = await this.datasources.ShippingContainers.ds_container_types_assignment_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_container_type_assignment_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  async addRow(entity?: IFootPrintManager_container_type_assignment_gridComponentEntity) {
    const row = new FootPrintManager_container_type_assignment_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_container_type_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.canEdit = false;
  
  $grid.inParams.ownerId
  $grid.inParams.enabled
  }
  on_browse_containers_clicked(event = null) {
    return this.on_browse_containers_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_browse_containers_clickedInternal(
    $grid: FootPrintManager_container_type_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  let results = await $shell.FootPrintManager.opencontainer_types_gridDialog({ownerId: $grid.inParams.ownerId, projectId: $grid.inParams.projectId, materialId: $grid.inParams.materialId});
  
  $grid.refresh();
  }
  on_unassign_clicked(event = null) {
    return this.on_unassign_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unassign_clickedInternal(
    $grid: FootPrintManager_container_type_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let result: string[] = [];
  if (!$utils.isDefined($grid.inParams.materialId) || $grid.inParams.materialId === null) {
      for (let row of $grid.selectedRows) {
          result = (await $flows.ShippingContainers.container_unassignment_flow({ ownerId: $grid.inParams.ownerId, projectId: $grid.inParams.projectId, containerId: row.entity.ContainerTypeId, assignmentLevel: $grid.inParams.assignmentLevel })).reasons
      }
  }
  else {
      for (let row of $grid.selectedRows) {
          result = (await $flows.ShippingContainers.container_unassignment_flow({ materialId: $grid.inParams.materialId, containerId: row.entity.ContainerTypeId, assignmentLevel: $grid.inParams.assignmentLevel })).reasons
      }
  }
  $grid.refresh();
  
  
  if ($utils.isDefined(result)) {
      $shell.FootPrintManager.openErrorDialog('Error unassigning container', result.join(' ,'))
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
