import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService, EModalSize, EToasterType, EToasterPosition } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { Notifications_auto_email_rule_contexts_dd_singleComponent } from './Notifications.auto_email_rule_contexts_dd_single.component'


interface INotifications_auto_email_report_configurations_gridComponentEntity {
configuration_id?: string, application_name?: string, user_name?: string, name?: string, value?: string, notes?: string, string_1?: string, string_2?: string, string_3?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, date_number?: number}



class Notifications_auto_email_report_configurations_gridComponentRowModel extends GridRowModel {
  grid: Notifications_auto_email_report_configurations_gridComponent;
  entity: INotifications_auto_email_report_configurations_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    context_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    label_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    package_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    report_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    context: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['context_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    label: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['label_edit'] as DatexFormControl, null, false, '')
      ),
    package_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['package_name_edit'] as DatexFormControl, null, false, '')
      ),
    report_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['report_name_edit'] as DatexFormControl, null, false, '')
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: Notifications_auto_email_report_configurations_gridComponent, entity: INotifications_auto_email_report_configurations_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.configuration_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_auto_email_report_configurations_gridComponent, entity?: INotifications_auto_email_report_configurations_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.configuration_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.configuration_id;
    const inParams = {
      $keys:[$resultKey],
      application_name:  $grid.vars.application_name ,
      user_name:  null ,
      name:  $grid.vars.config_name ,
      full_text_search:  $grid.fullTextSearch ,
      string_1:  null ,
      string_2:  null ,
      string_3:  null ,
    };
    const data = await this.datasources.Utilities.ds_configurations_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.configuration_id;
    (this.cells.context.displayControl as TextModel).text = $row.entity.string_1;
    (this.cells.context.editControl as SelectBoxModel).reset($row.entity.string_1);
    (this.cells.label.displayControl as TextModel).text = $row.entity.value;
    (this.cells.label.editControl as TextBoxModel).reset($row.entity.value);
    (this.cells.package_name.displayControl as TextModel).text = $row.entity.string_2;
    (this.cells.package_name.editControl as TextBoxModel).reset($row.entity.string_2);
    (this.cells.report_name.displayControl as TextModel).text = $row.entity.string_3;
    (this.cells.report_name.editControl as TextBoxModel).reset($row.entity.string_3);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.notes);
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.created_by;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.created_on?.toString();
    (this.cells.modified_by.displayControl as TextModel).text = $row.entity.modified_by;
    (this.cells.modified_on.displayControl as TextModel).text = $row.entity.modified_on?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Notifications_auto_email_report_configurations_gridComponentRowModel,
  $grid: Notifications_auto_email_report_configurations_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_on)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.created_on, format);
      }
      if ($utils.isDefined($row.entity.modified_on)) {
          $row.cells.modified_on.displayControl.text = $utils.date.format($row.entity.modified_on, format);
      }
  }
  
  let notes = $row.entity.notes;
  
  if ($utils.isDefined(notes)) {
      notes = (notes.length > 75) ? notes.substring(0, 75) : notes;
      $row.cells.notes.displayControl.text = notes;
  }
  
  let value = $row.entity.value;
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Notifications_auto_email_report_configurations_gridComponentRowModel,
  $grid: Notifications_auto_email_report_configurations_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let missing_fields: string[] = [];
  if (!$utils.isDefinedTrimmed($row.cells.context.editControl.value)) {
      missing_fields.push('Context');
  }
  if (!$utils.isDefinedTrimmed($row.cells.label.editControl.value)) {
      missing_fields.push('Label');
  }
  
  if (!$utils.isDefinedTrimmed($row.cells.package_name.editControl.value)) {
      missing_fields.push('Package');
  }
  if (!$utils.isDefinedTrimmed($row.cells.report_name.editControl.value)) {
      missing_fields.push('Report');
  }
  
  if ($utils.isDefined(missing_fields))  {
      await $shell.Notifications.openErrorDialog('Missing required fields', `Missing required fields: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: missing_fields})).formattedValue}`);
      return;
  }
  
  if ($row.cells.context.editControl.isChanged ||
      $row.cells.package_name.editControl.isChanged ||
      $row.cells.notes.editControl.isChanged ||
      $row.cells.label.editControl.isChanged ||
      $row.cells.report_name.editControl.isChanged) {
  
      let config_id: string = $row.entity?.configuration_id ?? "";
      let config_name: string = $grid.vars.config_name;
      let config_value: string = $row.cells.label.editControl.value;
      let config_notes: string = $row.cells.notes.editControl.value;
      let string_1: string = $row.cells.context.editControl.value;
      let string_2: string = $row.cells.package_name.editControl.value;
      let string_3: string = $row.cells.report_name.editControl.value;
      let application_name: string = $grid.vars.application_name;
      let user_name: string = "";
  
      try {
          if (config_id === "" && application_name === "") {
              throw new Error("You need to specify the application to insert a configuration!")
          }
  
          let input = { configuration_id: config_id, name: config_name, value: config_value, notes: config_notes, user_name: user_name, string_1: string_1, string_2: string_2, string_3: string_3 };
          let criteria = { application_name: application_name, encrypt: false };
  
          // Check for value uniqueness 
          let existing_configs = (await $datasources.Utilities.ds_configurations_grid.getList({
              application_name: $grid.vars.application_name,
              name: $grid.vars.config_name
          })).result;
  
          let matching_config = existing_configs.find(c => c.configuration_id !== config_id && c.string_1.trim().toUpperCase() === string_1.trim().toUpperCase() && c.value.trim().toUpperCase() === config_value.trim().toUpperCase());
  
          if ($utils.isDefined(matching_config)) {
              throw new Error(`Report '${config_value}' already exists for context '${string_1}'.`);
          }
  
          if (config_id !== "") {
              let result = await $flows.Utilities.configurations_update({
                  input: [input],
                  criteria: criteria
              });
              
              if (!result.success) {
                  throw new Error(`Error updating configuration: ${result.error}`);
              }
          } else {
              let result = await $flows.Utilities.configurations_write({
                  input: [input],
                  criteria: criteria
              });
  
              if (!result.success) {
                  throw new Error(`Error creating configuration: ${result.error}`);
              }
          }
  
          $grid.refresh();
  
      } catch (error) {
          $shell.Utilities.openErrorDialog('Error saving configuration', error);
          throw error; // to prevent displayMode 
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_auto_email_rule_contexts_dd_singleComponent),
  ],
  selector: 'Notifications-auto_email_report_configurations_grid',
  templateUrl: './Notifications.auto_email_report_configurations_grid.component.html'
})
export class Notifications_auto_email_report_configurations_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_auto_email_report_configurations_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;



  //#region Variables
  vars: { application_name?: string, config_name?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       context: new GridHeaderModel(new HeaderStyles(null, null), 'Context', true, false, null),       label: new GridHeaderModel(new HeaderStyles(null, null), 'Label', true, false, null),       package_name: new GridHeaderModel(new HeaderStyles(null, null), 'Package', true, false, null),       report_name: new GridHeaderModel(new HeaderStyles(null, null), 'Report', true, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       modified_by: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, null),       modified_on: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_auto_email_report_configurations_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      delete_configuration: new ToolModel(new ButtonModel('delete_configuration', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      field_selector: new ToolModel(new SplitButtonModel<{ fs_id: ButtonModel, fs_context: ButtonModel, fs_label: ButtonModel, fs_component_package: ButtonModel, fs_report_name: ButtonModel, fs_notes: ButtonModel, fs_created_by: ButtonModel, fs_created_on: ButtonModel, fs_modified_by: ButtonModel, fs_modified_on: ButtonModel }>(
        'field_selector',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_multiselect_ltr_20_filled',
        [
          new ButtonModel('fs_id', new ButtonStyles(null, null), false, 'ID', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_context', new ButtonStyles(['creation'], null), true, 'Context', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_label', new ButtonStyles(['creation'], null), true, 'Label', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_component_package', new ButtonStyles(['creation'], null), true, 'Package', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_report_name', new ButtonStyles(['creation'], null), true, 'Report', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_notes', new ButtonStyles(['creation'], null), false, 'Notes', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_created_by', new ButtonStyles(null, null), false, 'Created by', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_created_on', new ButtonStyles(null, null), false, 'Created on', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_modified_by', new ButtonStyles(null, null), false, 'Modified by', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_modified_on', new ButtonStyles(null, null), false, 'Modified on', 'icon-ic_fluent_circle_20_regular')
        ])
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    ) {
    super();
    this.title = 'Auto-email reports';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      application_name:  $grid.vars.application_name ,
      user_name:  null ,
      name:  $grid.vars.config_name ,
      full_text_search:  $grid.fullTextSearch ,
      string_1:  null ,
      string_2:  null ,
      string_3:  null ,
    };
    try {
    const data = await this.datasources.Utilities.ds_configurations_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_auto_email_report_configurations_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: INotifications_auto_email_report_configurations_gridComponentEntity) {
    const row = new Notifications_auto_email_report_configurations_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_auto_email_report_configurations_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.delete_configuration.control.readOnly = true;
  
  $grid.vars.application_name = 'AutoEmail';
  $grid.vars.config_name = 'Reports';
  
  
  $grid.headers.id.hidden = true;
  $grid.headers.created_by.hidden = true;
  $grid.headers.created_on.hidden = true;
  $grid.headers.modified_by.hidden = true;
  $grid.headers.modified_on.hidden = true;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: Notifications_auto_email_report_configurations_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Notifications_auto_email_report_configurations_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  const selected_rows = $grid.selectedRows;
  let errors: { name: string, id: string, errorconfigurations: string[] }[] = [];
  let resubmitted_records = [];
  
  let logs: $types.Utilities.Log[];
  let log_payload: typeof logs[0];
  
  let configurations: $types.Utilities.Configuration[];
  let configuration_payload: typeof configurations[0];
  
  if (selected_rows.length === 0) {
      return;
  }
  
  let plural_char = $grid.selectedRows.length > 1 ? 's' : '';
  let is_confirmed = await $shell.Notifications.openConfirmationDialog(
      `Delete auto-email report configuration${plural_char}?`,
      `Are you sure you want to delete the ${$grid.selectedRows.length > 1 ? `${$grid.selectedRows.length} ` : ''}selected report configuration${plural_char}?`,
      `Delete configuration${plural_char}`, 'Cancel');
  
  if (!is_confirmed) {
      return;
  }
  
  if (is_confirmed) {
  
      for (const row of selected_rows) {
  
          let current_error: string;
          let success = false;
          current_error = null;
  
          try {
  
              let entity = row.entity
  
              await $flows.Utilities.configurations_delete({
                  input: [{ configuration_id: entity.configuration_id }],
                  criteria: { application_name: entity.application_name }
              });
  
              await $flows.Utilities.logs_write({
                  input: [{
                      process_name: "configuration_grid",
                      level: 1,
                      notes: `Deleted setting!`,
                      message: JSON.stringify(entity)
                  }],
                  criteria: { application_name: entity.application_name }
              });
  
              success = true;
  
          } catch (error) {
  
              let target_error = error;
  
              if (typeof target_error === "string") {
                  target_error = { configuration: target_error }
              } else {
                  while ($utils.isDefined(target_error.error)) {
                      target_error = target_error.error;
                  }
              }
  
              if (!$utils.isDefined(target_error.configuration)) {
                  target_error = { configuration: `Uncaught exception! ${JSON.stringify(target_error)}` }
              }
  
              current_error = target_error.configuration;
          }
  
          if (success) {
              resubmitted_records.push(row.entity.configuration_id.toString())
          } else {
              errors.push({
                  name: row.entity.name, id: row.entity.configuration_id, errorconfigurations: [current_error]
              });
          }
      }
  
      if ($utils.isDefined(errors)) {
  
          await $shell.Utilities.openErrorDialog(
              `Delete record${selected_rows.length > 1 ? 's' : ''}`,
              resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
              null,
              `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
              errors.map(error => ({ detail: error.errorconfigurations[0], message: `Id: ${error.id} Name: ${error.name}` })))
      } else {
          $shell.Notifications.openToaster(`Deleted configuration${plural_char}`, `Successfully deleted ${$grid.selectedRows.length > 1 ? `${$grid.selectedRows.length} ` : ''}auto-email report configuration${plural_char}`,
          EToasterType.Success,
          { timeOut: 5000, positionClass: EToasterPosition.topRight });
      }
  }
  
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Notifications_auto_email_report_configurations_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_configuration.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: Notifications_auto_email_report_configurations_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($grid.rows.length > 0) {
  
      let ids: string[] = [];
  
      if ($utils.isDefined($grid.selectedRows)) {
          if ($grid.selectedRows.length > 0) {
              ids = $grid.selectedRows.map(o => o.entity.configuration_id);
          }
      }
  
      let full_text_search = $grid.fullTextSearch;
  
      let data = (await $datasources.Utilities.ds_configurations_grid.getList({
          full_text_search: full_text_search,
          application_name: $grid.vars.application_name
      }));
  
      if (data.totalCount > 0) {
  
          let config: any;
          let configs: any[] = [];
  
          let results = data.result;
  
          if (ids.length > 0) {
              results = results.filter(r => ids.indexOf(r.configuration_id) >= 0);
          }
  
          for (let result of results) {
              config = {
                  "Application": result.application_name,
                  "User Name": result.user_name,
                  "Name": result.name,
                  "Value": result.value.substring(0, 30000),
                  "Notes": result.notes.substring(0, 30000),
                  "Created By": result.created_by,
                  "Created On": result.created_on,
                  "Modified By": result.modified_by,
                  "Modified On": result.modified_on
              }
  
              config.push(config);
          }
  
          let wb = $event;
          let ws = $utils.excel.json_to_sheet(configs);
          $utils.excel.book_append_sheet(wb, ws, "Configurations");
  
      } else {
          $shell.Utilities.openInfoDialog('Error', 'The parameters did not return any records to export.')
      }
  } else {
      $shell.Utilities.openInfoDialog('Error', 'There are no records to export!')
  }
  }
  on_field_select_change(event = null) {
    return this.on_field_select_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_field_select_changeInternal(
    $grid: Notifications_auto_email_report_configurations_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let field_name = $event?.target?.innerText;
  let enabled = false;
  
  for (let key in $grid.headers) {
      if (field_name === $grid.headers[key].name) {
          if ($grid.headers[key].hidden) {
              $grid.headers[key].hidden = false;
              enabled = true;
          }  else {
              $grid.headers[key].hidden = true;
              enabled = false;
          }
      }
  }
  
  for (let key in $grid.topToolbar.field_selector.control.buttons) {
      if ($grid.topToolbar.field_selector.control.buttons[key].label === field_name) {
          if (enabled) {
              $grid.topToolbar.field_selector.control.buttons[key].icon = 'icon icon-ic_fluent_checkmark_circle_20_regular';
              $grid.topToolbar.field_selector.control.buttons[key].styles.setCreationClass();
          } else {
              $grid.topToolbar.field_selector.control.buttons[key].styles.clearClasses();
              $grid.topToolbar.field_selector.control.buttons[key].icon = 'icon icon-ic_fluent_circle_20_regular';
          }
      }
  }
  
  $event.preventDefault();
  $event.stopPropagation();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
