import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { Reports_HomeComponent } from './Reports.Home.component';
import { Reports_custom_appointment_badge_reportComponent } from './Reports.custom_appointment_badge_report.component';
import { Reports_custom_license_plate_report_by_serialIdComponent } from './Reports.custom_license_plate_report_by_serialId.component';
import { Reports_custom_outbound_labels_reportComponent } from './Reports.custom_outbound_labels_report.component';
import { Reports_custom_packing_list_reportComponent } from './Reports.custom_packing_list_report.component';
import { Reports_custom_packing_slip_reportComponent } from './Reports.custom_packing_slip_report.component';
import { Reports_custom_receiving_reportComponent } from './Reports.custom_receiving_report.component';
import { Reports_custom_transaction_reportComponent } from './Reports.custom_transaction_report.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openHome(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Reports_Home',
        component: Reports_HomeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openHomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_HomeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_appointment_badge_report(inParams:{ dockAppointmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_appointment_badge_report',
        referenceName: 'Reports_custom_appointment_badge_report',
        component: Reports_custom_appointment_badge_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_appointment_badge_reportDialog(
    inParams:{ dockAppointmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_appointment_badge_reportComponent,
      'custom_appointment_badge_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_license_plate_report_by_serialId(inParams:{ serialNumberId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_license_plate_report_by_serialId',
        referenceName: 'Reports_custom_license_plate_report_by_serialId',
        component: Reports_custom_license_plate_report_by_serialIdComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_license_plate_report_by_serialIdDialog(
    inParams:{ serialNumberId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_license_plate_report_by_serialIdComponent,
      'custom_license_plate_report_by_serialId',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_labels_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_outbound_labels_report',
        referenceName: 'Reports_custom_outbound_labels_report',
        component: Reports_custom_outbound_labels_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_labels_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_outbound_labels_reportComponent,
      'custom_outbound_labels_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_packing_list_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_packing_list_report',
        referenceName: 'Reports_custom_packing_list_report',
        component: Reports_custom_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_packing_list_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_packing_list_reportComponent,
      'custom_packing_list_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_packing_slip_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_packing_slip_report',
        referenceName: 'Reports_custom_packing_slip_report',
        component: Reports_custom_packing_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_packing_slip_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_packing_slip_reportComponent,
      'custom_packing_slip_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'Reports_custom_receiving_report',
        component: Reports_custom_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_transaction_report(inParams:{ notes: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_transaction_report',
        referenceName: 'Reports_custom_transaction_report',
        component: Reports_custom_transaction_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_transaction_reportDialog(
    inParams:{ notes: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_transaction_reportComponent,
      'custom_transaction_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_Home') {
      const title = 'Home';
      const component = Reports_HomeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_appointment_badge_report') {
      const title = 'custom_appointment_badge_report';
      const component = Reports_custom_appointment_badge_reportComponent;
      const inParams:{ dockAppointmentId: number } = { dockAppointmentId: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_license_plate_report_by_serialId') {
      const title = 'custom_license_plate_report_by_serialId';
      const component = Reports_custom_license_plate_report_by_serialIdComponent;
      const inParams:{ serialNumberId?: number } = { serialNumberId: null };
      if (!isNil(params.get('serialNumberId'))) {
        const paramValueString = params.get('serialNumberId');
        inParams.serialNumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_outbound_labels_report') {
      const title = 'custom_outbound_labels_report';
      const component = Reports_custom_outbound_labels_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_packing_list_report') {
      const title = 'custom_packing_list_report';
      const component = Reports_custom_packing_list_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_packing_slip_report') {
      const title = 'custom_packing_slip_report';
      const component = Reports_custom_packing_slip_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_report') {
      const title = 'Receiving Report';
      const component = Reports_custom_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_transaction_report') {
      const title = 'custom_transaction_report';
      const component = Reports_custom_transaction_reportComponent;
      const inParams:{ notes: string } = { notes: null };
      if (!isNil(params.get('notes'))) {
        const paramValueString = params.get('notes');
        // TODO: date
        inParams.notes = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
