import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Instructions_instruction_types_dd_singleComponent } from './Instructions.instruction_types_dd_single.component'


interface IFootPrintManager_instructions_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Enabled?: boolean, EntityKey1?: number, EntityKey2?: number, EntityKey3?: number, EntityKey4?: number, EntityName?: string, Instructions?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, TypeId?: number, Type?: { Name?: string }}

interface IFootPrintManager_instructions_gridComponentInParams {
  entityName: string, enabled: boolean, typeIds?: number[], entityKey1: number, entityKey2?: number, entityKey3?: number, entityKey4?: number}


class FootPrintManager_instructions_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_instructions_gridComponent;
  entity: IFootPrintManager_instructions_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    instructions_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    URL_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    enabled_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    enabled_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    instructions: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['instructions_edit'] as DatexFormControl, null, false, '')
      ),
    URL: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['URL_edit'] as DatexFormControl, null, false, '')
      ),
    enabled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['enabled_display'] as DatexFormControl, null, false, '')
,
      new CheckBoxModel(this.formGroup.controls['enabled_edit'] as DatexFormControl, null, false, '')
      ),
    created_sys_user: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_sys_date_time: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'MM/DD/YYYY')
,
null
      ),
    modified_sys_user: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_sys_date_time: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'MM/DD/YYYY')
,
null
      ),
  }

  cacheValueFor_$fields_type_selector_inParams_typeIds: number[];
  get $fields_type_selector_inParams_typeIds(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.typeIds;
    
    if(!isEqual(this.cacheValueFor_$fields_type_selector_inParams_typeIds, expr)) {
      this.cacheValueFor_$fields_type_selector_inParams_typeIds = expr;
    }
    return this.cacheValueFor_$fields_type_selector_inParams_typeIds;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_instructions_gridComponent, entity: IFootPrintManager_instructions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_instructions_gridComponent, entity?: IFootPrintManager_instructions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      entityName:  $grid.inParams.entityName ,
      enabled:  $grid.inParams.enabled ,
      fullTextSearch:  $grid.fullTextSearch ,
      typeIds:  $grid.inParams.typeIds ,
      entityKey1:  $grid.inParams.entityKey1 ,
      entityKey2:  $grid.inParams.entityKey2 ,
      entityKey3:  $grid.inParams.entityKey3 ,
      entityKey4:  $grid.inParams.entityKey4 ,
    };
    const data = await this.datasources.Instructions.ds_instructions_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.type.displayControl as TextModel).text = $row.entity.Type?.Name;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.TypeId);
    (this.cells.instructions.displayControl as TextModel).text = $row.entity.Instructions;
    (this.cells.instructions.editControl as TextBoxModel).reset($row.entity.Instructions);
    
    (this.cells.enabled.displayControl as CheckBoxModel).reset($row.entity.Enabled);
    (this.cells.enabled.editControl as CheckBoxModel).reset($row.entity.Enabled);
    (this.cells.created_sys_user.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.created_sys_date_time.displayControl as TextModel).text = $row.entity.CreatedSysDateTime;
    (this.cells.modified_sys_user.displayControl as TextModel).text = $row.entity.ModifiedSysUser;
    (this.cells.modified_sys_date_time.displayControl as TextModel).text = $row.entity.ModifiedSysDateTime;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_instructions_gridComponentRowModel,
  $grid: FootPrintManager_instructions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.enabled.editControl.value = true;
  $row.entity.Enabled = true;
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_instructions_gridComponentRowModel,
  $grid: FootPrintManager_instructions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const instructionId = $row.entity.Id;
  const enabled = $row.cells.enabled.editControl.value;
  var instruction = $row.cells.instructions.editControl.value;
  
  try {
  
      if ($utils.isDefined($row.cells.URL.editControl.value)) {
          if ($row.cells.URL.editControl.value.includes('http') > 0) {
              $row.cells.URL.editControl.value = `[{<${$row.cells.URL.editControl.value}>}]`
          } else {
              $row.cells.URL.editControl.value = `[{<https://${$row.cells.URL.editControl.value}>}]`
          }
          instruction = instruction + $row.cells.URL.editControl.value;
          $row.cells.URL.displayControl.text = $row.cells.URL.editControl.value
      }
  
      await $flows.Instructions.update_instruction_flow({
          instructionId: instructionId,
          enabled: enabled,
          instruction: instruction
      });
  
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Instructions.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_instructions_gridComponentRowModel,
  $grid: FootPrintManager_instructions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.type.editControl.value,
      $row.cells.instructions.editControl.value,
      $row.cells.enabled.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Instructions.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  
  try {
  
      const entityName = $grid.inParams.entityName;
      const entityKey1 = $grid.inParams.entityKey1;
      const entityKey2 = $grid.inParams.entityKey2;
      const entityKey3 = $grid.inParams.entityKey3;
      const entityKey4 = $grid.inParams.entityKey4;
  
      var instruction = $row.cells.instructions.editControl.value;
      const typeId = $row.cells.type.editControl.value
  
      if ($utils.isDefined($row.cells.URL.editControl.value)) {
          if ($row.cells.URL.editControl.value.includes('http') > 0) {
              $row.cells.URL.editControl.value = `[{<${$row.cells.URL.editControl.value}>}]`
          } else {
              $row.cells.URL.editControl.value = `[{<https://${$row.cells.URL.editControl.value}>}]`
          }
          instruction = instruction + $row.cells.URL.editControl.value;
          $row.cells.URL.displayControl.text = $row.cells.URL.editControl.value
      }
  
  
      let result = await $flows.Instructions.create_instruction_flow({
          entityName: entityName,
          entityKey1: entityKey1,
          entityKey2: entityKey2,
          entityKey3: entityKey3,
          entityKey4: entityKey4,
          instruction: instruction,
          typeId: typeId
      });
  
      $row.entity.Id = result.instruction_id;
  
      await $grid.refresh();
  
  } catch (error) {
      $shell.Instructions.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_instructions_gridComponentRowModel,
  $grid: FootPrintManager_instructions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Lock down changing of the type from the row
  if (!$row.isNew) {
      $row.cells.type.editControl.readOnly = true;
  }
  if ($utils.isDefined($row.entity.Instructions)) {
      if ($row.entity.Instructions.includes('[{<')) {
          $row.cells.instructions.displayControl.text = $row.entity.Instructions.split('[{<')[0]
          $row.cells.instructions.editControl.value = $row.entity.Instructions.split('[{<')[0]
          $row.cells.URL.displayControl.text = $row.entity.Instructions.split('[{<')[1].split('>}]')[0]
          $row.cells.URL.editControl.value = $row.entity.Instructions.split('[{<')[1].split('>}]')[0]
      }
      else {
          $row.cells.URL.displayControl.text = ''
      }
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Instructions_instruction_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-instructions_grid',
  templateUrl: './FootPrintManager.instructions_grid.component.html'
})
export class FootPrintManager_instructions_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_instructions_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_instructions_gridComponentInParams = { entityName: null, enabled: null, typeIds: [], entityKey1: null, entityKey2: null, entityKey3: null, entityKey4: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       instructions: new GridHeaderModel(new HeaderStyles(null, null), 'Instructions', true, false, null),       URL: new GridHeaderModel(new HeaderStyles(null, null), 'URL', false, false, null),       enabled: new GridHeaderModel(new HeaderStyles(null, null), 'Enabled', false, false, null),       created_sys_user: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       created_sys_date_time: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       modified_sys_user: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, null),       modified_sys_date_time: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_instructions_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('entityName') set $inParams_entityName(value: any) {
    this.inParams['entityName'] = value;
  }
  get $inParams_entityName(): any {
    return this.inParams['entityName'] ;
  }
  @Input('enabled') set $inParams_enabled(value: any) {
    this.inParams['enabled'] = value;
  }
  get $inParams_enabled(): any {
    return this.inParams['enabled'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('entityKey1') set $inParams_entityKey1(value: any) {
    this.inParams['entityKey1'] = value;
  }
  get $inParams_entityKey1(): any {
    return this.inParams['entityKey1'] ;
  }
  @Input('entityKey2') set $inParams_entityKey2(value: any) {
    this.inParams['entityKey2'] = value;
  }
  get $inParams_entityKey2(): any {
    return this.inParams['entityKey2'] ;
  }
  @Input('entityKey3') set $inParams_entityKey3(value: any) {
    this.inParams['entityKey3'] = value;
  }
  get $inParams_entityKey3(): any {
    return this.inParams['entityKey3'] ;
  }
  @Input('entityKey4') set $inParams_entityKey4(value: any) {
    this.inParams['entityKey4'] = value;
  }
  get $inParams_entityKey4(): any {
    return this.inParams['entityKey4'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Instructions';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.entityName)) {
        this.$missingRequiredInParams.push('entityName');
      }
      if(isNil(this.inParams.enabled)) {
        this.$missingRequiredInParams.push('enabled');
      }
      if(isNil(this.inParams.entityKey1)) {
        this.$missingRequiredInParams.push('entityKey1');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      entityName:  $grid.inParams.entityName ,
      enabled:  $grid.inParams.enabled ,
      fullTextSearch:  $grid.fullTextSearch ,
      typeIds:  $grid.inParams.typeIds ,
      entityKey1:  $grid.inParams.entityKey1 ,
      entityKey2:  $grid.inParams.entityKey2 ,
      entityKey3:  $grid.inParams.entityKey3 ,
      entityKey4:  $grid.inParams.entityKey4 ,
    };
    try {
    const data = await this.datasources.Instructions.ds_instructions_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_instructions_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_instructions_gridComponentEntity) {
    const row = new FootPrintManager_instructions_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_instructions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Instructions.openErrorDialog('Instruction Delete Error', 'No instruction selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              instructionId: row.entity.Id
          };
          const result = await $flows.Instructions.is_instruction_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete Instruction errors';
          const errorMessage = `Instruction ${failures.map(c => c.entity.Instructions).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.Instructions.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Instruction ${candidates.map(c => c.entity.Instructions).join(',')} - Once deleted, the Instruction cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some Instructions cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Instructions.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following Instructions';
              const message = confirmCandidates;
              confirm = await $shell.Instructions.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      instructionId: candidate.entity.Id
                      }
                      const result = await $flows.Instructions.delete_instruction_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Instruction ${candidate.entity.Instructions} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All the selected Instructions deleted';
                  const message = `Instructions ${processSuccess.map(c => c.entity.Instructions).join(',')} deleted`;
                  $shell.FootPrintManager.openToaster(title,message,EToasterType.Success,{ timeOut: 5000, positionClass: EToasterPosition.topRight})
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All the selected Instructions failed to delete';
                      const message = `Instructions ${processFailures.map(c => c.entity.Instructions).join(',')} could not be deleted`;
                      await $shell.Instructions.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Instructions could not be deleted';
                      const deleted = `Instructions ${processSuccess.map(c => c.entity.Instructions).join(',')} were deleted.`;
                      const errors = `The following Instructions could not be deleted: ${processFailures.map(c => c.entity.Instructions).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.Instructions.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_instructions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else{
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
