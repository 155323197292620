import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Utilities_ShellService, EModalSize, EToasterType, EToasterPosition } from './Utilities.shell.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './Utilities.frontend.types'
import { $frontendTypes as $types} from './Utilities.frontend.types' 


import { Utilities_messages_gridComponent } from './Utilities.messages_grid.component';
import { Utilities_logs_gridComponent } from './Utilities.logs_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Utilities_messages_gridComponent),
    forwardRef(() => Utilities_logs_gridComponent),
  ],
  selector: 'Utilities-transaction_hub',
  templateUrl: './Utilities.transaction_hub.component.html'
})
export class Utilities_transaction_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { transaction_id?: string, activate_grid?: string, column_names?: any, filter_values?: any } = { transaction_id: null, activate_grid: null, column_names: null, filter_values: null };
  //#region Inputs
  @Input('transaction_id') set $inParams_transaction_id(v: string) {
    this.inParams.transaction_id = v;
  }
  get $inParams_transaction_id(): string {
    return this.inParams.transaction_id;
  }
  @Input('activate_grid') set $inParams_activate_grid(v: string) {
    this.inParams.activate_grid = v;
  }
  get $inParams_activate_grid(): string {
    return this.inParams.activate_grid;
  }
  @Input('column_names') set $inParams_column_names(v: any) {
    this.inParams.column_names = v;
  }
  get $inParams_column_names(): any {
    return this.inParams.column_names;
  }
  @Input('filter_values') set $inParams_filter_values(v: any) {
    this.inParams.filter_values = v;
  }
  get $inParams_filter_values(): any {
    return this.inParams.filter_values;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      messages: new TabItemModel(
        this.rootTabGroup, 
        'Messages', 
        ),
      logs: new TabItemModel(
        this.rootTabGroup, 
        'Logs', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_messages_messages_grid_inParams_transaction_id(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.transaction_id;
      
      return expr;
    }
  
    cacheValueFor_$tabs_messages_messages_grid_inParams_column_names: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string };
    get $tabs_messages_messages_grid_inParams_column_names(): { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.column_names;
      
      if(!isEqual(this.cacheValueFor_$tabs_messages_messages_grid_inParams_column_names, expr)) {
        this.cacheValueFor_$tabs_messages_messages_grid_inParams_column_names = expr;
      }
      return this.cacheValueFor_$tabs_messages_messages_grid_inParams_column_names;
    }
  
    cacheValueFor_$tabs_messages_messages_grid_inParams_filter_values: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string };
    get $tabs_messages_messages_grid_inParams_filter_values(): { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.filter_values;
      
      if(!isEqual(this.cacheValueFor_$tabs_messages_messages_grid_inParams_filter_values, expr)) {
        this.cacheValueFor_$tabs_messages_messages_grid_inParams_filter_values = expr;
      }
      return this.cacheValueFor_$tabs_messages_messages_grid_inParams_filter_values;
    }
  
    get $tabs_logs_logs_grid_inParams_transaction_id(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.transaction_id;
      
      return expr;
    }
  
    cacheValueFor_$tabs_logs_logs_grid_inParams_column_names: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string };
    get $tabs_logs_logs_grid_inParams_column_names(): { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.column_names;
      
      if(!isEqual(this.cacheValueFor_$tabs_logs_logs_grid_inParams_column_names, expr)) {
        this.cacheValueFor_$tabs_logs_logs_grid_inParams_column_names = expr;
      }
      return this.cacheValueFor_$tabs_logs_logs_grid_inParams_column_names;
    }
  
    cacheValueFor_$tabs_logs_logs_grid_inParams_filter_values: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string };
    get $tabs_logs_logs_grid_inParams_filter_values(): { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.filter_values;
      
      if(!isEqual(this.cacheValueFor_$tabs_logs_logs_grid_inParams_filter_values, expr)) {
        this.cacheValueFor_$tabs_logs_logs_grid_inParams_filter_values = expr;
      }
      return this.cacheValueFor_$tabs_logs_logs_grid_inParams_filter_values;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_messages', { read: Utilities_messages_gridComponent }) $tabs_messages: Utilities_messages_gridComponent;
      @ViewChild('$tabs_logs', { read: Utilities_logs_gridComponent }) $tabs_logs: Utilities_logs_gridComponent;
    //#endregion tabs children




  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Utilities_ShellService,
  private datasources: Utilities_DatasourceService,
  private flows: Utilities_FlowService,
  private reports: Utilities_ReportService,
  private localization: Utilities_LocalizationService,
  private operations: Utilities_OperationService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.messages,
      this.tabs.logs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'transaction_hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_messages') {
      if (!isNil(this.$tabs_messages) && !this.tabs.messages.hidden) {
        this.$tabs_messages.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_logs') {
      if (!isNil(this.$tabs_logs) && !this.tabs.logs.hidden) {
        this.$tabs_logs.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Utilities_transaction_hubComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/20/2024
  
  if ($utils.isDefined($hub.inParams.transaction_id)) {
      $hub.title = `Transaction id ${$hub.inParams.transaction_id}`
  }
  
  const activate = $hub.inParams.activate_grid ?? "";
  
  if (activate === "logs") {
      $hub.tabs.logs.activate();
  } else {
      $hub.tabs.messages.activate();
  }
  }
  //#endregion private flows
}
