<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.cancel_button.readOnly"
                [ngStyle]="row.options.cancel_button.styles.style"
                [ngClass]="row.options.cancel_button.styles.classes"
                (click)="row.cancel_job_button()">
          <span *ngIf="row.options.cancel_button.label">{{row.options.cancel_button.label}}</span>
        </button>
      </div>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.refresh_row.readOnly"
                [ngStyle]="row.options.refresh_row.styles.style"
                [ngClass]="row.options.refresh_row.styles.classes"
                (click)="row.on_row_refresh_clicked()">
          <span *ngIf="row.options.refresh_row.label">{{row.options.refresh_row.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="toteId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.toteId.displayControl.styles.style"
          [ngClass]="row.cells.toteId.displayControl.styles.classes">{{row.cells.toteId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobPriority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.jobPriority.displayControl.styles.style"
          [ngClass]="row.cells.jobPriority.displayControl.styles.classes">{{row.cells.jobPriority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.bot.displayControl.styles.style"
          [ngClass]="row.cells.bot.displayControl.styles.classes">{{row.cells.bot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobTaskCount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.jobTaskCount.displayControl.styles.style"
          [ngClass]="row.cells.jobTaskCount.displayControl.styles.classes">{{row.cells.jobTaskCount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_known_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.last_known_location.displayControl.styles.style"
          [ngClass]="row.cells.last_known_location.displayControl.styles.classes">{{row.cells.last_known_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="time_on_floor">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.time_on_floor.displayControl.styles.style"
          [ngClass]="row.cells.time_on_floor.displayControl.styles.classes">{{row.cells.time_on_floor.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.jobDate.displayControl.styles.style"
          [ngClass]="row.cells.jobDate.displayControl.styles.classes">{{row.cells.jobDate.displayControl.text | formatText : row.cells.jobDate.displayControl.formatType : row.cells.jobDate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.jobId.displayControl.styles.style"
          [ngClass]="row.cells.jobId.displayControl.styles.classes">{{row.cells.jobId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
