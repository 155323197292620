import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { SalesOrders_outbound_processing_strategies_dd_singleComponent } from './SalesOrders.outbound_processing_strategies_dd_single.component'
import { Cartonization_cartonization_strategies_dd_singleComponent } from './Cartonization.cartonization_strategies_dd_single.component'
import { SalesOrders_wave_priorities_dd_singleComponent } from './SalesOrders.wave_priorities_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SalesOrders_outbound_processing_strategies_dd_singleComponent),
    forwardRef(() => Cartonization_cartonization_strategies_dd_singleComponent),
    forwardRef(() => SalesOrders_wave_priorities_dd_singleComponent),
  ],
  selector: 'FootPrintManager-sales_orders_processing_options_form',
  templateUrl: './FootPrintManager.sales_orders_processing_options_form.component.html'
})
export class FootPrintManager_sales_orders_processing_options_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { isMultiShipment: boolean, waveOptionDefaultCreate: boolean, expand_order_line_kits?: boolean } = { isMultiShipment: null, waveOptionDefaultCreate: null, expand_order_line_kits: null };
  //#region Inputs
  @Input('isMultiShipment') set $inParams_isMultiShipment(v: boolean) {
    this.inParams.isMultiShipment = v;
  }
  get $inParams_isMultiShipment(): boolean {
    return this.inParams.isMultiShipment;
  }
  @Input('waveOptionDefaultCreate') set $inParams_waveOptionDefaultCreate(v: boolean) {
    this.inParams.waveOptionDefaultCreate = v;
  }
  get $inParams_waveOptionDefaultCreate(): boolean {
    return this.inParams.waveOptionDefaultCreate;
  }
  @Input('expand_order_line_kits') set $inParams_expand_order_line_kits(v: boolean) {
    this.inParams.expand_order_line_kits = v;
  }
  get $inParams_expand_order_line_kits(): boolean {
    return this.inParams.expand_order_line_kits;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, waveOptions?: { isCreateWave?: boolean, isProcessWave?: boolean, isReleaseWave?: boolean, priority?: number, description?: string, abortIfErrors?: boolean, groupOnWave?: boolean, isCreateManualAllocationTasks?: boolean }, processingStrategyWorkflowId?: string, expand_order_line_kits_request?: boolean } = { confirm: null, waveOptions: { isCreateWave: null, isProcessWave: null, isReleaseWave: null, priority: null, description: null, abortIfErrors: null, groupOnWave: null, isCreateManualAllocationTasks: null }, processingStrategyWorkflowId: null, expand_order_line_kits_request: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, waveOptions?: { isCreateWave?: boolean, isProcessWave?: boolean, isReleaseWave?: boolean, priority?: number, description?: string, abortIfErrors?: boolean, groupOnWave?: boolean, isCreateManualAllocationTasks?: boolean }, processingStrategyWorkflowId?: string, expand_order_line_kits_request?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    processing_strategy: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    cartonization_strategy: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expand_kit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_create_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_process_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_create_manual_allocation_tasks: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_release_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    group_by_wave: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    abort_if_errors: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    processing_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['processing_strategy'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Processing strategy', true)
,
    cartonization_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['cartonization_strategy'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Cartonization strategy', false)
,
    expand_kit: new FieldModel(new CheckBoxModel(this.formGroup.controls['expand_kit'] as DatexFormControl, null, false, 'Expand order line kits?')
, new ControlContainerStyles(null, null), '', false)
,
    is_create_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_create_wave'] as DatexFormControl, null, false, 'Create wave')
, new ControlContainerStyles(null, null), '', false)
,
    is_process_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_process_wave'] as DatexFormControl, null, false, 'Process wave')
, new ControlContainerStyles(null, null), '', false)
,
    is_create_manual_allocation_tasks: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_create_manual_allocation_tasks'] as DatexFormControl, null, false, 'Create manual allocation tasks')
, new ControlContainerStyles(null, null), '', false)
,
    is_release_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_release_wave'] as DatexFormControl, null, false, 'Release wave')
, new ControlContainerStyles(null, null), '', false)
,
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Description', false)
,
    priority: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['priority'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Priority', false)
,
    group_by_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['group_by_wave'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Group on single wave', true)
,
    abort_if_errors: new FieldModel(new CheckBoxModel(this.formGroup.controls['abort_if_errors'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Abort wave release if any orders error', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
  wave_options: new FieldsetModel('Wave options', false, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    processing_strategy: this.fields.processing_strategy.control.valueChanges
    ,
    cartonization_strategy: this.fields.cartonization_strategy.control.valueChanges
    ,
    expand_kit: this.fields.expand_kit.control.valueChanges
    ,
    is_create_wave: this.fields.is_create_wave.control.valueChanges
    ,
    is_process_wave: this.fields.is_process_wave.control.valueChanges
    ,
    is_create_manual_allocation_tasks: this.fields.is_create_manual_allocation_tasks.control.valueChanges
    ,
    is_release_wave: this.fields.is_release_wave.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    priority: this.fields.priority.control.valueChanges
    ,
    group_by_wave: this.fields.group_by_wave.control.valueChanges
    ,
    abort_if_errors: this.fields.abort_if_errors.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.isMultiShipment)) {
        this.$missingRequiredInParams.push('isMultiShipment');
      }
      if(isNil(this.inParams.waveOptionDefaultCreate)) {
        this.$missingRequiredInParams.push('waveOptionDefaultCreate');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Processing options';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.processing_strategy.control as SelectBoxModel).reset('_');
    (this.fields.cartonization_strategy.control as SelectBoxModel).reset('Default');
    (this.fields.is_create_wave.control as CheckBoxModel).reset(false);
    
    (this.fields.group_by_wave.control as CheckBoxModel).reset(false);
    (this.fields.abort_if_errors.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .processing_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .cartonization_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expand_kit
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_create_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_create_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_process_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_process_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_create_manual_allocation_tasks
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_process_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_release_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_release_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .group_by_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_group_by_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .abort_if_errors
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.group_by_wave.control.value
  );
  
  var errors = '';
  
  if (!allRequiredFieldHaveValue) {
      $shell.FootPrintManager.openErrorDialog('Processing Options Error', 'Please enter in data in all required fields.');
      return;
  }
  
  else {
      try {
  
  
          // Set expand kit request
          if($utils.isDefined($form.fields.expand_kit.control.value) && $form.fields.expand_kit.control.value === true){
              $form.outParams.expand_order_line_kits_request = true;
          }
  
          let processingStrategyWorkflowId = $form.fields.processing_strategy.control.value;
          if (processingStrategyWorkflowId === '_') {
              //let processingStrategies = (await $datasources.SalesOrders.ds_get_outbound_processing_strategies_by_projectId.get({ projectId: $}))
              processingStrategyWorkflowId = '304DF5F5-EFE3-4944-9304-9D0D7D675001';
          }
  
  
          $form.outParams.waveOptions.isCreateWave = $form.fields.is_create_wave.control.value;
          $form.outParams.waveOptions.isProcessWave = $form.fields.is_process_wave.control.value;
          $form.outParams.waveOptions.isReleaseWave = $form.fields.is_release_wave.control.value;
          $form.outParams.waveOptions.isCreateManualAllocationTasks = $form.fields.is_create_manual_allocation_tasks.control.value ?? false;
          $form.outParams.processingStrategyWorkflowId = processingStrategyWorkflowId;
  
  
          if ($utils.isDefined($form.fields.group_by_wave.control.value)) {
              if ($form.fields.group_by_wave.control.value)
                  $form.outParams.waveOptions.groupOnWave = true;
          }
  
          if ($utils.isDefined($form.fields.description.control.value)) {
  
              $form.outParams.waveOptions.description = $form.fields.description.control.value;
          }
  
          if ($utils.isDefined($form.fields.priority.control.value)) {
  
              $form.outParams.waveOptions.priority = $form.fields.priority.control.value;
          }
  
          $form.outParams.waveOptions.abortIfErrors = $form.fields.abort_if_errors.control.value ?? false;
  
  
          $form.outParams.confirm = true;
          $form.close();
  
      }
      catch (error) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Error on save');
          throw error; // To prevent display mode
      }
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  $form.outParams.waveOptions.isCreateWave = false;
  $form.outParams.waveOptions.isProcessWave = false;
  $form.outParams.waveOptions.isReleaseWave = false;
  
  // Handle expand kits input
  if ($utils.isDefined($form.inParams.expand_order_line_kits) && $form.inParams.expand_order_line_kits === true) {
      $form.fields.expand_kit.hidden = false;
      $form.fields.expand_kit.control.value = true;
  }
  else {
      $form.fields.expand_kit.hidden = true;
  
  }
  
  var defaultWave = false
  // Set wave option behavior
  
  if ($form.inParams.waveOptionDefaultCreate) {
      defaultWave = true;
  }
  
  // Set title field
  
  var title = 'Sales Order Processing Options'
  
  $form.title = title;
  
  $form.fields.abort_if_errors.hidden = true;
  
  
  // Show/hide fields
  $form.fields.is_create_wave.control.value = defaultWave;
  $form.fields.is_process_wave.control.value = defaultWave;
  $form.fields.is_release_wave.control.value = defaultWave;
  
  $form.fields.is_process_wave.hidden = !defaultWave;
  $form.fields.is_release_wave.hidden = !defaultWave;
  $form.fields.description.hidden = !defaultWave;
  $form.fields.priority.hidden = !defaultWave;
  $form.fields.group_by_wave.hidden = !defaultWave;
  $form.fields.is_create_manual_allocation_tasks.hidden = false;
  
  if (!$form.inParams.isMultiShipment) {
      // Check for default outbound processing strategy workflow
  
  }
  
  
  // TODO: Remove once Cartonization fully implemented
  if (!(await $flows.Utilities.get_username_flow({})).domain.includes('datexcorp.com')) {
      $form.fields.cartonization_strategy.hidden = true;
  }
  }
  on_group_by_wave_changed(event = null) {
    return this.on_group_by_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_group_by_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.fields.group_by_wave.control.value) && $form.fields.group_by_wave.control.value) {
      $form.fields.abort_if_errors.hidden = false;
  }
  else {
      $form.fields.abort_if_errors.hidden = true;
      $form.fields.abort_if_errors.control.value = false;
  }
  }
  on_is_create_wave_changed(event = null) {
    return this.on_is_create_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_create_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.is_create_wave.control.value) {
      $form.fields.is_process_wave.hidden = false;
      $form.fields.is_release_wave.hidden = false;
      
      $form.fields.description.hidden = !$form.inParams.isMultiShipment;
      $form.fields.priority.hidden = !$form.inParams.isMultiShipment;
      $form.fields.group_by_wave.hidden = !$form.inParams.isMultiShipment;
      $form.fields.abort_if_errors.hidden = !$form.fields.group_by_wave.control.value;
  
      $form.fields.is_process_wave.control.value = true;
      $form.fields.is_release_wave.control.value = true;
      $form.fields.is_create_manual_allocation_tasks.hidden = false
  }
  else {
      $form.fields.is_process_wave.hidden = true;
      $form.fields.is_release_wave.hidden = true;
      $form.fields.description.hidden = true;
      $form.fields.priority.hidden = true;
      $form.fields.group_by_wave.hidden = true;
      $form.fields.abort_if_errors.hidden = true;
  
      $form.fields.is_process_wave.control.value = false;
      $form.fields.is_release_wave.control.value = false;
      $form.fields.is_create_manual_allocation_tasks.hidden = true
  }
  }
  on_is_process_wave_changed(event = null) {
    return this.on_is_process_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_process_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$form.fields.is_process_wave.control.value) {
      $form.fields.is_release_wave.control.value = false;
      $form.fields.is_create_manual_allocation_tasks.hidden = true;
      $form.fields.is_create_manual_allocation_tasks.control.value = false;
  }
  else
  {
      $form.fields.is_create_manual_allocation_tasks.hidden = false;
  
  
  }
  
  
  
  
  
  
  }
  on_is_release_wave_changed(event = null) {
    return this.on_is_release_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_release_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.is_release_wave.control.value) {
      $form.fields.is_process_wave.control.value = true;
      $form.fields.is_create_manual_allocation_tasks.hidden = false;
  }
  }
  //#endregion private flows
}
