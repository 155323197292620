import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'


interface IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentEntity {
id?: number, taskId?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }, Order?: { OrderStatusId?: number } }}

interface IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentInParams {
  taskId: number, palletCount: number, fullPalletQuantity?: number}


class FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent;
  entity: IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    location_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    licenseplate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    lineNumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, '', '')
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['location_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['licenseplate_edit'] as DatexFormControl, null, false, '')
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.taskId.MaterialId;
    
    return expr;
  }
  get $fields_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.taskId.WarehouseId;
    
    return expr;
  }
  get $fields_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.taskId.MaterialId;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.vendorlot.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.taskId.MaterialId;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.lot.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
    this.formGroup
      .controls['location_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_change();
      });
    this.formGroup
      .controls['licenseplate_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_licenseplate_change();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, entity: IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, entity?: IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      rows:  $grid.inParams.palletCount ,
      taskId:  $grid.inParams.taskId ,
    };
    const data = await this.datasources.PurchaseOrders.ds_purchase_order_bulk_receiving_by_taskId_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lineNumber.displayControl as TextModel).text = $row.entity.taskId.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.taskId.Material?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.taskId.ActualPackagedPack?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.taskId.ActualPackagedPackId);
    (this.cells.location.displayControl as TextModel).text = $row.entity.taskId.ActualTargetLocation?.Name;
    (this.cells.location.editControl as SelectBoxModel).reset($row.entity.taskId.ActualTargetLocationId);
    (this.cells.licenseplate.displayControl as TextModel).text = '';
    (this.cells.licenseplate.editControl as TextBoxModel).reset('');
    (this.cells.lot.displayControl as TextModel).text = $row.entity.taskId.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity.taskId.LotId);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.taskId.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.taskId.VendorLotId);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_licenseplate_change(event = null) {
    return this.on_licenseplate_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_changeInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefinedTrimmed($row.cells.licenseplate.editControl.value)) {
          let exists = true;
  
          const licenseplate = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
              warehouseId: $row.entity.taskId.WarehouseId,
              lookupcode: $row.cells.licenseplate.editControl.value
          })).result;
          exists = $utils.isDefined(licenseplate);
  
          if (exists) {
              $row.cells.licenseplate.styles.setAttentionClass();
          } else {
              $row.cells.licenseplate.styles.resetClasses();
          }
      } else {
          $row.cells.licenseplate.styles.resetClasses();
      }
  }
  on_location_change(event = null) {
    return this.on_location_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changeInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefinedTrimmed($row.cells.location.editControl.value)) {
  
      const licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({
          locationId: $row.cells.location.editControl.value
      })).result;
      if ($utils.isDefined(licenseplate)) {
          $row.cells.licenseplate.editControl.reset(licenseplate[0].LookupCode);
          $row.cells.licenseplate.displayControl.text = licenseplate[0].LookupCode;
          $row.cells.licenseplate.editControl.readOnly = true;
          $row.cells.licenseplate.editControl.styles.setStyle('background', 'transparent');
      } else {
          $row.cells.licenseplate.editControl.reset('');
          $row.cells.licenseplate.editControl.readOnly = false;
          $row.cells.licenseplate.editControl.styles.resetStyle();
      }
  
  }
  
  
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.cells.lot.editControl.value)) {
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({
          lotId: $row.cells.lot.editControl.value
      })).result;
      if ($utils.isDefined(lot)) {
          $row.cells.vendorlot.editControl.value = lot[0].VendorLotId;
      }
  } else {
      $row.cells.vendorlot.editControl.value = null;
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  var materialId = $row.entity.taskId.MaterialId;
  var packagingId = $row.entity.taskId.ActualPackagedPackId;
  
  
  // Set quantity to the fullPalletQuantity input  to expected packaged amount from task or from pallet high and tie if found
  
  if ($utils.isDefined($grid.inParams.fullPalletQuantity)) {
  
      $row.cells.quantity.displayControl.text = $grid.inParams.fullPalletQuantity.toString();
      $row.cells.quantity.editControl.value = $grid.inParams.fullPalletQuantity;
  }
  else {
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      })).result;
      if ($utils.isDefined(packaging) && ($utils.isAllDefined(packaging[0].PalletHigh, packaging[0].PalletTie))) {
  
          const palletQty = packaging[0].PalletHigh * packaging[0].PalletTie;
          $row.cells.quantity.displayControl.text = palletQty?.toString();
          $row.cells.quantity.editControl.value = palletQty;
  
      } else {
  
          $row.cells.quantity.displayControl.text = $row.entity.taskId.ExpectedPackagedAmount.toString();
          $row.cells.quantity.editControl.value = $row.entity.taskId.ExpectedPackagedAmount;
      }
  }
  
  
  const orderIsInProcessingStatus = $row.entity.taskId.Order.OrderStatusId === 2;
  if (orderIsInProcessingStatus) {
      const licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({
          locationId: $row.cells.location.editControl.value
      })).result;
      if ($utils.isDefined(licenseplate)) {
          $row.cells.licenseplate.editControl.value = licenseplate[0].Id;
          $row.cells.licenseplate.displayControl.text = licenseplate[0].LookupCode;
          $row.cells.licenseplate.editControl.readOnly = true;
          $row.cells.licenseplate.editControl.styles.setStyle('background', 'transparent');
      } else {
          $row.cells.licenseplate.editControl.reset('');
          $row.cells.licenseplate.editControl.readOnly = false;
          $row.cells.licenseplate.editControl.styles.resetStyle();
      }
  }
  
  
  // Check if material is serial controlled and or lot controlled
  const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
      materialId: materialId
  })).result;
  
  
  const materialControllerTypeId = material[0].ControllerTypeId;
  
  // Lot controlled
  if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
      $row.cells.lot.editControl.readOnly = false;
      $row.cells.lot.editControl.styles.resetStyle();
      $row.cells.vendorlot.editControl.readOnly = false;
      $row.cells.vendorlot.editControl.styles.resetStyle();
  } else {
      $row.cells.lot.editControl.readOnly = true;
      $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
      $row.cells.vendorlot.editControl.readOnly = true;
      $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
  }
  
  ($row as any).receivingReady = true;
  ($row as any).selected = true;
  
  
  
  
  
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
  $row.cells.packaging.displayControl.text = $row.cells.packaging.editControl.displayText;
  $row.cells.licenseplate.displayControl.text = $row.cells.licenseplate.editControl.value;
  $row.cells.location.displayControl.text = $row.cells.location.editControl.displayText;
  $row.cells.lot.displayControl.text = $row.cells.lot.editControl.displayText;
  $row.cells.vendorlot.displayControl.text = $row.cells.vendorlot.editControl.displayText;
  
  ($row as any).receivingReady = true;
  ($row as any).selected = true;
  
  
  // Set current tally label
  // const expectedPackagedAmount = $row.entity.taskId.ExpectedPackagedAmount;
  // const materialId = $row.entity.taskId.MaterialId;
  // const packagingId = $row.entity.taskId.ActualPackagedPackId
  
  // var fullPalletQuantity;
  // // Set quantity to the fullPalletQuantity input  to expected packaged amount from task or from pallet high and tie if found
  // if ($utils.isDefined($grid.inParams.fullPalletQuantity)) {
  
  //     fullPalletQuantity = $grid.inParams.fullPalletQuantity;
  // }
  // else {
  //     const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
  //         materialId: materialId,
  //         packagingId: packagingId
  //     })).result;
  //     if ($utils.isDefined(packaging) && ($utils.isAllDefined(packaging[0].PalletHigh, packaging[0].PalletTie))) {
  
  //         fullPalletQuantity = packaging[0].PalletHigh * packaging[0].PalletTie;
  
  //     } else {
  //         fullPalletQuantity = expectedPackagedAmount;
  //     }
  // }
  
  
  // if ($row.cells.quantity.editControl.isChanged) {
  //     const delta = $row.cells.quantity.editControl.value - fullPalletQuantity;
  //     $grid.vars.currentTally += delta;
  //     $grid.topToolbar.current_tally.control.label = `Current tally ${$grid.vars.currentTally}`
  
  // }
  
  
  if ($row.cells.quantity.editControl.isChanged) {
  
      var updatedTally = 0;
      const rows = $grid.selectedRows;
      
      for (let row of rows) {
  
          updatedTally = (updatedTally + row.cells.quantity.editControl.value);
  
      }
      $grid.vars.currentTally = updatedTally;
      $grid.topToolbar.current_tally.control.label = `Current tally ${updatedTally}`
  
  }
  
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.lot.editControl.value = null;
  
  
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.entity.taskId.MaterialId;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.quantity.editControl.format = decimalFormat.format;
  
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
  ],
  selector: 'FootPrintManager-purchase_order_bulk_receiving_by_taskId_grid',
  templateUrl: './FootPrintManager.purchase_order_bulk_receiving_by_taskId_grid.component.html'
})
export class FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentEntity[];

  pageSize = 100;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentInParams = { taskId: null, palletCount: null, fullPalletQuantity: null };


  //#region Variables
  vars: { entities?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { Name?: string }, ActualPackagedPack?: { Name?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string } } }[], totalCount?: number, currentTally?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lineNumber: new GridHeaderModel(new HeaderStyles(null, null), 'Line #', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', true, false, null),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Receive quantity', true, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Receive location', true, false, null),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'Receive lp', true, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', true, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', true, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('taskId') set $inParams_taskId(value: any) {
    this.inParams['taskId'] = value;
  }
  get $inParams_taskId(): any {
    return this.inParams['taskId'] ;
  }
  @Input('palletCount') set $inParams_palletCount(value: any) {
    this.inParams['palletCount'] = value;
  }
  get $inParams_palletCount(): any {
    return this.inParams['palletCount'] ;
  }
  @Input('fullPalletQuantity') set $inParams_fullPalletQuantity(value: any) {
    this.inParams['fullPalletQuantity'] = value;
  }
  get $inParams_fullPalletQuantity(): any {
    return this.inParams['fullPalletQuantity'] ;
  }

  topToolbar = {
      on_confirm: new ToolModel(new ButtonModel('on_confirm', new ButtonStyles(null, null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      received: new ToolModel(new ButtonModel('received', new ButtonStyles(null, null), false, ' ', '')
    ),
      current_tally: new ToolModel(new ButtonModel('current_tally', new ButtonStyles(null, null), false, ' ', '')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Bulk receiving task';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
      if(isNil(this.inParams.palletCount)) {
        this.$missingRequiredInParams.push('palletCount');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      rows:  $grid.inParams.palletCount ,
      taskId:  $grid.inParams.taskId ,
    };
    try {
    const data = await this.datasources.PurchaseOrders.ds_purchase_order_bulk_receiving_by_taskId_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      
      $grid.topToolbar.on_confirm.control.readOnly = false;
  
  }
  else {
  
      $grid.topToolbar.on_confirm.control.readOnly = true;
  
  }
  
  
  
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const rowsModified = $grid.selectedRows;
  
  if ($utils.isDefined(rowsModified) && rowsModified.length > 0) {
  
      var rowsNotReceivingReady;
      rowsNotReceivingReady = rowsModified.filter(r => (r as any).receivingReady === false);
      rowsNotReceivingReady = rowsModified.filter(r => !$utils.isDefined(r.cells.licenseplate.editControl.value));
  
  
      if ($utils.isDefined(rowsNotReceivingReady) && rowsNotReceivingReady.length > 0) {
          $shell.FootPrintManager.openInfoDialog('Receiving', 'Some rows are missing required data. Please enter in all information.');
      } else {
          const candidates = rowsModified.filter(r => (r as any).receivingReady);
          const listOfSuccess = [];
          const listOfFailures = [];
          const dialogMessageList = [];
          const dialogMessageListDetails = [];
  
          for (const row of candidates) {
  
              try {
                  const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({
                      materialId: row.entity.taskId.MaterialId
                  })).result;
                  if ($utils.isDefined(materials)) {
  
                      var controllerTypeId = materials[0].ControllerTypeId;
                      var isFixedWeight = materials[0].IsFixedWeight;
  
                  } else {
                      throw new Error('Unable to determine material properties.')
                  }
  
                  // Check if material is serial controlled
                  if (controllerTypeId === 3 || controllerTypeId === 4 || isFixedWeight === false) {
  
                      // Only pass in average weight from order line if the material is not fixed weight and the data is available on the order line
                      if ($utils.isAllDefined(row.entity.taskId.OrderLine.GrossWeight, row.entity.taskId.OrderLine.WeightUomId) && isFixedWeight === false) {
                          var averageGross = row.entity.taskId.OrderLine.GrossWeight / row.entity.taskId.OrderLine.PackagedAmount;
                          var weightUomId = row.entity.taskId.OrderLine.WeightUomId;
                      }
  
                      const dialogResult = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({
                          packagingId: row.cells.packaging.editControl.value,
                          materialId: row.entity.taskId.MaterialId,
                          packagedAmount: row.cells.quantity.editControl.value,
                          averageGross: averageGross,
                          averageNet: averageGross,
                          weightUomId: weightUomId
                      }, 'modal');
  
  
                      var serialnumbersResult = dialogResult.serialNumbers;
                      var confirmResult = dialogResult.confirm;
                      if (confirmResult === false) {
                          throw new Error('Serial numbers were not created, please try again.')
                      }
  
                  }
                  //Re-initialize variable
                  var serialNumbers = [];
  
                  if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === row.cells.quantity.editControl.value) {
  
                      serialNumbers = serialnumbersResult;
  
                  }
                  if (confirmResult === true && (!$utils.isDefined(serialnumbersResult) || serialnumbersResult.length !== row.cells.quantity.editControl.value)) {
  
                      throw new Error('Error creating serial numbers, please try again.')
  
                  }
  
                  // Check if target license plate exists, use 2 different APIs based on result
                  const licenseplates = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
                      warehouseId: row.entity.taskId.WarehouseId,
                      lookupcode: row.cells.licenseplate.editControl.value
                  })).result;
                  if (!$utils.isDefined(licenseplates)) {
  
                      const result = (await $flows.PurchaseOrders.receive_on_new_licenseplate_flow({
                          licenseplateLookupcode: row.cells.licenseplate.editControl.value,
                          locationId: row.cells.location.editControl.value,
                          lotId: row.cells.lot.editControl.value,
                          packagedAmount: row.cells.quantity.editControl.value,
                          packagingId: row.cells.packaging.editControl.value,
                          taskId: row.entity.taskId.Id,
                          serialNumbers: serialNumbers
                      }));
  
                      if ($utils.isDefined(result.reasons)) {
  
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
  
                      }
  
  
                  } else {
                      const licenseplateId = licenseplates[0].Id;
  
                      const result = (await $flows.PurchaseOrders.receive_on_existing_licenseplate_flow({
                          licenseplateId: licenseplateId,
                          lotId: row.cells.lot.editControl.value,
                          packagedAmount: row.cells.quantity.editControl.value,
                          packagingId: row.cells.packaging.editControl.value,
                          taskId: row.entity.taskId.Id,
                          serialNumbers: serialNumbers
                      }));
  
                      if ($utils.isDefined(result.reasons)) {
  
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
  
                      }
  
                  }
  
                  listOfSuccess.push(row.entity.taskId.OrderLineNumber);
  
  
              } catch (error) {
                  listOfFailures.push(row.entity.taskId.OrderLineNumber);
  
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = ''.concat('Line ', row.entity.taskId.OrderLineNumber.toString(), ' - ', errorMessage);
                  dialogMessageList.push(errorDescription)
                  dialogMessageListDetails.push({ message: errorDescription, detail: errorDetail });
              }
          }
          if (listOfSuccess.length === candidates.length) {
              $shell.FootPrintManager.openInfoDialog('Receiving', 'Selected receiving tasks confirmed.');
              $grid.refresh();
          } else if (listOfFailures.length === candidates.length) {
              $shell.FootPrintManager.openErrorDialog('Receiving', 'None of the selected receiving tasks could be confirmed.', dialogMessageList, '', dialogMessageListDetails);
          } else {
              $shell.FootPrintManager.openErrorDialog('Receiving', 'One or more selected receiving tasks could not be confirmed.', dialogMessageList, '', dialogMessageListDetails);
              $grid.refresh();
          }
      }
  } else {
      $shell.FootPrintManager.openInfoDialog('Receiving', 'No tasks have been selected for receiving.');
  }
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const gridData = $grid.selectedRows;
  
  
  // Set remaining amount
  const expectedPackagedAmount = gridData[0].entity.taskId.ExpectedPackagedAmount;
  const expectedPackaging = gridData[0].entity.taskId.ExpectedPackagedPack?.ShortName;
  const actualPackagedAmount = gridData[0].entity.taskId.ShipmentLine.ActualPackagedAmount;
  
  const materialId = gridData[0].entity.taskId.MaterialId;
  const packagingId = gridData[0].entity.taskId.ActualPackagedPackId
  
  if ($utils.isAllDefined(expectedPackagedAmount, expectedPackaging, actualPackagedAmount)) {
      $grid.topToolbar.received.control.label = `Received ${actualPackagedAmount} ${expectedPackaging} Remaining ${expectedPackagedAmount} ${expectedPackaging}`;
  }
  
  var currentTally;
  var palletCount = $grid.inParams.palletCount;
  
  // Set quantity to the fullPalletQuantity input  to expected packaged amount from task or from pallet high and tie if found
  if ($utils.isDefined($grid.inParams.fullPalletQuantity)) {
  
      currentTally = $grid.inParams.fullPalletQuantity * palletCount;
  }
  else {
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      })).result;
      if ($utils.isDefined(packaging) && ($utils.isAllDefined(packaging[0].PalletHigh, packaging[0].PalletTie))) {
  
          const palletQty = packaging[0].PalletHigh * packaging[0].PalletTie;
          currentTally = palletQty * palletCount;
  
  
      } else {
  
      
          currentTally =  expectedPackagedAmount * palletCount;
      }
  }
  
  $grid.topToolbar.current_tally.control.label = `Current tally ${currentTally}`
  $grid.vars.currentTally = currentTally;
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
