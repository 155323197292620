import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { ListItemModel, ELoadingStatus } from './components/list.component';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService, EModalSize, EToasterType, EToasterPosition } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 



import { LocusRobotics_jobs_by_license_plateComponent } from './LocusRobotics.jobs_by_license_plate.component';

interface ILocusRobotics_jobs_by_license_plate_listComponentEntity {
jobId?: string, status?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, jobCount?: number, totalUnits?: number, botType?: string}

class LocusRobotics_jobs_by_license_plate_listComponentItemModel extends ListItemModel {
  list: LocusRobotics_jobs_by_license_plate_listComponent;
  entity: ILocusRobotics_jobs_by_license_plate_listComponentEntity;



  get $content_LocusRobotics_jobs_by_license_plate_inParams_orderId(): number {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = Number($item.entity.orderId);
    
    return expr;
  }


  get $content_LocusRobotics_jobs_by_license_plate_inParams_jobId(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.jobId;
    
    return expr;
  }

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: LocusRobotics_ShellService,
    private datasources: LocusRobotics_DatasourceService,
    private flows: LocusRobotics_FlowService,
    private reports: LocusRobotics_ReportService,
    private localization: LocusRobotics_LocalizationService,
    private operations: LocusRobotics_OperationService,
   ) {
    super();
  }

  private $unsubscribe$ = new Subject();
  destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $initializeExisting(list: LocusRobotics_jobs_by_license_plate_listComponent, entity: ILocusRobotics_jobs_by_license_plate_listComponentEntity) {
    this.list = list;
    this.entity = entity;

    this.styles = new Styles(
      ['small-card'], 
      { 
        width: '25%', 
        height: '200px' 
      }
    );
  }
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_jobs_by_license_plateComponent)
  ],
  selector: 'LocusRobotics-jobs_by_license_plate_list',
  templateUrl: './LocusRobotics.jobs_by_license_plate_list.component.html'
})
export class LocusRobotics_jobs_by_license_plate_listComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  topToolbar = {
      build_pallet: new ToolModel(new ButtonModel('build_pallet', new ButtonStyles(null, null), false, 'Build pallet', 'ms-Icon ms-Icon--Build')
    ),
      split_button: new ToolModel(new ButtonModel('split_button', new ButtonStyles(null, null), false, 'Split', 'ms-Icon ms-Icon--Split')
    )
  };

  formGroup: FormGroup = new FormGroup({
    idle_jobs_flag: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    idle_jobs_flag: new FieldModel(new CheckBoxModel(this.formGroup.controls['idle_jobs_flag'] as DatexFormControl, null, false, 'Show only idle jobs')
, new ControlContainerStyles(null, null), '', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  entities: ILocusRobotics_jobs_by_license_plate_listComponentEntity[] = [];
  pageSize = 15;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus: ELoadingStatus = ELoadingStatus.Loading;

  items: LocusRobotics_jobs_by_license_plate_listComponentItemModel[] = [];
  

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
) { 
    super();
    this.title = 'Jobs by licenseplate';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $list = this;
    const $utils = this.utils;



    this.initialized = true;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.refresh();
  }
 
  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    this.$reset();
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    
  }

  $reset() {
    this.pageSkip = 0;
    this.entities = [];
    this.items.forEach(i => i.destroy());
    this.items = [];
  }


  $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $list = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  null ,
      orderId:  null ,
      projectIds:  null ,
      warehouseId:  null ,
    };
    this.datasources.LocusRobotics.ds_jobs_header_grid.getList(inParams)
    .then(data => {
      this.entities.push(...data.result);
      this.totalCount = data.totalCount;
      this.$dataLoaded(data.result);
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    })
    .catch(error => {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    });
  }

  async $dataLoaded(data: ILocusRobotics_jobs_by_license_plate_listComponentEntity[]) {
    for (let entity of data) {
      const item = new LocusRobotics_jobs_by_license_plate_listComponentItemModel(
        this.utils,
        this.settings,
        this.shell, 
        this.datasources,
        this.flows,
        this.reports,
        this.localization,
        this.operations);
      item.$initializeExisting(this, entity);
      this.items.push(item);
    } 
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  

  close() {
    this.$finish.emit();
  }
}
