import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService, EModalSize, EToasterType, EToasterPosition } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 


import { LocusRobotics_jobs_by_license_plate_gridComponent } from './LocusRobotics.jobs_by_license_plate_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_jobs_by_license_plate_gridComponent),
  ],
  selector: 'LocusRobotics-jobs_by_license_plate',
  templateUrl: './LocusRobotics.jobs_by_license_plate.component.html'
})
export class LocusRobotics_jobs_by_license_plateComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['attention','border-left']);

  inParams: { orderId?: number, licensePlateId?: string, bot?: string, jobId?: string } = { orderId: null, licensePlateId: null, bot: null, jobId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('licensePlateId') set $inParams_licensePlateId(v: string) {
    this.inParams.licensePlateId = v;
  }
  get $inParams_licensePlateId(): string {
    return this.inParams.licensePlateId;
  }
  @Input('bot') set $inParams_bot(v: string) {
    this.inParams.bot = v;
  }
  get $inParams_bot(): string {
    return this.inParams.bot;
  }
  @Input('jobId') set $inParams_jobId(v: string) {
    this.inParams.jobId = v;
  }
  get $inParams_jobId(): string {
    return this.inParams.jobId;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();

  actionbar = {
      build_pallet: new ToolModel(new ButtonModel('build_pallet', new ButtonStyles(null, null), false, 'Build pallet', 'ms-Icon ms-Icon--Build')
    )
  };

  formGroupContent: FormGroup = new FormGroup({
  });


  content = {
    fields: {
        order: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order', false)
,
        job: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Job', false)
,
        bot: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Bot', false)
,
        time_on_floor: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Time on floor', false)
,
        newField5: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
        newField6: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
        newField7: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
    },
    fieldsets: {
  newGroup1: new FieldsetModel('Label of newGroup1', false, false, true),
  newGroup2: new FieldsetModel('Label of newGroup2', false, false, true),
  newGroup3: new FieldsetModel('Label of newGroup3', false, false, true),
  newGroup4: new FieldsetModel('Label of newGroup4', false, false, true),
}
  }


    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      cart_info_grid: new TabItemModel(
        this.rootTabGroup, 
        ' ', 
        ),
    };
  
    //#region tabs inParams
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_cart_info_grid', { read: LocusRobotics_jobs_by_license_plate_gridComponent }) $tabs_cart_info_grid: LocusRobotics_jobs_by_license_plate_gridComponent;
    //#endregion tabs children
  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.inParams.licensePlateId;
    return expr;
  }


  get headerIcon() {
    const $card = this;
    const $utils = this.utils;
    const expr = `Barcode`;
    return expr;
  }


  get headerIconSize() {
      return " card-icon-size-lg";
  }

  get headerIconColor() {
      return "";
  }

  get headerIconTransparency() {
      return "";
  }


  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.cart_info_grid,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;

    (this.content.fields.order.control as TextModel).text = $card.inParams.orderId.toString();
    (this.content.fields.job.control as TextModel).text = $card.inParams.jobId;
    (this.content.fields.bot.control as TextModel).text = $card.inParams.bot;
    (this.content.fields.time_on_floor.control as TextModel).text = '15 minutes';



    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_cart_info_grid') {
      if (!isNil(this.$tabs_cart_info_grid) && !this.tabs.cart_info_grid.hidden) {
        this.$tabs_cart_info_grid.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }


}
