import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { Addresses_contact_types_dd_singleComponent } from './Addresses.contact_types_dd_single.component'


interface Iapp_custom_contact_addresses_gridComponentEntity {
Id?: number, AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string }, Type?: { Name?: string }}

interface Iapp_custom_contact_addresses_gridComponentInParams {
  entity: string, entityId: number, typeIds?: number[]}


class app_custom_contact_addresses_gridComponentRowModel extends GridRowModel {
  grid: app_custom_contact_addresses_gridComponent;
  entity: Iapp_custom_contact_addresses_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    attention_of_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line1_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line2_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    line3_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    city_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    state_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    postal_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    country_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_telephone_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_email_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    attention_of: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['attention_of_edit'] as DatexFormControl, null, false, '')
      ),
    line1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line1_edit'] as DatexFormControl, null, false, '')
      ),
    line2: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line2_edit'] as DatexFormControl, null, false, '')
      ),
    line3: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line3_edit'] as DatexFormControl, null, false, '')
      ),
    city: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['city_edit'] as DatexFormControl, null, false, '')
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['state_edit'] as DatexFormControl, null, false, '')
      ),
    postal_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['postal_code_edit'] as DatexFormControl, null, false, '')
      ),
    country: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['country_edit'] as DatexFormControl, null, false, '')
      ),
    primary_telephone: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['primary_telephone_edit'] as DatexFormControl, null, false, '')
      ),
    primary_email: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['primary_email_edit'] as DatexFormControl, null, false, '')
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
  }

  cacheValueFor_$fields_type_selector_inParams_typeIds: number[];
  get $fields_type_selector_inParams_typeIds(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.typeIds;
    
    if(!isEqual(this.cacheValueFor_$fields_type_selector_inParams_typeIds, expr)) {
      this.cacheValueFor_$fields_type_selector_inParams_typeIds = expr;
    }
    return this.cacheValueFor_$fields_type_selector_inParams_typeIds;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_contact_addresses_gridComponent, entity: Iapp_custom_contact_addresses_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_contact_addresses_gridComponent, entity?: Iapp_custom_contact_addresses_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      entity:  $grid.inParams.entity ,
      entityId:  $grid.inParams.entityId ,
      typeIds:  $grid.inParams.typeIds ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.app.custom_ds_contact_addresses_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.type.displayControl as TextModel).text = $row.entity.Type?.Name;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.TypeId);
    (this.cells.attention_of.displayControl as TextModel).text = $row.entity.Address?.AttentionOf;
    (this.cells.attention_of.editControl as TextBoxModel).reset($row.entity.Address?.AttentionOf);
    (this.cells.line1.displayControl as TextModel).text = $row.entity.Address?.Line1;
    (this.cells.line1.editControl as TextBoxModel).reset($row.entity.Address?.Line1);
    (this.cells.line2.displayControl as TextModel).text = $row.entity.Address?.Line2;
    (this.cells.line2.editControl as TextBoxModel).reset($row.entity.Address?.Line2);
    (this.cells.line3.displayControl as TextModel).text = $row.entity.Address?.Line3;
    (this.cells.line3.editControl as TextBoxModel).reset($row.entity.Address?.Line3);
    (this.cells.city.displayControl as TextModel).text = $row.entity.Address?.City;
    (this.cells.city.editControl as TextBoxModel).reset($row.entity.Address?.City);
    (this.cells.state.displayControl as TextModel).text = $row.entity.Address?.State;
    (this.cells.state.editControl as TextBoxModel).reset($row.entity.Address?.State);
    (this.cells.postal_code.displayControl as TextModel).text = $row.entity.Address?.PostalCode;
    (this.cells.postal_code.editControl as TextBoxModel).reset($row.entity.Address?.PostalCode);
    (this.cells.country.displayControl as TextModel).text = $row.entity.Address?.Country;
    (this.cells.country.editControl as TextBoxModel).reset($row.entity.Address?.Country);
    (this.cells.primary_telephone.displayControl as TextModel).text = $row.entity.PrimaryTelephone;
    (this.cells.primary_telephone.editControl as TextBoxModel).reset($row.entity.PrimaryTelephone);
    (this.cells.primary_email.displayControl as TextModel).text = $row.entity.PrimaryEmail;
    (this.cells.primary_email.editControl as TextBoxModel).reset($row.entity.PrimaryEmail);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: app_custom_contact_addresses_gridComponentRowModel,
  $grid: app_custom_contact_addresses_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Contact" })).nextId;
  
  $row.entity = {
      Id: nextId
  };
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: app_custom_contact_addresses_gridComponentRowModel,
  $grid: app_custom_contact_addresses_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Update carrier service type
  let payload: any = {};
  let payloadAddress: any = {};
  
  /** Validations  ***/
  await $row.common_validate_row();
  
  if ($row.cells.type.editControl.isChanged) {
      payload.TypeId = $row.cells.type.editControl.value;
  }
  if ($row.cells.primary_email.editControl.isChanged) {
      payload.PrimaryEmail = trim($row.cells.primary_email.editControl.value, 128);
  }
  if ($row.cells.primary_telephone.editControl.isChanged) {
      payload.PrimaryTelephone = trim($row.cells.primary_telephone.editControl.value, 50);
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  
  
  if ($row.cells.line1.editControl.isChanged) {
      payloadAddress.Line1 = trim($row.cells.line1.editControl.value, 128);
  }
  if ($row.cells.line2.editControl.isChanged) {
      payloadAddress.Line2 = trim($row.cells.line2.editControl.value, 128);
  }
  if ($row.cells.line3.editControl.isChanged) {
      payloadAddress.Line3 = trim($row.cells.line3.editControl.value, 128);
  }
  if ($row.cells.attention_of.editControl.isChanged) {
      payloadAddress.AttentionOf = trim($row.cells.attention_of.editControl.value, 64);
      payload.FirstName = trim($row.cells.attention_of.editControl.value, 32);
      payload.LastName = trim($row.cells.attention_of.editControl.value, 32);
  }
  if ($row.cells.city.editControl.isChanged) {
      payloadAddress.City = trim($row.cells.city.editControl.value, 64);
  }
  if ($row.cells.state.editControl.isChanged) {
      payloadAddress.State = trim($row.cells.state.editControl.value, 64);
  }
  if ($row.cells.postal_code.editControl.isChanged) {
      payloadAddress.PostalCode = trim($row.cells.postal_code.editControl.value, 64);
  }
  if ($row.cells.country.editControl.isChanged) {
      payloadAddress.Country = trim($row.cells.country.editControl.value, 32);
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Contacts', id: $row.entity.Id, entity: payload });
      await $flows.Utilities.crud_update_flow({ entitySet: 'Addresses', id: $row.entity.AddressId, entity: payloadAddress });
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  function trim(candidate: any, length: number) {
  
      if ((length ?? 0) > 0) {
          if ($utils.isDefined(candidate)) {
              if (typeof candidate === 'string') {
                  let string_candidate = candidate?.toString();
                  if (string_candidate.length > length) {
                      string_candidate = string_candidate.substring(0, length);
                      candidate = string_candidate;
                  }
              }
          }
      }
      return candidate;
  }
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: app_custom_contact_addresses_gridComponentRowModel,
  $grid: app_custom_contact_addresses_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  /** Validations  ***/
  await $row.common_validate_row();
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.type.editControl.value,
      $row.cells.attention_of.editControl.value,
      $row.cells.line1.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Addresses.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  try {
  
      const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Address' })).nextId;
      const payloadAddress = {
          "Id": nextAddressId,
          "Line1": trim($row.cells.line1?.editControl.value, 128),
          "Line2": trim($row.cells.line2?.editControl.value, 128),
          "Line3": trim($row.cells.line3?.editControl.value, 128),
          "City": trim($row.cells.city?.editControl.value, 64),
          "State": trim($row.cells.state?.editControl.value, 64),
          "PostalCode": trim($row.cells.postal_code?.editControl.value, 64),
          "Country": trim($row.cells.country?.editControl.value, 32),
          "AttentionOf": trim($row.cells.attention_of?.editControl.value, 64),
      };
   
      await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress});
  
      const payloadContacts = {
          "Id": $row.entity.Id,
          "OwnerEntity": $grid.inParams.entity,
          "OwnerId": $grid.inParams.entityId,
          "TypeId": $row.cells.type.editControl.value,
          "FirstName": trim($row.cells.attention_of.editControl.value, 32),
          "LastName": trim($row.cells.attention_of.editControl.value, 32),
          "AddressId": nextAddressId,
          "PrimaryEmail": trim($row.cells.primary_email.editControl.value, 128),
          "PrimaryTelephone": trim($row.cells.primary_telephone.editControl.value, 50),
          "Notes": $row.cells.notes?.editControl.value
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts});
  
      await $grid.refresh();
  
  } catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  function trim(candidate: any, length: number) {
  
      if ((length ?? 0) > 0) {
          if ($utils.isDefined(candidate)) {
              if (typeof candidate === 'string') {
                  let string_candidate = candidate?.toString();
                  if (string_candidate.length > length) {
                      string_candidate = string_candidate.substring(0, length);
                      candidate = string_candidate;
                  }
              }
          }
      }
      return candidate;
  }
  }
  common_validate_row(event = null) {
    return this.common_validate_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async common_validate_rowInternal(
    $row: app_custom_contact_addresses_gridComponentRowModel,
  $grid: app_custom_contact_addresses_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  let errors: string[] = [];
  /*** INDIVIDUAL FIELDS ***/
  
  // Name
  if (!$utils.isDefinedTrimmed($row.cells.attention_of.editControl.value)) {
      errors.push('Name is required');
  }
  
  // Country
  let country: string;
  if (!$utils.isDefinedTrimmed($row.cells.country.editControl.value)) {
      errors.push("Missing required field: 'Country'");
  }
  else {
      country = $row.cells.country.editControl.value.trim().toUpperCase();
      if (country.length !== 3) {
          errors.push("Invalid value entered for 'Country': must be 3-digit country code");
      }
      if (!$utils.isDefined((await $datasources.Addresses.ds_country_codes.getList({ fullTextSearch: country })).result)) {
          errors.push(`Invalid country code provided`);
      }
  }
  
  // Line 1
  if (!$utils.isDefinedTrimmed($row.cells.line1.editControl.value)) {
      errors.push(`Missing required field: 'Line 1'`);
  }
  
  // City
  if (country === 'USA' && !$utils.isDefinedTrimmed($row.cells.city.editControl.value)) {
      errors.push(`Missing required field: 'City'`);
  }
  
  // State
  if (country === 'USA') {
      if ($utils.isDefinedTrimmed($row.cells.state.editControl.value)) {
          $row.cells.state.editControl.value = $row.cells.state.editControl.value.trim().toUpperCase();
  
          if (!/^(AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|UT|VT|VA|VI|WA|WV|WI|WY)$/.test($row.cells.state.editControl.value)) {
              errors.push(`Invalid value entered for 'State': must be a valid, two-digit USA state abbreviation`);
          }
      }
      else {
          errors.push(`Missing required field: 'State'`);
      }
  }
  
  
  // Zip code
  if (country === 'USA') {
      if ($utils.isDefinedTrimmed($row.cells.postal_code.editControl.value)) {
          let postalCode = $row.cells.postal_code.editControl.value.trim();
          if (postalCode.length !== 5 || isNaN(postalCode)) {
              errors.push(`Invalid value entered for 'Zip code': must be 5-digit numeric value`);
          }
      }
      else {
          errors.push(`Missing required field: 'Zip code'`);
      }
  }
  
  // Phone
  if (country === 'USA' && $utils.isDefinedTrimmed($row.cells.primary_telephone.editControl.value)) {
      $row.cells.primary_telephone.editControl.value = $row.cells.primary_telephone.editControl.value.trim();
  
      if (!(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test($row.cells.primary_telephone.editControl.value))) {
          errors.push(`Invalid value entered for 'Phone number': must match format 'XXX-XXX-XXXX'`);
      }
  }
  
  /*** DUPLICATES ***/
  // Duplicate Address
  if ($grid.rows.find(cl =>
      cl !== $row
      && (cl?.entity?.Address?.Line1 ?? '').trim().toUpperCase() === ($row.cells.line1.editControl.value ?? '').trim().toUpperCase()
      && (cl?.entity?.Address?.Line2 ?? '').trim().toUpperCase() === ($row.cells.line2.editControl.value ?? '').trim().toUpperCase()
      && (cl?.entity?.Address?.Line3 ?? '').trim().toUpperCase() === ($row.cells.line3.editControl.value ?? '').trim().toUpperCase()
      && (cl?.entity?.Address?.City ?? '').trim().toUpperCase() === ($row.cells.city.editControl.value ?? '').trim().toUpperCase()
      && (cl?.entity?.Address?.State ?? '').trim().toUpperCase() === ($row.cells.state.editControl.value ?? '').trim().toUpperCase()
      && (cl?.entity?.Address?.PostalCode ?? '').trim().toUpperCase() === ($row.cells.postal_code.editControl.value ?? '').trim().toUpperCase()
      && (cl?.entity?.Address?.Country ?? '').trim().toUpperCase() === ($row.cells.country.editControl.value ?? '').trim().toUpperCase()
  ))
  
  {
      let message = `Entered address already exists.`;
      errors.push(message);
  }
  
  // Duplicate Name
  if ($grid.rows.find(r => r !== $row && r.entity.Address?.AttentionOf?.trim().toUpperCase() === $row.cells.attention_of.editControl.value?.trim().toUpperCase())) {
      let message = `Name '${$row.cells.attention_of.editControl.value}' already exists.`;
      errors.push(message);
  }
  
  
  /*** STOP IF ERRORS ***/
  if ($utils.isDefined(errors)) {
      $shell.openErrorDialog('Error saving address', null, errors);
      throw new Error();
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Addresses_contact_types_dd_singleComponent),
  ],
  selector: 'app-custom_contact_addresses_grid',
  templateUrl: './app.custom_contact_addresses_grid.component.html'
})
export class app_custom_contact_addresses_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_contact_addresses_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_contact_addresses_gridComponentInParams = { entity: null, entityId: null, typeIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       attention_of: new GridHeaderModel(new HeaderStyles(null, null), 'Name', true, false, null),       line1: new GridHeaderModel(new HeaderStyles(null, null), 'Line 1', true, false, null),       line2: new GridHeaderModel(new HeaderStyles(null, null), 'Line 2', false, false, null),       line3: new GridHeaderModel(new HeaderStyles(null, null), 'Line 3', false, false, null),       city: new GridHeaderModel(new HeaderStyles(null, null), 'City', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       postal_code: new GridHeaderModel(new HeaderStyles(null, null), 'Zip Code', false, false, null),       country: new GridHeaderModel(new HeaderStyles(null, null), 'Country', false, false, null),       primary_telephone: new GridHeaderModel(new HeaderStyles(null, null), 'Phone', false, false, null),       primary_email: new GridHeaderModel(new HeaderStyles(null, null), 'Email', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_contact_addresses_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('entity') set $inParams_entity(value: any) {
    this.inParams['entity'] = value;
  }
  get $inParams_entity(): any {
    return this.inParams['entity'] ;
  }
  @Input('entityId') set $inParams_entityId(value: any) {
    this.inParams['entityId'] = value;
  }
  get $inParams_entityId(): any {
    return this.inParams['entityId'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    ) {
    super();
    this.title = 'Addresses';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.entity)) {
        this.$missingRequiredInParams.push('entity');
      }
      if(isNil(this.inParams.entityId)) {
        this.$missingRequiredInParams.push('entityId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      entity:  $grid.inParams.entity ,
      entityId:  $grid.inParams.entityId ,
      typeIds:  $grid.inParams.typeIds ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.app.custom_ds_contact_addresses_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_contact_addresses_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: Iapp_custom_contact_addresses_gridComponentEntity) {
    const row = new app_custom_contact_addresses_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: app_custom_contact_addresses_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Addresses.openErrorDialog('Address Delete Error', 'No Address selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              addressId: row.entity.AddressId
          };
          const result = await $flows.Addresses.is_address_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Address ${row.entity.Address.AttentionOf} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete Address errors';
          const errorMessage = `Address ${failures.map(c => c.entity.Address.AttentionOf).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.Addresses.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Address ${candidates.map(c => c.entity.Address.AttentionOf).join(',')} - Once deleted, the Address cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some Addresses cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following Addresses';
              const message = confirmCandidates;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      addressId: candidate.entity.AddressId
                      }
                      const result = await $flows.Addresses.delete_address_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Address ${candidate.entity.Address.AttentionOf} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All the selected Addresses deleted';
                  const message = `Addresses ${processSuccess.map(c => c.entity.Address.AttentionOf).join(',')} deleted`;
                  await $shell.Addresses.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All the selected Addresses failed to delete';
                      const message = `Addresses ${processFailures.map(c => c.entity.Address.AttentionOf).join(',')} could not be deleted`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Addresses could not be deleted';
                      const deleted = `Addresses ${processSuccess.map(c => c.entity.Address.AttentionOf).join(',')} were deleted.`;
                      const errors = `The following Addresses could not be deleted: ${processFailures.map(c => c.entity.Address.AttentionOf).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_contact_addresses_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else{
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
