import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_outbound_labelsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { LookupCode?: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OwnerReference?: string, ProjectId?: number, ShippingAddressId?: number, VendorReference?: string, Project?: { LookupCode?: string, Name?: string }, Account?: { Id?: number, Name?: string, AccountsContactsLookup?: { AccountId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, Address?: { City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, LookupCode?: string, Carrier?: { Id?: number, Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, LoadContainer?: { LookupCode?: string, ContainerType?: { Name?: string } }, ShipToContact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } }, ShippingContainers?: { Id?: number, LookupCode?: string, ContainerType?: { Name?: string } }[] } }[], PreferredWarehouse?: { Id?: number, Name?: string, Address?: { City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } }, OrderClass?: { Id?: number }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, Line1?: string, LookupCode?: string, Name?: string, PostalCode?: string, State?: string, TypeId?: number }[], PreferredCarrierService?: { Name?: string, ServiceType?: { Name?: string } }, First7charactersofprojectname?: string, CustomerPOwithAIcodeinfront400?: string, First4charactersofPO?: string, OAddresses?: string, Owner_reference_4characters?: string } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_outbound_labels/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
