import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_inventoryCount_resultService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { InventoryCountResult?: { JobId?: string, JobTaskId?: string, TaskLocation?: string, ItemNo?: string, LotNo?: string, CountDiscrepency?: boolean, CountExpectedQty?: number, CountActualQty?: number, CountUser?: string, CountDate?: string, CountRobot?: string, UOM?: string } }): Promise<{ hasDiscrepancy?: boolean }> 
  {
    let url = `${environment.backendUrl}api/LocusRobotics/functions/inventoryCount_result`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

