import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Equipment_ShellService, EModalSize, EToasterType, EToasterPosition } from './Equipment.shell.service';
import { Equipment_OperationService } from './Equipment.operation.service';
import { Equipment_DatasourceService } from './Equipment.datasource.index';
import { Equipment_FlowService } from './Equipment.flow.index';
import { Equipment_ReportService } from './Equipment.report.index';
import { Equipment_LocalizationService } from './Equipment.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './Equipment.frontend.types'
import { $frontendTypes as $types} from './Equipment.frontend.types' 

import { Equipment_equipment_type_dd_singleComponent } from './Equipment.equipment_type_dd_single.component'


interface IEquipment_equipment_gridComponentEntity {
Id?: number, LookupCode?: string, MacAddress?: string, TypeId?: number, LocationContainers?: { EquipmentId?: number, LocationContainerId?: number, LocationContainer?: { Name?: string, TypeId?: number, WarehouseId?: number, Warehouse?: { Name?: string } } }[]}

interface IEquipment_equipment_gridComponentInParams {
  warehouseId?: number}


class Equipment_equipment_gridComponentRowModel extends GridRowModel {
  grid: Equipment_equipment_gridComponent;
  entity: IEquipment_equipment_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    typeId_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    LookupCode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    typeId: new GridCellModel(
      new CellStyles(null, null),
      new SelectBoxModel(
  this.formGroup.controls['typeId_display'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  true, 
  '')
,
null
      ),
    MacAddress: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    warehouses: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Equipment_ShellService,
private datasources: Equipment_DatasourceService,
private flows: Equipment_FlowService,
private reports: Equipment_ReportService,
private localization: Equipment_LocalizationService,
private operations: Equipment_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: Equipment_equipment_gridComponent, entity: IEquipment_equipment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Equipment_equipment_gridComponent, entity?: IEquipment_equipment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Equipment.ds_equipment_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.LookupCode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.typeId.displayControl as SelectBoxModel).reset($row.entity.TypeId);
    (this.cells.MacAddress.displayControl as TextModel).text = $row.entity.MacAddress;
    (this.cells.warehouses.displayControl as TextModel).text = $row.entity.LocationContainers.map(item => { return item.LocationContainer.Warehouse.Name }).sort((a,b) => { return a.localeCompare(b) }).join(', ');

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'LookupCode' : {
        this.on_device_id_cell_click();
        break;
      }
    }
  }

  //#region private flows
  on_device_id_cell_click(event = null) {
    return this.on_device_id_cell_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_device_id_cell_clickInternal(
    $row: Equipment_equipment_gridComponentRowModel,
  $grid: Equipment_equipment_gridComponent, 
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  const result = await $shell.Equipment.openequipment_editorDialog({ equipmentId: $row.entity.Id }, 'flyout');
  
  if(result.confirmed === true)
      $row.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Equipment_equipment_type_dd_singleComponent),
  ],
  selector: 'Equipment-equipment_grid',
  templateUrl: './Equipment.equipment_grid.component.html'
})
export class Equipment_equipment_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IEquipment_equipment_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', null);

  fullTextSearch: string;

  inParams: IEquipment_equipment_gridComponentInParams = { warehouseId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Device id', false, false, null),       typeId: new GridHeaderModel(new HeaderStyles(null, null), 'Type', false, false, null),       MacAddress: new GridHeaderModel(new HeaderStyles(null, null), 'MAC address', false, false, null),       warehouses: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouses', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Equipment_equipment_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }

  topToolbar = {
      add_equipment: new ToolModel(new ButtonModel('add_equipment', new ButtonStyles(['creation'], null), false, 'Add equipment', 'ms-Icon ms-Icon--Add')
    ),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'ms-Icon ms-Icon--Refresh')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Equipment_ShellService,
    private datasources: Equipment_DatasourceService,
    private flows: Equipment_FlowService,
    private reports: Equipment_ReportService,
    private localization: Equipment_LocalizationService,
    private operations: Equipment_OperationService,
    ) {
    super();
    this.title = 'Equipment';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_grid_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.Equipment.ds_equipment_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Equipment_equipment_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_add_clicked(event = null) {
    return this.on_add_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_clickedInternal(
    $grid: Equipment_equipment_gridComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  try {
  
      $grid.topToolbar.add_equipment.control.readOnly = true;
  
      const result = await $shell.Equipment.opencreate_equipment_formDialog('flyout');
  
      if(result.confirmed === true)
          $grid.refresh();
  }
  catch(error) {
  
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      $shell.Utilities.openErrorDialog('Could not create Equipment', error)
  
  }
  finally {
  
      $grid.topToolbar.add_equipment.control.readOnly = false;
  
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Equipment_equipment_gridComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  
  }
  on_grid_init(event = null) {
    return this.on_grid_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_initInternal(
    $grid: Equipment_equipment_gridComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: Equipment_equipment_gridComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  $grid.refresh();
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Equipment_equipment_gridComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
