<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error_message">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.error_message.displayControl.styles.style"
          [ngClass]="row.cells.error_message.displayControl.styles.classes">{{row.cells.error_message.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_material.displayControl.styles.style"
          [ngClass]="row.cells.source_material.displayControl.styles.classes">{{row.cells.source_material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.source_vendorlot.displayControl.styles.classes">{{row.cells.source_vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_lot.displayControl.styles.style"
          [ngClass]="row.cells.source_lot.displayControl.styles.classes">{{row.cells.source_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_packaging.displayControl.styles.style"
          [ngClass]="row.cells.source_packaging.displayControl.styles.classes">{{row.cells.source_packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.source_packaged_amount.displayControl.styles.classes">{{row.cells.source_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.source_licenseplate.displayControl.styles.classes">{{row.cells.source_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_serial_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_serial_number.displayControl.styles.style"
          [ngClass]="row.cells.source_serial_number.displayControl.styles.classes">{{row.cells.source_serial_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_key">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.allocation_key.displayControl.styles.style"
          [ngClass]="row.cells.allocation_key.displayControl.styles.classes">{{row.cells.allocation_key.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot_status.displayControl.styles.style"
          [ngClass]="row.cells.lot_status.displayControl.styles.classes">{{row.cells.lot_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="license_plate_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.license_plate_status.displayControl.styles.style"
          [ngClass]="row.cells.license_plate_status.displayControl.styles.classes">{{row.cells.license_plate_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="target_material_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.target_material_id.displayControl.styles.style"
          [ngClass]="row.cells.target_material_id.displayControl.styles.classes">{{row.cells.target_material_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allow_inactive_inventory">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['allow_inactive_inventory_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.allow_inactive_inventory.displayControl.styles.style"
                  [ngClass]="row.cells.allow_inactive_inventory.displayControl.styles.classes">{{row.cells.allow_inactive_inventory.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
