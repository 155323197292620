import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { AsnOrders_adjust_asn_orderline_and_shipmentline_flowService } from './AsnOrders.flow.index';
import { AsnOrders_auto_move_asn_inventory_by_licenseplate_task_flowService } from './AsnOrders.flow.index';
import { AsnOrders_auto_receive_asn_ordersService } from './AsnOrders.flow.index';
import { AsnOrders_auto_receive_asn_taskService } from './AsnOrders.flow.index';
import { AsnOrders_cancel_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_cancel_receiving_task_flowService } from './AsnOrders.flow.index';
import { AsnOrders_complete_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_complete_putaway_flowService } from './AsnOrders.flow.index';
import { AsnOrders_copy_asn_order_udfs_to_new_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_create_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_create_asn_order_line_in_processing_status_flowService } from './AsnOrders.flow.index';
import { AsnOrders_create_asn_serial_numbers_flowService } from './AsnOrders.flow.index';
import { AsnOrders_create_licenseplate_flowService } from './AsnOrders.flow.index';
import { AsnOrders_create_shipment_for_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_delete_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_delete_asn_order_line_flowService } from './AsnOrders.flow.index';
import { AsnOrders_delete_children_serial_numbers_flowService } from './AsnOrders.flow.index';
import { AsnOrders_format_order_lookupcode_flowService } from './AsnOrders.flow.index';
import { AsnOrders_format_order_lookupcode_flow_multipleService } from './AsnOrders.flow.index';
import { AsnOrders_generate_asn_child_serial_numbers_flowService } from './AsnOrders.flow.index';
import { AsnOrders_get_asn_order_stateService } from './AsnOrders.flow.index';
import { AsnOrders_get_license_plates_state_flowService } from './AsnOrders.flow.index';
import { AsnOrders_get_serials_to_delete_with_children_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_auto_receivable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_cancelable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_completable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_deletable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_line_deletable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_processable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_revertable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_unreceivable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_is_asn_order_validatable_flowService } from './AsnOrders.flow.index';
import { AsnOrders_print_license_plate_labels_flowService } from './AsnOrders.flow.index';
import { AsnOrders_process_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_revert_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_set_asn_receiving_task_flowService } from './AsnOrders.flow.index';
import { AsnOrders_transfer_outstanding_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_unreceive_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_unreceive_licenseplate_asn_order_flowService } from './AsnOrders.flow.index';
import { AsnOrders_update_asn_licenseplate_flowService } from './AsnOrders.flow.index';
import { AsnOrders_validate_order_flowService } from './AsnOrders.flow.index';

import { $frontendTypes } from './AsnOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class AsnOrders_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public AsnOrders: AsnOrders_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _adjust_asn_orderline_and_shipmentline_flow: AsnOrders_adjust_asn_orderline_and_shipmentline_flowService;
  public async adjust_asn_orderline_and_shipmentline_flow(inParams: { adjustPackagedAmount: number, shipmentlineId: number, orderId: number, orderlineNumber: number }): Promise<void> {
    if(!this._adjust_asn_orderline_and_shipmentline_flow) {
      this._adjust_asn_orderline_and_shipmentline_flow = this.injector.get(AsnOrders_adjust_asn_orderline_and_shipmentline_flowService);
    }
    return this._adjust_asn_orderline_and_shipmentline_flow.run(inParams);
  }
   
   

   
 
  private _auto_move_asn_inventory_by_licenseplate_task_flow: AsnOrders_auto_move_asn_inventory_by_licenseplate_task_flowService;
  public async auto_move_asn_inventory_by_licenseplate_task_flow(inParams: { licenseplateId: number, taskId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._auto_move_asn_inventory_by_licenseplate_task_flow) {
      this._auto_move_asn_inventory_by_licenseplate_task_flow = this.injector.get(AsnOrders_auto_move_asn_inventory_by_licenseplate_task_flowService);
    }
    return this._auto_move_asn_inventory_by_licenseplate_task_flow.run(inParams);
  }
   
   

   
 
  private _auto_receive_asn_orders: AsnOrders_auto_receive_asn_ordersService;
  public async auto_receive_asn_orders(inParams: { orderIds: number[] }): Promise< { reasons?: string[], confirmedLicensePlates?: number, confirmedPackagedAmount?: number }> {
    if(!this._auto_receive_asn_orders) {
      this._auto_receive_asn_orders = this.injector.get(AsnOrders_auto_receive_asn_ordersService);
    }
    return this._auto_receive_asn_orders.run(inParams);
  }
   
   

   
 
  private _auto_receive_asn_task: AsnOrders_auto_receive_asn_taskService;
  public async auto_receive_asn_task(inParams: { taskId: number }): Promise<void> {
    if(!this._auto_receive_asn_task) {
      this._auto_receive_asn_task = this.injector.get(AsnOrders_auto_receive_asn_taskService);
    }
    return this._auto_receive_asn_task.run(inParams);
  }
   
   

   
 
  private _cancel_asn_order_flow: AsnOrders_cancel_asn_order_flowService;
  public async cancel_asn_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_asn_order_flow) {
      this._cancel_asn_order_flow = this.injector.get(AsnOrders_cancel_asn_order_flowService);
    }
    return this._cancel_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _cancel_receiving_task_flow: AsnOrders_cancel_receiving_task_flowService;
  public async cancel_receiving_task_flow(inParams: { taskId: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_receiving_task_flow) {
      this._cancel_receiving_task_flow = this.injector.get(AsnOrders_cancel_receiving_task_flowService);
    }
    return this._cancel_receiving_task_flow.run(inParams);
  }
   
   

   
 
  private _complete_asn_order_flow: AsnOrders_complete_asn_order_flowService;
  public async complete_asn_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_asn_order_flow) {
      this._complete_asn_order_flow = this.injector.get(AsnOrders_complete_asn_order_flowService);
    }
    return this._complete_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _complete_putaway_flow: AsnOrders_complete_putaway_flowService;
  public async complete_putaway_flow(inParams: { licenseplateId: number, targetLocationId: number, taskId?: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_putaway_flow) {
      this._complete_putaway_flow = this.injector.get(AsnOrders_complete_putaway_flowService);
    }
    return this._complete_putaway_flow.run(inParams);
  }
   
   

   
 
  private _copy_asn_order_udfs_to_new_order_flow: AsnOrders_copy_asn_order_udfs_to_new_order_flowService;
  public async copy_asn_order_udfs_to_new_order_flow(inParams: { sourceOrderId: number, targetOrderId: number }): Promise<void> {
    if(!this._copy_asn_order_udfs_to_new_order_flow) {
      this._copy_asn_order_udfs_to_new_order_flow = this.injector.get(AsnOrders_copy_asn_order_udfs_to_new_order_flowService);
    }
    return this._copy_asn_order_udfs_to_new_order_flow.run(inParams);
  }
   
   

   
 
  private _create_asn_order_flow: AsnOrders_create_asn_order_flowService;
  public async create_asn_order_flow(inParams: { projectId: number, warehouseId: number, lookupcode?: string, orderClassId: number, ownerReference?: string }): Promise< { reason?: string, orderId?: number, shipmentId?: number }> {
    if(!this._create_asn_order_flow) {
      this._create_asn_order_flow = this.injector.get(AsnOrders_create_asn_order_flowService);
    }
    return this._create_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _create_asn_order_line_in_processing_status_flow: AsnOrders_create_asn_order_line_in_processing_status_flowService;
  public async create_asn_order_line_in_processing_status_flow(inParams: { projectId: number, orderId: number, shipmentId: number, materialId: number, packagingId: number, packagedAmount: number, lotId?: number, vendorLotId?: number, grossWeight?: number, weightUom?: number, notes?: string }): Promise< { reason?: string, linenumber?: number }> {
    if(!this._create_asn_order_line_in_processing_status_flow) {
      this._create_asn_order_line_in_processing_status_flow = this.injector.get(AsnOrders_create_asn_order_line_in_processing_status_flowService);
    }
    return this._create_asn_order_line_in_processing_status_flow.run(inParams);
  }
   
   

   
 
  private _create_asn_serial_numbers_flow: AsnOrders_create_asn_serial_numbers_flowService;
  public async create_asn_serial_numbers_flow(inParams: { serialNumbers: { Archived?: boolean, ChainHead?: number, DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LicensePlateId?: number, LotId?: number, NetVolumeValue?: number, NetWeightValue?: number, Notes?: string, OutboundShipmentId?: number, PackagingId?: number, StatusId?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, LookupCode?: string, MaterialId?: number }[] }): Promise< { reasons?: string[], serialNumberIds?: number[] }> {
    if(!this._create_asn_serial_numbers_flow) {
      this._create_asn_serial_numbers_flow = this.injector.get(AsnOrders_create_asn_serial_numbers_flowService);
    }
    return this._create_asn_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _create_licenseplate_flow: AsnOrders_create_licenseplate_flowService;
  public async create_licenseplate_flow(inParams: { properties: any }): Promise< { reasons?: string[], licenseplateId?: number }> {
    if(!this._create_licenseplate_flow) {
      this._create_licenseplate_flow = this.injector.get(AsnOrders_create_licenseplate_flowService);
    }
    return this._create_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_shipment_for_asn_order_flow: AsnOrders_create_shipment_for_asn_order_flowService;
  public async create_shipment_for_asn_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._create_shipment_for_asn_order_flow) {
      this._create_shipment_for_asn_order_flow = this.injector.get(AsnOrders_create_shipment_for_asn_order_flowService);
    }
    return this._create_shipment_for_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_asn_order_flow: AsnOrders_delete_asn_order_flowService;
  public async delete_asn_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_asn_order_flow) {
      this._delete_asn_order_flow = this.injector.get(AsnOrders_delete_asn_order_flowService);
    }
    return this._delete_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_asn_order_line_flow: AsnOrders_delete_asn_order_line_flowService;
  public async delete_asn_order_line_flow(inParams: { orderId: number, linenumber: number }): Promise< { reason?: string }> {
    if(!this._delete_asn_order_line_flow) {
      this._delete_asn_order_line_flow = this.injector.get(AsnOrders_delete_asn_order_line_flowService);
    }
    return this._delete_asn_order_line_flow.run(inParams);
  }
   
   

   
 
  private _delete_children_serial_numbers_flow: AsnOrders_delete_children_serial_numbers_flowService;
  public async delete_children_serial_numbers_flow(inParams: { serialnumberIds?: number[] }): Promise< { reasons?: string[] }> {
    if(!this._delete_children_serial_numbers_flow) {
      this._delete_children_serial_numbers_flow = this.injector.get(AsnOrders_delete_children_serial_numbers_flowService);
    }
    return this._delete_children_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _format_order_lookupcode_flow: AsnOrders_format_order_lookupcode_flowService;
  public async format_order_lookupcode_flow(inParams: { orderId: number, additionalData?: any, context: string }): Promise< { formattedLookupcode?: string, errors?: string[] }> {
    if(!this._format_order_lookupcode_flow) {
      this._format_order_lookupcode_flow = this.injector.get(AsnOrders_format_order_lookupcode_flowService);
    }
    return this._format_order_lookupcode_flow.run(inParams);
  }
   
   

   
 
  private _format_order_lookupcode_flow_multiple: AsnOrders_format_order_lookupcode_flow_multipleService;
  public async format_order_lookupcode_flow_multiple(inParams: { context: string, orders?: { orderId?: number, additionalData?: any }[] }): Promise< { errors?: string[], orders?: { orderId?: number, formattedLookupCode?: string }[] }> {
    if(!this._format_order_lookupcode_flow_multiple) {
      this._format_order_lookupcode_flow_multiple = this.injector.get(AsnOrders_format_order_lookupcode_flow_multipleService);
    }
    return this._format_order_lookupcode_flow_multiple.run(inParams);
  }
   
   

   
 
  private _generate_asn_child_serial_numbers_flow: AsnOrders_generate_asn_child_serial_numbers_flowService;
  public async generate_asn_child_serial_numbers_flow(inParams: { serialNumberIds: number[] }): Promise< { reasons?: string[] }> {
    if(!this._generate_asn_child_serial_numbers_flow) {
      this._generate_asn_child_serial_numbers_flow = this.injector.get(AsnOrders_generate_asn_child_serial_numbers_flowService);
    }
    return this._generate_asn_child_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _get_asn_order_state: AsnOrders_get_asn_order_stateService;
  public async get_asn_order_state(inParams: { orderId: number, shipmentId: number }): Promise< { state?: number }> {
    if(!this._get_asn_order_state) {
      this._get_asn_order_state = this.injector.get(AsnOrders_get_asn_order_stateService);
    }
    return this._get_asn_order_state.run(inParams);
  }
   
   

   
 
  private _get_license_plates_state_flow: AsnOrders_get_license_plates_state_flowService;
  public async get_license_plates_state_flow(inParams: { license_plate_ids: number[] }): Promise< { reasons?: string[], license_plates?: { fail_action_reasons: { deletion: string[], unreceive: string[], receive: string[] }, id: number }[] }> {
    if(!this._get_license_plates_state_flow) {
      this._get_license_plates_state_flow = this.injector.get(AsnOrders_get_license_plates_state_flowService);
    }
    return this._get_license_plates_state_flow.run(inParams);
  }
   
   

   
 
  private _get_serials_to_delete_with_children_flow: AsnOrders_get_serials_to_delete_with_children_flowService;
  public async get_serials_to_delete_with_children_flow(inParams: { licenseplateId: number }): Promise< { reasons?: string[], parentSerialNumbers?: number[], firstChildSerials?: number[], secondChildSerials?: number[], thirdChildSerials?: number[], fourthChildSerials?: number[] }> {
    if(!this._get_serials_to_delete_with_children_flow) {
      this._get_serials_to_delete_with_children_flow = this.injector.get(AsnOrders_get_serials_to_delete_with_children_flowService);
    }
    return this._get_serials_to_delete_with_children_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_auto_receivable_flow: AsnOrders_is_asn_order_auto_receivable_flowService;
  public async is_asn_order_auto_receivable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasPlannedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_auto_receivable_flow) {
      this._is_asn_order_auto_receivable_flow = this.injector.get(AsnOrders_is_asn_order_auto_receivable_flowService);
    }
    return this._is_asn_order_auto_receivable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_cancelable_flow: AsnOrders_is_asn_order_cancelable_flowService;
  public async is_asn_order_cancelable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasCompletedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_cancelable_flow) {
      this._is_asn_order_cancelable_flow = this.injector.get(AsnOrders_is_asn_order_cancelable_flowService);
    }
    return this._is_asn_order_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_completable_flow: AsnOrders_is_asn_order_completable_flowService;
  public async is_asn_order_completable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasCompletedReceivingTasks?: boolean, checkForPlannedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_completable_flow) {
      this._is_asn_order_completable_flow = this.injector.get(AsnOrders_is_asn_order_completable_flowService);
    }
    return this._is_asn_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_deletable_flow: AsnOrders_is_asn_order_deletable_flowService;
  public async is_asn_order_deletable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasCompletedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_deletable_flow) {
      this._is_asn_order_deletable_flow = this.injector.get(AsnOrders_is_asn_order_deletable_flowService);
    }
    return this._is_asn_order_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_line_deletable_flow: AsnOrders_is_asn_order_line_deletable_flowService;
  public async is_asn_order_line_deletable_flow(inParams: { orderId: number, linenumber: number }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_line_deletable_flow) {
      this._is_asn_order_line_deletable_flow = this.injector.get(AsnOrders_is_asn_order_line_deletable_flowService);
    }
    return this._is_asn_order_line_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_processable_flow: AsnOrders_is_asn_order_processable_flowService;
  public async is_asn_order_processable_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_processable_flow) {
      this._is_asn_order_processable_flow = this.injector.get(AsnOrders_is_asn_order_processable_flowService);
    }
    return this._is_asn_order_processable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_revertable_flow: AsnOrders_is_asn_order_revertable_flowService;
  public async is_asn_order_revertable_flow(inParams: { orderId: number, useValidation: boolean, optimalInputs?: { statusId?: number, hasCompletedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_revertable_flow) {
      this._is_asn_order_revertable_flow = this.injector.get(AsnOrders_is_asn_order_revertable_flowService);
    }
    return this._is_asn_order_revertable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_unreceivable_flow: AsnOrders_is_asn_order_unreceivable_flowService;
  public async is_asn_order_unreceivable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_unreceivable_flow) {
      this._is_asn_order_unreceivable_flow = this.injector.get(AsnOrders_is_asn_order_unreceivable_flowService);
    }
    return this._is_asn_order_unreceivable_flow.run(inParams);
  }
   
   

   
 
  private _is_asn_order_validatable_flow: AsnOrders_is_asn_order_validatable_flowService;
  public async is_asn_order_validatable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasCompletedReceivingTasks?: boolean, customInputs?: any } }): Promise< { reasons?: string[] }> {
    if(!this._is_asn_order_validatable_flow) {
      this._is_asn_order_validatable_flow = this.injector.get(AsnOrders_is_asn_order_validatable_flowService);
    }
    return this._is_asn_order_validatable_flow.run(inParams);
  }
   
   

   
 
  private _print_license_plate_labels_flow: AsnOrders_print_license_plate_labels_flowService;
  public async print_license_plate_labels_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._print_license_plate_labels_flow) {
      this._print_license_plate_labels_flow = this.injector.get(AsnOrders_print_license_plate_labels_flowService);
    }
    return this._print_license_plate_labels_flow.run(inParams);
  }
   
   

   
 
  private _process_asn_order_flow: AsnOrders_process_asn_order_flowService;
  public async process_asn_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._process_asn_order_flow) {
      this._process_asn_order_flow = this.injector.get(AsnOrders_process_asn_order_flowService);
    }
    return this._process_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _revert_asn_order_flow: AsnOrders_revert_asn_order_flowService;
  public async revert_asn_order_flow(inParams: { orderId: number, useValidation: boolean }): Promise< { reasons?: string[] }> {
    if(!this._revert_asn_order_flow) {
      this._revert_asn_order_flow = this.injector.get(AsnOrders_revert_asn_order_flowService);
    }
    return this._revert_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _set_asn_receiving_task_flow: AsnOrders_set_asn_receiving_task_flowService;
  public async set_asn_receiving_task_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, orderId: number, vendorlotId: number, packagedAmount: number, serialNumbersToCreate?: { DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, LicensePlateId?: number, LotId?: number, PackagingId?: number, MaterialId?: number }[], serialNumberIdsToDelete?: number[], materialId: number, existingTaskId?: number }): Promise< { taskId?: number, orderlineNumber?: number }> {
    if(!this._set_asn_receiving_task_flow) {
      this._set_asn_receiving_task_flow = this.injector.get(AsnOrders_set_asn_receiving_task_flowService);
    }
    return this._set_asn_receiving_task_flow.run(inParams);
  }
   
   

   
 
  private _transfer_outstanding_asn_order_flow: AsnOrders_transfer_outstanding_asn_order_flowService;
  public async transfer_outstanding_asn_order_flow(inParams: { orderId: number, shipmentId: number, notes?: string, createNewOrder: boolean, copyUdfs: boolean }): Promise< { reasons?: string[], newOrderId?: number, newShipmentId?: number }> {
    if(!this._transfer_outstanding_asn_order_flow) {
      this._transfer_outstanding_asn_order_flow = this.injector.get(AsnOrders_transfer_outstanding_asn_order_flowService);
    }
    return this._transfer_outstanding_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _unreceive_asn_order_flow: AsnOrders_unreceive_asn_order_flowService;
  public async unreceive_asn_order_flow(inParams: { orderId: number, reasonCodeId: number, shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._unreceive_asn_order_flow) {
      this._unreceive_asn_order_flow = this.injector.get(AsnOrders_unreceive_asn_order_flowService);
    }
    return this._unreceive_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _unreceive_licenseplate_asn_order_flow: AsnOrders_unreceive_licenseplate_asn_order_flowService;
  public async unreceive_licenseplate_asn_order_flow(inParams: { licenseplateId: number, reasonCodeId: number, shipmentId: number, orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._unreceive_licenseplate_asn_order_flow) {
      this._unreceive_licenseplate_asn_order_flow = this.injector.get(AsnOrders_unreceive_licenseplate_asn_order_flowService);
    }
    return this._unreceive_licenseplate_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _update_asn_licenseplate_flow: AsnOrders_update_asn_licenseplate_flowService;
  public async update_asn_licenseplate_flow(inParams: { licenseplateId: number }): Promise< { reasons?: string[] }> {
    if(!this._update_asn_licenseplate_flow) {
      this._update_asn_licenseplate_flow = this.injector.get(AsnOrders_update_asn_licenseplate_flowService);
    }
    return this._update_asn_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _validate_order_flow: AsnOrders_validate_order_flowService;
  public async validate_order_flow(inParams: { orderId?: number }): Promise< { reason?: string }> {
    if(!this._validate_order_flow) {
      this._validate_order_flow = this.injector.get(AsnOrders_validate_order_flowService);
    }
    return this._validate_order_flow.run(inParams);
  }
   
   

   
}
