import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { LocusRobotics_bot_configuration_gridComponent } from './LocusRobotics.bot_configuration_grid.component';
import { LocusRobotics_jobs_by_botComponent } from './LocusRobotics.jobs_by_bot.component';
import { LocusRobotics_jobs_by_license_plate_gridComponent } from './LocusRobotics.jobs_by_license_plate_grid.component';
import { LocusRobotics_jobs_by_station_gridComponent } from './LocusRobotics.jobs_by_station_grid.component';
import { LocusRobotics_jobs_detail_by_order_gridComponent } from './LocusRobotics.jobs_detail_by_order_grid.component';
import { LocusRobotics_jobs_detail_by_station_gridComponent } from './LocusRobotics.jobs_detail_by_station_grid.component';
import { LocusRobotics_jobs_header_gridComponent } from './LocusRobotics.jobs_header_grid.component';
import { LocusRobotics_configuration_hubComponent } from './LocusRobotics.configuration_hub.component';
import { LocusRobotics_jobs_hubComponent } from './LocusRobotics.jobs_hub.component';
import { LocusRobotics_single_bot_hubComponent } from './LocusRobotics.single_bot_hub.component';
import { LocusRobotics_single_job_hubComponent } from './LocusRobotics.single_job_hub.component';
import { LocusRobotics_single_tote_hubComponent } from './LocusRobotics.single_tote_hub.component';
import { LocusRobotics_jobs_by_license_plate_listComponent } from './LocusRobotics.jobs_by_license_plate_list.component';
import { LocusRobotics_outbound_labelsComponent } from './LocusRobotics.outbound_labels.component';
import { LocusRobotics_bot_type_dd_singleComponent } from './LocusRobotics.bot_type_dd_single.component';
import { LocusRobotics_measurement_units_dd_singleComponent } from './LocusRobotics.measurement_units_dd_single.component';
import { LocusRobotics_owners_dd_singleComponent } from './LocusRobotics.owners_dd_single.component';
import { LocusRobotics_projects_dd_singleComponent } from './LocusRobotics.projects_dd_single.component';
import { LocusRobotics_warehouses_dd_singleComponent } from './LocusRobotics.warehouses_dd_single.component';
import { LocusRobotics_bot_type_dd_multiComponent } from './LocusRobotics.bot_type_dd_multi.component';
import { LocusRobotics_measurement_units_dd_multiComponent } from './LocusRobotics.measurement_units_dd_multi.component';
import { LocusRobotics_owners_dd_multiComponent } from './LocusRobotics.owners_dd_multi.component';
import { LocusRobotics_projects_dd_multiComponent } from './LocusRobotics.projects_dd_multi.component';
import { LocusRobotics_warehouses_dd_multiComponent } from './LocusRobotics.warehouses_dd_multi.component';
import { LocusRobotics_open_jobs_widgetComponent } from './LocusRobotics.open_jobs_widget.component';
import { LocusRobotics_open_ordersComponent } from './LocusRobotics.open_orders.component';
import { LocusRobotics_jobs_by_license_plateComponent } from './LocusRobotics.jobs_by_license_plate.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';
import { InventoryCounts_ShellService } from './InventoryCounts.shell.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
    public PrintNode: PrintNode_ShellService,
    public InventoryCounts: InventoryCounts_ShellService,
  ) {
    super(dialog, toastr);
  }

  public LocusRobotics: LocusRobotics_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openbot_configuration_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Cart configuration',
        referenceName: 'LocusRobotics_bot_configuration_grid',
        component: LocusRobotics_bot_configuration_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbot_configuration_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_bot_configuration_gridComponent,
      'Cart configuration',
      mode,
      dialogSize
    )
  }
  public openjobs_by_bot(inParams:{ bot?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Jobs by bot',
        referenceName: 'LocusRobotics_jobs_by_bot',
        component: LocusRobotics_jobs_by_botComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openjobs_by_botDialog(
    inParams:{ bot?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_by_botComponent,
      'Jobs by bot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openjobs_by_license_plate_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Jobs by licenseplate',
        referenceName: 'LocusRobotics_jobs_by_license_plate_grid',
        component: LocusRobotics_jobs_by_license_plate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openjobs_by_license_plate_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_by_license_plate_gridComponent,
      'Jobs by licenseplate',
      mode,
      dialogSize
    )
  }
  public openjobs_by_station_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Jobs by station',
        referenceName: 'LocusRobotics_jobs_by_station_grid',
        component: LocusRobotics_jobs_by_station_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openjobs_by_station_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_by_station_gridComponent,
      'Jobs by station',
      mode,
      dialogSize
    )
  }
  public openjobs_detail_by_order_grid(inParams:{ orderId?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Job details by order grid',
        referenceName: 'LocusRobotics_jobs_detail_by_order_grid',
        component: LocusRobotics_jobs_detail_by_order_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openjobs_detail_by_order_gridDialog(
    inParams:{ orderId?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_detail_by_order_gridComponent,
      'Job details by order grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openjobs_detail_by_station_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Jobs detail by station ',
        referenceName: 'LocusRobotics_jobs_detail_by_station_grid',
        component: LocusRobotics_jobs_detail_by_station_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openjobs_detail_by_station_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_detail_by_station_gridComponent,
      'Jobs detail by station ',
      mode,
      dialogSize
    )
  }
  public openjobs_header_grid(inParams:{ projectIds?: number[], warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Job header grid',
        referenceName: 'LocusRobotics_jobs_header_grid',
        component: LocusRobotics_jobs_header_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openjobs_header_gridDialog(
    inParams:{ projectIds?: number[], warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_header_gridComponent,
      'Job header grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openconfiguration_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Configuration hub',
        referenceName: 'LocusRobotics_configuration_hub',
        component: LocusRobotics_configuration_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openconfiguration_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_configuration_hubComponent,
      'Configuration hub',
      mode,
      dialogSize
    )
  }
  public openjobs_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Jobs hub',
        referenceName: 'LocusRobotics_jobs_hub',
        component: LocusRobotics_jobs_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openjobs_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_hubComponent,
      'Jobs hub',
      mode,
      dialogSize
    )
  }
  public opensingle_bot_hub(inParams:{ bot: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single bot hub',
        referenceName: 'LocusRobotics_single_bot_hub',
        component: LocusRobotics_single_bot_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_bot_hubDialog(
    inParams:{ bot: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_single_bot_hubComponent,
      'Single bot hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_job_hub(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single job hub',
        referenceName: 'LocusRobotics_single_job_hub',
        component: LocusRobotics_single_job_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_job_hubDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_single_job_hubComponent,
      'Single job hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_tote_hub(inParams:{ tote?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'single_tote_hub',
        referenceName: 'LocusRobotics_single_tote_hub',
        component: LocusRobotics_single_tote_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_tote_hubDialog(
    inParams:{ tote?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_single_tote_hubComponent,
      'single_tote_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openjobs_by_license_plate_list(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Jobs by licenseplate',
        referenceName: 'LocusRobotics_jobs_by_license_plate_list',
        component: LocusRobotics_jobs_by_license_plate_listComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openjobs_by_license_plate_listDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_jobs_by_license_plate_listComponent,
      'Jobs by licenseplate',
      mode,
      dialogSize
    )
  }
  public openoutbound_labels(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'outbound_labels',
        referenceName: 'LocusRobotics_outbound_labels',
        component: LocusRobotics_outbound_labelsComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_labelsDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      LocusRobotics_outbound_labelsComponent,
      'outbound_labels',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'LocusRobotics_bot_configuration_grid') {
      const title = 'Cart configuration';
      const component = LocusRobotics_bot_configuration_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_by_bot') {
      const title = 'Jobs by bot';
      const component = LocusRobotics_jobs_by_botComponent;
      const inParams:{ bot?: string } = { bot: null };
      if (!isNil(params.get('bot'))) {
        const paramValueString = params.get('bot');
        // TODO: date
        inParams.bot = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_by_license_plate_grid') {
      const title = 'Jobs by licenseplate';
      const component = LocusRobotics_jobs_by_license_plate_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_by_station_grid') {
      const title = 'Jobs by station';
      const component = LocusRobotics_jobs_by_station_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_detail_by_order_grid') {
      const title = 'Job details by order grid';
      const component = LocusRobotics_jobs_detail_by_order_gridComponent;
      const inParams:{ orderId?: string } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        // TODO: date
        inParams.orderId = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_detail_by_station_grid') {
      const title = 'Jobs detail by station ';
      const component = LocusRobotics_jobs_detail_by_station_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_header_grid') {
      const title = 'Job header grid';
      const component = LocusRobotics_jobs_header_gridComponent;
      const inParams:{ projectIds?: number[], warehouseId?: number } = { projectIds: [], warehouseId: null };
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_configuration_hub') {
      const title = 'Configuration hub';
      const component = LocusRobotics_configuration_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_hub') {
      const title = 'Jobs hub';
      const component = LocusRobotics_jobs_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_single_bot_hub') {
      const title = 'Single bot hub';
      const component = LocusRobotics_single_bot_hubComponent;
      const inParams:{ bot: string } = { bot: null };
      if (!isNil(params.get('bot'))) {
        const paramValueString = params.get('bot');
        // TODO: date
        inParams.bot = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_single_job_hub') {
      const title = 'Single job hub';
      const component = LocusRobotics_single_job_hubComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_single_tote_hub') {
      const title = 'single_tote_hub';
      const component = LocusRobotics_single_tote_hubComponent;
      const inParams:{ tote?: string } = { tote: null };
      if (!isNil(params.get('tote'))) {
        const paramValueString = params.get('tote');
        // TODO: date
        inParams.tote = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_by_license_plate_list') {
      const title = 'Jobs by licenseplate';
      const component = LocusRobotics_jobs_by_license_plate_listComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_outbound_labels') {
      const title = 'outbound_labels';
      const component = LocusRobotics_outbound_labelsComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_bot_type_dd_single') {
      const title = 'bot_type_dd';
      const component = LocusRobotics_bot_type_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_measurement_units_dd_single') {
      const title = 'Measurement Units Dropdown';
      const component = LocusRobotics_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_owners_dd_single') {
      const title = 'Owners dropdown';
      const component = LocusRobotics_owners_dd_singleComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = LocusRobotics_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number, excludedProjectIds?: number[] } = { statusId: null, ownerId: null, excludedProjectIds: [] };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedProjectIds'))) {
        const paramValueString = params.get('excludedProjectIds');
        inParams.excludedProjectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_warehouses_dd_single') {
      const title = 'Warehouses Dropdown';
      const component = LocusRobotics_warehouses_dd_singleComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_bot_type_dd_multi') {
      const title = 'bot_type_dd';
      const component = LocusRobotics_bot_type_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_measurement_units_dd_multi') {
      const title = 'Measurement Units Dropdown';
      const component = LocusRobotics_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_owners_dd_multi') {
      const title = 'Owners dropdown';
      const component = LocusRobotics_owners_dd_multiComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = LocusRobotics_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number, excludedProjectIds?: number[] } = { statusId: null, ownerId: null, excludedProjectIds: [] };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedProjectIds'))) {
        const paramValueString = params.get('excludedProjectIds');
        inParams.excludedProjectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_warehouses_dd_multi') {
      const title = 'Warehouses Dropdown';
      const component = LocusRobotics_warehouses_dd_multiComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_open_jobs_widget') {
      const title = 'Open jobs';
      const component = LocusRobotics_open_jobs_widgetComponent;
      const inParams:{ statuses?: string, projects?: number[], warehouse?: number } = { statuses: null, projects: [], warehouse: null };
      if (!isNil(params.get('statuses'))) {
        const paramValueString = params.get('statuses');
        // TODO: date
        inParams.statuses = paramValueString;
              }
      if (!isNil(params.get('projects'))) {
        const paramValueString = params.get('projects');
        inParams.projects = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouse'))) {
        const paramValueString = params.get('warehouse');
        inParams.warehouse = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_open_orders') {
      const title = 'Open orders';
      const component = LocusRobotics_open_ordersComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_jobs_by_license_plate') {
      const title = 'Jobs by license plate';
      const component = LocusRobotics_jobs_by_license_plateComponent;
      const inParams:{ orderId?: number, licensePlateId?: string, bot?: string, jobId?: string } = { orderId: null, licensePlateId: null, bot: null, jobId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        // TODO: date
        inParams.licensePlateId = paramValueString;
              }
      if (!isNil(params.get('bot'))) {
        const paramValueString = params.get('bot');
        // TODO: date
        inParams.bot = paramValueString;
              }
      if (!isNil(params.get('jobId'))) {
        const paramValueString = params.get('jobId');
        // TODO: date
        inParams.jobId = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.InventoryCounts.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
