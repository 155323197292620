import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Addresses_OperationService } from './Addresses.operation.service';
import { AsnOrders_OperationService } from './AsnOrders.operation.service';
import { Carriers_OperationService } from './Carriers.operation.service';
import { Cartonization_OperationService } from './Cartonization.operation.service';
import { Locations_OperationService } from './Locations.operation.service';
import { Lots_OperationService } from './Lots.operation.service';
import { LoadContainers_OperationService } from './LoadContainers.operation.service';
import { LaborManagement_OperationService } from './LaborManagement.operation.service';
import { Owners_OperationService } from './Owners.operation.service';
import { ShippingContainers_OperationService } from './ShippingContainers.operation.service';
import { TemperatureReadings_OperationService } from './TemperatureReadings.operation.service';
import { WarehouseTransfers_OperationService } from './WarehouseTransfers.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Replenishments_OperationService } from './Replenishments.operation.service';
import { SerialNumbers_OperationService } from './SerialNumbers.operation.service';
import { ExcelLocationImport_OperationService } from './ExcelLocationImport.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { WorkOrders_OperationService } from './WorkOrders.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { PackVerification_OperationService } from './PackVerification.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { Reports_OperationService } from './Reports.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Addresses = this.injector.get(Addresses_OperationService);
    this.AsnOrders = this.injector.get(AsnOrders_OperationService);
    this.Carriers = this.injector.get(Carriers_OperationService);
    this.Cartonization = this.injector.get(Cartonization_OperationService);
    this.Locations = this.injector.get(Locations_OperationService);
    this.Lots = this.injector.get(Lots_OperationService);
    this.LoadContainers = this.injector.get(LoadContainers_OperationService);
    this.LaborManagement = this.injector.get(LaborManagement_OperationService);
    this.Owners = this.injector.get(Owners_OperationService);
    this.ShippingContainers = this.injector.get(ShippingContainers_OperationService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_OperationService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Replenishments = this.injector.get(Replenishments_OperationService);
    this.SerialNumbers = this.injector.get(SerialNumbers_OperationService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.DockAppointments = this.injector.get(DockAppointments_OperationService);
    this.WorkOrders = this.injector.get(WorkOrders_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.InventoryCounts = this.injector.get(InventoryCounts_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.PackVerification = this.injector.get(PackVerification_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.FootPrintManager = this.injector.get(FootPrintManager_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
    this.LocusRobotics = this.injector.get(LocusRobotics_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
  }

    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public Utilities: Utilities_OperationService;
    public Addresses: Addresses_OperationService;
    public AsnOrders: AsnOrders_OperationService;
    public Carriers: Carriers_OperationService;
    public Cartonization: Cartonization_OperationService;
    public Locations: Locations_OperationService;
    public Lots: Lots_OperationService;
    public LoadContainers: LoadContainers_OperationService;
    public LaborManagement: LaborManagement_OperationService;
    public Owners: Owners_OperationService;
    public ShippingContainers: ShippingContainers_OperationService;
    public TemperatureReadings: TemperatureReadings_OperationService;
    public WarehouseTransfers: WarehouseTransfers_OperationService;
    public Notifications: Notifications_OperationService;
    public Replenishments: Replenishments_OperationService;
    public SerialNumbers: SerialNumbers_OperationService;
    public ExcelLocationImport: ExcelLocationImport_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public DockAppointments: DockAppointments_OperationService;
    public WorkOrders: WorkOrders_OperationService;
    public Materials: Materials_OperationService;
    public InventoryCounts: InventoryCounts_OperationService;
    public Inventory: Inventory_OperationService;
    public PackVerification: PackVerification_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public FootPrintManager: FootPrintManager_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
    public LocusRobotics: LocusRobotics_OperationService;
    public Reports: Reports_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
