<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="build_pallet" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="split_button" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-idle_jobs_flag" *ngIf="!filters.idle_jobs_flag.hidden" 
            class="field-container standard {{filters.idle_jobs_flag.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.idle_jobs_flag.styles.style"
            [ngClass]="filters.idle_jobs_flag.styles.classes">
        <div class="label-container"
              title="{{filters.idle_jobs_flag.label}}{{filters.idle_jobs_flag.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.idle_jobs_flag.label}}">{{filters.idle_jobs_flag.label}}<span *ngIf="filters.idle_jobs_flag.required"
                  class="required-asterisk">*</span></label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="idle_jobs_flag"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.idle_jobs_flag.control.styles.style"
                      [ngClass]="filters.idle_jobs_flag.control.styles.classes">{{filters.idle_jobs_flag.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.idle_jobs_flag.invalid">
          <ng-container *ngFor="let error of filters.idle_jobs_flag.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-template listItemDef
               let-item>
    <LocusRobotics-jobs_by_license_plate
    [orderId]="item.$content_LocusRobotics_jobs_by_license_plate_inParams_orderId"
    [jobId]="item.$content_LocusRobotics_jobs_by_license_plate_inParams_jobId"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </LocusRobotics-jobs_by_license_plate>
  </ng-template>

</datex-list>