import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_inventory_transfer_lines_gridComponent } from './FootPrintManager.inventory_transfer_lines_grid.component';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_inventory_transfer_lines_gridComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_transfer_editor',
  templateUrl: './FootPrintManager.inventory_transfer_editor.component.html'
})
export class FootPrintManager_inventory_transfer_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { inventoryTransferId: number, warehouseId?: number } = { inventoryTransferId: null, warehouseId: null };
  //#region Inputs
  @Input('inventoryTransferId') set $inParams_inventoryTransferId(v: number) {
    this.inParams.inventoryTransferId = v;
  }
  get $inParams_inventoryTransferId(): number {
    return this.inParams.inventoryTransferId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, Status?: { Id?: number, Name?: string }, SourceProject?: { Id?: number, OwnerId?: number }, TargetProject?: { Id?: number, OwnerId?: number } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    source_owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    source_project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    target_owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    target_project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['destructive'], null), false, 'Cancel', 'ms-Icon ms-Icon--Cancel')
    ),
      delete_transfer: new ToolModel(new ButtonModel('delete_transfer', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Code', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    status: new FieldModel(new TextBoxModel(this.formGroup.controls['status'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    source_owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Source owner', false)
,
    source_project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Source project', true)
,
    target_owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Target owner', false)
,
    target_project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Target project', true)
,
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
  source: new FieldsetModel('Source', true, false, true),
  target: new FieldsetModel('Target', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      lines: new TabItemModel(
        this.rootTabGroup, 
        'Transfer lines', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_lines_inventory_transfer_lines_grid_inParams_inventoryTransferId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_lines_inventory_transfer_lines_grid_inParams_sourceProjectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.source_project.control.value;
      
      return expr;
    }
  
    get $tabs_lines_inventory_transfer_lines_grid_inParams_targetProjectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.target_project.control.value;
      
      return expr;
    }
  
    get $tabs_lines_inventory_transfer_lines_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.warehouse.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_lines', { read: FootPrintManager_inventory_transfer_lines_gridComponent }) $tabs_lines: FootPrintManager_inventory_transfer_lines_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_source_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_source_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.source_project.control.value;
    
    return expr;
  }

  get $fields_source_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_source_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.source_owner.control.value;
    
    return expr;
  }

  cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds: number[];
  get $fields_source_project_selector_inParams_excludedProjectIds(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $utils.isDefined($editor.fields.target_project.control.value) ? [$editor.fields.target_project.control.value] : null;
    
    if(!isEqual(this.cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds, expr)) {
      this.cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds = expr;
    }
    return this.cacheValueFor_$fields_source_project_selector_inParams_excludedProjectIds;
  }

  get $fields_target_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_target_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.target_project.control.value;
    
    return expr;
  }

  get $fields_target_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_target_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.target_owner.control.value;
    
    return expr;
  }

  cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds: number[];
  get $fields_target_project_selector_inParams_excludedProjectIds(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $utils.isDefined($editor.fields.source_project.control.value) ? [$editor.fields.source_project.control.value] : null;
    
    if(!isEqual(this.cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds, expr)) {
      this.cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds = expr;
    }
    return this.cacheValueFor_$fields_target_project_selector_inParams_excludedProjectIds;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    source_owner: this.fields.source_owner.control.valueChanges
    ,
    source_project: this.fields.source_project.control.valueChanges
    ,
    target_owner: this.fields.target_owner.control.valueChanges
    ,
    target_project: this.fields.target_project.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventoryTransferId)) {
        this.$missingRequiredInParams.push('inventoryTransferId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Inventory transfer';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      inventoryTransferId:  $editor.inParams.inventoryTransferId 
    };

    const data = await this.datasources.InventoryTransfers.ds_inventory_transfer_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.inParams.warehouseId);
    (this.fields.status.control as TextBoxModel).reset($editor.entity.Status.Name);
    (this.fields.source_project.control as SelectBoxModel).reset($editor.entity.SourceProject.Id);
    (this.fields.target_project.control as SelectBoxModel).reset($editor.entity.TargetProject.Id);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_lines') {
        if (!isNil(this.$tabs_lines) && !this.tabs.lines.hidden) {
          this.$tabs_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .source_owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .source_project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_project_changed();
      });
    this.$formGroupFieldValidationObservables
      .target_owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .target_project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_project_changed();
      });
  }

  //#region private flows
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.process.control.readOnly = true;
  $editor.toolbar.process.control.icon = 'icon ms-Icon ms-Icon--ProgressRingDots'
  
  try {
      let result = (await $flows.InventoryTransfers.execute_inventory_transfer_flow({ inventoryTransferId: $editor.entity.Id }));
  
      if ($utils.isDefined(result?.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error processing inventory transfer', error.message);
  }
  
  
  await $editor.refresh();
  $editor.toolbar.process.control.styles.reset();
  
  
  
  
  async function monitorEvent(myRow: any) {
      let checkIntervalSeconds = 10;
      let checkMaxDurationSeconds = 300;
      let index = 0;
      let isFinished = false;
  
      while (index * checkIntervalSeconds < checkMaxDurationSeconds && !isFinished) {
          await new Promise(resolve => setTimeout(resolve, checkIntervalSeconds * 1000));
  
          let eventDetails = (await $datasources.WorkOrders.ds_get_confirmed_accessorial_task_event_details.get({ taskId: myRow.entity.Id })).result;
  
          if ($utils.isDefined(eventDetails)) {
              // Is not in a 'Published' or 'Processing' status
              if (eventDetails.FootPrintEvent.StatusId !== 1 && eventDetails.FootPrintEvent.StatusId !== 5) {
                  isFinished = true;
                  //await $grid.rows.find(r => r.entity.Id === myRow.entity.Id)?.refresh();
              }
          }
  
          index++;
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.process.hidden = true;
  $editor.toolbar.separator1.hidden = true;
  $editor.toolbar.cancel.hidden = true;
  $editor.toolbar.delete_transfer.hidden = true;
  
  const transfer_state = (await $flows.InventoryTransfers.get_inventory_transfers_states({ inventoryTransferIds: [$editor.entity.Id]})).inventoryTransfers[0];
  
  let lines = (await $datasources.InventoryTransfers.ds_inventory_transfer_lines_grid.get({ inventoryTransferId: $editor.inParams.inventoryTransferId })).result;
  if ($utils.isDefined(lines)) {
      $editor.fields.warehouse.control.value = lines[0]?.Warehouse?.Id;
      $editor.fields.warehouse.control.readOnly = true;
      
      $editor.fields.source_owner.control.readOnly = true;
      $editor.fields.source_project.control.readOnly = true;
      $editor.fields.target_owner.control.readOnly = true;
      $editor.fields.target_project.control.readOnly = true;
  } else {
      $editor.fields.warehouse.control.value = $editor.inParams.warehouseId;
  }
  
  // Unhide toolbar based on allowable actions
  $editor.toolbar.process.hidden = !lines.some(l => [1,2,3,4].includes(l.Status.Id));
  $editor.toolbar.cancel.hidden = $utils.isDefined(transfer_state.failActions.cancelReasons);
  $editor.toolbar.delete_transfer.hidden = $utils.isDefined(transfer_state.failActions.deleteReasons);
  
  if (!$editor.toolbar.process.hidden && (!$editor.toolbar.cancel.hidden || !$editor.toolbar.delete_transfer.hidden)) {
      $editor.toolbar.separator1.hidden = false;
  }
  
  await $editor.on_source_project_changed();
  await $editor.on_target_project_changed();
  }
  on_source_owner_changed(event = null) {
    return this.on_source_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_owner_changedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.source_project.control.value = null;
  }
  on_target_owner_changed(event = null) {
    return this.on_target_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_owner_changedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.target_project.control.value = null;
  }
  on_source_project_changed(event = null) {
    return this.on_source_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_project_changedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($editor.fields.source_owner.control.value) && $utils.isDefined($editor.fields.source_project.control.value))  {
      let project = (await $datasources.Owners.ds_get_project_by_projectId.getList({ $top: 1, projectId: $editor.fields.source_project.control.value})).result[0];
  
      $editor.fields.source_owner.control.value = project.OwnerId;
  }
  }
  on_target_project_changed(event = null) {
    return this.on_target_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_project_changedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($editor.fields.target_owner.control.value) && $utils.isDefined($editor.fields.target_project.control.value))  {
      let project = (await $datasources.Owners.ds_get_project_by_projectId.getList({ $top: 1, projectId: $editor.fields.target_project.control.value})).result[0];
  
      $editor.fields.target_owner.control.value = project.OwnerId;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const isConfirmed = (await $shell.FootPrintManager.openConfirmationDialog('Cancel inventory transfer?', null, 'Cancel transfer', `Don't cancel`)) ?? false;
  
  if (!isConfirmed) { return; }
  
  let result = (await $flows.InventoryTransfers.cancel_inventory_transfers_flow({ inventory_transfer_ids: [$editor.entity.Id]})).results[0];
  
  if (result.is_success) {
      $shell.FootPrintManager.openToaster('Inventory transfer has been cancelled', null,EToasterType.Success, { timeOut: 5000, progressBar: true, positionClass: EToasterPosition.topRight });
  } else {
      $shell.FootPrintManager.openToaster('Could not cancel inventory transfer', (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue,EToasterType.Error, { disableTimeOut: true, tapToDismiss: true, positionClass: EToasterPosition.topRight });
  }
  
  $editor.refresh();
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_inventory_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const isConfirmed = (await $shell.FootPrintManager.openConfirmationDialog('Delete inventory transfer?', null, 'Delete transfer', `Don't delete`)) ?? false;
  
  if (!isConfirmed) { return; }
  
  let result = (await $flows.InventoryTransfers.delete_inventory_transfers_flow({ inventory_transfer_ids: [$editor.entity.Id]})).results[0];
  
  if (result.is_success) {
      $shell.FootPrintManager.openToaster('Inventory transfer has been deleted', null, EToasterType.Success, { timeOut: 5000, progressBar: true, positionClass: EToasterPosition.topRight });
  } else {
      $shell.FootPrintManager.openToaster('Could not delete inventory transfer', (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue, EToasterType.Error, { disableTimeOut: true, tapToDismiss: true, positionClass: EToasterPosition.topRight });
  }
  
  $editor.close();
  }
  //#endregion private flows
}
