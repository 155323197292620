import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


import { app_custom_lot_statuses_dd_singleComponent } from './app.custom_lot_statuses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => app_custom_lot_statuses_dd_singleComponent),
  ],
  selector: 'app-custom_lot_editor',
  templateUrl: './app.custom_lot_editor.component.html'
})
export class app_custom_lot_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { lotId: number } = { lotId: null };
  //#region Inputs
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, Description?: string, LookupCode?: string, MaterialId?: number, Notes?: string, StatusId?: number, VendorLot?: { Id?: number, Description?: string, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Name?: string }, Material?: { ProjectId?: number } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendorlot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendorlot_vat: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expiration_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lookupcode', false)
,
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Description', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    vendorlot: new FieldModel(new TextBoxModel(this.formGroup.controls['vendorlot'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vendor Lot', false)
,
    vendorlot_vat: new FieldModel(new TextBoxModel(this.formGroup.controls['vendorlot_vat'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Mass per UOM', false)
,
    expiration_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expiration_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Expiration Date', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};


  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    vendorlot: this.fields.vendorlot.control.valueChanges
    ,
    vendorlot_vat: this.fields.vendorlot_vat.control.valueChanges
    ,
    expiration_date: this.fields.expiration_date.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.lotId)) {
        this.$missingRequiredInParams.push('lotId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Lot';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      lotId:  $editor.inParams.lotId 
    };

    const data = await this.datasources.Lots.ds_lot_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.StatusId);
    (this.fields.description.control as TextBoxModel).reset($editor.entity.Description);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.vendorlot.control as TextBoxModel).reset($editor.entity.VendorLot.LookupCode);
    (this.fields.vendorlot_vat.control as TextBoxModel).reset($editor.entity.VendorLot?.Vat);
    (this.fields.expiration_date.control as DateBoxModel).reset($editor.entity.VendorLot.ExpirationDate);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vendorlot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vendorlot_vat
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expiration_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_lot_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: app_custom_lot_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.confirm.control.readOnly = true;
  let payload: any = {};
  
  try {
      /*** VALIDATIONS ***/
      if ($utils.isDefined($editor.fields.vendorlot_vat.control.value)) {
          if (isNaN($editor.fields.vendorlot_vat.control.value)) {
              throw new Error("'Mass per UOM' must be a numeric value.");
          }
          if ($editor.fields.vendorlot_vat.control.value <= 0) {
              throw new Error("'Mass per UOM' must be greater than zero.");
          }
      }
  
      // Lot Changes
      if ($editor.fields.lookupcode.control.isChanged) {
  
          if (!$utils.isDefinedTrimmed($editor.fields.lookupcode.control.value)) {
              $editor.toolbar.confirm.control.readOnly = false;
              $shell.Lots.openErrorDialog('Lot Edit Error', 'Please enter in a valid value for the lot lookup code!');
              return;
          }
  
          const lot = $editor.fields.lookupcode.control.value;
          const vendorlot = $editor.fields.vendorlot.control.value;
          // Check if lot exists
          const Lot = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
              materialId: $editor.entity.MaterialId,
              vendorLookupcode: $editor.fields.vendorlot.control.isChanged ? vendorlot : $editor.entity.VendorLot.LookupCode,
              lotLookupcode: lot
          })).result;
          if ($utils.isDefined(Lot)) {
              $editor.toolbar.confirm.control.readOnly = false;
              $shell.Lots.openErrorDialog('Lot Edit Error', 'Lot already exists for selected material!');
              return;
  
          } else {
              payload.LookupCode = $editor.fields.lookupcode.control.value;
          }
      }
  
      if ($editor.fields.description.control.isChanged) {
          payload.Description = $editor.fields.description.control.value;
      }
  
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
  
      if ($editor.fields.status.control.isChanged) {
          payload.StatusId = $editor.fields.status.control.value;
  
          const currentStatus = (await $datasources.Lots.ds_get_status_by_statusId.get({ statusId: $editor.entity.StatusId })).result;
          var currentStatusName = currentStatus.Name;
  
          const updatedStatus = (await $datasources.Lots.ds_get_status_by_statusId.get({ statusId: $editor.fields.status.control.value })).result;
          var updatedStatusName = updatedStatus.Name;
      }
  
      // Vendor Lot Changes
      let vendorlotPayload: any = {};
  
      if ($editor.fields.vendorlot_vat.control.isChanged) {
          vendorlotPayload.Vat = $editor.fields.vendorlot_vat.control.value;
      }
  
      if ($editor.fields.vendorlot.control.isChanged) {
  
          const vendorlot = $editor.fields.vendorlot.control.value;
          // Check if vendor lot exists
          const vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
              materialId: $editor.entity.MaterialId,
              vendorLookupcode: vendorlot
          })).result;
          if ($utils.isDefined(vendorLot)) {
              $editor.toolbar.confirm.control.readOnly = false;
              $shell.Lots.openErrorDialog('Lot Edit Error', 'Vendor Lot already exists for selected material!');
              return;
  
          } else {
              vendorlotPayload.LookupCode = vendorlot;
          }
      }
  
      if ($editor.fields.expiration_date.control.isChanged) {
  
          vendorlotPayload.ExpirationDate = $editor.fields.expiration_date.control.value;
      }
  
  
      try {
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'Lots', id: $editor.entity.Id, entity: payload });
          await $flows.Utilities.crud_update_flow({ entitySet: 'VendorLots', id: $editor.entity.VendorLot.Id, entity: vendorlotPayload });
  
          const format = `MM/DD/YYYY, LT`;
  
          const statusChange = `Status changed from [${currentStatusName}] to [${updatedStatusName}] on [${$utils.date.format($utils.date.now(), format)}]`
  
          if ($editor.fields.status.control.isChanged) {
  
              const nextTaskId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
              const employee = (await $flows.Utilities.get_username_flow({})).userName;
  
  
              const taskPayload = {
                  "Id": nextTaskId,
                  "ChainHead": 0,
                  "StatusId": 2,
                  "WarehouseId": 1,
                  "OperationCodeId": 35,
                  "ProjectId": $editor.entity.Material.ProjectId,
                  "MaterialId": $editor.entity.MaterialId,
                  "LotId": $editor.entity.Id,
                  "VendorLotId": $editor.entity.VendorLot.Id,
                  "Employee": employee,
                  "CreatedSysDateTime": $utils.date.now(),
                  "ModifiedSysDateTime": $utils.date.now(),
                  "Notes": statusChange
              };
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'Tasks', entity: taskPayload });
  
          }
  
          $editor.outParams.confirm = true;
          $editor.close();
  
      }
  
      catch (error) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Lots.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
  
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.openErrorDialog('Error editing lot', targetError.message);
  }
  
  $editor.toolbar.confirm.control.readOnly = false;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_lot_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  
  let lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $editor.inParams.lotId })).result[0];
  let materialUdfs = (await $datasources.custom_ds_materials_udf_grid.get({ materialIds: [lot.MaterialId] })).result[0];
  
  // MassRequired
  if (!$utils.isDefined(materialUdfs?.MassRequired) || !materialUdfs.MassRequired) {
      $editor.fields.vendorlot_vat.hidden = true;
  }
  }
  //#endregion private flows
}
