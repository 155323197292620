import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { PurchaseOrders_cancel_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_cancel_putaway_tasks_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_complete_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_copy_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_damage_receiving_task_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_purchase_order_line_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_purchase_order_line_in_processing_status_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_purchase_order_line_in_wait_status_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_reasoncode_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_create_shipment_for_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_delete_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_delete_purchase_order_line_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_excel_export_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_format_order_lookupcode_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_format_order_lookupcode_flow_multipleService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_get_purchase_order_stateService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_cancelable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_completable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_deletable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_line_deletable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_processable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_revertable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_is_purchase_order_unreceivable_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_process_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_receive_on_existing_licenseplate_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_receive_on_new_licenseplate_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_revert_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_unreceive_content_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_unreceive_purchase_order_flowService } from './PurchaseOrders.flow.index';
import { PurchaseOrders_validate_order_flowService } from './PurchaseOrders.flow.index';

import { $frontendTypes } from './PurchaseOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public PurchaseOrders: PurchaseOrders_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _cancel_purchase_order_flow: PurchaseOrders_cancel_purchase_order_flowService;
  public async cancel_purchase_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_purchase_order_flow) {
      this._cancel_purchase_order_flow = this.injector.get(PurchaseOrders_cancel_purchase_order_flowService);
    }
    return this._cancel_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _cancel_putaway_tasks_flow: PurchaseOrders_cancel_putaway_tasks_flowService;
  public async cancel_putaway_tasks_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_putaway_tasks_flow) {
      this._cancel_putaway_tasks_flow = this.injector.get(PurchaseOrders_cancel_putaway_tasks_flowService);
    }
    return this._cancel_putaway_tasks_flow.run(inParams);
  }
   
   

   
 
  private _complete_purchase_order_flow: PurchaseOrders_complete_purchase_order_flowService;
  public async complete_purchase_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_purchase_order_flow) {
      this._complete_purchase_order_flow = this.injector.get(PurchaseOrders_complete_purchase_order_flowService);
    }
    return this._complete_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _copy_purchase_order_flow: PurchaseOrders_copy_purchase_order_flowService;
  public async copy_purchase_order_flow(inParams: { orderId: number, copyAddresses: boolean }): Promise< { newOrderId?: number, reason?: string, newShipmentId?: number }> {
    if(!this._copy_purchase_order_flow) {
      this._copy_purchase_order_flow = this.injector.get(PurchaseOrders_copy_purchase_order_flowService);
    }
    return this._copy_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _create_damage_receiving_task_flow: PurchaseOrders_create_damage_receiving_task_flowService;
  public async create_damage_receiving_task_flow(inParams: { orderId: number, linenumber: number, shipmentId: number, packagingId: number, packagedAmount: number }): Promise< { reason?: string }> {
    if(!this._create_damage_receiving_task_flow) {
      this._create_damage_receiving_task_flow = this.injector.get(PurchaseOrders_create_damage_receiving_task_flowService);
    }
    return this._create_damage_receiving_task_flow.run(inParams);
  }
   
   

   
 
  private _create_purchase_order_flow: PurchaseOrders_create_purchase_order_flowService;
  public async create_purchase_order_flow(inParams: { projectId: number, warehouseId: number, lookupcode?: string, orderClassId: number, ownerReference?: string, waitStatus?: boolean }): Promise< { reason?: string, orderId?: number, shipmentId?: number }> {
    if(!this._create_purchase_order_flow) {
      this._create_purchase_order_flow = this.injector.get(PurchaseOrders_create_purchase_order_flowService);
    }
    return this._create_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _create_purchase_order_line_flow: PurchaseOrders_create_purchase_order_line_flowService;
  public async create_purchase_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_purchase_order_line_flow) {
      this._create_purchase_order_line_flow = this.injector.get(PurchaseOrders_create_purchase_order_line_flowService);
    }
    return this._create_purchase_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_purchase_order_line_in_processing_status_flow: PurchaseOrders_create_purchase_order_line_in_processing_status_flowService;
  public async create_purchase_order_line_in_processing_status_flow(inParams: { projectId: number, orderId: number, shipmentId: number, materialId: number, packagingId: number, packagedAmount: number, lotId?: number, vendorLotId?: number, grossWeight?: number, weightUom?: number, notes?: string, netWeight?: number }): Promise< { lineNumber?: number, reason?: string }> {
    if(!this._create_purchase_order_line_in_processing_status_flow) {
      this._create_purchase_order_line_in_processing_status_flow = this.injector.get(PurchaseOrders_create_purchase_order_line_in_processing_status_flowService);
    }
    return this._create_purchase_order_line_in_processing_status_flow.run(inParams);
  }
   
   

   
 
  private _create_purchase_order_line_in_wait_status_flow: PurchaseOrders_create_purchase_order_line_in_wait_status_flowService;
  public async create_purchase_order_line_in_wait_status_flow(inParams: { projectId: number, orderId: number, shipmentId: number, materialId: number, packagingId: number, packagedAmount: number, lotId?: number, vendorLotId?: number, notes?: string }): Promise< { lineNumber?: number, reason?: string }> {
    if(!this._create_purchase_order_line_in_wait_status_flow) {
      this._create_purchase_order_line_in_wait_status_flow = this.injector.get(PurchaseOrders_create_purchase_order_line_in_wait_status_flowService);
    }
    return this._create_purchase_order_line_in_wait_status_flow.run(inParams);
  }
   
   

   
 
  private _create_reasoncode_flow: PurchaseOrders_create_reasoncode_flowService;
  public async create_reasoncode_flow(inParams: { properties?: any }): Promise< { reasons?: string[], reasonCodeId?: number }> {
    if(!this._create_reasoncode_flow) {
      this._create_reasoncode_flow = this.injector.get(PurchaseOrders_create_reasoncode_flowService);
    }
    return this._create_reasoncode_flow.run(inParams);
  }
   
   

   
 
  private _create_shipment_for_purchase_order_flow: PurchaseOrders_create_shipment_for_purchase_order_flowService;
  public async create_shipment_for_purchase_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._create_shipment_for_purchase_order_flow) {
      this._create_shipment_for_purchase_order_flow = this.injector.get(PurchaseOrders_create_shipment_for_purchase_order_flowService);
    }
    return this._create_shipment_for_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_purchase_order_flow: PurchaseOrders_delete_purchase_order_flowService;
  public async delete_purchase_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._delete_purchase_order_flow) {
      this._delete_purchase_order_flow = this.injector.get(PurchaseOrders_delete_purchase_order_flowService);
    }
    return this._delete_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_purchase_order_line_flow: PurchaseOrders_delete_purchase_order_line_flowService;
  public async delete_purchase_order_line_flow(inParams: { orderId: number, lineNumber: number }): Promise< { reason?: string }> {
    if(!this._delete_purchase_order_line_flow) {
      this._delete_purchase_order_line_flow = this.injector.get(PurchaseOrders_delete_purchase_order_line_flowService);
    }
    return this._delete_purchase_order_line_flow.run(inParams);
  }
   
   

   
 
  private _excel_export_flow: PurchaseOrders_excel_export_flowService;
  public async excel_export_flow(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string, carrierId?: number, carrierServiceTypeId?: number, materialIds?: number[], fullTextSearch?: string, reference?: string, orderIds?: number[], exportLimit?: number, exportType?: string, dateType?: string, dateFormat?: string, totalRecords?: number }): Promise< { transactions?: any, logMessage?: string[] }> {
    if(!this._excel_export_flow) {
      this._excel_export_flow = this.injector.get(PurchaseOrders_excel_export_flowService);
    }
    return this._excel_export_flow.run(inParams);
  }
   
   

   
 
  private _format_order_lookupcode_flow: PurchaseOrders_format_order_lookupcode_flowService;
  public async format_order_lookupcode_flow(inParams: { orderId: number, additionalData?: any, context: string }): Promise< { formattedLookupcode?: string, errors?: string[] }> {
    if(!this._format_order_lookupcode_flow) {
      this._format_order_lookupcode_flow = this.injector.get(PurchaseOrders_format_order_lookupcode_flowService);
    }
    return this._format_order_lookupcode_flow.run(inParams);
  }
   
   

   
 
  private _format_order_lookupcode_flow_multiple: PurchaseOrders_format_order_lookupcode_flow_multipleService;
  public async format_order_lookupcode_flow_multiple(inParams: { context: string, orders?: { orderId?: number, additionalData?: any }[] }): Promise< { errors?: string[], orders?: { orderId?: number, formattedLookupCode?: string }[] }> {
    if(!this._format_order_lookupcode_flow_multiple) {
      this._format_order_lookupcode_flow_multiple = this.injector.get(PurchaseOrders_format_order_lookupcode_flow_multipleService);
    }
    return this._format_order_lookupcode_flow_multiple.run(inParams);
  }
   
   

   
 
  private _get_purchase_order_state: PurchaseOrders_get_purchase_order_stateService;
  public async get_purchase_order_state(inParams: { orderId: number, shipmentId: number }): Promise< { state?: number }> {
    if(!this._get_purchase_order_state) {
      this._get_purchase_order_state = this.injector.get(PurchaseOrders_get_purchase_order_stateService);
    }
    return this._get_purchase_order_state.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_cancelable_flow: PurchaseOrders_is_purchase_order_cancelable_flowService;
  public async is_purchase_order_cancelable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_cancelable_flow) {
      this._is_purchase_order_cancelable_flow = this.injector.get(PurchaseOrders_is_purchase_order_cancelable_flowService);
    }
    return this._is_purchase_order_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_completable_flow: PurchaseOrders_is_purchase_order_completable_flowService;
  public async is_purchase_order_completable_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_completable_flow) {
      this._is_purchase_order_completable_flow = this.injector.get(PurchaseOrders_is_purchase_order_completable_flowService);
    }
    return this._is_purchase_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_deletable_flow: PurchaseOrders_is_purchase_order_deletable_flowService;
  public async is_purchase_order_deletable_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_deletable_flow) {
      this._is_purchase_order_deletable_flow = this.injector.get(PurchaseOrders_is_purchase_order_deletable_flowService);
    }
    return this._is_purchase_order_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_line_deletable_flow: PurchaseOrders_is_purchase_order_line_deletable_flowService;
  public async is_purchase_order_line_deletable_flow(inParams: { orderId: number, lineNumber: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_line_deletable_flow) {
      this._is_purchase_order_line_deletable_flow = this.injector.get(PurchaseOrders_is_purchase_order_line_deletable_flowService);
    }
    return this._is_purchase_order_line_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_processable_flow: PurchaseOrders_is_purchase_order_processable_flowService;
  public async is_purchase_order_processable_flow(inParams: { orderId?: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_processable_flow) {
      this._is_purchase_order_processable_flow = this.injector.get(PurchaseOrders_is_purchase_order_processable_flowService);
    }
    return this._is_purchase_order_processable_flow.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_revertable_flow: PurchaseOrders_is_purchase_order_revertable_flowService;
  public async is_purchase_order_revertable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_revertable_flow) {
      this._is_purchase_order_revertable_flow = this.injector.get(PurchaseOrders_is_purchase_order_revertable_flowService);
    }
    return this._is_purchase_order_revertable_flow.run(inParams);
  }
   
   

   
 
  private _is_purchase_order_unreceivable_flow: PurchaseOrders_is_purchase_order_unreceivable_flowService;
  public async is_purchase_order_unreceivable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_purchase_order_unreceivable_flow) {
      this._is_purchase_order_unreceivable_flow = this.injector.get(PurchaseOrders_is_purchase_order_unreceivable_flowService);
    }
    return this._is_purchase_order_unreceivable_flow.run(inParams);
  }
   
   

   
 
  private _process_purchase_order_flow: PurchaseOrders_process_purchase_order_flowService;
  public async process_purchase_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._process_purchase_order_flow) {
      this._process_purchase_order_flow = this.injector.get(PurchaseOrders_process_purchase_order_flowService);
    }
    return this._process_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _receive_on_existing_licenseplate_flow: PurchaseOrders_receive_on_existing_licenseplate_flowService;
  public async receive_on_existing_licenseplate_flow(inParams: { lotId: number, packagingId: number, packagedAmount: number, taskId: number, licenseplateId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise< { reasons?: string[], confirm?: boolean, taskId?: number }> {
    if(!this._receive_on_existing_licenseplate_flow) {
      this._receive_on_existing_licenseplate_flow = this.injector.get(PurchaseOrders_receive_on_existing_licenseplate_flowService);
    }
    return this._receive_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _receive_on_new_licenseplate_flow: PurchaseOrders_receive_on_new_licenseplate_flowService;
  public async receive_on_new_licenseplate_flow(inParams: { lotId: number, packagingId: number, packagedAmount: number, taskId: number, locationId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[], licenseplateLookupcode?: string }): Promise< { reasons?: string[], confirm?: boolean, taskId?: number }> {
    if(!this._receive_on_new_licenseplate_flow) {
      this._receive_on_new_licenseplate_flow = this.injector.get(PurchaseOrders_receive_on_new_licenseplate_flowService);
    }
    return this._receive_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _revert_purchase_order_flow: PurchaseOrders_revert_purchase_order_flowService;
  public async revert_purchase_order_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._revert_purchase_order_flow) {
      this._revert_purchase_order_flow = this.injector.get(PurchaseOrders_revert_purchase_order_flowService);
    }
    return this._revert_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _unreceive_content_flow: PurchaseOrders_unreceive_content_flowService;
  public async unreceive_content_flow(inParams: { taskId: number, updatedPackagedAmount: number, serialNumberIds?: number[], reasonCodeId: number }): Promise< { reasons?: string[] }> {
    if(!this._unreceive_content_flow) {
      this._unreceive_content_flow = this.injector.get(PurchaseOrders_unreceive_content_flowService);
    }
    return this._unreceive_content_flow.run(inParams);
  }
   
   

   
 
  private _unreceive_purchase_order_flow: PurchaseOrders_unreceive_purchase_order_flowService;
  public async unreceive_purchase_order_flow(inParams: { orderId: number, shipmentId: number, reasonCodeId: number }): Promise< { reason?: string }> {
    if(!this._unreceive_purchase_order_flow) {
      this._unreceive_purchase_order_flow = this.injector.get(PurchaseOrders_unreceive_purchase_order_flowService);
    }
    return this._unreceive_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _validate_order_flow: PurchaseOrders_validate_order_flowService;
  public async validate_order_flow(inParams: { orderId?: number }): Promise< { reason?: string }> {
    if(!this._validate_order_flow) {
      this._validate_order_flow = this.injector.get(PurchaseOrders_validate_order_flowService);
    }
    return this._validate_order_flow.run(inParams);
  }
   
   

   
}
