import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_wave_creation_gridComponent } from './FootPrintManager.wave_creation_grid.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'
import { Waves_waves_warehouse_dd_singleComponent } from './Waves.waves_warehouse_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_wave_creation_gridComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
    forwardRef(() => Waves_waves_warehouse_dd_singleComponent),
  ],
  selector: 'FootPrintManager-wave_editor',
  templateUrl: './FootPrintManager.wave_editor.component.html'
})
export class FootPrintManager_wave_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { waveId: number, projectId?: number, ownerId?: number, warehouseId?: number, showCreate?: boolean } = { waveId: null, projectId: null, ownerId: null, warehouseId: null, showCreate: null };
  //#region Inputs
  @Input('waveId') set $inParams_waveId(v: number) {
    this.inParams.waveId = v;
  }
  get $inParams_waveId(): number {
    return this.inParams.waveId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('showCreate') set $inParams_showCreate(v: boolean) {
    this.inParams.showCreate = v;
  }
  get $inParams_showCreate(): boolean {
    return this.inParams.showCreate;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, BatchPick?: boolean, Description?: string, Notes?: string, ReleaseDate?: string, StagingLocationId?: number, Status?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Priority?: { Id?: number, Description?: string, Name?: string, Value?: number } };

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    )
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Carrier', false)
,
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Service', false)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      unassigned_waves: new TabItemModel(
        this.rootTabGroup, 
        'Unassigned', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_unassigned_waves_wave_creation_grid_inParams_ownerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.owner.control.value;
      
      return expr;
    }
  
    get $tabs_unassigned_waves_wave_creation_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_unassigned_waves_wave_creation_grid_inParams_warehouseIds: number[];
    get $tabs_unassigned_waves_wave_creation_grid_inParams_warehouseIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.warehouse.control.value[0];
      
      if(!isEqual(this.cacheValueFor_$tabs_unassigned_waves_wave_creation_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_unassigned_waves_wave_creation_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_unassigned_waves_wave_creation_grid_inParams_warehouseIds;
    }
  
    get $tabs_unassigned_waves_wave_creation_grid_inParams_waveId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.waveId;
      
      return expr;
    }
  
    get $tabs_unassigned_waves_wave_creation_grid_inParams_showCreate(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    get $tabs_unassigned_waves_wave_creation_grid_inParams_carrierId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.carrier.control.value;
      
      return expr;
    }
  
    get $tabs_unassigned_waves_wave_creation_grid_inParams_carrierServiceId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.carrier_service.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_unassigned_waves', { read: FootPrintManager_wave_creation_gridComponent }) $tabs_unassigned_waves: FootPrintManager_wave_creation_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.carrier.control.value;
    
    return expr;
  }

  get $fields_warehouse_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.inParams.warehouseId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    carrier_service: this.fields.carrier_service.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.unassigned_waves,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.waveId)) {
        this.$missingRequiredInParams.push('waveId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Wave editor';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      waveId:  $editor.inParams.waveId 
    };

    const data = await this.datasources.Waves.ds_get_wave_by_wave_id.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.owner.control as SelectBoxModel).reset($editor.inParams.ownerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.inParams.projectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.inParams.warehouseId);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_unassigned_waves') {
        if (!isNil(this.$tabs_unassigned_waves) && !this.tabs.unassigned_waves.hidden) {
          this.$tabs_unassigned_waves.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier_service
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $editor: FootPrintManager_wave_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $editor.fields.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($editor.fields.owner.control.value)) {
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $editor.fields.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_wave_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_wave_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.title = `Editing wave ${$editor.inParams.waveId}`
  
  
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_wave_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.confirm.control.readOnly = true;
  $editor.outParams.confirm = true;
  $editor.close();
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_wave_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_wave_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if($editor.entity.Status.Id != 1){
      $editor.tabs.unassigned_waves.hidden = true;
  }
  }
  //#endregion private flows
}
