<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="delete_button" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="Lookup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Lookup.displayControl.styles.style"
          [ngClass]="row.cells.Lookup.displayControl.styles.classes">{{row.cells.Lookup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['Lookup_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.Lookup.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Lookup.editControl.placeholder}}"
            [ngStyle]="row.cells.Lookup.editControl.styles.style"
            [ngClass]="row.cells.Lookup.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_capacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight_capacity.displayControl.styles.style"
          [ngClass]="row.cells.weight_capacity.displayControl.styles.classes">{{row.cells.weight_capacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_capacity_edit']"
            matInput
            numberBox
            [format]="row.cells.weight_capacity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.weight_capacity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight_capacity.editControl.placeholder}}"
            [ngStyle]="row.cells.weight_capacity.editControl.styles.style"
            [ngClass]="row.cells.weight_capacity.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <LocusRobotics-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.weight_uom.editControl.type"
        [formControl]="row.formGroup.controls['weight_uom_edit']"
        (displayTextChange)="row.cells.weight_uom.editControl.displayText=$event"
        [placeholder]="row.cells.weight_uom.editControl.placeholder"
        [styles]="row.cells.weight_uom.editControl.styles"
      [typeId]="row.$fields_weight_uom_selector_inParams_typeId"
    >
    </LocusRobotics-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.width.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.length.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimension_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.dimension_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimension_uom.displayControl.styles.classes">{{row.cells.dimension_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <LocusRobotics-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.dimension_uom.editControl.type"
        [formControl]="row.formGroup.controls['dimension_uom_edit']"
        (displayTextChange)="row.cells.dimension_uom.editControl.displayText=$event"
        [placeholder]="row.cells.dimension_uom.editControl.placeholder"
        [styles]="row.cells.dimension_uom.editControl.styles"
      [typeId]="row.$fields_dimension_uom_selector_inParams_typeId"
    >
    </LocusRobotics-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <LocusRobotics-warehouses_dd_single 
        data-cy="selector"
        [type]="row.cells.warehouse.editControl.type"
        [formControl]="row.formGroup.controls['warehouse_edit']"
        (displayTextChange)="row.cells.warehouse.editControl.displayText=$event"
        [placeholder]="row.cells.warehouse.editControl.placeholder"
        [styles]="row.cells.warehouse.editControl.styles"
    >
    </LocusRobotics-warehouses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <LocusRobotics-bot_type_dd_single 
        data-cy="selector"
        [type]="row.cells.type.editControl.type"
        [formControl]="row.formGroup.controls['type_edit']"
        (displayTextChange)="row.cells.type.editControl.displayText=$event"
        [placeholder]="row.cells.type.editControl.placeholder"
        [styles]="row.cells.type.editControl.styles"
    >
    </LocusRobotics-bot_type_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bin_capacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.bin_capacity.displayControl.styles.style"
          [ngClass]="row.cells.bin_capacity.displayControl.styles.classes">{{row.cells.bin_capacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['bin_capacity_edit']"
            matInput
            numberBox
            [format]="row.cells.bin_capacity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.bin_capacity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.bin_capacity.editControl.placeholder}}"
            [ngStyle]="row.cells.bin_capacity.editControl.styles.style"
            [ngClass]="row.cells.bin_capacity.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume_capacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.volume_capacity.displayControl.styles.style"
          [ngClass]="row.cells.volume_capacity.displayControl.styles.classes">{{row.cells.volume_capacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['volume_capacity_edit']"
            matInput
            numberBox
            [format]="row.cells.volume_capacity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.volume_capacity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.volume_capacity.editControl.placeholder}}"
            [ngStyle]="row.cells.volume_capacity.editControl.styles.style"
            [ngClass]="row.cells.volume_capacity.editControl.styles.classes">
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
