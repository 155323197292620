<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="linecount" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="import_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.import_status.displayControl.styles.style"
          [ngClass]="row.cells.import_status.displayControl.styles.classes">{{row.cells.import_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="row_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.row_count.displayControl.styles.style"
          [ngClass]="row.cells.row_count.displayControl.styles.classes">{{row.cells.row_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-location_type_dd_single 
        data-cy="selector"
        [type]="row.cells.type.editControl.type"
        [formControl]="row.formGroup.controls['type_edit']"
        (displayTextChange)="row.cells.type.editControl.displayText=$event"
        [placeholder]="row.cells.type.editControl.placeholder"
        [styles]="row.cells.type.editControl.styles"
    >
    </ExcelLocationImport-location_type_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-warehouses_dd_single 
        data-cy="selector"
        [type]="row.cells.warehouse.editControl.type"
        [formControl]="row.formGroup.controls['warehouse_edit']"
        (displayTextChange)="row.cells.warehouse.editControl.displayText=$event"
        [placeholder]="row.cells.warehouse.editControl.placeholder"
        [styles]="row.cells.warehouse.editControl.styles"
    >
    </ExcelLocationImport-warehouses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="parent">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.parent.displayControl.styles.style"
          [ngClass]="row.cells.parent.displayControl.styles.classes">{{row.cells.parent.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-zones_dd_single 
        data-cy="selector"
        [type]="row.cells.parent.editControl.type"
        [formControl]="row.formGroup.controls['parent_edit']"
        (displayTextChange)="row.cells.parent.editControl.displayText=$event"
        [placeholder]="row.cells.parent.editControl.placeholder"
        [styles]="row.cells.parent.editControl.styles"
      [warehouseId]="row.$fields_parent_selector_inParams_warehouseId"
    >
    </ExcelLocationImport-zones_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.name.editControl.placeholder}}"
            [ngStyle]="row.cells.name.editControl.styles.style"
            [ngClass]="row.cells.name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-materialstatuses_dd_single 
        data-cy="selector"
        [type]="row.cells.status.editControl.type"
        [formControl]="row.formGroup.controls['status_edit']"
        (displayTextChange)="row.cells.status.editControl.displayText=$event"
        [placeholder]="row.cells.status.editControl.placeholder"
        [styles]="row.cells.status.editControl.styles"
    >
    </ExcelLocationImport-materialstatuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="eligible_for_allocation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.eligible_for_allocation.displayControl.styles.style"
          [ngClass]="row.cells.eligible_for_allocation.displayControl.styles.classes">{{row.cells.eligible_for_allocation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.eligible_for_allocation.editControl.type"
        [formControl]="row.formGroup.controls['eligible_for_allocation_edit']"
        (displayTextChange)="row.cells.eligible_for_allocation.editControl.displayText=$event"
        [placeholder]="row.cells.eligible_for_allocation.editControl.placeholder"
        [styles]="row.cells.eligible_for_allocation.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_loose">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.is_loose.displayControl.styles.style"
          [ngClass]="row.cells.is_loose.displayControl.styles.classes">{{row.cells.is_loose.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.is_loose.editControl.type"
        [formControl]="row.formGroup.controls['is_loose_edit']"
        (displayTextChange)="row.cells.is_loose.editControl.displayText=$event"
        [placeholder]="row.cells.is_loose.editControl.placeholder"
        [styles]="row.cells.is_loose.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_primary">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.is_primary.displayControl.styles.style"
          [ngClass]="row.cells.is_primary.displayControl.styles.classes">{{row.cells.is_primary.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.is_primary.editControl.type"
        [formControl]="row.formGroup.controls['is_primary_edit']"
        (displayTextChange)="row.cells.is_primary.editControl.displayText=$event"
        [placeholder]="row.cells.is_primary.editControl.placeholder"
        [styles]="row.cells.is_primary.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.length.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.width.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimensions_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.dimensions_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimensions_uom.displayControl.styles.classes">{{row.cells.dimensions_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.dimensions_uom.editControl.type"
        [formControl]="row.formGroup.controls['dimensions_uom_edit']"
        (displayTextChange)="row.cells.dimensions_uom.editControl.displayText=$event"
        [placeholder]="row.cells.dimensions_uom.editControl.placeholder"
        [styles]="row.cells.dimensions_uom.editControl.styles"
      [typeId]="row.$fields_dimensions_uom_selector_inParams_typeId"
    >
    </ExcelLocationImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_capacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight_capacity.displayControl.styles.style"
          [ngClass]="row.cells.weight_capacity.displayControl.styles.classes">{{row.cells.weight_capacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_capacity_edit']"
            matInput
            numberBox
            [format]="row.cells.weight_capacity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.weight_capacity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight_capacity.editControl.placeholder}}"
            [ngStyle]="row.cells.weight_capacity.editControl.styles.style"
            [ngClass]="row.cells.weight_capacity.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.weight_uom.editControl.type"
        [formControl]="row.formGroup.controls['weight_uom_edit']"
        (displayTextChange)="row.cells.weight_uom.editControl.displayText=$event"
        [placeholder]="row.cells.weight_uom.editControl.placeholder"
        [styles]="row.cells.weight_uom.editControl.styles"
      [typeId]="row.$fields_weight_uom_selector_inParams_typeId"
    >
    </ExcelLocationImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="child_footprint">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.child_footprint.displayControl.styles.style"
          [ngClass]="row.cells.child_footprint.displayControl.styles.classes">{{row.cells.child_footprint.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['child_footprint_edit']"
            matInput
            numberBox
            [format]="row.cells.child_footprint.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.child_footprint.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.child_footprint.editControl.placeholder}}"
            [ngStyle]="row.cells.child_footprint.editControl.styles.style"
            [ngClass]="row.cells.child_footprint.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="child_stack_height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.child_stack_height.displayControl.styles.style"
          [ngClass]="row.cells.child_stack_height.displayControl.styles.classes">{{row.cells.child_stack_height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['child_stack_height_edit']"
            matInput
            numberBox
            [format]="row.cells.child_stack_height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.child_stack_height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.child_stack_height.editControl.placeholder}}"
            [ngStyle]="row.cells.child_stack_height.editControl.styles.style"
            [ngClass]="row.cells.child_stack_height.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="skip_validation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.skip_validation.displayControl.styles.style"
          [ngClass]="row.cells.skip_validation.displayControl.styles.classes">{{row.cells.skip_validation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.skip_validation.editControl.type"
        [formControl]="row.formGroup.controls['skip_validation_edit']"
        (displayTextChange)="row.cells.skip_validation.editControl.displayText=$event"
        [placeholder]="row.cells.skip_validation.editControl.placeholder"
        [styles]="row.cells.skip_validation.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_sequence">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.allocation_sequence.displayControl.styles.style"
          [ngClass]="row.cells.allocation_sequence.displayControl.styles.classes">{{row.cells.allocation_sequence.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['allocation_sequence_edit']"
            matInput
            numberBox
            [format]="row.cells.allocation_sequence.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.allocation_sequence.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.allocation_sequence.editControl.placeholder}}"
            [ngStyle]="row.cells.allocation_sequence.editControl.styles.style"
            [ngClass]="row.cells.allocation_sequence.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pick_sequence">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.pick_sequence.displayControl.styles.style"
          [ngClass]="row.cells.pick_sequence.displayControl.styles.classes">{{row.cells.pick_sequence.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pick_sequence_edit']"
            matInput
            numberBox
            [format]="row.cells.pick_sequence.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.pick_sequence.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pick_sequence.editControl.placeholder}}"
            [ngStyle]="row.cells.pick_sequence.editControl.styles.style"
            [ngClass]="row.cells.pick_sequence.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="putaway_sequence">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.putaway_sequence.displayControl.styles.style"
          [ngClass]="row.cells.putaway_sequence.displayControl.styles.classes">{{row.cells.putaway_sequence.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['putaway_sequence_edit']"
            matInput
            numberBox
            [format]="row.cells.putaway_sequence.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.putaway_sequence.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.putaway_sequence.editControl.placeholder}}"
            [ngStyle]="row.cells.putaway_sequence.editControl.styles.style"
            [ngClass]="row.cells.putaway_sequence.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="mixing_restrictions">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.mixing_restrictions.displayControl.styles.style"
          [ngClass]="row.cells.mixing_restrictions.displayControl.styles.classes">{{row.cells.mixing_restrictions.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-mixing_rule_dd_single 
        data-cy="selector"
        [type]="row.cells.mixing_restrictions.editControl.type"
        [formControl]="row.formGroup.controls['mixing_restrictions_edit']"
        (displayTextChange)="row.cells.mixing_restrictions.editControl.displayText=$event"
        [placeholder]="row.cells.mixing_restrictions.editControl.placeholder"
        [styles]="row.cells.mixing_restrictions.editControl.styles"
    >
    </ExcelLocationImport-mixing_rule_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="storage_category">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.storage_category.displayControl.styles.style"
          [ngClass]="row.cells.storage_category.displayControl.styles.classes">{{row.cells.storage_category.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-storage_category_dd_single 
        data-cy="selector"
        [type]="row.cells.storage_category.editControl.type"
        [formControl]="row.formGroup.controls['storage_category_edit']"
        (displayTextChange)="row.cells.storage_category.editControl.displayText=$event"
        [placeholder]="row.cells.storage_category.editControl.placeholder"
        [styles]="row.cells.storage_category.editControl.styles"
    >
    </ExcelLocationImport-storage_category_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_pack_verification">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.is_pack_verification.displayControl.styles.style"
          [ngClass]="row.cells.is_pack_verification.displayControl.styles.classes">{{row.cells.is_pack_verification.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.is_pack_verification.editControl.type"
        [formControl]="row.formGroup.controls['is_pack_verification_edit']"
        (displayTextChange)="row.cells.is_pack_verification.editControl.displayText=$event"
        [placeholder]="row.cells.is_pack_verification.editControl.placeholder"
        [styles]="row.cells.is_pack_verification.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="skip_cycle_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.skip_cycle_count.displayControl.styles.style"
          [ngClass]="row.cells.skip_cycle_count.displayControl.styles.classes">{{row.cells.skip_cycle_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.skip_cycle_count.editControl.type"
        [formControl]="row.formGroup.controls['skip_cycle_count_edit']"
        (displayTextChange)="row.cells.skip_cycle_count.editControl.displayText=$event"
        [placeholder]="row.cells.skip_cycle_count.editControl.placeholder"
        [styles]="row.cells.skip_cycle_count.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_dynamic_primary_pick">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.is_dynamic_primary_pick.displayControl.styles.style"
          [ngClass]="row.cells.is_dynamic_primary_pick.displayControl.styles.classes">{{row.cells.is_dynamic_primary_pick.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.is_dynamic_primary_pick.editControl.type"
        [formControl]="row.formGroup.controls['is_dynamic_primary_pick_edit']"
        (displayTextChange)="row.cells.is_dynamic_primary_pick.editControl.displayText=$event"
        [placeholder]="row.cells.is_dynamic_primary_pick.editControl.placeholder"
        [styles]="row.cells.is_dynamic_primary_pick.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="staging_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.staging_location.displayControl.styles.style"
          [ngClass]="row.cells.staging_location.displayControl.styles.classes">{{row.cells.staging_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.staging_location.editControl.type"
        [formControl]="row.formGroup.controls['staging_location_edit']"
        (displayTextChange)="row.cells.staging_location.editControl.displayText=$event"
        [placeholder]="row.cells.staging_location.editControl.placeholder"
        [styles]="row.cells.staging_location.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hazardous_material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.hazardous_material.displayControl.styles.style"
          [ngClass]="row.cells.hazardous_material.displayControl.styles.classes">{{row.cells.hazardous_material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.hazardous_material.editControl.type"
        [formControl]="row.formGroup.controls['hazardous_material_edit']"
        (displayTextChange)="row.cells.hazardous_material.editControl.displayText=$event"
        [placeholder]="row.cells.hazardous_material.editControl.placeholder"
        [styles]="row.cells.hazardous_material.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="skip_recommendation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.skip_recommendation.displayControl.styles.style"
          [ngClass]="row.cells.skip_recommendation.displayControl.styles.classes">{{row.cells.skip_recommendation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.skip_recommendation.editControl.type"
        [formControl]="row.formGroup.controls['skip_recommendation_edit']"
        (displayTextChange)="row.cells.skip_recommendation.editControl.displayText=$event"
        [placeholder]="row.cells.skip_recommendation.editControl.placeholder"
        [styles]="row.cells.skip_recommendation.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_this_hospital">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.is_this_hospital.displayControl.styles.style"
          [ngClass]="row.cells.is_this_hospital.displayControl.styles.classes">{{row.cells.is_this_hospital.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelLocationImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.is_this_hospital.editControl.type"
        [formControl]="row.formGroup.controls['is_this_hospital_edit']"
        (displayTextChange)="row.cells.is_this_hospital.editControl.displayText=$event"
        [placeholder]="row.cells.is_this_hospital.editControl.placeholder"
        [styles]="row.cells.is_this_hospital.editControl.styles"
    >
    </ExcelLocationImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hospital_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.hospital_location.displayControl.styles.style"
          [ngClass]="row.cells.hospital_location.displayControl.styles.classes">{{row.cells.hospital_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['hospital_location_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.hospital_location.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.hospital_location.editControl.placeholder}}"
            [ngStyle]="row.cells.hospital_location.editControl.styles.style"
            [ngClass]="row.cells.hospital_location.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
