import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { DockAppointments_dock_doors_dd_multiComponent } from './DockAppointments.dock_doors_dd_multi.component'


interface Iapp_custom_dock_appointments_gridComponentEntity {
Id?: number, CheckedInBy?: string, CheckedInOn?: string, EstimatedPallets?: number, EstimatedQuantity?: number, linkedDockAppointmentId?: number, LoadConfiguration?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TrailerNumber?: string, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string }}

interface Iapp_custom_dock_appointments_gridComponentInParams {
  typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[]}


class app_custom_dock_appointments_gridComponentRowModel extends GridRowModel {
  grid: app_custom_dock_appointments_gridComponent;
  entity: Iapp_custom_dock_appointments_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    scheduled_arrival: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    scheduled_departure: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    dock_door: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    warehouse_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    checked_in_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    reference_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    carrier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    estimated_pallets: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    estimated_quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    load_configuration: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    trailer_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_dock_appointments_gridComponent, entity: Iapp_custom_dock_appointments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_dock_appointments_gridComponent, entity?: Iapp_custom_dock_appointments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      locationIds:  $grid.filters.dock_doors.control.value ,
      typeIds:  $grid.inParams.typeIds ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      carrierId:  $grid.inParams.carrierId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      timeZoneDates:  $grid.vars.timeZoneDates as { timeZoneId: string, fromDate: string, toDate: string }[] ,
    };
    const data = await this.datasources.app.custom_ds_dock_appointments_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.type.displayControl as TextModel).text = $row.entity.dockAppointmentType?.Name;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.ScheduledProject?.LookupCode;
    (this.cells.dock_door.displayControl as TextModel).text = $utils.isDefined($row.entity.AssignedLocation?.Name) ? $row.entity.AssignedLocation?.Name : $row.entity.ScheduledLocation?.Name;
    (this.cells.warehouse_name.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.reference_number.displayControl as TextModel).text = $row.entity.ReferenceNumber;
    (this.cells.carrier.displayControl as TextModel).text = $row.entity.ScheduledCarrier?.Name;
    (this.cells.estimated_pallets.displayControl as TextModel).text = $row.entity.EstimatedPallets?.toString();
    (this.cells.estimated_quantity.displayControl as TextModel).text = $row.entity.EstimatedQuantity?.toString();
    (this.cells.load_configuration.displayControl as TextModel).text = $row.entity.LoadConfiguration;
    (this.cells.trailer_number.displayControl as TextModel).text = $row.entity.TrailerNumber;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_appointment_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: app_custom_dock_appointments_gridComponentRowModel,
  $grid: app_custom_dock_appointments_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
      dockAppointmentId: $row.entity.Id
  });
  if ($utils.isDefined(dialogResult)) {
      const userConfirm = dialogResult.confirm;
      if (userConfirm) {
          $grid.refresh();
      }
  }
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_dock_appointments_gridComponentRowModel,
  $grid: app_custom_dock_appointments_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  if ($utils.isDefined($row.entity.convertedScheduledArrival?.convertedDate)){
  $row.cells.scheduled_arrival.displayControl.text = $utils.date.format($row.entity.convertedScheduledArrival?.convertedDate , format);
  }
  
  if ($utils.isDefined($row.entity.convertedScheduledDeparture?.convertedDate)){
  $row.cells.scheduled_departure.displayControl.text = $utils.date.format($row.entity.convertedScheduledDeparture?.convertedDate, format);
  }
  
  if ($utils.isDefined($row.entity.convertedCheckInDate?.convertedDate)){
  $row.cells.checked_in_on.displayControl.text =  $utils.date.format($row.entity.convertedCheckInDate?.convertedDate, format);
  }
  
  
  // Format appointment lookupcode with linked appointment
  
  const linkedAppointment = $row.entity.linkedDockAppointmentId;
  if ($utils.isDefined(linkedAppointment)){
  
      $row.cells.lookupcode.displayControl.text = `${$row.entity.LookupCode}-L${linkedAppointment}`;
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_dock_doors_dd_multiComponent),
  ],
  selector: 'app-custom_dock_appointments_grid',
  templateUrl: './app.custom_dock_appointments_grid.component.html'
})
export class app_custom_dock_appointments_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_dock_appointments_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_dock_appointments_gridComponentInParams = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], fromDate: null, toDate: null, dockDoorIds: [] };


  //#region Variables
  vars: { isLoading?: boolean, timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[] } = { };
  //#endregion
  //#region Events
  @Output()
  filtersUpdated = new EventEmitter<{ fromDate?: string, toDate?: string, dockDoorIds?: number[] }>();
  
  events = {
    filtersUpdated: this.filtersUpdated,
  }
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookupcode', false, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       scheduled_arrival: new GridHeaderModel(new HeaderStyles(null, null), 'Scheduled Arrival', false, false, null),       scheduled_departure: new GridHeaderModel(new HeaderStyles(null, null), 'Scheduled Departure', false, false, null),       dock_door: new GridHeaderModel(new HeaderStyles(null, null), 'Dock Door', false, false, null),       warehouse_name: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       checked_in_on: new GridHeaderModel(new HeaderStyles(null, null), 'Checked In On', false, false, null),       reference_number: new GridHeaderModel(new HeaderStyles(null, null), 'Reference Number', false, false, null),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier', false, false, null),       estimated_pallets: new GridHeaderModel(new HeaderStyles(null, null), 'Estimated Pallets', false, false, null),       estimated_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Estimated Quantity', false, false, null),       load_configuration: new GridHeaderModel(new HeaderStyles(null, null), 'Load Configuration', false, false, null),       trailer_number: new GridHeaderModel(new HeaderStyles(null, null), 'Trailer Number', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_dock_appointments_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('carrierId') set $inParams_carrierId(value: any) {
    this.inParams['carrierId'] = value;
  }
  get $inParams_carrierId(): any {
    return this.inParams['carrierId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('fromDate') set $inParams_fromDate(value: any) {
    this.inParams['fromDate'] = value;
  }
  get $inParams_fromDate(): any {
    return this.inParams['fromDate'] ;
  }
  @Input('toDate') set $inParams_toDate(value: any) {
    this.inParams['toDate'] = value;
  }
  get $inParams_toDate(): any {
    return this.inParams['toDate'] ;
  }
  @Input('dockDoorIds') set $inParams_dockDoorIds(value: any) {
    this.inParams['dockDoorIds'] = value;
  }
  get $inParams_dockDoorIds(): any {
    return this.inParams['dockDoorIds'] ;
  }

  topToolbar = {
      on_previous_day: new ToolModel(new ButtonModel('on_previous_day', new ButtonStyles(null, null), false, 'Previous day ', 'ms-Icon ms-Icon--NavigateBack')
    ),
      on_next_day: new ToolModel(new ButtonModel('on_next_day', new ButtonStyles(null, null), false, 'Next day', 'ms-Icon ms-Icon--NavigateForward')
    ),
      on_today: new ToolModel(new ButtonModel('on_today', new ButtonStyles(null, null), false, 'Today', 'ms-Icon ms-Icon--DateTime')
    ),
      on_week: new ToolModel(new ButtonModel('on_week', new ButtonStyles(null, null), false, 'This Week', 'ms-Icon ms-Icon--EventDate')
    ),
      create_appointment: new ToolModel(new ButtonModel('create_appointment', new ButtonStyles(null, null), false, 'Create Appointment', 'ms-Icon ms-Icon--Add')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Attach')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dock_doors: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'From date', false)
,
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'To date', false)
,
    dock_doors: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dock_doors'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Dock doors', false)
,
  }

  //#region filters inParams
  cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds: number[];
  get $fields_dock_doors_selector_inParams_warehouseIds(): number[] {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.warehouseIds;
    
    if(!isEqual(this.cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds;
  }

  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    ) {
    super();
    this.title = 'Dock Appointments';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseIds)) {
        this.$missingRequiredInParams.push('warehouseIds');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.from_date.control as DateBoxModel).reset($grid.inParams.fromDate);
    (this.filters.to_date.control as DateBoxModel).reset($grid.inParams.toDate);
    (this.filters.dock_doors.control as SelectBoxModel).reset($grid.inParams.dockDoorIds);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['from_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['to_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['dock_doors']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      locationIds:  $grid.filters.dock_doors.control.value ,
      typeIds:  $grid.inParams.typeIds ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      carrierId:  $grid.inParams.carrierId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      timeZoneDates:  $grid.vars.timeZoneDates as { timeZoneId: string, fromDate: string, toDate: string }[] ,
    };
    try {
    const data = await this.datasources.app.custom_ds_dock_appointments_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_dock_appointments_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Initialize date format
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $grid.filters.from_date.control.format = dateFormat;
  $grid.filters.to_date.control.format = dateFormat;
  
  
  
  // Clear dock door filters
  $grid.filters.dock_doors.control.value = $grid.inParams.dockDoorIds;
  
  // Default current date
  $grid.filters.from_date.control.value = $grid.inParams.fromDate ?? $utils.date.now()
  $grid.filters.to_date.control.value = $grid.inParams.toDate ?? $utils.date.now()
  
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_create_appointment_clicked(event = null) {
    return this.on_create_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_appointment_clickedInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: "DockAppointment" })).nextId;
  
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
      warehouseId: $utils.isDefined($grid.inParams.warehouseIds) ? $grid.inParams.warehouseIds : null,
      lookupcode: nextAppointmentId.toString(),
      scheduledArrival: $utils.date.now(),
      scheduledDeparture: $utils.date.add(1, 'hour', $utils.date.now()),
      typeId: $grid.inParams.typeIds[0],
      ownerId: $grid.inParams.ownerId,
      projectId: $grid.inParams.projectId,
      carrierId: $grid.inParams.carrierId
  }));
  
  const userConfirm = dialogResult.confirm;
  if (userConfirm) {
      $grid.refresh();
  }
  
  
  
  }
  on_next_day(event = null) {
    return this.on_next_dayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_next_dayInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.add(1, 'day', $grid.filters.from_date.control.value);
  $grid.filters.to_date.control.value = $utils.date.add(1, 'day', $grid.filters.to_date.control.value);
  $grid.refresh();
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_previous_day(event = null) {
    return this.on_previous_dayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_previous_dayInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.add(-1, 'day', $grid.filters.from_date.control.value);
  $grid.filters.to_date.control.value = $utils.date.add(-1, 'day', $grid.filters.to_date.control.value);
  $grid.refresh();
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_today(event = null) {
    return this.on_todayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_todayInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.now();
  $grid.filters.to_date.control.value = $utils.date.now();
  $grid.refresh();
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_this_week(event = null) {
    return this.on_this_weekInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_this_weekInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  let current = new Date 
  let week = []
  
  var today = current.getDate(); // return the number 1 - 31
  var currentDay = current.getDay(); // return day of the week 0 - 6, 0 = sunday
  var startDay = new Date(current.setDate(today - currentDay + 1)); // monday
  
  for (let i = 1; i <= 7; i++) {
    var firstDay = current.getDate() - current.getDay() + i 
    var lastDay = new Date(current.setDate(firstDay)).toISOString().slice(0, 10)
    week.push(lastDay)
  }
  
  $grid.filters.from_date.control.value = $utils.date.format($utils.date.startOf('day', startDay.toISOString()));
  $grid.filters.to_date.control.value = lastDay;
  
  $grid.refresh();
  
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_filter_changed(event = null) {
    return this.on_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_changedInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Get warehouse timezone details
  if ($utils.isDefined($grid.filters.from_date.control.value) || $utils.isDefined($grid.filters.to_date.control.value)) {
      let timeZoneDates = (await $flows.DockAppointments.convert_dates_by_warehouse_timezones({ warehouseIds: $grid.inParams.warehouseIds, dates: { fromDate: $utils.date.startOf('day', $grid.filters.from_date.control.value), toDate: $utils.date.endOf('day', $grid.filters.to_date.control.value) } })).timeZoneDates;
      $grid.vars.timeZoneDates = timeZoneDates.map(tz => ({ timeZoneId: tz.timeZoneId, fromDate: tz.dates.fromDate, toDate: tz.dates.toDate }));
  }
  else {
      $grid.vars.timeZoneDates = [];
  }
  
  $grid.events.filtersUpdated.emit({
      fromDate: $grid.filters.from_date.control.value,
      toDate: $grid.filters.to_date.control.value,
      dockDoorIds: $grid.filters.dock_doors.control.value
  });
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.attachments.control.readOnly = true;
  
  if ($grid.selectedRows.length === 1) {
      $grid.topToolbar.attachments.control.readOnly = false;
  }
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $grid: app_custom_dock_appointments_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 1) {
      await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'DockAppointment', entityKeys: [{ name: 'Id', value: $grid.selectedRows[0].entity.Id }]});
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
