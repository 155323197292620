import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_custom_create_fda_purchase_order_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { projectId: number, warehouseId: number, lookupcode?: string, orderClassId: number, awb: string, entryNumber: string, palletCount: number, ownerReference?: string, vendorReference?: string, accountId?: number, billingAddressId?: number, materialGroupId: number, controllerTypeId: number, isFixedWeight: boolean, isFixedLength: boolean, isFixedVolume: boolean, shelfLifeSpan?: number, storageCategoryRuleId?: number, allocationStrategyId?: number, allocationStrategyWorkflowId?: string }): Promise<{ reason?: string, orderId?: number, shipmentId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.orderClassId)) {
      missingRequiredInParams.push('\'orderClassId\'');
    }
    if (isNil(inParams.awb)) {
      missingRequiredInParams.push('\'awb\'');
    }
    if (isNil(inParams.entryNumber)) {
      missingRequiredInParams.push('\'entryNumber\'');
    }
    if (isNil(inParams.palletCount)) {
      missingRequiredInParams.push('\'palletCount\'');
    }
    if (isNil(inParams.materialGroupId)) {
      missingRequiredInParams.push('\'materialGroupId\'');
    }
    if (isNil(inParams.controllerTypeId)) {
      missingRequiredInParams.push('\'controllerTypeId\'');
    }
    if (isNil(inParams.isFixedWeight)) {
      missingRequiredInParams.push('\'isFixedWeight\'');
    }
    if (isNil(inParams.isFixedLength)) {
      missingRequiredInParams.push('\'isFixedLength\'');
    }
    if (isNil(inParams.isFixedVolume)) {
      missingRequiredInParams.push('\'isFixedVolume\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/functions/custom_create_fda_purchase_order_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

