import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Addresses_ReportService } from './Addresses.report.index';
import { AsnOrders_ReportService } from './AsnOrders.report.index';
import { Carriers_ReportService } from './Carriers.report.index';
import { Cartonization_ReportService } from './Cartonization.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { LoadContainers_ReportService } from './LoadContainers.report.index';
import { LaborManagement_ReportService } from './LaborManagement.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { ShippingContainers_ReportService } from './ShippingContainers.report.index';
import { TemperatureReadings_ReportService } from './TemperatureReadings.report.index';
import { WarehouseTransfers_ReportService } from './WarehouseTransfers.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Replenishments_ReportService } from './Replenishments.report.index';
import { SerialNumbers_ReportService } from './SerialNumbers.report.index';
import { ExcelLocationImport_ReportService } from './ExcelLocationImport.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { WorkOrders_ReportService } from './WorkOrders.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { InventoryCounts_ReportService } from './InventoryCounts.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { PackVerification_ReportService } from './PackVerification.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { Reports_ReportService } from './Reports.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Addresses = this.injector.get(Addresses_ReportService);
    this.AsnOrders = this.injector.get(AsnOrders_ReportService);
    this.Carriers = this.injector.get(Carriers_ReportService);
    this.Cartonization = this.injector.get(Cartonization_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.LoadContainers = this.injector.get(LoadContainers_ReportService);
    this.LaborManagement = this.injector.get(LaborManagement_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.ShippingContainers = this.injector.get(ShippingContainers_ReportService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_ReportService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Replenishments = this.injector.get(Replenishments_ReportService);
    this.SerialNumbers = this.injector.get(SerialNumbers_ReportService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.DockAppointments = this.injector.get(DockAppointments_ReportService);
    this.WorkOrders = this.injector.get(WorkOrders_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.InventoryCounts = this.injector.get(InventoryCounts_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.PackVerification = this.injector.get(PackVerification_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
    this.LocusRobotics = this.injector.get(LocusRobotics_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
  }
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Utilities: Utilities_ReportService;
    public Addresses: Addresses_ReportService;
    public AsnOrders: AsnOrders_ReportService;
    public Carriers: Carriers_ReportService;
    public Cartonization: Cartonization_ReportService;
    public Locations: Locations_ReportService;
    public Lots: Lots_ReportService;
    public LoadContainers: LoadContainers_ReportService;
    public LaborManagement: LaborManagement_ReportService;
    public Owners: Owners_ReportService;
    public ShippingContainers: ShippingContainers_ReportService;
    public TemperatureReadings: TemperatureReadings_ReportService;
    public WarehouseTransfers: WarehouseTransfers_ReportService;
    public Notifications: Notifications_ReportService;
    public Replenishments: Replenishments_ReportService;
    public SerialNumbers: SerialNumbers_ReportService;
    public ExcelLocationImport: ExcelLocationImport_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public DockAppointments: DockAppointments_ReportService;
    public WorkOrders: WorkOrders_ReportService;
    public Materials: Materials_ReportService;
    public InventoryCounts: InventoryCounts_ReportService;
    public Inventory: Inventory_ReportService;
    public PackVerification: PackVerification_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;
    public LocusRobotics: LocusRobotics_ReportService;
    public Reports: Reports_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

