import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_entity_import_requests_gridComponent } from './FootPrintManager.entity_import_requests_grid.component';
import { EntityImport_entity_import_total_errors_by_date_widgetComponent } from './EntityImport.entity_import_total_errors_by_date_widget.component';
import { EntityImport_entity_import_total_ready_by_date_widgetComponent } from './EntityImport.entity_import_total_ready_by_date_widget.component';
import { EntityImport_entity_import_total_completed_by_date_widgetComponent } from './EntityImport.entity_import_total_completed_by_date_widget.component';
import { EntityImport_entity_import_statuses_dd_multiComponent } from './EntityImport.entity_import_statuses_dd_multi.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_multiComponent } from './Owners.projects_dd_multi.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_entity_import_requests_gridComponent),
    forwardRef(() => EntityImport_entity_import_total_errors_by_date_widgetComponent),
    forwardRef(() => EntityImport_entity_import_total_ready_by_date_widgetComponent),
    forwardRef(() => EntityImport_entity_import_total_completed_by_date_widgetComponent),
    forwardRef(() => EntityImport_entity_import_statuses_dd_multiComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_multiComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
  ],
  selector: 'FootPrintManager-entity_import_hub',
  templateUrl: './FootPrintManager.entity_import_hub.component.html'
})
export class FootPrintManager_entity_import_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, projects?: string[], warehouses?: string[], singleWarehouse?: string, singleProjectName?: string, singleProjectLookupCode?: string, requestId?: string, projectsExact?: string[], warehousesExact?: string[], dateFrom?: string, dateTo?: string, dateType?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    requestId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    no_project: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    owners: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    projects: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    no_warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    requestId: new FieldModel(new TextBoxModel(this.formGroup.controls['requestId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'RequestId', false)
,
    no_project: new FieldModel(new CheckBoxModel(this.formGroup.controls['no_project'] as DatexFormControl, null, false, 'Showing all records')
, new ControlContainerStyles(null, null), 'Records without project', false)
,
    owners: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owners'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owners', false)
,
    projects: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['projects'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Projects', false)
,
    no_warehouse: new FieldModel(new CheckBoxModel(this.formGroup.controls['no_warehouse'] as DatexFormControl, null, false, 'Showing all records')
, new ControlContainerStyles(null, null), 'Records without warehouse', false)
,
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouses', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('', false, true, true),
  project_filters: new FieldsetModel('Project filters (uses a "contains" search predicate)', false, true, false),
  warehouse_filters: new FieldsetModel('Warehouse filters (uses a "contains" search predicate)', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      entity_import_requests: new TabItemModel(
        this.rootTabGroup, 
        'Entity imports', 
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_statusCodes: string[];
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_statusCodes(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_statusCodes, expr)) {
        this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_statusCodes = expr;
      }
      return this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_statusCodes;
    }
  
    cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projects: string[];
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_projects(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.projects;
      
      if(!isEqual(this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projects, expr)) {
        this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projects = expr;
      }
      return this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projects;
    }
  
    cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehouses: string[];
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_warehouses(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.warehouses;
      
      if(!isEqual(this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehouses, expr)) {
        this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehouses = expr;
      }
      return this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehouses;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_singleWarehouse(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.singleWarehouse;
      
      return expr;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_singleProjectName(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.singleProjectName;
      
      return expr;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_singleProjectLookupCode(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.singleProjectLookupCode;
      
      return expr;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_requestId(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.requestId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projectsExact: string[];
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_projectsExact(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.projectsExact;
      
      if(!isEqual(this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projectsExact, expr)) {
        this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projectsExact = expr;
      }
      return this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_projectsExact;
    }
  
    cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehousesExact: string[];
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_warehousesExact(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.warehousesExact;
      
      if(!isEqual(this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehousesExact, expr)) {
        this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehousesExact = expr;
      }
      return this.cacheValueFor_$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehousesExact;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_dateFrom(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.dateFrom;
      
      return expr;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_dateTo(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.dateTo;
      
      return expr;
    }
  
    get $tabs_entity_import_requests_entity_import_requests_grid_inParams_dateType(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.dateType;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_entity_import_requests', { read: FootPrintManager_entity_import_requests_gridComponent }) $tabs_entity_import_requests: FootPrintManager_entity_import_requests_gridComponent;
    //#endregion tabs children
    widgets = {
      entity_import_total_errors_by_date_widget: new WidgetModel(),
      entity_import_total_ready_by_date_widget: new WidgetModel(),
      entity_import_total_completed_by_date_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_entity_import_total_errors_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_entity_import_total_ready_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_entity_import_total_completed_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_entity_import_total_errors_by_date_widget', { read:  EntityImport_entity_import_total_errors_by_date_widgetComponent }) $widgets_entity_import_total_errors_by_date_widget: EntityImport_entity_import_total_errors_by_date_widgetComponent;
      @ViewChild('$widgets_entity_import_total_ready_by_date_widget', { read:  EntityImport_entity_import_total_ready_by_date_widgetComponent }) $widgets_entity_import_total_ready_by_date_widget: EntityImport_entity_import_total_ready_by_date_widgetComponent;
      @ViewChild('$widgets_entity_import_total_completed_by_date_widget', { read:  EntityImport_entity_import_total_completed_by_date_widgetComponent }) $widgets_entity_import_total_completed_by_date_widget: EntityImport_entity_import_total_completed_by_date_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owners_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_projects_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_projects_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owners.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Entity import hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view entity import data requests.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.entity_import_requests,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Entity Import Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset(['Error']);
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['requestId']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_request_id_change();
      });
    this.formGroup
      .controls['no_project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.no_project();
      });
    this.formGroup
      .controls['owners']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['projects']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['no_warehouse']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.no_warehouse();
      });
    this.formGroup
      .controls['warehouses']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_entity_import_total_errors_by_date_widget') {
      if (!isNil(this.$widgets_entity_import_total_errors_by_date_widget) && !this.widgets.entity_import_total_errors_by_date_widget.hidden) {
        this.$widgets_entity_import_total_errors_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_entity_import_total_ready_by_date_widget') {
      if (!isNil(this.$widgets_entity_import_total_ready_by_date_widget) && !this.widgets.entity_import_total_ready_by_date_widget.hidden) {
        this.$widgets_entity_import_total_ready_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_entity_import_total_completed_by_date_widget') {
      if (!isNil(this.$widgets_entity_import_total_completed_by_date_widget) && !this.widgets.entity_import_total_completed_by_date_widget.hidden) {
        this.$widgets_entity_import_total_completed_by_date_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_entity_import_requests') {
      if (!isNil(this.$tabs_entity_import_requests) && !this.tabs.entity_import_requests.hidden) {
        this.$tabs_entity_import_requests.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/22/2023
  
  //Setting the initial dates for the date filters
  $hub.vars.currentDate = $utils.date.now();
  $hub.vars.dateFrom = $utils.date.add(-2,'day',$utils.date.now());
  $hub.vars.dateTo = $utils.date.now();
  $hub.vars.dateType = 'CreatedOn';
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/21/2023
  
  let projects = [];
  let ids = []
  
  ids = $hub.filters.projects.control.value;
  
  if ($utils.isDefined(ids)) {
  
      //Get all the projects selected
      let projects_data = (await $datasources.Owners.ds_get_projects_by_projectIds.get({ projectIds: ids })).result;
  
      if ($utils.isDefined(projects_data)) {
  
          /*
          //Commented out as a solution was implemented to use the contains with array values
          if (projects_data.length === 1) {
  
              //Pass on the data to the variable
              $hub.vars.projects = null;
              $hub.vars.singleProjectLookupCode = projects_data[0].LookupCode;
              $hub.vars.singleProjectName = projects_data[0].Name;
  
          } else {*/
  
          //Map the lookup code
          projects = projects_data.map(p => p.LookupCode);
  
          //Map the name as well, just in case it's used
          //projects = projects.concat(projects_data.map(p => p.Name)); //Checking the code, this would only populate with the Project.LookupCode
  
          //Pass on the data to the variable
          $hub.vars.projects = projects;
          $hub.vars.singleProjectLookupCode = null;
          $hub.vars.singleProjectName = null;
  
          /*};*/
  
          // Set Owner from Project
          if (ids.length > 0 && projects_data.length > 0 && !$utils.isDefined($hub.filters.owners.control.value)) {
  
              $hub.filters.owners.control.value = projects_data[0].OwnerId;
          };
      };
  
  } else {
      $hub.vars.projects = null;
      $hub.vars.singleProjectLookupCode = null;
      $hub.vars.singleProjectName = null;
  };
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.projects.control.value = null;
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/18/2023
  
  let warehouses = [];
  let ids = []
  
  ids = $hub.filters.warehouses.control.value;
  
  if ($utils.isDefined(ids)) {
  
      //Get all the projects selected
      let warehouses_data = (await $datasources.Locations.ds_get_warehouses_by_warehouseIds.get({ warehouseIds: ids })).result;
  
      if ($utils.isDefined(warehouses_data)) {
  
          /*
          //Commented out as a solution was implemented to use the contains with array values
          if (warehouses_data.length === 1) {
  
              //Pass on the data to the variable
              $hub.vars.warehouses = null;
              $hub.vars.singleWarehouse = warehouses_data[0].Name;
  
          } else {*/
  
          //Map the name
          warehouses = warehouses_data.map(p => p.Name);
  
          //Pass on the data to the variable
          $hub.vars.warehouses = warehouses;
          $hub.vars.singleWarehouse = null;
  
          /*};*/
      };
  
  } else {
      $hub.vars.warehouses = null;
  };
  }
  no_project(event = null) {
    return this.no_projectInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async no_projectInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/18/2023
  
  let projects = [null, '']
  let no_project = false;
  
  $hub.vars.singleProjectLookupCode = null;
  $hub.vars.singleProjectName = null;
  
  no_project = $hub.filters.no_project.control.value;
  
  if (no_project) {
  
      $hub.filters.owners.hidden = true;
      $hub.filters.projects.hidden = true;
      $hub.vars.projects = null;
      $hub.vars.projectsExact = projects;
      $hub.filters.no_project.control.label = "No project"
  
  } else {
  
      $hub.filters.owners.hidden = false;
      $hub.filters.owners.control.value = null;
      $hub.filters.projects.hidden = false;
      $hub.filters.projects.control.value = null;
      $hub.vars.projects = null;
      $hub.vars.projectsExact = null;
      $hub.filters.no_project.control.label = "Showing all"
  
  };
  }
  no_warehouse(event = null) {
    return this.no_warehouseInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async no_warehouseInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/18/2023
  
  let warehouses = [null, '']
  let no_warehouse = false;
  
  $hub.vars.singleWarehouse = null;
  
  no_warehouse = $hub.filters.no_warehouse.control.value;
  
  if (no_warehouse) {
  
      $hub.filters.warehouses.hidden = true;
      $hub.vars.warehouses = null;
      $hub.vars.warehousesExact = warehouses;
      $hub.filters.no_warehouse.control.label = "No warehouse"
  
  } else {
  
      $hub.filters.warehouses.hidden = false;
      $hub.filters.warehouses.control.value = null;
      $hub.vars.warehouses = null;
      $hub.vars.warehousesExact = null;
      $hub.filters.no_warehouse.control.label = "Showing all"
  
  };
  }
  on_request_id_change(event = null) {
    return this.on_request_id_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_request_id_changeInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/18/2023
  
  $hub.vars.requestId = null;
  let requestId = $hub.filters.requestId.control.value;
  
  if ($utils.isDefined(requestId)) {
  
      if (isValidGUID(requestId)) {
          $hub.vars.requestId = requestId;
      } else {
          $shell.EntityImport.openErrorDialog("Invalid Request Id", `The supplied string (${requestId}) is not a valid GUID, please double check and enter a valid GUID (Ex. B3379064-18C4-4D17-AFCB-FD30AC58A802).`);
      };
  };
  
  /* Generated from Google AI */
  function isValidGUID(guid: string): boolean {
      // Check if the string is 36 characters long
      if (guid.length !== 36) {
          return false;
      };
  
      // Check if the string contains the correct dashes
      if (guid[8] !== '-' || guid[13] !== '-' || guid[18] !== '-') {
          return false;
      };
  
      return true;
  };
  }
  on_date_params_change(event = null) {
    return this.on_date_params_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_date_params_changeInternal(
    $hub: FootPrintManager_entity_import_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/21/2023
  
  if ($utils.isDefined($event.DateFrom)) {
      $hub.vars.dateFrom = $event.DateFrom;
  } else {
      $hub.vars.dateFrom = null;
  };
  if ($utils.isDefined($event.DateTo)) {
      $hub.vars.dateTo = $event.DateTo;
  } else {
      $hub.vars.dateTo = null;
  };
  if ($utils.isDefined($event.DateType)) {
      $hub.vars.dateType = $event.DateType;
  } else {
      $hub.vars.dateType = null;
  };
  }
  //#endregion private flows
}
