import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Inventory_measurement_units_dd_singleComponent } from './Inventory.measurement_units_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inventory_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-get_average_serial_weights_form',
  templateUrl: './FootPrintManager.get_average_serial_weights_form.component.html'
})
export class FootPrintManager_get_average_serial_weights_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { serial_numbers: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], weight_uom_id: number } = { serial_numbers: [], weight_uom_id: null };
  //#region Inputs
  @Input('serial_numbers') set $inParams_serial_numbers(v: { id?: number, lookup: string, net_weight: number, gross_weight: number }[]) {
    this.inParams.serial_numbers = v;
  }
  get $inParams_serial_numbers(): { id?: number, lookup: string, net_weight: number, gross_weight: number }[] {
    return this.inParams.serial_numbers;
  }
  @Input('weight_uom_id') set $inParams_weight_uom_id(v: number) {
    this.inParams.weight_uom_id = v;
  }
  get $inParams_weight_uom_id(): number {
    return this.inParams.weight_uom_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { serial_numbers?: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], total_net?: number, total_gross?: number, weight_uom_id?: number } = { serial_numbers: [], total_net: null, total_gross: null, weight_uom_id: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { serial_numbers_working?: { id?: number, lookup: string, net_weight: number, gross_weight: number, tare?: number }[], cache?: any } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ serial_numbers?: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], total_net?: number, total_gross?: number, weight_uom_id?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    net_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    net_auto_calculate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    gross_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    gross_auto_calculate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Distribute weight', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Weight UOM', false)
,
    net_weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['net_weight'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), '', false)
,
    net_auto_calculate: new FieldModel(new CheckBoxModel(this.formGroup.controls['net_auto_calculate'] as DatexFormControl, null, false, 'Calculate based on gross')
, new ControlContainerStyles(null, null), '', false)
,
    gross_weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['gross_weight'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), '', false)
,
    gross_auto_calculate: new FieldModel(new CheckBoxModel(this.formGroup.controls['gross_auto_calculate'] as DatexFormControl, null, false, 'Calculate based on net')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
  net_weight: new FieldsetModel('Net weight', false, false, true),
  gross_weight: new FieldsetModel('Gross weight', false, false, true),
};

  //#region fields inParams
  get $fields_weight_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    net_weight: validateControlOnChange(this.fields.net_weight.control, () => this.validate_weight_option(this.fields.net_weight.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form())))
    .pipe(shareReplay()),
    gross_weight: validateControlOnChange(this.fields.gross_weight.control, () => this.validate_weight_option(this.fields.gross_weight.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form())))
    .pipe(shareReplay()),
    weight_uom: this.fields.weight_uom.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    net_auto_calculate: this.fields.net_auto_calculate.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    gross_auto_calculate: this.fields.gross_auto_calculate.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.serial_numbers)) {
        this.$missingRequiredInParams.push('serial_numbers');
      }
      if(isNil(this.inParams.weight_uom_id)) {
        this.$missingRequiredInParams.push('weight_uom_id');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Distribute total weight across all items';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.weight_uom.control as SelectBoxModel).reset($form.inParams.weight_uom_id);
    (this.fields.net_auto_calculate.control as CheckBoxModel).reset(false);
    (this.fields.gross_auto_calculate.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .net_weight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_state();
      });
    this.$formGroupFieldValidationObservables
      .net_auto_calculate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_state();
      });
    this.$formGroupFieldValidationObservables
      .gross_weight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_state();
      });
    this.$formGroupFieldValidationObservables
      .gross_auto_calculate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_state();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_get_average_serial_weights_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($form.valid) {
          validateWeights();
          setOutputs();
  
          $form.close();
      }
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      $shell.Inventory.openToaster('Error averaging weights', targetError.message, EToasterType.Error, { positionClass: EToasterPosition.topRight, timeOut: 10000, progressBar: true, tapToDismiss: true });
  }
  
  /****************************************************
   * FUNCTIONS
  *****************************************************/
  function validateWeights() {
      if (!$form.fields.net_auto_calculate.control.value) {
          if (!$utils.isDefined($form.fields.net_weight.control.value)) {
              throw new Error('No value provided for net weight.');
          }
          if ($form.fields.net_weight.control.value <= 0) {
              throw new Error('Net weight must be greater than zero.');
          }
      }
      if (!$form.fields.net_auto_calculate.control.value) {
          if (!$utils.isDefined($form.fields.gross_weight.control.value)) {
              throw new Error('No value provided for gross weight.');
          }
          if ($form.fields.gross_weight.control.value <= 0) {
              throw new Error('Gross weight must be greater than zero.');
          }
      }
  }
  
  function setOutputs() {
      $form.outParams.serial_numbers = $form.vars.serial_numbers_working;
      $form.outParams.total_net = $form.fields.net_weight.control.value;
      $form.outParams.total_gross = $form.fields.gross_weight.control.value;
      $form.outParams.weight_uom_id = $form.fields.weight_uom.control.value;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_get_average_serial_weights_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_get_average_serial_weights_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize components
  $form.toolbar.confirm.control.readOnly = true;
  $form.title = `Distribute total weight across ${$form.inParams.serial_numbers.length} items`;
  
  if (!$utils.isDefined($form.inParams.serial_numbers)) {
      $shell.Inventory.openToaster('Error opening average weight form', 'No serial numbers were provided', EToasterType.Error, { positionClass: EToasterPosition.topRight, timeOut: 5000, progressBar: true });
      $form.close();
  }
  
  $form.vars.serial_numbers_working = [];
  $form.inParams.serial_numbers.forEach(sn => {
      let clone = Object.assign({}, sn);
      clone['tare'] = clone.gross_weight - clone.net_weight;
  
      $form.vars.serial_numbers_working.push(clone);
  });
  
  $form.vars.cache = {};
  
  $form.fields.net_weight.control.value = roundToDecimal($form.inParams.serial_numbers.reduce((totalWeight, serial) => totalWeight += serial.net_weight, 0));
  $form.fields.gross_weight.control.value = roundToDecimal($form.inParams.serial_numbers.reduce((totalWeight, serial) => totalWeight += serial.gross_weight, 0));
  
  $form.set_state();
  
  
  
  
  
  
  
  
  
  
  function roundToDecimal(value) {
      const factor = Math.pow(10, 8);
      return Math.round(value * factor) / factor;
  }
  }
  set_state(event = null) {
    return this.set_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_stateInternal(
    $form: FootPrintManager_get_average_serial_weights_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  reconcile_cache();
  
  if ($form.fields.net_auto_calculate.control['isValueChanged']) {
      if ($form.fields.net_auto_calculate.control.value) {
          set_field_to_autocalculate($form.fields.net_weight);
          auto_calculate_net();
  
          $form.fields.gross_auto_calculate.control.readOnly = true;
      } else {
          set_field_to_manual_entry($form.fields.net_weight);
  
          $form.fields.gross_auto_calculate.control.readOnly = false;
      }
  }
  
  if ($form.fields.gross_auto_calculate.control['isValueChanged']) {
      if ($form.fields.gross_auto_calculate.control.value) {
          set_field_to_autocalculate($form.fields.gross_weight);
          auto_calculate_gross();
  
          $form.fields.net_auto_calculate.control.readOnly = true;
      } else {
          set_field_to_manual_entry($form.fields.gross_weight);
  
          $form.fields.net_auto_calculate.control.readOnly = false;
      }
  }
  
  if ($form.fields.net_weight.control['isValueChanged']) {
      if ($form.fields.net_weight.control.valid) {
          if ($form.fields.gross_auto_calculate.control.value) {
              auto_calculate_gross();
          }
          
          apply_net_weight();
      }
  }
  
  if ($form.fields.gross_weight.control['isValueChanged']) {
      if ($form.fields.gross_weight.control.valid) {
          if ($form.fields.net_auto_calculate.control.value) {
              auto_calculate_net();
          }
  
          apply_gross_weight();
      }
  }
  
  /***************************************************************
   * FUNCTIONS
  ****************************************************************/
  
  function set_field_to_autocalculate(field: typeof $form.fields.gross_weight) {
      field.control.readOnly = true;
      field.styles.setPlannedClass();
  }
  function set_field_to_manual_entry(field: typeof $form.fields.gross_weight) {
      field.control.readOnly = false;
      field.styles.clearClasses();
  }
  
  function auto_calculate_net(): void {
      if (!$utils.isDefined($form.fields.gross_weight.control.value) || $form.fields.gross_auto_calculate.control.value) {
          return;
      }
  
      const average_gross_weight = $form.fields.gross_weight.control.value / $form.vars.serial_numbers_working.length;
  
      $form.vars.serial_numbers_working.forEach(sn => {
          sn.net_weight = average_gross_weight - sn.tare;
      });
  
      $form.fields.net_weight.control.value = roundToDecimal($form.vars.serial_numbers_working.reduce((total, sn) => total += sn.net_weight, 0));
  }
  
  function auto_calculate_gross(): void {
      if (!$utils.isDefined($form.fields.net_weight.control.value) || $form.fields.net_auto_calculate.control.value) {
          return;
      }
  
      const average_net_weight = $form.fields.net_weight.control.value / $form.vars.serial_numbers_working.length;
  
      $form.vars.serial_numbers_working.forEach(sn => {
          sn.gross_weight = average_net_weight + sn.tare;
      });
  
      $form.fields.gross_weight.control.value = roundToDecimal($form.vars.serial_numbers_working.reduce((total, sn) => total += sn.gross_weight, 0));
  }
  
  function apply_net_weight(): void {
      const average_net_weight = $form.fields.net_weight.control.value / $form.vars.serial_numbers_working.length;
  
      $form.vars.serial_numbers_working.forEach(sn => {
          sn.net_weight = roundToDecimal(average_net_weight);
      });
  }
  
  function apply_gross_weight(): void {
      const average_gross_weight = $form.fields.gross_weight.control.value / $form.vars.serial_numbers_working.length;
  
      $form.vars.serial_numbers_working.forEach(sn => {
          sn.gross_weight = roundToDecimal(average_gross_weight);
      });
  }
  
  function reconcile_cache() {
      for (let key in $form.fields) {
          if ($form.vars.cache[key] === $form.fields[key].control.value) {
              $form.fields[key].control.isValueChanged = false;
          } else {
              $form.fields[key].control.isValueChanged = true;
          }
  
          $form.vars.cache[key] = $form.fields[key].control.value;
      }
  }
  
  
  function roundToDecimal(value) {
      const factor = Math.pow(10, 8);
      return Math.round(value * factor) / factor;
  }
  }
  //#endregion private flows
  //#region validation flows
  validate_weight_option<TC extends ValueControlModel>(field: TC) {
    if (!this.initialized){
      return Promise.resolve([]);
    }
    return this.validate_weight_optionInternal(
      { control: field, errors: [] },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_weight_optionInternal<TC extends ValueControlModel>(
    $validation:{
      errors: Pick<string[], 'push'>,
      control: TC
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    if (!$utils.isDefined($validation.control.value)) {
      $validation.errors.push('Field is required');
  } else if ($validation.control.value <= 0) {
      $validation.errors.push('Must be greater than zero');
  } 
    return $validation.errors as string[];
  }
  //#endregion validation flows
  //#region validation flows
  
  validate_form() {
    const emptyResult = { weight_uom:[],net_weight:[],net_auto_calculate:[],gross_weight:[],gross_auto_calculate:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $form: FootPrintManager_get_average_serial_weights_formComponent,
    $validation:{
      fieldErrors: { weight_uom: Pick<string[], 'push'>,net_weight: Pick<string[], 'push'>,net_auto_calculate: Pick<string[], 'push'>,gross_weight: Pick<string[], 'push'>,gross_auto_calculate: Pick<string[], 'push'>, }
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    let isValid = true;
  
  if ($form.fields.gross_weight.control.value < $form.fields.net_weight.control.value && (!$form.fields.net_auto_calculate.control.value && !$form.fields.gross_auto_calculate.control.value)) {
      $validation.fieldErrors.gross_weight.push('Gross weight cannot be less than net weight');
      isValid = false;
  }
  
  $form.toolbar.confirm.control.readOnly = !$form.valid || !isValid;
    return $validation.fieldErrors as { weight_uom:[],net_weight:[],net_auto_calculate:[],gross_weight:[],gross_auto_calculate:[], };
  }
  //#endregion validation flows
}
