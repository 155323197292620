import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Attachments_ReportService } from './Attachments.report.index';
import { Instructions_ReportService } from './Instructions.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';

import { FootPrintApiManager_transaction_reportService } from './FootPrintApiManager.report.index';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Attachments = this.injector.get(Attachments_ReportService);
    this.Instructions = this.injector.get(Instructions_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Attachments: Attachments_ReportService;
    public Instructions: Instructions_ReportService;
    public Owners: Owners_ReportService;
    public Notifications: Notifications_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Invoices: Invoices_ReportService;
    public Materials: Materials_ReportService;
    public Inventory: Inventory_ReportService;
    public SalesOrders: SalesOrders_ReportService;

  public FootPrintApiManager: FootPrintApiManager_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _transaction_report: FootPrintApiManager_transaction_reportService;
  public get transaction_report(): FootPrintApiManager_transaction_reportService {
    if(!this._transaction_report) {
      this._transaction_report = this.injector.get(FootPrintApiManager_transaction_reportService);
    }
    return this._transaction_report;
  }
}

