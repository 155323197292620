import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_order_from_shipping_containerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { scid?: number }): 
  Promise<{ result: { Id?: number, ShipmentId?: number, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string } }[] } } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_order_from_shipping_container/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
