<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized ">



      <mat-card-title-group class="card-header">
        <div class="card-title-container">
          <ng-content></ng-content>
          <mat-card-title data-cy="card-title" class="card-title " >{{headerTitle}}</mat-card-title>
        </div>

        <div class="card-icon-container">
            <i data-cy="card-icon" [class]="'card-icon icon ms-Icon ms-Icon--' + headerIcon + headerIconSize + headerIconColor + headerIconTransparency"></i>
        </div>
      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup1</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-order" *ngIf="!content.fields.order.hidden" 
                            class="field-container standard {{content.fields.order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.order.styles.style"
                            [ngClass]="content.fields.order.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.order.label}}{{content.fields.order.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.order.label}}">{{content.fields.order.label}}<span *ngIf="content.fields.order.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.order.control.styles.style"
                              [ngClass]="content.fields.order.control.styles.classes">{{content.fields.order.control.text }}</div>
                        <ng-container *ngIf="content.fields.order.invalid">
                          <ng-container *ngFor="let error of content.fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-job" *ngIf="!content.fields.job.hidden" 
                            class="field-container standard {{content.fields.job.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.job.styles.style"
                            [ngClass]="content.fields.job.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.job.label}}{{content.fields.job.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.job.label}}">{{content.fields.job.label}}<span *ngIf="content.fields.job.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.job.control.styles.style"
                              [ngClass]="content.fields.job.control.styles.classes">{{content.fields.job.control.text }}</div>
                        <ng-container *ngIf="content.fields.job.invalid">
                          <ng-container *ngFor="let error of content.fields.job.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-bot" *ngIf="!content.fields.bot.hidden" 
                            class="field-container standard {{content.fields.bot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.bot.styles.style"
                            [ngClass]="content.fields.bot.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.bot.label}}{{content.fields.bot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.bot.label}}">{{content.fields.bot.label}}<span *ngIf="content.fields.bot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.bot.control.styles.style"
                              [ngClass]="content.fields.bot.control.styles.classes">{{content.fields.bot.control.text }}</div>
                        <ng-container *ngIf="content.fields.bot.invalid">
                          <ng-container *ngFor="let error of content.fields.bot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-time_on_floor" *ngIf="!content.fields.time_on_floor.hidden" 
                            class="field-container standard {{content.fields.time_on_floor.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.time_on_floor.styles.style"
                            [ngClass]="content.fields.time_on_floor.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.time_on_floor.label}}{{content.fields.time_on_floor.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.time_on_floor.label}}">{{content.fields.time_on_floor.label}}<span *ngIf="content.fields.time_on_floor.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.time_on_floor.control.styles.style"
                              [ngClass]="content.fields.time_on_floor.control.styles.classes">{{content.fields.time_on_floor.control.text }}</div>
                        <ng-container *ngIf="content.fields.time_on_floor.invalid">
                          <ng-container *ngFor="let error of content.fields.time_on_floor.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-newField5" *ngIf="!content.fields.newField5.hidden" 
                            class="field-container standard {{content.fields.newField5.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.newField5.styles.style"
                            [ngClass]="content.fields.newField5.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.newField5.label}}{{content.fields.newField5.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.newField5.label}}">{{content.fields.newField5.label}}<span *ngIf="content.fields.newField5.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.newField5.control.styles.style"
                              [ngClass]="content.fields.newField5.control.styles.classes">{{content.fields.newField5.control.text }}</div>
                        <ng-container *ngIf="content.fields.newField5.invalid">
                          <ng-container *ngFor="let error of content.fields.newField5.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-newField6" *ngIf="!content.fields.newField6.hidden" 
                            class="field-container standard {{content.fields.newField6.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.newField6.styles.style"
                            [ngClass]="content.fields.newField6.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.newField6.label}}{{content.fields.newField6.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.newField6.label}}">{{content.fields.newField6.label}}<span *ngIf="content.fields.newField6.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.newField6.control.styles.style"
                              [ngClass]="content.fields.newField6.control.styles.classes">{{content.fields.newField6.control.text }}</div>
                        <ng-container *ngIf="content.fields.newField6.invalid">
                          <ng-container *ngFor="let error of content.fields.newField6.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-formGroupContent-id-newGroup2"
                     *ngIf="!content.fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup2</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-newField7" *ngIf="!content.fields.newField7.hidden" 
                            class="field-container full {{content.fields.newField7.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.newField7.styles.style"
                            [ngClass]="content.fields.newField7.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.newField7.label}}{{content.fields.newField7.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.newField7.label}}">{{content.fields.newField7.label}}<span *ngIf="content.fields.newField7.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.newField7.control.styles.style"
                              [ngClass]="content.fields.newField7.control.styles.classes">{{content.fields.newField7.control.text }}</div>
                        <ng-container *ngIf="content.fields.newField7.invalid">
                          <ng-container *ngFor="let error of content.fields.newField7.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-formGroupContent-id-newGroup3"
                     *ngIf="!content.fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup3</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-formGroupContent-id-newGroup4"
                     *ngIf="!content.fieldsets.newGroup4.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup4.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup4.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup4.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup4</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup4.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup4.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup4.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup4.expanded"
                         class="fieldsetsGroup">
                    </div>
                  </div>
                </div>
              </div>        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.cart_info_grid.hidden" class="tab" data-cy="tab-cart_info_grid">
              <h2 [className]="tabs.cart_info_grid.active? 'active': ''" (click)="tabs.cart_info_grid.activate()">{{tabs.cart_info_grid.title}}</h2>
            </div>
          </div>
        
              <LocusRobotics-jobs_by_license_plate_grid *ngIf="tabs.cart_info_grid.active"
              #$tabs_cart_info_grid
              ($refreshEvent)="refresh(false, false, '$tabs_cart_info_grid')">
              </LocusRobotics-jobs_by_license_plate_grid>
        </div>
      </mat-card-content>

      <mat-card-actions data-cy="card-actions" class="card-actions">
          <app-toolbar [toolbar]="actionbar">
              <ng-template toolbarToolDef="build_pallet" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </mat-card-actions>


    </ng-container>

  </mat-card>
  <div class="card-gripper"></div>
</div>