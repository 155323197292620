import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Discussions_OperationService } from './Discussions.operation.service';
import { EasyPost_OperationService } from './EasyPost.operation.service';
import { BarcodeParsing_OperationService } from './BarcodeParsing.operation.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Addresses_OperationService } from './Addresses.operation.service';
import { AsnOrders_OperationService } from './AsnOrders.operation.service';
import { Attachments_OperationService } from './Attachments.operation.service';
import { Carriers_OperationService } from './Carriers.operation.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { Cartonization_OperationService } from './Cartonization.operation.service';
import { ExcelInventoryImport_OperationService } from './ExcelInventoryImport.operation.service';
import { Instructions_OperationService } from './Instructions.operation.service';
import { Inspections_OperationService } from './Inspections.operation.service';
import { ExcelProjectImport_OperationService } from './ExcelProjectImport.operation.service';
import { ExcelOrderImport_OperationService } from './ExcelOrderImport.operation.service';
import { Locations_OperationService } from './Locations.operation.service';
import { Lots_OperationService } from './Lots.operation.service';
import { LoadContainers_OperationService } from './LoadContainers.operation.service';
import { LaborManagement_OperationService } from './LaborManagement.operation.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Returns_OperationService } from './Returns.operation.service';
import { ShippingContainers_OperationService } from './ShippingContainers.operation.service';
import { TemperatureReadings_OperationService } from './TemperatureReadings.operation.service';
import { Surveys_OperationService } from './Surveys.operation.service';
import { WarehouseTransfers_OperationService } from './WarehouseTransfers.operation.service';
import { Waves_OperationService } from './Waves.operation.service';
import { Equipment_OperationService } from './Equipment.operation.service';
import { ExcelMaterialImport_OperationService } from './ExcelMaterialImport.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Replenishments_OperationService } from './Replenishments.operation.service';
import { PalletTransactions_OperationService } from './PalletTransactions.operation.service';
import { SerialNumbers_OperationService } from './SerialNumbers.operation.service';
import { ExcelLocationImport_OperationService } from './ExcelLocationImport.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { WorkOrders_OperationService } from './WorkOrders.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { InventoryTransfers_OperationService } from './InventoryTransfers.operation.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { TransloadOrders_OperationService } from './TransloadOrders.operation.service';
import { PackVerification_OperationService } from './PackVerification.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';


@Injectable({ providedIn: 'root' })
export class FootPrintManager_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Discussions = this.injector.get(Discussions_OperationService);
    this.EasyPost = this.injector.get(EasyPost_OperationService);
    this.BarcodeParsing = this.injector.get(BarcodeParsing_OperationService);
    this.BarTender = this.injector.get(BarTender_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Addresses = this.injector.get(Addresses_OperationService);
    this.AsnOrders = this.injector.get(AsnOrders_OperationService);
    this.Attachments = this.injector.get(Attachments_OperationService);
    this.Carriers = this.injector.get(Carriers_OperationService);
    this.EntityImport = this.injector.get(EntityImport_OperationService);
    this.Cartonization = this.injector.get(Cartonization_OperationService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_OperationService);
    this.Instructions = this.injector.get(Instructions_OperationService);
    this.Inspections = this.injector.get(Inspections_OperationService);
    this.ExcelProjectImport = this.injector.get(ExcelProjectImport_OperationService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_OperationService);
    this.Locations = this.injector.get(Locations_OperationService);
    this.Lots = this.injector.get(Lots_OperationService);
    this.LoadContainers = this.injector.get(LoadContainers_OperationService);
    this.LaborManagement = this.injector.get(LaborManagement_OperationService);
    this.Manifesting = this.injector.get(Manifesting_OperationService);
    this.Owners = this.injector.get(Owners_OperationService);
    this.Returns = this.injector.get(Returns_OperationService);
    this.ShippingContainers = this.injector.get(ShippingContainers_OperationService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_OperationService);
    this.Surveys = this.injector.get(Surveys_OperationService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_OperationService);
    this.Waves = this.injector.get(Waves_OperationService);
    this.Equipment = this.injector.get(Equipment_OperationService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Replenishments = this.injector.get(Replenishments_OperationService);
    this.PalletTransactions = this.injector.get(PalletTransactions_OperationService);
    this.SerialNumbers = this.injector.get(SerialNumbers_OperationService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.DockAppointments = this.injector.get(DockAppointments_OperationService);
    this.WorkOrders = this.injector.get(WorkOrders_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_OperationService);
    this.InventoryCounts = this.injector.get(InventoryCounts_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.TransloadOrders = this.injector.get(TransloadOrders_OperationService);
    this.PackVerification = this.injector.get(PackVerification_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
  }

    public Discussions: Discussions_OperationService;
    public EasyPost: EasyPost_OperationService;
    public BarcodeParsing: BarcodeParsing_OperationService;
    public BarTender: BarTender_OperationService;
    public Utilities: Utilities_OperationService;
    public Addresses: Addresses_OperationService;
    public AsnOrders: AsnOrders_OperationService;
    public Attachments: Attachments_OperationService;
    public Carriers: Carriers_OperationService;
    public EntityImport: EntityImport_OperationService;
    public Cartonization: Cartonization_OperationService;
    public ExcelInventoryImport: ExcelInventoryImport_OperationService;
    public Instructions: Instructions_OperationService;
    public Inspections: Inspections_OperationService;
    public ExcelProjectImport: ExcelProjectImport_OperationService;
    public ExcelOrderImport: ExcelOrderImport_OperationService;
    public Locations: Locations_OperationService;
    public Lots: Lots_OperationService;
    public LoadContainers: LoadContainers_OperationService;
    public LaborManagement: LaborManagement_OperationService;
    public Manifesting: Manifesting_OperationService;
    public Owners: Owners_OperationService;
    public Returns: Returns_OperationService;
    public ShippingContainers: ShippingContainers_OperationService;
    public TemperatureReadings: TemperatureReadings_OperationService;
    public Surveys: Surveys_OperationService;
    public WarehouseTransfers: WarehouseTransfers_OperationService;
    public Waves: Waves_OperationService;
    public Equipment: Equipment_OperationService;
    public ExcelMaterialImport: ExcelMaterialImport_OperationService;
    public Notifications: Notifications_OperationService;
    public Replenishments: Replenishments_OperationService;
    public PalletTransactions: PalletTransactions_OperationService;
    public SerialNumbers: SerialNumbers_OperationService;
    public ExcelLocationImport: ExcelLocationImport_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public DockAppointments: DockAppointments_OperationService;
    public WorkOrders: WorkOrders_OperationService;
    public Invoices: Invoices_OperationService;
    public PrintNode: PrintNode_OperationService;
    public Materials: Materials_OperationService;
    public InventoryTransfers: InventoryTransfers_OperationService;
    public InventoryCounts: InventoryCounts_OperationService;
    public Inventory: Inventory_OperationService;
    public TransloadOrders: TransloadOrders_OperationService;
    public PackVerification: PackVerification_OperationService;
    public SalesOrders: SalesOrders_OperationService;
  public FootPrintManager: FootPrintManager_OperationService = this;

  public Disable_Accessorials_ChargeEntry = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Accessorials_ChargeEntry');
    }
  }
  public Disable_AsnOrders_AutoReceiving = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_AutoReceiving');
    }
  }
  public Disable_AsnOrders_LicenseplateEditing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_LicenseplateEditing');
    }
  }
  public Disable_AsnOrders_OrderCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_OrderCreation');
    }
  }
  public Disable_AsnOrders_OrderEdits = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_OrderEdits');
    }
  }
  public Disable_Inventory_MovementConfirmation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_MovementConfirmation');
    }
  }
  public Disable_InventoryGrid_TransferInventory = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InventoryGrid_TransferInventory');
    }
  }
  public Disable_InventoryTransfers_TransferCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InventoryTransfers_TransferCreation');
    }
  }
  public Disable_LoadContainer_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LoadContainer_Creation');
    }
  }
  public Disable_Navigation_Billing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing');
    }
  }
  public Disable_Navigation_Inbound = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound');
    }
  }
  public Disable_Navigation_Integrations = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations');
    }
  }
  public Disable_Navigation_Inventory = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory');
    }
  }
  public Disable_Navigation_Outbound = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound');
    }
  }
  public Disable_Navigation_Owners = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners');
    }
  }
  public Disable_Navigation_Planning = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning');
    }
  }
  public Disable_Navigation_Settings = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings');
    }
  }
  public Disable_Navigation_Transportation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation');
    }
  }
  public Disable_ReturnOrders_OrderCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_ReturnOrders_OrderCreation');
    }
  }
  public Disable_SalesOrders_AutoPicking = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_AutoPicking');
    }
  }
  public Disable_SerialNumbers_DistributeWeight = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SerialNumbers_DistributeWeight');
    }
  }
  public Disable_Temperatures_Entry = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Temperatures_Entry');
    }
  }
  public Enable_Accessorials_ShowAllOperations = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Accessorials_ShowAllOperations');
    }
  }
  public Enable_Inventory_AdjustmentsValidateAvailability = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Inventory_AdjustmentsValidateAvailability');
    }
  }
  public Enable_Locations_DefaultWarehouseByActivity = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Locations_DefaultWarehouseByActivity');
    }
  }
  public Enable_SalesOrders_DefaultOrderHubCreatedStatus = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_SalesOrders_DefaultOrderHubCreatedStatus');
    }
  }
  public Enable_SalesOrders_SelectChildSerialNumbers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_SalesOrders_SelectChildSerialNumbers');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/FootPrintManager/operations/${operationName}/isauthorized`, null);
  }
}
