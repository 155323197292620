import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Addresses_FlowService } from './Addresses.flow.index';
import { AsnOrders_FlowService } from './AsnOrders.flow.index';
import { Carriers_FlowService } from './Carriers.flow.index';
import { Cartonization_FlowService } from './Cartonization.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { Lots_FlowService } from './Lots.flow.index';
import { LoadContainers_FlowService } from './LoadContainers.flow.index';
import { LaborManagement_FlowService } from './LaborManagement.flow.index';
import { Owners_FlowService } from './Owners.flow.index';
import { ShippingContainers_FlowService } from './ShippingContainers.flow.index';
import { TemperatureReadings_FlowService } from './TemperatureReadings.flow.index';
import { WarehouseTransfers_FlowService } from './WarehouseTransfers.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Replenishments_FlowService } from './Replenishments.flow.index';
import { SerialNumbers_FlowService } from './SerialNumbers.flow.index';
import { ExcelLocationImport_FlowService } from './ExcelLocationImport.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { WorkOrders_FlowService } from './WorkOrders.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { PackVerification_FlowService } from './PackVerification.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { Reports_FlowService } from './Reports.flow.index';

import { app_custom_auto_move_asn_inventory_by_licenseplate_task_flowService } from './app.flow.index';
import { app_custom_copy_asn_order_udfs_to_new_order_flowService } from './app.flow.index';
import { app_custom_create_fda_lp_moves_flowService } from './app.flow.index';
import { app_custom_create_fda_purchase_order_flowService } from './app.flow.index';
import { app_custom_format_order_lookupcode_flowService } from './app.flow.index';
import { app_custom_format_order_lookupcode_flow_multipleService } from './app.flow.index';
import { app_custom_get_blind_return_lot_flowService } from './app.flow.index';
import { app_custom_get_blind_return_new_licenseplate_statusService } from './app.flow.index';
import { app_custom_get_blind_return_new_lot_statusService } from './app.flow.index';
import { app_custom_get_failure_location_flowService } from './app.flow.index';
import { app_custom_get_failure_reasoncode_flowService } from './app.flow.index';
import { app_custom_get_sales_order_stateService } from './app.flow.index';
import { app_custom_get_serial_number_code_by_parsedValuesService } from './app.flow.index';
import { app_custom_get_serial_number_substitution_reason_code_flowService } from './app.flow.index';
import { app_custom_get_shipment_quantities_to_cartonize_flowService } from './app.flow.index';
import { app_custom_is_asn_order_auto_receivable_flowService } from './app.flow.index';
import { app_custom_is_asn_order_updatable_project_flowService } from './app.flow.index';
import { app_custom_is_pack_verification_location_valid_flowService } from './app.flow.index';
import { app_custom_is_purchase_order_completable_flowService } from './app.flow.index';
import { app_custom_is_purchase_order_processable_flowService } from './app.flow.index';
import { app_custom_is_purchase_order_unreceivable_flowService } from './app.flow.index';
import { app_custom_recommend_lot_creationService } from './app.flow.index';
import { app_custom_save_shipping_container_actual_weight_flowService } from './app.flow.index';
import { app_custom_update_asn_licenseplate_flowService } from './app.flow.index';
import { app_custom_update_project_asn_order_flowService } from './app.flow.index';
import { app_custom_validate_barcode_flowService } from './app.flow.index';
import { app_custom_validate_order_flowService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Addresses = this.injector.get(Addresses_FlowService);
    this.AsnOrders = this.injector.get(AsnOrders_FlowService);
    this.Carriers = this.injector.get(Carriers_FlowService);
    this.Cartonization = this.injector.get(Cartonization_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.Lots = this.injector.get(Lots_FlowService);
    this.LoadContainers = this.injector.get(LoadContainers_FlowService);
    this.LaborManagement = this.injector.get(LaborManagement_FlowService);
    this.Owners = this.injector.get(Owners_FlowService);
    this.ShippingContainers = this.injector.get(ShippingContainers_FlowService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_FlowService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.Replenishments = this.injector.get(Replenishments_FlowService);
    this.SerialNumbers = this.injector.get(SerialNumbers_FlowService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.DockAppointments = this.injector.get(DockAppointments_FlowService);
    this.WorkOrders = this.injector.get(WorkOrders_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.InventoryCounts = this.injector.get(InventoryCounts_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.PackVerification = this.injector.get(PackVerification_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.FootPrintManager = this.injector.get(FootPrintManager_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
    this.LocusRobotics = this.injector.get(LocusRobotics_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
  }

    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Utilities: Utilities_FlowService;
    public Addresses: Addresses_FlowService;
    public AsnOrders: AsnOrders_FlowService;
    public Carriers: Carriers_FlowService;
    public Cartonization: Cartonization_FlowService;
    public Locations: Locations_FlowService;
    public Lots: Lots_FlowService;
    public LoadContainers: LoadContainers_FlowService;
    public LaborManagement: LaborManagement_FlowService;
    public Owners: Owners_FlowService;
    public ShippingContainers: ShippingContainers_FlowService;
    public TemperatureReadings: TemperatureReadings_FlowService;
    public WarehouseTransfers: WarehouseTransfers_FlowService;
    public Notifications: Notifications_FlowService;
    public Replenishments: Replenishments_FlowService;
    public SerialNumbers: SerialNumbers_FlowService;
    public ExcelLocationImport: ExcelLocationImport_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public DockAppointments: DockAppointments_FlowService;
    public WorkOrders: WorkOrders_FlowService;
    public Materials: Materials_FlowService;
    public InventoryCounts: InventoryCounts_FlowService;
    public Inventory: Inventory_FlowService;
    public PackVerification: PackVerification_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public FootPrintManager: FootPrintManager_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
    public LocusRobotics: LocusRobotics_FlowService;
    public Reports: Reports_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_auto_move_asn_inventory_by_licenseplate_task_flow: app_custom_auto_move_asn_inventory_by_licenseplate_task_flowService;
  public async custom_auto_move_asn_inventory_by_licenseplate_task_flow(inParams: { licenseplateId: number, taskId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._custom_auto_move_asn_inventory_by_licenseplate_task_flow) {
      this._custom_auto_move_asn_inventory_by_licenseplate_task_flow = this.injector.get(app_custom_auto_move_asn_inventory_by_licenseplate_task_flowService);
    }
    return this._custom_auto_move_asn_inventory_by_licenseplate_task_flow.run(inParams);
  }
   
   

   
 
  private _custom_copy_asn_order_udfs_to_new_order_flow: app_custom_copy_asn_order_udfs_to_new_order_flowService;
  public async custom_copy_asn_order_udfs_to_new_order_flow(inParams: { sourceOrderId: number, targetOrderId: number }): Promise<void> {
    if(!this._custom_copy_asn_order_udfs_to_new_order_flow) {
      this._custom_copy_asn_order_udfs_to_new_order_flow = this.injector.get(app_custom_copy_asn_order_udfs_to_new_order_flowService);
    }
    return this._custom_copy_asn_order_udfs_to_new_order_flow.run(inParams);
  }
   
   

   
 
  private _custom_create_fda_lp_moves_flow: app_custom_create_fda_lp_moves_flowService;
  public async custom_create_fda_lp_moves_flow(inParams: { orderId: number, shipmentId: number, targetLocationId?: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_create_fda_lp_moves_flow) {
      this._custom_create_fda_lp_moves_flow = this.injector.get(app_custom_create_fda_lp_moves_flowService);
    }
    return this._custom_create_fda_lp_moves_flow.run(inParams);
  }
   
   

   
 
  private _custom_create_fda_purchase_order_flow: app_custom_create_fda_purchase_order_flowService;
  public async custom_create_fda_purchase_order_flow(inParams: { projectId: number, warehouseId: number, lookupcode?: string, orderClassId: number, awb: string, entryNumber: string, palletCount: number, ownerReference?: string, vendorReference?: string, accountId?: number, billingAddressId?: number, materialGroupId: number, controllerTypeId: number, isFixedWeight: boolean, isFixedLength: boolean, isFixedVolume: boolean, shelfLifeSpan?: number, storageCategoryRuleId?: number, allocationStrategyId?: number, allocationStrategyWorkflowId?: string }): Promise< { reason?: string, orderId?: number, shipmentId?: number }> {
    if(!this._custom_create_fda_purchase_order_flow) {
      this._custom_create_fda_purchase_order_flow = this.injector.get(app_custom_create_fda_purchase_order_flowService);
    }
    return this._custom_create_fda_purchase_order_flow.run(inParams);
  }
   
   

   
 
  private _custom_format_order_lookupcode_flow: app_custom_format_order_lookupcode_flowService;
  public async custom_format_order_lookupcode_flow(inParams: { orderId: number, additionalData?: any, context: string }): Promise< { formattedLookupcode?: string, errors?: string[] }> {
    if(!this._custom_format_order_lookupcode_flow) {
      this._custom_format_order_lookupcode_flow = this.injector.get(app_custom_format_order_lookupcode_flowService);
    }
    return this._custom_format_order_lookupcode_flow.run(inParams);
  }
   
   

   
 
  private _custom_format_order_lookupcode_flow_multiple: app_custom_format_order_lookupcode_flow_multipleService;
  public async custom_format_order_lookupcode_flow_multiple(inParams: { context: string, orders?: { orderId?: number, additionalData?: any }[] }): Promise< { errors?: string[], orders?: { orderId?: number, formattedLookupCode?: string }[] }> {
    if(!this._custom_format_order_lookupcode_flow_multiple) {
      this._custom_format_order_lookupcode_flow_multiple = this.injector.get(app_custom_format_order_lookupcode_flow_multipleService);
    }
    return this._custom_format_order_lookupcode_flow_multiple.run(inParams);
  }
   
   

   
 
  private _custom_get_blind_return_lot_flow: app_custom_get_blind_return_lot_flowService;
  public async custom_get_blind_return_lot_flow(inParams: { materialId: number, vendorLotId: number, vendorLotCode: string, lotCode?: string, expirationDate?: string, shippingContainerId?: number }): Promise< { reasons?: string[], lot?: { id?: number, lookupcode?: string, statusId?: number } }> {
    if(!this._custom_get_blind_return_lot_flow) {
      this._custom_get_blind_return_lot_flow = this.injector.get(app_custom_get_blind_return_lot_flowService);
    }
    return this._custom_get_blind_return_lot_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_blind_return_new_licenseplate_status: app_custom_get_blind_return_new_licenseplate_statusService;
  public async custom_get_blind_return_new_licenseplate_status(inParams: { manufactureDate?: string, expirationDate?: string, lookupcode?: string, otherProperties?: any, lotStatusId?: number }): Promise< { recommendedStatus?: { id?: number, name?: string }, reasons?: string[], isMandatory?: boolean }> {
    if(!this._custom_get_blind_return_new_licenseplate_status) {
      this._custom_get_blind_return_new_licenseplate_status = this.injector.get(app_custom_get_blind_return_new_licenseplate_statusService);
    }
    return this._custom_get_blind_return_new_licenseplate_status.run(inParams);
  }
   
   

   
 
  private _custom_get_blind_return_new_lot_status: app_custom_get_blind_return_new_lot_statusService;
  public async custom_get_blind_return_new_lot_status(inParams: { manufactureDate?: string, expirationDate?: string, lookupcode?: string, materialId?: number }): Promise< { recommendedStatusId?: number, reasons?: string[], isMandatory?: boolean }> {
    if(!this._custom_get_blind_return_new_lot_status) {
      this._custom_get_blind_return_new_lot_status = this.injector.get(app_custom_get_blind_return_new_lot_statusService);
    }
    return this._custom_get_blind_return_new_lot_status.run(inParams);
  }
   
   

   
 
  private _custom_get_failure_location_flow: app_custom_get_failure_location_flowService;
  public async custom_get_failure_location_flow(inParams: { inputs: any }): Promise< { targetLocationId?: number, reasons?: string[] }> {
    if(!this._custom_get_failure_location_flow) {
      this._custom_get_failure_location_flow = this.injector.get(app_custom_get_failure_location_flowService);
    }
    return this._custom_get_failure_location_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_failure_reasoncode_flow: app_custom_get_failure_reasoncode_flowService;
  public async custom_get_failure_reasoncode_flow(inParams: { inputs: any }): Promise< { reasonCodeId?: number, reasons?: string[] }> {
    if(!this._custom_get_failure_reasoncode_flow) {
      this._custom_get_failure_reasoncode_flow = this.injector.get(app_custom_get_failure_reasoncode_flowService);
    }
    return this._custom_get_failure_reasoncode_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_sales_order_state: app_custom_get_sales_order_stateService;
  public async custom_get_sales_order_state(inParams: { orders: { orderId: number, orderStatusId?: number, orderStatus?: string, requestedDeliveryDate?: string, shipmentId?: number, shipmentStatusId?: number, expectedDate?: string, containerSize?: string, waveId?: number, waveStatus?: string, waveStatusId?: number }[] }): Promise< { states?: { orderId?: number, stateId?: number, stateName?: string, has_manual?: boolean, picked_total?: number, expected_total?: number }[], messages?: any[] }> {
    if(!this._custom_get_sales_order_state) {
      this._custom_get_sales_order_state = this.injector.get(app_custom_get_sales_order_stateService);
    }
    return this._custom_get_sales_order_state.run(inParams);
  }
   
   

   
 
  private _custom_get_serial_number_code_by_parsedValues: app_custom_get_serial_number_code_by_parsedValuesService;
  public async custom_get_serial_number_code_by_parsedValues(inParams: { parsedValues: { gtin?: string, serialNumber?: string, expirationDate?: string, lotNumber?: string, sscc?: string, varCount?: string } }): Promise< { serialNumberCode?: string, reasons?: string[] }> {
    if(!this._custom_get_serial_number_code_by_parsedValues) {
      this._custom_get_serial_number_code_by_parsedValues = this.injector.get(app_custom_get_serial_number_code_by_parsedValuesService);
    }
    return this._custom_get_serial_number_code_by_parsedValues.run(inParams);
  }
   
   

   
 
  private _custom_get_serial_number_substitution_reason_code_flow: app_custom_get_serial_number_substitution_reason_code_flowService;
  public async custom_get_serial_number_substitution_reason_code_flow(inParams: {  }): Promise< { reasonCodeId?: number, reasonCodeName?: string }> {
    if(!this._custom_get_serial_number_substitution_reason_code_flow) {
      this._custom_get_serial_number_substitution_reason_code_flow = this.injector.get(app_custom_get_serial_number_substitution_reason_code_flowService);
    }
    return this._custom_get_serial_number_substitution_reason_code_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_shipment_quantities_to_cartonize_flow: app_custom_get_shipment_quantities_to_cartonize_flowService;
  public async custom_get_shipment_quantities_to_cartonize_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[], quantities?: { orderId?: number, lineNumber?: number, materialId?: number, packagingId?: number, amount?: number }[] }> {
    if(!this._custom_get_shipment_quantities_to_cartonize_flow) {
      this._custom_get_shipment_quantities_to_cartonize_flow = this.injector.get(app_custom_get_shipment_quantities_to_cartonize_flowService);
    }
    return this._custom_get_shipment_quantities_to_cartonize_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_asn_order_auto_receivable_flow: app_custom_is_asn_order_auto_receivable_flowService;
  public async custom_is_asn_order_auto_receivable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasPlannedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_asn_order_auto_receivable_flow) {
      this._custom_is_asn_order_auto_receivable_flow = this.injector.get(app_custom_is_asn_order_auto_receivable_flowService);
    }
    return this._custom_is_asn_order_auto_receivable_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_asn_order_updatable_project_flow: app_custom_is_asn_order_updatable_project_flowService;
  public async custom_is_asn_order_updatable_project_flow(inParams: { orderId: number, shipmentId: number, targetProjectId?: number }): Promise< { reason?: string }> {
    if(!this._custom_is_asn_order_updatable_project_flow) {
      this._custom_is_asn_order_updatable_project_flow = this.injector.get(app_custom_is_asn_order_updatable_project_flowService);
    }
    return this._custom_is_asn_order_updatable_project_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_pack_verification_location_valid_flow: app_custom_is_pack_verification_location_valid_flowService;
  public async custom_is_pack_verification_location_valid_flow(inParams: { shippingContainerId: number, locationId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_pack_verification_location_valid_flow) {
      this._custom_is_pack_verification_location_valid_flow = this.injector.get(app_custom_is_pack_verification_location_valid_flowService);
    }
    return this._custom_is_pack_verification_location_valid_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_purchase_order_completable_flow: app_custom_is_purchase_order_completable_flowService;
  public async custom_is_purchase_order_completable_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_purchase_order_completable_flow) {
      this._custom_is_purchase_order_completable_flow = this.injector.get(app_custom_is_purchase_order_completable_flowService);
    }
    return this._custom_is_purchase_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_purchase_order_processable_flow: app_custom_is_purchase_order_processable_flowService;
  public async custom_is_purchase_order_processable_flow(inParams: { orderId?: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_purchase_order_processable_flow) {
      this._custom_is_purchase_order_processable_flow = this.injector.get(app_custom_is_purchase_order_processable_flowService);
    }
    return this._custom_is_purchase_order_processable_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_purchase_order_unreceivable_flow: app_custom_is_purchase_order_unreceivable_flowService;
  public async custom_is_purchase_order_unreceivable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_purchase_order_unreceivable_flow) {
      this._custom_is_purchase_order_unreceivable_flow = this.injector.get(app_custom_is_purchase_order_unreceivable_flowService);
    }
    return this._custom_is_purchase_order_unreceivable_flow.run(inParams);
  }
   
   

   
 
  private _custom_recommend_lot_creation: app_custom_recommend_lot_creationService;
  public async custom_recommend_lot_creation(inParams: { vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string, materialId?: number, recommendVendorLotOnly: boolean, optimalInputs?: { shelfLifeSpan?: number }, controllerTypeId?: number }): Promise< { lotId?: number, lotLookupCode?: string, vendorLotId?: number, vendorLotLookupCode?: string, vat?: string, errors?: string[], manufactureDate?: string, expirationDate?: string }> {
    if(!this._custom_recommend_lot_creation) {
      this._custom_recommend_lot_creation = this.injector.get(app_custom_recommend_lot_creationService);
    }
    return this._custom_recommend_lot_creation.run(inParams);
  }
   
   

   
 
  private _custom_save_shipping_container_actual_weight_flow: app_custom_save_shipping_container_actual_weight_flowService;
  public async custom_save_shipping_container_actual_weight_flow(inParams: { shippingContainerId: number, actualWeight: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_save_shipping_container_actual_weight_flow) {
      this._custom_save_shipping_container_actual_weight_flow = this.injector.get(app_custom_save_shipping_container_actual_weight_flowService);
    }
    return this._custom_save_shipping_container_actual_weight_flow.run(inParams);
  }
   
   

   
 
  private _custom_update_asn_licenseplate_flow: app_custom_update_asn_licenseplate_flowService;
  public async custom_update_asn_licenseplate_flow(inParams: { licenseplateId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_update_asn_licenseplate_flow) {
      this._custom_update_asn_licenseplate_flow = this.injector.get(app_custom_update_asn_licenseplate_flowService);
    }
    return this._custom_update_asn_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _custom_update_project_asn_order_flow: app_custom_update_project_asn_order_flowService;
  public async custom_update_project_asn_order_flow(inParams: { orderId: number, shipmentId: number, targetProjectId: number }): Promise< { reason?: string, targetOrderId?: number }> {
    if(!this._custom_update_project_asn_order_flow) {
      this._custom_update_project_asn_order_flow = this.injector.get(app_custom_update_project_asn_order_flowService);
    }
    return this._custom_update_project_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _custom_validate_barcode_flow: app_custom_validate_barcode_flowService;
  public async custom_validate_barcode_flow(inParams: { barcode: string, parsedValues: any, context: string }): Promise< { reasons?: string[] }> {
    if(!this._custom_validate_barcode_flow) {
      this._custom_validate_barcode_flow = this.injector.get(app_custom_validate_barcode_flowService);
    }
    return this._custom_validate_barcode_flow.run(inParams);
  }
   
   

   
 
  private _custom_validate_order_flow: app_custom_validate_order_flowService;
  public async custom_validate_order_flow(inParams: { orderId?: number }): Promise< { reason?: string }> {
    if(!this._custom_validate_order_flow) {
      this._custom_validate_order_flow = this.injector.get(app_custom_validate_order_flowService);
    }
    return this._custom_validate_order_flow.run(inParams);
  }
   
   

   
}
