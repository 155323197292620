import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Invoices_billingcodes_dd_multiComponent } from './Invoices.billingcodes_dd_multi.component'


interface IFootPrintManager_billing_records_gridComponentEntity {
Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, Notes?: string, ProjectId?: number, UomId?: number, BillingRecord?: { ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, InvoiceLineId?: number, Notes?: string, Total?: number, InvoiceLine?: { Id?: number, InvoiceId?: number, LineNumber?: number, Invoice?: { Id?: number, Status?: { Name?: string } } }, ActualMeasurementUnit?: { Short_name?: string } }, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string }, BillingContractLine?: { Id?: number, BillingAggregationStrategy?: { Name?: string } }}

interface IFootPrintManager_billing_records_gridComponentInParams {
  ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number}


class FootPrintManager_billing_records_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_billing_records_gridComponent;
  entity: IFootPrintManager_billing_records_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    cancelled_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    billing_task_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    billing_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    billing_strategy_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    applicable_rate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    applicable_minimum_charge: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    invoiced: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    details: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    cancelled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['cancelled_display'] as DatexFormControl, null, true, '')
,
null
      ),
    cancelled_reason_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_billing_records_gridComponent, entity: IFootPrintManager_billing_records_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_billing_records_gridComponent, entity?: IFootPrintManager_billing_records_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      materialIds:  $grid.inParams.materialIds ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      orderIds:  $grid.inParams.orderIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      includeCancelled:  $grid.inParams.includeCancelled ,
      includeInvoiced:  $grid.inParams.includeInvoiced ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
      shipmentIds:  $grid.inParams.shipmentIds ,
      workOrderId:  $grid.inParams.workOrderId ,
      billingCodeIds:  $grid.filters.billing_code.control.value ,
    };
    const data = await this.datasources.Invoices.ds_billing_records_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.billing_task_id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.billing_code.displayControl as TextModel).text = $row.entity.BillingCode?.Name;
    (this.cells.billing_strategy_name.displayControl as TextModel).text = $row.entity.BillingContractLine?.BillingAggregationStrategy?.Name;
    (this.cells.actual_amount.displayControl as TextModel).text = $row.entity.BillingRecord?.ActualAmount?.toString() ?? $row.entity.Amount?.toString();
    (this.cells.actual_uom.displayControl as TextModel).text = $row.entity.BillingRecord?.ActualMeasurementUnit?.Short_name ?? $row.entity.Uom?.Short_name;
    (this.cells.applicable_rate.displayControl as TextModel).text = $row.entity.BillingRecord?.ApplicableRate?.toString();
    (this.cells.applicable_minimum_charge.displayControl as TextModel).text = $row.entity.BillingRecord?.ApplicableMinimumCharge?.toString();
    (this.cells.total.displayControl as TextModel).text = $row.entity.BillingRecord?.Total?.toString();
    (this.cells.owner.displayControl as TextModel).text = $row.entity.Project?.Owner?.LookupCode;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project?.LookupCode;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.cancelled.displayControl as CheckBoxModel).reset($row.entity.Cancelled);
    (this.cells.cancelled_reason_code.displayControl as TextModel).text = $row.entity.CancelledReasonCode?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'billing_task_id' : {
        this.on_billing_record_clicked();
        break;
      }
      case 'invoiced' : {
        this.on_invoiced_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_billing_records_gridComponentRowModel,
  $grid: FootPrintManager_billing_records_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime) && $utils.isDefined(format)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var createdDate = $utils.date.format($row.entity.CreatedSysDateTime, format);
      $row.cells.created_date.displayControl.text = createdDate;
  }
  
  
  
  if ($utils.isDefined($row.entity.BillingRecord.InvoiceLineId)){
      var invoice = $row.entity.BillingRecord.InvoiceLine?.InvoiceId;
      var invoiceStatus = $row.entity.BillingRecord.InvoiceLine?.Invoice?.Status?.Name;
      var linenumber = $row.entity.BillingRecord.InvoiceLine?.LineNumber;
      $row.cells.invoiced.displayControl.text = `Invoice ${invoice} Line ${linenumber} Status ${invoiceStatus}`
  }
  
  
  
  const licenseplate = $utils.isDefined($row.entity.LicensePlate?.LookupCode) ? ` Licenseplate ${$row.entity.LicensePlate?.LookupCode}` : '';
  const locationName = $utils.isDefined($row.entity.Location?.Name) ? ` Location ${$row.entity.Location?.Name}` : '';
  const material = $utils.isDefined($row.entity.Material?.LookupCode) ? ` Material ${$row.entity.Material?.LookupCode}` : '';
  const packaging = $utils.isDefined($row.entity.Packaging?.ShortName) ? ` Packaging ${$row.entity.Packaging?.ShortName}` : '';
  const lot = $utils.isDefined($row.entity.Lot?.LookupCode) ? ` Lot ${$row.entity.Lot?.LookupCode}` : '';
  const order = $utils.isDefined($row.entity.Order?.LookupCode) ? ` Order ${$row.entity.Order?.LookupCode}` : '';
  const warehouse = $utils.isDefined($row.entity.Warehouse?.Name) ? `Warehouse ${$row.entity.Warehouse?.Name}` : '';
  const workorder = $utils.isDefined($row.entity.WorkOrder?.Id) ? ` Workorder ${$row.entity.WorkOrder?.Id?.toString()}` : '';
  const operationcode = $utils.isDefined($row.entity.OperationCode?.Name) ? ` Operation ${$row.entity.OperationCode?.Name}` : '';
  
  
  const details = `${warehouse}${locationName}${licenseplate}${material}${packaging}${lot}${order}${workorder}${operationcode}`
  $row.cells.details.displayControl.text = details;
  
  
  }
  on_billing_record_clicked(event = null) {
    return this.on_billing_record_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_billing_record_clickedInternal(
    $row: FootPrintManager_billing_records_gridComponentRowModel,
  $grid: FootPrintManager_billing_records_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.openbilling_record_activity_listDialog({billingTaskId: $row.entity.Id},'flyout');
  }
  on_invoiced_clicked(event = null) {
    return this.on_invoiced_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_invoiced_clickedInternal(
    $row: FootPrintManager_billing_records_gridComponentRowModel,
  $grid: FootPrintManager_billing_records_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  if ($utils.isDefined($row.entity.BillingRecord.InvoiceLine?.InvoiceId)) {
      $shell.FootPrintManager.openinvoice_editor({ invoiceId: $row.entity.BillingRecord.InvoiceLine?.InvoiceId });
  }
  
  else {
  
      throw new Error ('Unable to determine invoice.')
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_billingcodes_dd_multiComponent),
  ],
  selector: 'FootPrintManager-billing_records_grid',
  templateUrl: './FootPrintManager.billing_records_grid.component.html'
})
export class FootPrintManager_billing_records_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_billing_records_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_billing_records_gridComponentInParams = { ownerId: null, projectId: null, materialIds: [], warehouseIds: [], orderIds: [], includeCancelled: null, includeInvoiced: null, shipmentIds: [], workOrderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     billing_task_id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       billing_code: new GridHeaderModel(new HeaderStyles(null, null), 'Billing code', false, false, null),       billing_strategy_name: new GridHeaderModel(new HeaderStyles(null, null), 'Billing strategy', false, false, null),       actual_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount', false, false, null),       actual_uom: new GridHeaderModel(new HeaderStyles(null, null), 'UOM', false, false, null),       applicable_rate: new GridHeaderModel(new HeaderStyles(null, null), 'Rate', false, false, null),       applicable_minimum_charge: new GridHeaderModel(new HeaderStyles(null, null), 'Minimum charge', false, false, null),       total: new GridHeaderModel(new HeaderStyles(null, null), 'Total', false, false, null),       invoiced: new GridHeaderModel(new HeaderStyles(null, null), 'Invoiced', false, false, null),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       details: new GridHeaderModel(new HeaderStyles(null, null), 'Details', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       cancelled: new GridHeaderModel(new HeaderStyles(null, null), 'Cancelled', false, false, null),       cancelled_reason_code: new GridHeaderModel(new HeaderStyles(null, null), 'Cancelled reason code', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_billing_records_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('orderIds') set $inParams_orderIds(value: any) {
    this.inParams['orderIds'] = value;
  }
  get $inParams_orderIds(): any {
    return this.inParams['orderIds'] ;
  }
  @Input('includeCancelled') set $inParams_includeCancelled(value: any) {
    this.inParams['includeCancelled'] = value;
  }
  get $inParams_includeCancelled(): any {
    return this.inParams['includeCancelled'] ;
  }
  @Input('includeInvoiced') set $inParams_includeInvoiced(value: any) {
    this.inParams['includeInvoiced'] = value;
  }
  get $inParams_includeInvoiced(): any {
    return this.inParams['includeInvoiced'] ;
  }
  @Input('shipmentIds') set $inParams_shipmentIds(value: any) {
    this.inParams['shipmentIds'] = value;
  }
  get $inParams_shipmentIds(): any {
    return this.inParams['shipmentIds'] ;
  }
  @Input('workOrderId') set $inParams_workOrderId(value: any) {
    this.inParams['workOrderId'] = value;
  }
  get $inParams_workOrderId(): any {
    return this.inParams['workOrderId'] ;
  }

  topToolbar = {
      invoice: new ToolModel(new ButtonModel('invoice', new ButtonStyles(null, null), false, 'Create new or add to existing invoice', 'icon-ic_fluent_receipt_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_20_regular')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    created_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    billing_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    created_from: new FieldModel(new DateBoxModel(this.formGroup.controls['created_from'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Created from', false)
,
    created_to: new FieldModel(new DateBoxModel(this.formGroup.controls['created_to'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Created to', false)
,
    billing_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Billing code', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Billing records';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      materialIds:  $grid.inParams.materialIds ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      orderIds:  $grid.inParams.orderIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      includeCancelled:  $grid.inParams.includeCancelled ,
      includeInvoiced:  $grid.inParams.includeInvoiced ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
      shipmentIds:  $grid.inParams.shipmentIds ,
      workOrderId:  $grid.inParams.workOrderId ,
      billingCodeIds:  $grid.filters.billing_code.control.value ,
    };
    try {
    const data = await this.datasources.Invoices.ds_billing_records_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_billing_records_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_billing_records_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize date format
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $grid.filters.created_from.control.format = dateFormat;
  $grid.filters.created_to.control.format = dateFormat;
  
  
  // Default current date
  if ($utils.isDefined($grid.inParams.orderIds) || $utils.isDefined($grid.inParams.shipmentIds)) {
      // Do nothing
  }
  else {
      $grid.filters.created_from.control.value = $utils.date.add(-30, 'day', $utils.date.now());
      $grid.filters.created_to.control.value = $utils.date.now()
  }
  
  
  // Populate default values
  if (!$utils.isDefined($grid.inParams.includeCancelled)) {
      $grid.inParams.includeCancelled = true;
  }
  if (!$utils.isDefined($grid.inParams.includeInvoiced)) {
      $grid.inParams.includeInvoiced = true;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_billing_records_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      $grid.topToolbar.invoice.control.readOnly = false;
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.on_delete.control.readOnly = false;
  
      // Invoice
      $grid.topToolbar.invoice.control.readOnly = !(await checkAllInvoicable());
  
      // Cancellation
      $grid.topToolbar.cancel.control.readOnly = !(await checkAllCancellable());
  
      // Deletion
      $grid.topToolbar.on_delete.control.readOnly = !(await checkAllDeletable());
  
  
  }
  else {
      $grid.topToolbar.invoice.control.readOnly = true;
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  
  async function checkAllInvoicable() {
      {
          let allow = $grid.selectedRows.length > 0;
  
          for (let row of $grid.selectedRows) {
  
              if ($utils.isDefined(row.entity.BillingRecord.InvoiceLineId)) {
  
                  return false;
  
              }
          }
  
          return allow;
      }
  
  }
  async function checkAllDeletable() {{
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
      
          if ($utils.isDefined(row.entity.BillingRecord.InvoiceLineId)) {
       
                  return false;
              
          }
      }
  
      return allow;
  }
  }
  
  async function checkAllCancellable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          // Check if there is a invoice tied to the billing record
          if ($utils.isDefined(row.entity.BillingRecord.InvoiceLineId)) {       
                  return false;           
          }
          else if (row.entity.Cancelled) {
          
                  return false;          
          }
      }
  
      return allow;
  }
  
  }
  on_invoice_clicked(event = null) {
    return this.on_invoice_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_invoice_clickedInternal(
    $grid: FootPrintManager_billing_records_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Invoiced Error', 'No lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              billingTaskId: row.entity.Id
          };
          const result = await $flows.Invoices.is_billing_task_invoicable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Billing record ${row.entity.Id.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Invoice billing record errors';
          const errorMessage = `Billing record(s) ${failures.map(c => c.entity.Id.toString()).join(',')} cannot be invoiced.`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Billing record(s) ${candidates.map(c => c.entity.Id.toString()).join(',')} - Once invoiced, the billing record(s) can still be altered.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some billing record(s) cannot be invoiced';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Invoice the following billing record(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
  
              // Make sure all of the selected billing records are from the same project.
              var projectCheck = null;
              var firstProject = null;
  
              for (const candidate of candidates) {
                  projectCheck = candidate.entity.ProjectId;
                  if (firstProject == null) {
                      firstProject = projectCheck;
                  }
                  if (projectCheck != firstProject) {
                      $shell.FootPrintManager.openErrorDialog('Invoice Creation Error', 'Selected billing records are not part of the same project.');
                      return;
                  }
              }
              const dialogResult = await $shell.FootPrintManager.openinvoice_creation_options_formDialog({
                  projectId: projectCheck
              });
              var userConfirmed = dialogResult.confirm;
              var invoiceId = dialogResult.invoiceId;
              var notes = dialogResult.notes;
  
              if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
                  return;
              }
  
              // Get invoice terms
              const terms = (await $datasources.Invoices.ds_get_current_billing_contract_by_projectId.get({projectId: firstProject })).result;
              if ($utils.isDefined(terms)){
                  var termId = terms.TermId;
              }
  
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
  
                  try {
  
                      if (!$utils.isDefined(invoiceId)) {
                          
                          const flowParams = {
                              projectId: candidate.entity.ProjectId,
                              invoiceTermId: $utils.isDefined(termId) ? termId : 1
                          }
                          const result = await $flows.Invoices.create_invoice_flow(flowParams);
                          invoiceId = result.invoiceId;
                      }
  
                      const flowLinesParams = {
                          invoiceId: invoiceId,
                          billingCodeId: candidate.entity.BillingCodeId,
                          quantity: candidate.entity.BillingRecord?.ActualAmount ?? candidate.entity.Amount,
                          unitPrice: candidate.entity.BillingRecord?.ApplicableRate,
                          minimumCharge: 0,
                          notes: candidate.entity.Notes
  
                      }
                      const linesResult = await $flows.Invoices.create_invoice_line_flow(flowLinesParams);
                      const invoiceLineId = linesResult.invoiceLineId;
                      const errors = linesResult.reason;
  
                      if ($utils.isDefined(errors)) {
  
                          processFailures.push(candidate);
  
                      }
                      else if ($utils.isDefined(invoiceLineId)) {
  
                          const results = (await $flows.Invoices.update_billing_task_flow({
                              billingTaskId: candidate.entity.Id,
                              invoiceLineId: invoiceLineId
                          }));
                          const reason = results.reason;
  
                          if ($utils.isDefined(reason)) {
  
                              processFailures.push(candidate);
                          }
                          else {
                              processSuccess.push(candidate);
                          }
                      }
                      else {
                          processFailures.push(candidate);
                      }
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Billing record ${candidate.entity.Id.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All billing record(s) invoiced';
                  const message = `Billing record(s) ${processSuccess.map(c => c.entity.Id.toString()).join(',')} invoiced`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All billing record(s) failed to invoiced';
                      const message = `Billing record(s) ${processFailures.map(c => c.entity.Id.toString()).join(',')} could not be invoiced`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some billing record(s) could not be invoiced';
                      const success = `Billing record(s) ${processSuccess.map(c => c.entity.Id.toString()).join(',')} were invoiced.`;
                      const errors = `The following billing record(s) could not be invoiced: ${processFailures.map(c => c.entity.Id.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_billing_records_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Billing Record Cancel Error', 'No lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              billingTaskId: row.entity.Id
          };
          const result = await $flows.Invoices.is_billing_task_cancelable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Billing record ${row.entity.Id.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Cancel billing record errors';
          const errorMessage = `Billing record(s) ${failures.map(c => c.entity.Id.toString()).join(',')} cannot be cancelled.`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Billing record(s) ${candidates.map(c => c.entity.Id.toString()).join(',')} - Once cancelled, the billing record(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some billing record(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following billing record(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const dialogResult = await $shell.FootPrintManager.openbilling_cancellation_options_formDialog({
                  title: 'Select a reason code',
                  parentId: 1,
                  parentEntity: 'InvoiceType'
              });
              var userConfirmed = dialogResult.confirm;
              var reasonCodeId = dialogResult.reasonCodeId;
              var notes = dialogResult.notes;
  
              if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
                  return;
              }
  
              if (userConfirmed) {
  
                  const processSuccess = [];
                  const processFailures = [];
                  const errorMsgList = [];
                  const errorMsgListDetails = [];
  
                  for (const candidate of candidates) {
                      try {
                          const flowParams = {
                              billingTaskId: candidate.entity.Id,
                              cancelledReasonCodeId: reasonCodeId,
                              cancelledNotes: notes
                          }
                          const result = await $flows.Invoices.cancel_billing_task_flow(flowParams);
  
                          processSuccess.push(candidate);
                      } catch (error) {
                          processFailures.push(candidate);
                          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                          const errorDescription = `Billing record ${candidate.entity.Id.toString()} - ${errorMessage}`;
                          errorMsgList.push(errorDescription);
                          errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                      }
                  }
  
  
                  // all succeeded
                  if (processSuccess.length === candidates.length) {
                      const title = 'All billing record(s) cancelled';
                      const message = `Billing record(s) ${processSuccess.map(c => c.entity.Id.toString()).join(',')} cancelled`;
                      await $shell.FootPrintManager.openInfoDialog(title, message);
                      await $grid.refresh();
                  } else {
                      // all failures
                      if (processFailures.length === candidates.length) {
                          const title = 'All billing record(s) failed to cancel';
                          const message = `Billing record(s) ${processFailures.map(c => c.entity.Id.toString()).join(',')} could not be cancelled`;
                          await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      } else {
                          const title = 'Some billing record(s) could not be cancelled';
                          const success = `Billing record(s) ${processSuccess.map(c => c.entity.Id.toString()).join(',')} were cancelled.`;
                          const errors = `The following billing record(s) could not be cancelled: ${processFailures.map(c => c.entity.Id.toString()).join(',')}`;
                          const message = `${success} \r\n\r\n${errors}`;
                          await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                          await $grid.refresh();
                      }
                  }
              }
          }
  
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_billing_records_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Billing Record Delete Error', 'No record selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              billingTaskId: row.entity.Id
          };
          const result = await $flows.Invoices.is_billing_task_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Billing record ${row.entity.Id.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete billing record errors';
          const errorMessage = `Billing record(s) ${failures.map(c => c.entity.Id.toString()).join(',')} cannot be deleted.`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Billing record(s) ${candidates.map(c => c.entity.Id.toString()).join(',')} - Once deleted, the record(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some billing record(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following billing record(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          billingTaskId: candidate.entity.Id
                      }
                      const result = await $flows.Invoices.delete_billing_task_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Billing record ${candidate.entity.Id.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All billing record(s) deleted';
                  const message = `Billing record(s) ${processSuccess.map(c => c.entity.Id.toString()).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All billing record(s) failed to delete';
                      const message = `Billing record(s) ${processFailures.map(c => c.entity.Id.toString()).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some billing record(s) could not be deleted';
                      const success = `Line(s) ${processSuccess.map(c => c.entity.Id.toString()).join(',')} were deleted.`;
                      const errors = `The following billing record(s) could not be deleted: ${processFailures.map(c => c.entity.Id.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_export_to_file(event = null) {
    return this.on_export_to_fileInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_to_fileInternal(
    $grid: FootPrintManager_billing_records_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/01/2024
  
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  let we_have_rows: boolean = false;
  
  if ($utils.isDefined($grid.rows)) {
      we_have_rows = true;
  }
  
  if (we_have_rows) {
  
      let selected = $grid.selectedRows;
  
      const wb = $event;
  
      let export_records: any[] = [];
  
      if ($utils.isDefined(selected)) {
  
          export_records = map_records(selected.map(s => s.entity));
  
      } else {
  
          let skip: number = 0;
          let take: number = 2500;
          let current: number = 1;
  
          while (current > 0) {
  
              let current_payload = (await $datasources.Invoices.ds_billing_records_grid.getList({
                  ownerId: $grid.inParams.ownerId,
                  projectId: $grid.inParams.projectId,
                  materialIds: $grid.inParams.materialIds,
                  warehouseIds: $grid.inParams.warehouseIds,
                  orderIds: $grid.inParams.orderIds,
                  fullTextSearch: $grid.fullTextSearch,
                  includeCancelled: $grid.inParams.includeCancelled,
                  includeInvoiced: $grid.inParams.includeInvoiced,
                  createdFrom: $grid.filters.created_from.control.value,
                  createdTo: $grid.filters.created_to.control.value,
                  shipmentIds: $grid.inParams.shipmentIds,
                  workOrderId: $grid.inParams.workOrderId,
                  billingCodeIds: $grid.filters.billing_code.control.value,
                  $skip: skip,
                  $top: take
              })).result;
  
              if ($utils.isDefined(current_payload)) {
                  current = current_payload.length;
                  export_records.push(...map_records(current_payload));
  
                  if (current < take) {
                      current = 0
                  } else {
                      skip = skip + take;
                  }
              } else {
                  current = 0;
              }
          }
      }
  
      if ($utils.isDefined(export_records)) {
          const ws = $utils.excel.json_to_sheet(export_records);
          $utils.excel.book_append_sheet(wb, ws, 'BillingRecords');
      }
  }
  
  function map_records(candidate: any[]) {
      let results: any[];
  
      results = candidate.map(c => {
  
          let createdDate: string = c.CreatedSysDateTime;
  
          if ($utils.isDefined(createdDate) && $utils.isDefined(format)) {
              createdDate = $utils.date.format(createdDate, format);
          }
          const invoice = c.BillingRecord.InvoiceLine?.InvoiceId;
          const invoiceStatus = c.BillingRecord.InvoiceLine?.Invoice?.Status?.Name;
          const linenumber = c.BillingRecord.InvoiceLine?.LineNumber;
          const invoiced = invoice ? `Invoice ${invoice} Line ${linenumber} Status ${invoiceStatus}` : ``;
  
          const licenseplate = $utils.isDefined(c.LicensePlate?.LookupCode) ? ` Licenseplate ${c.LicensePlate?.LookupCode}` : '';
          const locationName = $utils.isDefined(c.Location?.Name) ? ` Location ${c.Location?.Name}` : '';
          const material = $utils.isDefined(c.Material?.LookupCode) ? ` Material ${c.Material?.LookupCode}` : '';
          const packaging = $utils.isDefined(c.Packaging?.ShortName) ? ` Packaging ${c.Packaging?.ShortName}` : '';
          const lot = $utils.isDefined(c.Lot?.LookupCode) ? ` Lot ${c.Lot?.LookupCode}` : '';
          const order = $utils.isDefined(c.Order?.LookupCode) ? ` Order ${c.Order?.LookupCode}` : '';
          const warehouse = $utils.isDefined(c.Warehouse?.Name) ? `Warehouse ${c.Warehouse?.Name}` : '';
          const workorder = $utils.isDefined(c.WorkOrder?.Id) ? ` Workorder ${c.WorkOrder?.Id?.toString()}` : '';
          const operationcode = $utils.isDefined(c.OperationCode?.Name) ? ` Operation ${c.OperationCode?.Name}` : '';
  
          const details = `${warehouse}${locationName}${licenseplate}${material}${packaging}${lot}${order}${workorder}${operationcode}`
  
          return {
              "Id": c.Id,
              "Created Date Time": createdDate,
              "Billing Code": c.BillingCode?.Name,
              "Billing Strategy": c.BillingContractLine?.BillingAggregationStrategy?.Name,
              "Amount": c.Amount,
              "Uom": c.Packaging?.ShortName,
              "Rate": c.BillingRecord?.ApplicableRate,
              "Minimum Charge": c.BillingRecord?.ApplicableMinimumCharge,
              "Total": c.BillingRecord?.Total,
              "Invoiced": invoiced,
              "Owner": c.Project?.Owner?.LookupCode,
              "Project": c.Project?.LookupCode,
              "Details": details,
              "Notes": c.Notes,
              "Cancelled": c.Cancelled,
              "Cancelled Reason Code": c.CancelledReasonCode?.LookupCode,
          }
      })
      return results;
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_to_file(wb);

    writeExcelFile(wb, 'FootPrintManager_billing_records_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
