import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
  ],
  selector: 'FootPrintManager-lot_creation_form_with_material',
  templateUrl: './FootPrintManager.lot_creation_form_with_material.component.html'
})
export class FootPrintManager_lot_creation_form_with_materialComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId?: number, ownerId?: number, projectId?: number, vendorLotId?: number } = { materialId: null, ownerId: null, projectId: null, vendorLotId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('vendorLotId') set $inParams_vendorLotId(v: number) {
    this.inParams.vendorLotId = v;
  }
  get $inParams_vendorLotId(): number {
    return this.inParams.vendorLotId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { lotId?: number, confirm?: boolean } = { lotId: null, confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number, shelfLife?: number, isManufactureManuallySet?: boolean, isExpirationManuallySet?: boolean, ownerId?: number, projectId?: number, materialId?: number, lot?: string, vendorLot?: string, vendorLotId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ lotId?: number, confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    create_new_vendorlot: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendor_lot_lookup: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    manufacture_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    expiration_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vat: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      confirm_and_new: new ToolModel(new ButtonModel('confirm_and_new', new ButtonStyles(['tertiary'], null), false, 'Confirm and new', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Material', true)
,
    lot: new FieldModel(new TextBoxModel(this.formGroup.controls['lot'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lot', true)
,
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Description', false)
,
    create_new_vendorlot: new FieldModel(new CheckBoxModel(this.formGroup.controls['create_new_vendorlot'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'New vendor lot', false)
,
    vendor_lot_lookup: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_lot_lookup'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vendor lot', false)
,
    vendor_lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendor_lot'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Vendor lot', true)
,
    manufacture_date: new FieldModel(new DateBoxModel(this.formGroup.controls['manufacture_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Manufacture date', true)
,
    expiration_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expiration_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Expiration date', true)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    vat: new FieldModel(new TextBoxModel(this.formGroup.controls['vat'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vat', false)
,
  };

  fieldsets = {
  material: new FieldsetModel('Material information', false, true, true),
  lot: new FieldsetModel('Lot information', false, false, true),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_material_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    create_new_vendorlot: this.fields.create_new_vendorlot.control.valueChanges
    ,
    vendor_lot_lookup: this.fields.vendor_lot_lookup.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    manufacture_date: this.fields.manufacture_date.control.valueChanges
    ,
    expiration_date: this.fields.expiration_date.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    vat: this.fields.vat.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create Lot With Material';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.owner.control as SelectBoxModel).reset($form.inParams.ownerId);
    (this.fields.project.control as SelectBoxModel).reset($form.inParams.projectId);
    (this.fields.material.control as SelectBoxModel).reset($form.inParams.materialId);
    
    
    (this.fields.create_new_vendorlot.control as CheckBoxModel).reset(true);
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .create_new_vendorlot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_create_new_vendorlot_clicked();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot_lookup
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_lookup_change();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
    this.$formGroupFieldValidationObservables
      .manufacture_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_manufacture_date_changed();
      });
    this.$formGroupFieldValidationObservables
      .expiration_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_expiration_date_changed();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vat
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.lotId = null;
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
  
      await $form.confirm_flow();
      $form.close();
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Lot creation - ${errorMessage}`;
      await $shell.Lots.openErrorDialog('Create lot error', 'An error occurred during the creation of the lot', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      $form.toolbar.confirm.control.readOnly = false;
      $form.toolbar.confirm_and_new.control.readOnly = false;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  if ($utils.isDefined($form.inParams.ownerId)) {
      $form.vars.ownerId = $form.inParams.ownerId;
  };
  if ($utils.isDefined($form.inParams.projectId)) {
      $form.vars.projectId = $form.inParams.projectId;
  };
  if ($utils.isDefined($form.inParams.materialId)) {
      $form.vars.materialId = $form.inParams.materialId;
      $form.on_material_change();
  };
  if ($utils.isDefined($form.inParams.vendorLotId)) {
      $form.vars.vendorLotId = $form.inParams.vendorLotId
      $form.fields.vendor_lot.control.value = $form.inParams.vendorLotId;
      $form.fields.create_new_vendorlot.control.value = false;    
  };
  
  $form.on_create_new_vendorlot_clicked();
  $form.check_required_fields();
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/15/2023
  
  $form.vars.vendorLotId = null;
  const vendorLotId = $form.fields.vendor_lot.control.value;
  
  if ($utils.isDefined(vendorLotId)) {
  
      $form.vars.vendorLotId = vendorLotId;
      let vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({ vendorLotId: vendorLotId })).result;
  
      // Set form fields for selected vendor lot
      $form.fields.manufacture_date.control.value = vendorLot.ManufactureDate;
      $form.fields.expiration_date.control.value = vendorLot.ExpirationDate;
      $form.fields.vat.control.value = vendorLot.Vat;
      $form.fields.notes.control.value = vendorLot.Notes;
      $form.fields.description.control.value = vendorLot.Description;
  
      // Lock Down Fields
      $form.fields.manufacture_date.control.readOnly = true;
      $form.fields.expiration_date.control.readOnly = true;
      $form.fields.vat.control.readOnly = true;
      $form.fields.notes.control.readOnly = true;
      $form.fields.description.control.readOnly = true;
  }
  else {
      $form.fields.vat.control.value = null;
      $form.fields.manufacture_date.control.value = null;
      $form.fields.expiration_date.control.value = null;
      $form.fields.description.control.value = null;
      $form.fields.notes.control.value = null;
  
      // Unlock Fields
      $form.fields.manufacture_date.control.readOnly = false;
      $form.fields.expiration_date.control.readOnly = false;
      $form.fields.vat.control.readOnly = false;
      $form.fields.notes.control.readOnly = false;
      $form.fields.description.control.readOnly = false;
  }
  
  $form.check_required_fields();
  }
  on_expiration_date_changed(event = null) {
    return this.on_expiration_date_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiration_date_changedInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //ExpirationDate Changed
  if ($form.fields.expiration_date.control.isChanged) {
      
      $form.vars.isExpirationManuallySet = true;
  
      //Auto set ManufactureDate to ExpirationDate - Material.ShelfLife (if Manufacture date has not manually been set)
  
      if (!$form.vars.isManufactureManuallySet) {
  
          let newDate = $utils.date.subtract($form.vars.shelfLife, 'day', $form.fields.expiration_date.control.value);
          
          $form.fields.manufacture_date.control.value = $utils.date.format(newDate);
      };
  };
  
  $form.check_required_fields();
  }
  on_manufacture_date_changed(event = null) {
    return this.on_manufacture_date_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manufacture_date_changedInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // ManufactureDate Changed
  if ($form.fields.manufacture_date.control.isChanged) {
      $form.vars.isManufactureManuallySet = true;
  
      // Auto set ExpirationDate to ManufactureDate + Material.ShelfLife (if Expiration date has not manually been set)
      if (!$form.vars.isExpirationManuallySet) {
          let newDate = $utils.date.add($form.vars.shelfLife, 'day', $form.fields.manufacture_date.control.value);
  
          $form.fields.expiration_date.control.value = $utils.date.format(newDate);
      };
  };
  
  $form.check_required_fields();
  }
  on_create_new_vendorlot_clicked(event = null) {
    return this.on_create_new_vendorlot_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_new_vendorlot_clickedInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let create_new_vendorlot = true;
  
  if ($utils.isDefined($form.fields.create_new_vendorlot.control.value)) {
      create_new_vendorlot = $form.fields.create_new_vendorlot.control.value
  };
  
  if (create_new_vendorlot) {
  
      $form.fields.vendor_lot_lookup.hidden = false;
      $form.fields.vendor_lot_lookup.control.readOnly = false;
      $form.fields.vendor_lot.hidden = true;
      $form.fields.vendor_lot.control.readOnly = true;
  
      $form.fields.manufacture_date.control.readOnly = false;
      $form.fields.expiration_date.control.readOnly = false;
      $form.fields.vat.control.readOnly = false;
  
  } else {
  
      $form.fields.vendor_lot_lookup.hidden = true;
      $form.fields.vendor_lot.control.readOnly = true;
      $form.fields.vendor_lot.hidden = false;
      $form.fields.vendor_lot.control.readOnly = false;
  
      $form.fields.manufacture_date.control.readOnly = true;
      $form.fields.expiration_date.control.readOnly = true;
      $form.fields.vat.control.readOnly = true;
  
  };
  
  $form.check_required_fields();
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/15/2023
  
  $form.vars.ownerId = null;
  $form.vars.projectId = null;
  $form.vars.materialId = null;
  $form.vars.vendorLotId = null;
  $form.fields.project.control.value = null;
  $form.fields.material.control.value = null;
  
  let ownerId = $form.fields.owner.control.value;
  
  if ($utils.isDefined(ownerId)) {
      if (ownerId > 0) {
          $form.vars.ownerId = ownerId;
      };
  };
  
  $form.check_required_fields();
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/15/2023
  
  $form.vars.projectId = null;
  $form.vars.materialId = null;
  $form.vars.vendorLotId = null;
  const projectId = $form.fields.project.control.value
  
  // Set Owner from Project
  if ($utils.isDefined(projectId)) {
  
      if (projectId > 0) {
  
          $form.vars.projectId = projectId;
  
          if (!$utils.isDefined($form.fields.owner.control.value)) {
              const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
                  projectId: projectId
              })).result;
  
              if ($utils.isDefined(project)) {
                  $form.fields.owner.control.value = project[0].OwnerId;
                  $form.vars.ownerId = project[0].OwnerId;
              };
          };
      };
  };
  
  if (!$utils.isDefined(projectId)) {
      $form.fields.material.control.value = null;
  };
  
  $form.check_required_fields();
  
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/01/2023
  
  $form.vars.materialId = null;
  $form.vars.vendorLotId = null;
  const materialId = $form.fields.material.control.value;
  
  if ($utils.isDefined(materialId)) {
      if (materialId > 0) {
  
          $form.vars.materialId = materialId;
  
          let material = (await $datasources.Lots.ds_get_material_by_materialId.get({ materialId: materialId })).result;
          let controllerTypeId = material?.ControllerTypeId;
          let lot_controlled = true;
          let materialLookupCode = material?.LookupCode;
  
          //Check if material is GLOT
          if ($utils.isDefined(controllerTypeId)) {
              if (controllerTypeId === 1 || controllerTypeId === 3) {
                  lot_controlled = false;
              };
          };
  
          let shelfLife = 0;
  
          if ($utils.isDefined(material)) {
              if ($utils.isDefined(material.ShelfLifeSpan)) {
                  shelfLife = material.ShelfLifeSpan;
              };
          };
  
          $form.vars.shelfLife = shelfLife;
  
          // If the input lot lookupcode is not provided default to the next Id
          let result = (await $flows.Lots.recommend_lot_creation({
              lotLookupCode: $form.vars.lot,
              vendorLotId: $form.vars.vendorLotId,
              vendorLotLookupCode: $form.vars.vendorLot,
              recommendVendorLotOnly: false,
              materialId: materialId,
              controllerTypeId: controllerTypeId,
              optimalInputs: {
                  shelfLifeSpan: shelfLife
              }
          }));
  
          if ($utils.isDefined(result.lotId)) {
              $form.vars.nextId = result.lotId;
          } else {
              $form.vars.nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Lot' })).nextId;
          };
  
          if (lot_controlled) {
  
              $form.fields.lot.control.value = result.lotLookupCode ?? $form.vars.nextId.toString();
              $form.fields.vendor_lot.control.value = null;
              $form.fields.vendor_lot.control.value = result.vendorLotId;
              $form.fields.vendor_lot_lookup.control.value = result.vendorLotLookupCode ?? $form.fields.lot.control.value;
              $form.fields.create_new_vendorlot.control.readOnly = false;
  
              // Set field values
              if ($utils.isDefined($form.fields.vendor_lot.control.value)) {
  
                  // Populate Values
                  let vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({ vendorLotId: $form.fields.vendor_lot.control.value })).result;
  
                  $form.fields.vat.control.value = result.vat;
                  $form.fields.description.control.value = vendorLot?.Description;
                  $form.fields.notes.control.value = vendorLot?.Notes;
                  $form.fields.create_new_vendorlot.control.value = false;
  
              }
              else {
                  $form.fields.create_new_vendorlot.control.value = true;
              }
  
          } else {
  
              //GLOT
              $form.fields.lot.control.value = `${materialLookupCode}-Glot`
              $form.fields.vendor_lot.control.value = result.vendorLotId;
              $form.fields.vendor_lot_lookup.control.value = `${materialLookupCode}-Glot`
              $form.fields.create_new_vendorlot.control.readOnly = true;
  
              if ($utils.isDefined(result.vendorLotId)) {
                  $form.fields.create_new_vendorlot.control.value = false;
              } else {
                  $form.fields.create_new_vendorlot.control.value = true;
              };
  
          };
  
          $form.fields.manufacture_date.control.value = $utils.isDefined(result.manufactureDate) ? $utils.date.format(result.manufactureDate) : $utils.date.now();
          $form.fields.expiration_date.control.value = $utils.isDefined(result.expirationDate) ? $utils.date.format(result.expirationDate) : $utils.date.add(shelfLife, 'day', $utils.date.now());
          $form.on_create_new_vendorlot_clicked();
  
      };
  };
  
  $form.check_required_fields();
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/15/2023
  
  $form.vars.lot = null;
  const lot = $form.fields.lot.control.value;
  if ($utils.isDefined(lot)) {
      $form.vars.lot = lot;
  };
  
  $form.check_required_fields();
  
  }
  on_vendorlot_lookup_change(event = null) {
    return this.on_vendorlot_lookup_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_lookup_changeInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/15/2023
  
  $form.vars.vendorLot = null;
  const vendorLot = $form.fields.vendor_lot_lookup.control.value;
  if ($utils.isDefined(vendorLot)) {
      $form.vars.vendorLot = vendorLot;
  };
  
  $form.check_required_fields();
  
  }
  on_confirm_and_new(event = null) {
    return this.on_confirm_and_newInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_and_newInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
  
      $form.confirm_flow();
      await $shell.FootPrintManager.openlot_creation_form_with_materialDialog({ ownerId: $form.vars.ownerId, projectId: $form.vars.projectId, materialId: $form.vars.materialId, vendorLotId: $form.vars.vendorLotId });
      $form.close();
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Lot creation - ${errorMessage}`;
      await $shell.Lots.openErrorDialog('Create lot error', 'An error occurred during the creation of the lot', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      $form.toolbar.confirm.control.readOnly = false;
      $form.toolbar.confirm_and_new.control.readOnly = false;
  }
  }
  confirm_flow(event = null) {
    return this.confirm_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async confirm_flowInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  $form.toolbar.confirm_and_new.control.readOnly = true;
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.lot.control.value,
      $form.fields.manufacture_date.control.value,
      $form.fields.expiration_date.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = false;
     // $shell.FootPrintManager.openErrorDialog('Lot Creation Error', 'Please enter data in all required fields.');
      //return;
       throw new Error('Please enter data in all required fields!');
  }
  if(allRequiredFieldHaveValue && ($utils.date.startOf("day",$form.fields.expiration_date.control.value) < $utils.date.startOf("day",$form.fields.manufacture_date.control.value)))
  {
      //Throw error
      $form.toolbar.confirm.control.readOnly = false;
     //$shell.FootPrintManager.openErrorDialog('Lot Expiration Date Error', 'Manufacture date cannot be after expiration date!');
     //return;
     throw new Error('Manufacture date cannot be after expiration date!');
     
  }
  
  const materialId = $form.vars.materialId;
  
  if (!$utils.isDefinedTrimmed($form.fields.lot.control.value)) {
      $form.toolbar.confirm.control.readOnly = false;
      let message = 'Please enter a valid value for the lot lookup code.';
  
      throw new Error(message);
  }
  
  // Default vendor lot field to lot field if null
  if ($utils.isDefined($form.fields.vendor_lot.control.value)) {
      if (!$utils.isDefinedTrimmed($form.fields.vendor_lot.control.value)) {
  
          $form.toolbar.confirm.control.readOnly = false;
          let message = 'Please enter a valid value for the vendor lot lookup code.';
  
          throw new Error(message);
      }
  
      var vendorLotId = $form.fields.vendor_lot.control.value;
      var vendorLotLookup = $form.fields.vendor_lot.control.displayText;
  }
  else {
  
      if ($utils.isDefined($form.fields.vendor_lot_lookup.control.value)) {
          vendorLotLookup = $form.fields.vendor_lot_lookup.control.value;
      }
      else {
          vendorLotLookup = $form.fields.lot.control.value;
      }
  }
  
  // Check if lot exists
  const Lot = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
      materialId: materialId,
      vendorLookupcode: vendorLotLookup,
      lotLookupcode: $form.fields.lot.control.value
  })).result;
  if ($utils.isDefined(Lot)) {
      $form.toolbar.confirm.control.readOnly = false;
      throw new Error('Lot already exists for selected material!');
  }
  
  // Check if vendor lot exists
  const vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
      materialId: materialId,
      vendorLookupcode: vendorLotLookup
  })).result;
  if ($utils.isDefined(vendorLot)) {
      var foundVendorLotId = vendorLot[0].Id
      vendorLotId = foundVendorLotId
  }
  
  else {
  
      vendorLotId = (await $flows.Lots.create_vendor_lot({
          materialId: materialId,
          lookupCode: vendorLotLookup,
          manufactureDate: $form.fields.manufacture_date.control.value,
          expirationDate: $form.fields.expiration_date.control.value
      })).vendorLotId;
  
  
      // Update newly created vendor lot with vat value
      if ($utils.isDefined(vendorLotId)) {
          // Update other vendorlot properties
          let payload: any = {};
  
          // VAT
          if ($utils.isDefined($form.fields.vat.control.value)) {
              payload.Vat = $form.fields.vat.control.value;
          }
  
          // Notes
          if ($utils.isDefined($form.fields.notes.control.value)) {
              payload.Notes = $form.fields.notes.control.value;
          }
  
          // Description
          if ($utils.isDefined($form.fields.description.control.value)) {
              payload.Description = $form.fields.description.control.value;
          }
  
          if (Object.keys(payload).length > 0) {
              try {
                  await $flows.Utilities.crud_update_flow({ entitySet: 'VendorLots', id: vendorLotId, entity: payload });
              }
              catch (error) {
                  $shell.Lots.showErrorDetails('Save', 'Error on updating newly created vendor lot.', error);
                  throw error; // to prevent displayMode 
              }
          }
      }
  }
  var nextLotId = (await $flows.Lots.create_lot({
      lookupCode: $form.fields.lot.control.value,
      vendorLotId: vendorLotId,
      statusId: 1
  })).lotId;
  
  
  $form.outParams.lotId = nextLotId;
  $form.outParams.confirm = true;
  }
  check_required_fields(event = null) {
    return this.check_required_fieldsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async check_required_fieldsInternal(
    $form: FootPrintManager_lot_creation_form_with_materialComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.lot.control.value,
      $form.fields.manufacture_date.control.value,
      $form.fields.expiration_date.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = true;
      $form.toolbar.confirm_and_new.control.readOnly = true;;
  } else {
      $form.toolbar.confirm.control.readOnly = false;
      $form.toolbar.confirm_and_new.control.readOnly = false;
  };
  }
  //#endregion private flows
}
