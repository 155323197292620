import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 



interface IFootPrintManager_billing_contract_lines_gridComponentEntity {
Id?: number, BasePackageConversion?: boolean, BillingAggregationStrategyId?: number, BillingContractId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomBillingWorkflowId?: string, EndDate?: string, LineNumber?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ResultingBillingCodeId?: number, ResultingUomId?: number, StartDate?: string, WarehouseId?: number, BillingAggregationStrategy?: { Name?: string }, ResultingUom?: { Name?: string }, ResultingBillingCode?: { Name?: string }, Warehouse?: { Name?: string }, BillingRates?: { Rate?: number }[], Details?: { Value?: string }[], SupplementalFees?: { Id?: number }[], BillingProjects?: { Id?: number, Project?: { Name?: string } }[]}

interface IFootPrintManager_billing_contract_lines_gridComponentInParams {
  contractId: number, strategyName?: string, edit?: boolean, hide?: string[], statusId?: number}

interface IFootPrintManager_billing_contract_lines_gridComponentOutParams {
  updated?: boolean}

class FootPrintManager_billing_contract_lines_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_billing_contract_lines_gridComponent;
  entity: IFootPrintManager_billing_contract_lines_gridComponentEntity;

  vars: { operationCodeId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    LineNumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    BillingAggregationStrategy: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    BillingObject: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    BillingMethod: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ResultingBillingCode_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    OperationCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Rate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0.0000')
,
null
      ),
    Warehouse_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Priority: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ResultingUom_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    StartDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    EndDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    CreatedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    CreatedSysUser: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ModifiedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ModifiedSysUser: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_billing_contract_lines_gridComponent, entity: IFootPrintManager_billing_contract_lines_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_billing_contract_lines_gridComponent, entity?: IFootPrintManager_billing_contract_lines_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      contractId:  $grid.inParams.contractId ,
      strategyName:  $grid.inParams.strategyName ,
    };
    const data = await this.datasources.Invoices.ds_billing_contract_lines_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.LineNumber.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.BillingAggregationStrategy.displayControl as TextModel).text = $row.entity.BillingAggregationStrategy?.Name.toString();
    (this.cells.ResultingBillingCode_Name.displayControl as TextModel).text = $row.entity.ResultingBillingCode?.Name;
    (this.cells.OperationCode.displayControl as TextModel).text = $row.entity.Details[0]?.Value;
    (this.cells.Rate.displayControl as TextModel).text = $row.entity.BillingRates[0]?.Rate.toString();
    (this.cells.Warehouse_Name.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.Priority.displayControl as TextModel).text = $row.entity.Priority?.toString();
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.ResultingUom_Name.displayControl as TextModel).text = $row.entity.ResultingUom?.Name;
    (this.cells.StartDate.displayControl as TextModel).text = $row.entity.StartDate?.toString();
    (this.cells.EndDate.displayControl as TextModel).text = $row.entity.EndDate?.toString();
    (this.cells.CreatedSysUser.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.ModifiedSysUser.displayControl as TextModel).text = $row.entity.ModifiedSysUser;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Id' : {
        this.on_id_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_billing_contract_lines_gridComponentRowModel,
  $grid: FootPrintManager_billing_contract_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
          $row.cells.CreatedSysDateTime.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
      };
  
      if ($utils.isDefined($row.entity.ModifiedSysDateTime)) {
          $row.cells.ModifiedSysDateTime.displayControl.text = $utils.date.format($row.entity.ModifiedSysDateTime, format);
      };
  
      if ($utils.isDefined($row.entity.StartDate)) {
          $row.cells.StartDate.displayControl.text = $utils.date.format($row.entity.StartDate, format);
      };
  
      if ($utils.isDefined($row.entity.EndDate)) {
          $row.cells.EndDate.displayControl.text = $utils.date.format($row.entity.EndDate, format);
      };
  
  };
  }
  on_id_clicked(event = null) {
    return this.on_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_id_clickedInternal(
    $row: FootPrintManager_billing_contract_lines_gridComponentRowModel,
  $grid: FootPrintManager_billing_contract_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/02/2024
  
  let under_development = true;
  let billing_aggregation_strategy_name = $row.entity.BillingAggregationStrategy.Name;
  
  /* On line id clicked */
  if (billing_aggregation_strategy_name === 'Accessorial Charges' ||
      billing_aggregation_strategy_name === 'Accessorial Charge Template' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Unit' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Shipment' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Weight' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Volume' ||
      billing_aggregation_strategy_name === 'Inbound Handling by License Plate' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Unit' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Shipment' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Weight' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Volume' ||
      billing_aggregation_strategy_name === 'Inbound Storage by License Plate' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Unit' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Shipment' ||
      billing_aggregation_strategy_name === 'Outbound Handling By Shipment' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Weight' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Volume' ||
      billing_aggregation_strategy_name === 'Outbound Handling by License Plate' ||
      billing_aggregation_strategy_name === 'Outbound Shipment by Carrier Service Type' ||
      billing_aggregation_strategy_name === 'Initial Storage with Grace Period' ||
      billing_aggregation_strategy_name === 'Retainer' ||
      billing_aggregation_strategy_name === 'Recurring Storage') {
      under_development = false;
  
      let response = (await $shell.FootPrintManager.openbilling_contract_line_editorDialog({
          billingContractLineId: $row.entity.Id,
          edit: $grid.inParams.edit,
          operationCodeId: $row.vars.operationCodeId,
          billingAggregationStrategy: billing_aggregation_strategy_name,
          statusId: $grid.inParams.statusId
      }));
      $grid.outParams.updated = response.updated ?? false;
      $grid.events.line_update.emit({ line_updated: $grid.outParams.updated });
  };
  
  if (under_development) {
  
      await $shell.FootPrintManager.openErrorDialog('Under development.', `The ${$row.entity.BillingAggregationStrategy?.Name} line editor is under development.`);
  
  } else if ($grid.inParams.edit) {
      $row.refresh();
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-billing_contract_lines_grid',
  templateUrl: './FootPrintManager.billing_contract_lines_grid.component.html'
})
export class FootPrintManager_billing_contract_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_billing_contract_lines_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_billing_contract_lines_gridComponentInParams = { contractId: null, strategyName: null, edit: null, hide: [], statusId: null };

  outParams: IFootPrintManager_billing_contract_lines_gridComponentOutParams = { updated: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  line_update = new EventEmitter<{ line_updated?: boolean }>();
  @Output()
  outParamsChange = new EventEmitter<{ updated?: boolean }>();
  
  events = {
    line_update: this.line_update,
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     Id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       LineNumber: new GridHeaderModel(new HeaderStyles(null, null), 'Line number', false, false, null),       BillingAggregationStrategy: new GridHeaderModel(new HeaderStyles(null, null), 'Aggregation strategy', false, false, null),       BillingObject: new GridHeaderModel(new HeaderStyles(null, null), 'Billing object', false, false, null),       BillingMethod: new GridHeaderModel(new HeaderStyles(null, null), 'Billing method', false, false, null),       ResultingBillingCode_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Billing code', false, false, null),       OperationCode: new GridHeaderModel(new HeaderStyles(null, null), 'Operation code', false, false, null),       Rate: new GridHeaderModel(new HeaderStyles(null, null), 'Rate', false, false, null),       Warehouse_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       Priority: new GridHeaderModel(new HeaderStyles(null, null), 'Priority', false, false, null),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       ResultingUom_Name: new GridHeaderModel(new HeaderStyles(null, null), 'UOM', false, false, null),       StartDate: new GridHeaderModel(new HeaderStyles(null, null), 'Start date', false, false, null),       EndDate: new GridHeaderModel(new HeaderStyles(null, null), 'End date', false, false, null),       CreatedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Created date', false, false, null),       CreatedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       ModifiedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Modified date', false, false, null),       ModifiedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_billing_contract_lines_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('contractId') set $inParams_contractId(value: any) {
    this.inParams['contractId'] = value;
  }
  get $inParams_contractId(): any {
    return this.inParams['contractId'] ;
  }
  @Input('strategyName') set $inParams_strategyName(value: any) {
    this.inParams['strategyName'] = value;
  }
  get $inParams_strategyName(): any {
    return this.inParams['strategyName'] ;
  }
  @Input('edit') set $inParams_edit(value: any) {
    this.inParams['edit'] = value;
  }
  get $inParams_edit(): any {
    return this.inParams['edit'] ;
  }
  @Input('hide') set $inParams_hide(value: any) {
    this.inParams['hide'] = value;
  }
  get $inParams_hide(): any {
    return this.inParams['hide'] ;
  }
  @Input('statusId') set $inParams_statusId(value: any) {
    this.inParams['statusId'] = value;
  }
  get $inParams_statusId(): any {
    return this.inParams['statusId'] ;
  }

  topToolbar = {
      new_line: new ToolModel(new ButtonModel('new_line', new ButtonStyles(null, null), false, 'New', 'ms-Icon ms-Icon--CalculatorAddition')
    ),
      delete_line: new ToolModel(new ButtonModel('delete_line', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Billing contract lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.contractId)) {
        this.$missingRequiredInParams.push('contractId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      contractId:  $grid.inParams.contractId ,
      strategyName:  $grid.inParams.strategyName ,
    };
    try {
    const data = await this.datasources.Invoices.ds_billing_contract_lines_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_billing_contract_lines_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_select();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_billing_contract_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/04/2023
  
  const lines = $grid.selectedRows;
  
  if (lines.length === 0) {
  
      $shell.FootPrintManager.openErrorDialog('Delete contract line(s) errors', 'No line(s) were selected.');
      return;
  
  } else {
  
      const confirm = (await $shell.FootPrintManager.openConfirmationDialog(`Confirm deletion`, `You are deleting ${lines.length} line${lines.length > 1 ? 's' : ''}!`, `Delete`, `Cancel`));
  
      if (confirm) {
  
          const delete_success = [];
          const delete_failures = [];
          const errorMsgList = [];
          const errorMsgListDetails = [];
  
          for (let line of lines) {
  
              try {
  
                  let delete_results = (await $flows.Invoices.delete_billing_contract_line_flow({ billingContractLineId: line.entity.Id }))
  
                  if ($utils.isDefined(delete_results.result)) {
                      throw delete_results.result;
                  };
  
                  delete_success.push(line);
  
              } catch (error) {
  
                  delete_failures.push(line);
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Contract line ${line.entity.Id.toString()} - ${errorMessage}`;
                  errorMsgList.push(errorDescription);
                  errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
              }
          };
  
          // all succeeded
          if (delete_success.length === lines.length) {
  
              const title = 'All contract line(s) deleted';
              const message = `Contract lines(s) ${delete_success.map(c => c.entity.Id.toString()).join(',')} deleted`;
              await $shell.FootPrintManager.openInfoDialog(title, message);
              await $grid.refresh();
  
          } else {
  
              // all failures
              if (delete_failures.length === lines.length) {
  
                  const title = 'All contract line(s) failed to delete';
                  const message = `Contract line(s) ${delete_failures.map(c => c.entity.Id.toString()).join(',')} could not be deleted`;
                  await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
              } else {
  
                  const title = 'Some contract lines could not be deleted';
                  const success = `Contract line(s) ${delete_success.map(c => c.entity.Id.toString()).join(',')} were deleted.`;
                  const errors = `The following contract line(s) could not be deleted: ${delete_failures.map(c => c.entity.Id.toString()).join(',')}`;
                  const message = `${success} \r\n\r\n${errors}`;
                  await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  await $grid.refresh();
  
              }
          };
  
      } else {
          $grid.refresh();
      };
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_billing_contract_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/28/2024
  
  $grid.topToolbar.delete_line.control.readOnly = true;
  
  if ($grid.inParams.edit && $grid.inParams.statusId === 2) {
      $grid.topToolbar.new_line.control.readOnly = false;
  } else {
      $grid.topToolbar.new_line.control.readOnly = true;
  };
  
  
  if ($utils.isDefined($grid.inParams.hide)) {
  
      if ($grid.inParams.hide.indexOf('OperationCode') >= 0) {
          $grid.headers.OperationCode.hidden = true;
      };
      if ($grid.inParams.hide.indexOf('Priority') >= 0) {
          $grid.headers.Priority.hidden = true;
      };
  };
  
  //Start hidden
  $grid.headers.BillingMethod.hidden = true;
  $grid.headers.BillingObject.hidden = true;
  
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootPrintManager_billing_contract_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/24/2024
  
  //If we get the edit parameter
  if ($grid.inParams.edit && $grid.inParams.statusId === 2) {
      if ($grid.selectedRows.length === 0) {
          $grid.topToolbar.delete_line.control.readOnly = true;
          //$grid.topToolbar.delete_line.hidden = true;
      } else {
          $grid.topToolbar.delete_line.control.readOnly = false;
          //$grid.topToolbar.delete_line.hidden = false;
      };
  } else {
      $grid.topToolbar.delete_line.control.readOnly = true;
      //$grid.topToolbar.delete_line.hidden = true;
  };
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_billing_contract_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/28/2024
  
  //Start as read only
  $grid.topToolbar.delete_line.control.readOnly = true;
  
  if ($grid.inParams.edit && $grid.inParams.statusId === 2) {
      $grid.topToolbar.new_line.control.readOnly = false;
  } else {
      $grid.topToolbar.new_line.control.readOnly = true;
  };
  
  let billing_aggregation_strategy_name = $utils.isDefined($grid.inParams.strategyName) ? $grid.inParams.strategyName : '';
  
  if ($utils.isDefined($grid.rows)) {
      if ($grid.rows.length > 0) {
  
          let line_ids = $grid.rows.map(r => r.entity.Id)
          let details = (await $datasources.Invoices.ds_billing_contract_line_details_validation.get({ contractId: $grid.inParams.contractId, lineIds: line_ids })).result;
          let billing_methods: any;
          let recurring_billing_objects: any;
          let accessorial_templates: any;
  
          let static_index = -1;
          let retainer_rate_index = -1;
          let billing_method_index = -1;
          let recurring_storage_object_index = -1;
          let billing_object_index = -1;
          let weight_method_index = -1;
          let volume_method_index = -1;
          let accessorial_template_index = -1;
  
          if ($utils.isDefined(details)) {
  
              let details_array = details.map(d => d.StrategyFieldName);
  
              static_index = details_array.indexOf('StaticQuantity');
              retainer_rate_index = details_array.indexOf('RetainerRate');
              billing_method_index = details_array.indexOf('BillingMethodId');
              recurring_storage_object_index = details_array.indexOf('RecurringStorageBillingObjectId');
              billing_object_index = details_array.indexOf('BillingObjectId');
              weight_method_index = details_array.indexOf('WeightMethodId');
              volume_method_index = details_array.indexOf('VolumeMethodId');
              accessorial_template_index = details_array.indexOf('BillingAccessorialChargesTemplateId');
  
              if (billing_method_index >= 0 ||
                  weight_method_index >= 0 ||
                  volume_method_index >= 0) {
                  if (billing_method_index >= 0) {
                      billing_methods = (await $datasources.Invoices.ds_billing_methods_dd.get({})).result;
                  };
                  $grid.headers.BillingMethod.hidden = false;
              };
  
              if (recurring_storage_object_index >= 0 ||
                  billing_object_index >= 0) {
                  if (recurring_storage_object_index >= 0) {
                      recurring_billing_objects = (await $datasources.Invoices.ds_recurring_storage_billing_objects_dd.get({})).result;
                  };
                  $grid.headers.BillingObject.hidden = false;
              };
  
              if (accessorial_template_index >= 0) {
                  accessorial_templates = (await $datasources.Invoices.ds_billing_contract_accessorial_charges_templates_grid.get({})).result;
              };
  
              for (let row of $grid.rows) {
  
                  static_index = -1;
                  retainer_rate_index = -1;
                  billing_method_index = -1;
                  recurring_storage_object_index = -1;
                  billing_object_index = -1;
                  weight_method_index = -1;
                  volume_method_index = -1;
  
                  let static_quantity = 0;
                  let billing_rate = 0;
                  let details_for_row = details.filter(d => d.BillingContractLineId === row.entity.Id);
  
                  details_array = details_for_row.map(d => d.StrategyFieldName);
  
                  static_index = details_array.indexOf('StaticQuantity');
                  retainer_rate_index = details_array.indexOf('RetainerRate');
                  billing_method_index = details_array.indexOf('BillingMethodId');
                  recurring_storage_object_index = details_array.indexOf('RecurringStorageBillingObjectId');
                  billing_object_index = details_array.indexOf('BillingObjectId');
                  weight_method_index = details_array.indexOf('WeightMethodId');
                  volume_method_index = details_array.indexOf('VolumeMethodId');
                  accessorial_template_index = details_array.indexOf('BillingAccessorialChargesTemplateId');
  
                  if (billing_method_index >= 0) {
  
                      let billing_method_id = Number(details_for_row[billing_method_index].Value);
                      let billing_methods_row = billing_methods.filter(b => b.Id === billing_method_id);
  
                      if ($utils.isDefined(billing_methods_row)) {
                          row.cells.BillingMethod.displayControl.text = billing_methods_row[0]?.Name;
                      };
                  };
                  if (weight_method_index >= 0) {
  
                      let billing_object_id = Number(details_for_row[weight_method_index].Value)
  
                      if (billing_object_id === 1) {
                          row.cells.BillingMethod.displayControl.text = "Gross(Shipping) Weight";
                      } else if (billing_object_id === 2) {
                          row.cells.BillingMethod.displayControl.text = "Net Weight";
                      };
                  };
                  if (volume_method_index >= 0) {
  
                      let billing_object_id = Number(details_for_row[volume_method_index].Value)
  
                      if (billing_object_id === 1) {
                          row.cells.BillingMethod.displayControl.text = "Gross(Shipping) Volume";
                      } else if (billing_object_id === 2) {
                          row.cells.BillingMethod.displayControl.text = "Net Volume";
                      };
                  };
  
                  if (recurring_storage_object_index >= 0) {
  
                      let recurring_storage_object_id = Number(details_for_row[recurring_storage_object_index].Value);
                      let recurring_billing_objects_row = recurring_billing_objects.filter(b => b.Id === recurring_storage_object_id);
  
                      if ($utils.isDefined(recurring_billing_objects_row)) {
                          row.cells.BillingObject.displayControl.text = recurring_billing_objects_row[0]?.Name;
                      };
                  };
                  if (billing_object_index >= 0) {
  
                      let billing_object_id = Number(details_for_row[billing_object_index].Value)
  
                      if (billing_object_id === 1) {
                          row.cells.BillingObject.displayControl.text = "Lot";
                      } else if (billing_object_id === 2) {
                          row.cells.BillingObject.displayControl.text = "License Plate Lot";
                      } else if (billing_object_id === 3) {
                          row.cells.BillingObject.displayControl.text = "By Shipment";
                      };
                  };
                  if (accessorial_template_index >= 0) {
                      let accessorial_template_id = Number(details_for_row[accessorial_template_index].Value)
                      let accessorial_templates_row = accessorial_templates.filter(t => t.Id === accessorial_template_id);
  
                      if ($utils.isDefined(accessorial_templates_row)) {
                          row.cells.OperationCode.displayControl.text = accessorial_templates_row[0]?.LookupCode;
                      };
                  }
  
                  if (billing_aggregation_strategy_name.toUpperCase().includes('RETAINER')) {
  
                      if (static_index >= 0) {
                          static_quantity = Number(details_for_row[static_index].Value);
                      };
                      if (retainer_rate_index >= 0) {
                          billing_rate = Number(details_for_row[retainer_rate_index].Value);
                      };
  
                      row.cells.Rate.displayControl.text = (static_quantity * billing_rate).toString();
  
                  };
              };
          };
      };
  };
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $grid: FootPrintManager_billing_contract_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/28/2024
  
  const dialogResult = (await $shell.FootPrintManager.openbilling_contract_line_creation_formDialog({ billingContractId: $grid.inParams.contractId, strategyName: $grid.inParams.strategyName, statusId: $grid.inParams.statusId}));
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
