import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_locations_replenishments_gridComponent } from './FootPrintManager.locations_replenishments_grid.component';
import { FootPrintManager_replenishment_tasks_gridComponent } from './FootPrintManager.replenishment_tasks_grid.component';
import { FootPrintManager_replenishment_inventory_licenseplate_gridComponent } from './FootPrintManager.replenishment_inventory_licenseplate_grid.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { Locations_locations_dd_multiComponent } from './Locations.locations_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_locations_replenishments_gridComponent),
    forwardRef(() => FootPrintManager_replenishment_tasks_gridComponent),
    forwardRef(() => FootPrintManager_replenishment_inventory_licenseplate_gridComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => Locations_locations_dd_multiComponent),
  ],
  selector: 'FootPrintManager-replenishment_hub',
  templateUrl: './FootPrintManager.replenishment_hub.component.html'
})
export class FootPrintManager_replenishment_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    materials: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    locations: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    materials: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['materials'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Materials', false)
,
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouses', false)
,
    locations: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['locations'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Locations', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      replenishments: new TabItemModel(
        this.rootTabGroup, 
        'Replenishment', 
        ),
      replenishment_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Tasks', 
        ),
      inventory_by_lp: new TabItemModel(
        this.rootTabGroup, 
        'Current inventory', 
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_materialId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId;
    }
  
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_locationId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.locations.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId;
    }
  
    get $tabs_replenishments_locations_replenishments_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_replenishments_locations_replenishments_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_materialIds: number[];
    get $tabs_replenishment_tasks_replenishment_tasks_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_materialIds;
    }
  
    get $tabs_replenishment_tasks_replenishment_tasks_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_locationIds: number[];
    get $tabs_replenishment_tasks_replenishment_tasks_grid_inParams_locationIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.locations.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_locationIds, expr)) {
        this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_locationIds = expr;
      }
      return this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_locationIds;
    }
  
    get $tabs_replenishment_tasks_replenishment_tasks_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_warehouseIds: number[];
    get $tabs_replenishment_tasks_replenishment_tasks_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_replenishment_tasks_replenishment_tasks_grid_inParams_warehouseIds;
    }
  
    get $tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_warehouseIds: number[];
    get $tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_locationIds: number[];
    get $tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_locationIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.locations.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_locationIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_locationIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_lp_replenishment_inventory_licenseplate_grid_inParams_locationIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_replenishments', { read: FootPrintManager_locations_replenishments_gridComponent }) $tabs_replenishments: FootPrintManager_locations_replenishments_gridComponent;
      @ViewChild('$tabs_replenishment_tasks', { read: FootPrintManager_replenishment_tasks_gridComponent }) $tabs_replenishment_tasks: FootPrintManager_replenishment_tasks_gridComponent;
      @ViewChild('$tabs_inventory_by_lp', { read: FootPrintManager_replenishment_inventory_licenseplate_gridComponent }) $tabs_inventory_by_lp: FootPrintManager_replenishment_inventory_licenseplate_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  get $fields_materials_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_locations_selector_inParams_warehouseId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.warehouses.control.value?.length > 1 ? null : $hub.filters.warehouses.control.value;
    
    return expr;
  }



  get $fields_locations_selector_inParams_typeId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 3;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Replenishment hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view active replenishments.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.replenishments,
      this.tabs.replenishment_tasks,
      this.tabs.inventory_by_lp,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Replenishment Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['warehouses']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_replenishments') {
      if (!isNil(this.$tabs_replenishments) && !this.tabs.replenishments.hidden) {
        this.$tabs_replenishments.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_replenishment_tasks') {
      if (!isNil(this.$tabs_replenishment_tasks) && !this.tabs.replenishment_tasks.hidden) {
        this.$tabs_replenishment_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_lp') {
      if (!isNil(this.$tabs_inventory_by_lp) && !this.tabs.inventory_by_lp.hidden) {
        this.$tabs_inventory_by_lp.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_replenishment_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ((await $operations.FootPrintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized())) {
      let defaultWarehouse : number[] = [];
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
      defaultWarehouse.push(defaultWarehouseResult.warehouseId)
  
      $hub.filters.warehouses.control.value = $utils.isDefined(defaultWarehouse[0]) ? [defaultWarehouse[0]] : null;
  }
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_replenishment_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_replenishment_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $hub: FootPrintManager_replenishment_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.locations.control.value = null;
  }
  //#endregion private flows
}
